import angular from 'angular';

const Util = ($location) => {
  'ngInject';

  function guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  function inDevMode() {
    return !!$location.search().devMode;
  }

  return {
    guid,
    inDevMode,
  };
};

export default angular.module('CCAdaptor.App.Util', [])
  .factory('util', Util).name;
