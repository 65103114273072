import angular from 'angular';
import _ from 'lodash';

const chance = require('chance')();

class AuthService {
  constructor($http, $httpParamSerializer, $q, $log, $rootScope,
    $state, $uiRouterGlobals, $injector, agentFSM, Base64, IntegrationService,
    storagemanager, AgentInfoService, ApiService, CallService, StatusService, ErrorService,
    ConnectService) {
    'ngInject';

    this.listeners = [];
    this.manager = storagemanager;
    this.locals = {};
    this.watchedAttributes = [];

    this.$http = $http;
    this.$q = $q;
    this.$log = $log;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$uiRouterGlobals = $uiRouterGlobals;
    this.$injector = $injector;
    this.agentFSM = agentFSM;
    this.Base64 = Base64;
    this.ApiService = ApiService;
    this.httpParamSerializer = $httpParamSerializer;
    this.integrationService = IntegrationService;
    this.storagemanager = storagemanager;
    this.AgentInfoService = AgentInfoService;
    this.CallService = CallService;
    this.StatusService = StatusService;
    this.ConnectService = ConnectService;
    this.ErrorService = ErrorService;

    this.manager.watchers()
      .withObj(this.locals)
      .withId('auth')
      .buildWithAttributes((a) => {
        a.withBooleanKey('loggedIn').withDefault(false).build();
        a.withStringKey('timestamp').withDefault('').build();
        a.withStringKey('sst').withDefault(chance.guid()).build();
        a.withStringKey('ssoProvider').withDefault('').build();
        a.withStringKey('sessionToken').withDefault(null)
          .withCallBack((newValue) => {
            $http.defaults.headers.common.Authorization = newValue;
          }).build();
      });

    this.handleError = (path, response) => {
      if (!response.data) {
        $rootScope.$broadcast('event.notify', {
          action: 'error',
          tag: 'error.response',
          message: 'Unhandled error',
          details: [{
            debuggingInfo: 'action.error.network',
            description: 'An error has occurred.',
            name: path,
          }],
          response,
        });
      } else {
        $rootScope.$broadcast('event.notify', {
          action: 'error',
          tag: response.data.errorCode,
          message: response.data.errorDescription,
          details: response.data.errorDetails,
          response,
        });
      }
    };
  }

  get loggedIn() {
    return this.locals.loggedIn;
  }

  set loggedIn(flag) {
    this.locals.loggedIn = flag;
  }

  get timestamp() {
    return this.locals.timestamp;
  }

  set timestamp(time) {
    this.locals.timestamp = time;
  }

  get sst() {
    return this.locals.sst;
  }

  set sst(hash) {
    if (!_.isNil(hash) && hash.search('Basic') >= 0) {
      this.locals.sst = hash;
    } else {
      this.locals.sst = `Basic ${hash}`;
    }
  }

  get ssoProvider() {
    return this.locals.ssoProvider;
  }

  set ssoProvider(name) {
    this.locals.ssoProvider = name;
  }

  get sessionToken() {
    return this.locals.sessionToken;
  }

  set sessionToken(token) {
    if (!_.isNil(token) && token.search('BEARER') >= 0) {
      this.locals.sessionToken = token;
    } else {
      this.locals.sessionToken = `BEARER ${token}`;
    }
  }

  get userInfo() {
    return this.$rootScope.context.getCachedUser();
  }

  get accessToken() {
    return this.$rootScope.context.getCachedToken(window.IPSCAPE.adalConfig.clientId);
  }

  get isAuthenticated() {
    return this.userInfo && this.accessToken;
  }

  getToken() {
    return this.$http.defaults.headers.common.Authorization;
  }

  makeToken(username, password) {
    const hash = this.Base64.encode(`${username}:${password}`);
    this.$http.defaults.headers.common.Authorization = `Basic ${hash}`;
    this.sst = hash;
  }

  resetAuthHeader() {
    return new Promise((resolve, reject) => {
      try {
        if (!this.$http.defaults.headers.common.Authorization) {
          if (this.loggedIn && this.sessionToken) {
            if (this.$http.defaults) {
              this.$http.defaults.headers.common.Authorization = this.sessionToken;
              resolve();
            }
          }
          resolve();
        }
      } catch (error) {
        this.ErrorService.report('Error setting Auth header', { error });
        reject();
      }
    });
  }

  isLoggedIn() {
    return this.loggedIn;
  }

  login(username, password) {
    const dfd = this.$q.defer();
    const endpoint = '/oauth/token';
    // Create SST
    this.makeToken(username, password);
    const params = {
      apiKey: this.integrationService.apiKey,
      loginAppId: this.integrationService.loginAppId(),
      singleSessionToken: this.sst,
    };

    this.$http.post(this.integrationService.latestApiUri(endpoint),
      this.httpParamSerializer(params), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then((response) => {
      // Set token
      if (response.data.result) {
        this.sessionToken = response.data.result.access_token;
        this.timestamp = new Date().toString();
        this.loggedIn = true;
      }
      dfd.resolve(response);
    }).catch((response) => {
      this.handleError(endpoint, response);
      dfd.reject(response);
    });

    return dfd.promise;
  }

  logout() {
    const dfd = this.$q.defer();
    const endpoint = '/user/logout';
    const useSsoLogin = localStorage.getItem('useSsoLogin') === 'true';

    if (!this.loggedIn) {
      dfd.resolve();
      return dfd.promise;
    }

    this.$http.post(this.integrationService.latestApiUri(endpoint),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then((response) => {
      this.clearState();
      if (useSsoLogin) {
        localStorage.removeItem('useSsoLogin');
      }
      this.ConnectService.disconnect((data) => {
        this.$log.info('[WebConnect Disconnect]', data);
      });
      this.$state.transitionTo('login', this.$uiRouterGlobals.params, {
        reload: false,
        inherit: false,
        notify: true,
      });
      dfd.resolve(response);
    });

    return dfd.promise;
  }

  thirdPartyLogin() {
    return new Promise((resolve, reject) => {
      const token = sessionStorage.getItem('adal.idtoken');
      const session = sessionStorage.getItem('adal.session.state');
      const endpoint = '/user/loginthirdpartysso';
      const params = {
        apiKey: this.integrationService.apiKey,
        updateState: 1,
        destinationState: 'Login',
        loginAppId: this.integrationService.loginAppId(),
        ssoType: 'aadsso',
        sid: session,
      };

      this.$http.post(this.integrationService.latestApiUri(endpoint),
        this.httpParamSerializer(params), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Id-Token': token,
            Authorization: undefined,
          },
        }).then((response) => {
        this.$log.info('[SSO] thirdPartyLogin success');
        this.sessionToken = response.data.result.access_token;
        this.timestamp = new Date().toString();
        this.loggedIn = true;
        this.ssoProvider = (sessionStorage.getItem('adal.idtoken'))
          ? 'aadsso' : '';
        resolve(response);
      }).catch((error) => {
        this.handleError(endpoint, error);
        reject(Error(error));
      });
    });
  }

  closeError() {
    this.reset();
    this.$state.transitionTo('login', this.$uiRouterGlobals.params, {
      reload: false,
      inherit: false,
      notify: true,
    });
  }

  reset() {
    this.loggedIn = false;
    this.timestamp = '';
    this.sst = '';
    this.ssoProvider = '';
    delete this.$http.defaults.headers.common.Authorization;
    this.locals.sessionToken = null;
  }

  clearState() {
    this.reset();
    this.storagemanager.crop();
    this.$injector.get('SocketFactory').disconnect();
    this.$injector.get('AnalyticsService').reset();
    this.$injector.get('FeatureFlagService').reset();
    this.AgentInfoService.clear();
    this.CallService.clear();
    this.StatusService.clear();
    this.agentFSM.recreate();
    this.ApiService.clear();
    this.integrationService.reset();
  }
}

export default angular.module('CCAdaptor.App.AuthService', [])
  .service('AuthService', AuthService).name;
