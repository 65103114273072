/*
 * Javascripts
 * ========================================================================== */

import angular from 'angular';
import CallController from './controller/CallController';


import ServicesTab from './components/ServicesTab.component';
/*
 * Directives
 * ========================================================================== */

import CallAuxActionsDirective from './directives/actions/ips-call-auxactions.directive';
import CallActionsDirective from './directives/actions/ips-call-actions.directive';
import CallTaskLink from './directives/actions/ips-task-link.directive';
import Outbound from './directives/outbound/outbound.directive';
import CallPanel from './directives/panel/ips-call-panel.directive';
import CallInfoPanel from './directives/info/call-info-directive';
import CampaignInfoPanel from './directives/campaignInfo/campaignInfo.directive';
import Countdown from './directives/countdown/countdown.directive';
import RelatedChoice from './directives/panel/related-choice.directive';
import IPSNotes from './directives/notes/notes.directive';
import IPSAnnouncements from './directives/announcements/ipsAnnouncements.directive';

/*
 * Stylesheets
 * ========================================================================== */

import './stylesheets/call.scss';

/* ========================================================================== */

/**
 * Define call page module.
 * @param {String} moduleName
 * @param {Array} dependencies
 * @export Module name - name of this module.
 */
export default angular.module('CCAdaptor.App.CallController', [
  CallAuxActionsDirective,
  CallActionsDirective,
  CallTaskLink,
  Outbound,
  Countdown,
  CallPanel,
  CallInfoPanel,
  CampaignInfoPanel,
  RelatedChoice,
  IPSNotes,
  ServicesTab,
  IPSAnnouncements,
]).controller('CallController', CallController).name;
