import _ from 'lodash';

export default class CallController {
  constructor($rootScope, $scope, $state, $transition$, CallService, agentFSM) {
    'ngInject';

    $scope.callData = CallService.callData;
    $scope.callInfo = CallService.callInfo;
    $scope.isOutbound = agentFSM.isOnOutboundPreview();

    $rootScope.$watch(() => { return agentFSM.isOnOutboundPreview(); }, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        $scope.isOutbound = newVal;
      }
    });

    $rootScope.$on('event.system', (event, data) => {
      if (_.hasIn(data, 'action')) {
        if (data.action === 'call.outbound.preview') {
          $scope.isOutbound = true;
        }
      }
    });

    // We need to watch for changes in call info and keep scope updated
    $rootScope.$watch(() => { return CallService.callInfo; }, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        $scope.callInfo = newVal;
      }
    });

    if (!$scope.isOutbound && !$scope.callInfo) {
      if (!CallService.callData && !CallService.callInfo && (
        $rootScope.lastAgentCallInfo || $rootScope.lastAgentCallNotes ||
        $rootScope.lastAgentCallOutcome || $rootScope.lastAgentCallData)) {
        $scope.callData = $rootScope.lastAgentCallData;
        $scope.callInfo = $rootScope.lastAgentCallInfo;

        $rootScope.lastAgentCallData = null;
        $rootScope.lastAgentCallInfo = null;
      }
    }

    $scope.$on('event.system', (evt, data ) => {
      if (_.hasIn(data, 'action')) {
        if (data.action === 'agent.state.change') {
          if (data.params.to === 'onPhoneAvailable' || data.params.to === 'onPhonePaused') {
            $state.transitionTo('toolbar', $transition$.params(), {
              reload: false,
              inherit: false,
              notify: true,
            });
          }
        }
      }
    });
  }
}
