import angular from 'angular';

const DialPadInput = () => {

  return {
    restrict: 'EA',
    template: '<input required type="tel" id="{{id}}" class="{{class}}" name="{{inputName}}" placeholder="{{placeholder}}" ng-model="inputValue" />',
    scope: {
      idVal: '@',
      classVal: '@',
      onChange: '&',
      placeholder: '@',
      inputValue: '=',
      inputName: '='
    },
    link: function(scope) {
      scope.$watch('inputValue', function(newValue, oldValue) {
        var s = newValue ? newValue.trim() : newValue;
        if (!s || s.length === 0) return;
        else if (!s.match(/^\+$|^\+?[0-9#\*\(\)\- ]+$/)) {
          scope.inputValue = oldValue;
        }
        else if (scope.onChange) {
          scope.onChange();
        }
      });
    }
  };
};

export default angular.module("CCadaptor.App.DialPadInput", [])
  .directive('dialpadInput', DialPadInput).name;
