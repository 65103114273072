import angular from 'angular';

class PhoneService {
  constructor(storagemanager) {
    'ngInject';

    this.states = {
      disabled: 'disabled',
      active: 'active',
      hold: 'hold',
    };

    // FSM like structure for state changes
    this.transitions = [
      {
        name: 'activate',
        from: [this.states.disabled, this.states.hold],
        to: this.states.active,
      },
      {
        name: 'hold',
        from: [this.states.disabled, this.states.active],
        to: this.states.hold,
      },
      {
        name: 'disable',
        from: [this.states.hold, this.states.active],
        to: this.states.disabled,
      },
    ];

    this.listeners = [];
    this.manager = storagemanager;
    this.locals = {};
    this.watchedAttributes = [];
    this.manager.watchers()
      .withObj(this.locals)
      .withId('phone')
      .buildWithAttributes((a) => {
        this.watchedAttributes.push(
          a.withStringKey('lineStateOne')
            .withDefault('disabled')
            .build(),
        );
        this.watchedAttributes.push(
          a.withStringKey('lineStateTwo')
            .withDefault('disabled')
            .build(),
        );
        this.watchedAttributes.push(
          a.withStringKey('activeLine')
            .withDefault(1)
            .build(),
        );
        this.watchedAttributes.push(
          a.withBooleanKey('attendedTransfer')
            .withDefault(false)
            .build(),
        );
        this.watchedAttributes.push(
          a.withBooleanKey('conferenceCall')
            .withDefault(false)
            .build(),
        );
        this.watchedAttributes.push(
          a.withBooleanKey('transferActionsDisabled')
            .withDefault(false)
            .build(),
        );
        this.watchedAttributes.push(
          a.withBooleanKey('allowTransferInvalidPhone')
            .withDefault(false)
            .build(),
        );
      });

    this.manager.setSafeList('phone.allowTransferInvalidPhone');
  }

  get lineStateOne() {
    return this.locals.lineStateOne;
  }

  set lineStateOne(state) {
    this.locals.lineStateOne = state;
  }

  get lineStateTwo() {
    return this.locals.lineStateTwo;
  }

  set lineStateTwo(state) {
    this.locals.lineStateTwo = state;
  }

  get activeLine() {
    return parseInt(this.locals.activeLine, 10);
  }

  set activeLine(line) {
    this.locals.activeLine = line;
  }

  get attendedTransfer() {
    return this.locals.attendedTransfer;
  }

  set attendedTransfer(state) {
    this.locals.attendedTransfer = state;
  }

  get conferenceCall() {
    return this.locals.conferenceCall;
  }

  set conferenceCall(state) {
    this.locals.conferenceCall = state;
  }

  get transferActionsDisabled() {
    return this.locals.transferActionsDisabled;
  }

  set transferActionsDisabled(bool) {
    this.locals.transferActionsDisabled = bool;
  }

  get allowTransferInvalidPhone() {
    return this.locals.allowTransferInvalidPhone;
  }

  set allowTransferInvalidPhone(bool) {
    this.locals.allowTransferInvalidPhone = bool;
  }

  // Mutations for Line State Change
  TRANSIT(line, name) {
    try {
      const currentState = (line === 1) ? this.lineStateOne : this.lineStateTwo;
      this.transitions.forEach((transition) => {
        if (transition.name === name) {
          const fromArr = transition.from;
          fromArr.forEach((from) => {
            if (currentState === from) {
              if (line === 1) {
                this.locals.lineStateOne = transition.to;
              } else {
                this.locals.lineStateTwo = transition.to;
              }
              if (transition.to === this.states.active) {
                // Set Active Line
                this.activeLine = line;
              }
            }
          });
        }
      });
    } catch (error) {
      throw new Error(
        `Phone Line Transition Error: ${error}`,
      );
    }
  }

  // Mutations for Attended Transfer
  ATTENDED(action) {
    try {
      // Validate action
      const actions = ['start', 'stop'];
      if (actions.indexOf(action) === -1) {
        return;
      }

      if (action === 'start') {
        this.attendedTransfer = true;
      } else {
        this.attendedTransfer = false;
      }
    } catch (error) {
      throw new Error(
        `Attended Transfer Error: ${error}`,
      );
    }
  }

  // Mutations for Conference Call
  CONFERENCE(action) {
    try {
      // Validate action
      const actions = ['start', 'stop'];
      if (actions.indexOf(action) === -1) {
        return;
      }

      if (action === 'start') {
        this.conferenceCall = true;
      } else {
        this.conferenceCall = false;
      }
    } catch (error) {
      throw new Error(
        `Conference Call Error: ${error}`,
      );
    }
  }

  cancelTransferStateToActiveLine1Call() {
    this.TRANSIT(1, 'activate');
    this.TRANSIT(2, 'disable');
    this.ATTENDED('stop');
    this.transferActionsDisabled = false;
  }

  reset() {
    this.TRANSIT(1, 'disable');
    this.TRANSIT(2, 'disable');
    this.activeLine = 1;
    this.ATTENDED('stop');
    this.CONFERENCE('stop');
    this.transferActionsDisabled = false;
  }
}

export default angular.module('CCAdaptor.App.PhoneService', [])
  .service('PhoneService', PhoneService).name;
