import _ from 'lodash';

export default class LocalStorageOps {
  constructor(id, key) {
    this._id = id;
    this._key = key;
  }

  get booleanVal() {
    return 'true' === this.stringVal;
  }

  set booleanVal(val) {
    if (_.isBoolean(val)) {
      this.stringVal = val ? 'true' : 'false';
    } else {
      this.removeVal();
    }
  }

  get isBooleanVal() {
    return ['true', 'false'].indexOf(this.stringVal) >= 0;
  }

  get isObjectVal() {
    return this.isStringVal && this.stringVal.length > 0 && ['[','{'].indexOf(this.stringVal.charAt(0)) >= 0;
  }

  get isStringVal() {
    return _.isString(this.stringVal) && _.trim(this.stringVal) !== '';
  }

  get objectVal() {
    if (this.isObjectVal) {
      return JSON.parse(this.stringVal);
    }
    return {};
  }

  set objectVal(val) {
    if (_.isObject(val)) {
      let json = JSON.stringify(val);
      this.stringVal = json;
    } else {
      this.removeVal();
    }
  }

  removeVal() {
    window.localStorage.removeItem(this._id + '.' + this._key);
  }

  get stringVal() {
    return window.localStorage.getItem(this._id + '.' + this._key);
  }

  set stringVal(val) {
    if (_.isString(val)) {
      window.localStorage.setItem(this._id + '.' + this._key, val);
    }
    else if(_.isNumber(val)) {
      window.localStorage.setItem(this._id + '.' + this._key, `${val}`);
    }
    else if (!val) {
      this.removeVal();
    }
    else {
      console.error('attempted to store invalid value to localStorage', this._key, val);
      this.removeVal();
    }
  }
}
