import angular from 'angular';
import _ from 'lodash';

class AgentInfoService {
  constructor($http, $rootScope, $q, IntegrationService, ErrorService, storagemanager) {
    'ngInject';

    this.$http = $http;
    this.integrationService = IntegrationService;
    this.$rootScope = $rootScope;
    this.ErrorService = ErrorService;
    this.$q = $q;
    this.inProgress = undefined;

    this.locals = {};
    storagemanager.watchers()
      .withObj(this.locals)
      .withId('agentinfo')
      .buildWithAttributes((a) => {
        a.withObjectKey('details').withDefault({}).build();
        a.withObjectKey('integration').withDefault({}).build();
        a.withObjectKey('organisation').withDefault({}).build();
        a.withObjectKey('pauseReasons').withDefault([]).build();
      });
  }

  clear() {
    this.details = {};
    this.integration = {};
    this.organisationDetails = {};
    this.pauseReasons = []; // fixme: need to be reloaded after clearing
  }

  loadDetails(path, key, responseKey, filter) {
    const that = this;
    const dfd = that.$q.defer();
    this.inProgress = path;
    that.$http.get(that.integrationService.latestApiUri(path)).then((response) => {
      if (response.data.resultCode === 'success') {
        const data = _.get(response.data, responseKey);
        _.set(that, key, filter ? filter(data) : data);
        dfd.resolve(response);
      } else {
        this.ErrorService.handleError(path, response);
      }
      this.inProgress = undefined;
    }).catch((response) => {
      this.ErrorService.handleError(path, response);
      this.inProgress = undefined;
    });

    return dfd.promise;
  }

  fetchDetails() {
    const endpoint = '/agent/readagentdetails';
    if (this.inProgress === endpoint) return false;

    return this.loadDetails(endpoint, 'details', 'result');
  }

  fetchOrganisationDetails() {
    const endpoint = '/organisation/readorgdetails';
    if (this.inProgress === endpoint) return false;
    return this.loadDetails(endpoint, 'organisationDetails', 'result');
  }

  fetchPauseReasons() {
    const endpoint = '/organisation/readpausereasonslist';
    if (this.inProgress === endpoint) return false;
    return this.loadDetails(endpoint, 'pauseReasons', 'result', AgentInfoService.filterPauseReasons);
  }

  static filterPauseReasons(reasons) {
    const reserved = ['auto-pause', 'agent call', 'manual call'];
    return reasons.filter((r) => {
      return reserved.indexOf(r.pauseReasonTitle.toLowerCase()) < 0;
    });
  }

  fetchAll() {
    return Promise.all([
      this.fetchPauseReasons(),
      this.fetchDetails(),
      this.fetchOrganisationDetails()]);
  }

  get agentId() {
    return _.get(this.details, 'agentId', '');
  }

  get agentFirstName() {
    const firstName = _.get(this.details, 'firstName', '');
    return firstName.trim();
  }

  get agentLastName() {
    const lastName = _.get(this.details, 'lastName', '');
    return lastName.trim();
  }

  get agentFullName() {
    const firstName = _.get(this.details, 'firstName', '');
    const lastName = _.get(this.details, 'lastName', '');
    const fullName = `${firstName} ${lastName}`;
    return fullName.trim();
  }

  get agentUserName() {
    const userName = _.get(this.details, 'userName', '');
    return userName.trim();
  }

  get agentTimezone() {
    return _.get(this.details, 'timezone', '');
  }

  get details() {
    return this.locals.details;
  }

  set details(details) {
    this.locals.details = details;
  }

  get connectionPersistence() {
    return this.locals.details.persistentConnection;
  }

  set connectionPersistence(bool) {
    this.locals.details.persistentConnection = bool;
  }

  get connectionMode() {
    return this.locals.details.connectionMode;
  }

  set connectionMode(mode) {
    this.locals.details.connectionMode = mode;
  }

  get remotePhone() {
    return this.locals.details.remotePhone;
  }

  set remotePhone(num) {
    this.locals.details.remotePhone = num;
  }

  get integration() {
    return this.locals.integration;
  }

  set integration(details) {
    this.locals.integration = details;
  }

  get organisationDetails() {
    return this.locals.organisation;
  }

  set organisationDetails(details) {
    this.locals.organisation = details;
  }

  get pauseReasons() {
    return this.locals.pauseReasons;
  }

  set pauseReasons(list) {
    this.locals.pauseReasons = list;
  }
}

export default angular.module('CCAdaptor.App.AgentInfoService', [])
  .service('AgentInfoService', AgentInfoService).name;
