import angular from 'angular';

class SessionService {
  constructor($log, storagemanager) {
    'ngInject';

    this.manager = storagemanager;
    this.locals = {};
    this.manager.watchers()
      .withObj(this.locals)
      .withId('SessionService')
      .buildWithAttributes((a) => {
        a.withObjectKey('config').withDefault({
          relatedObjectViewer: true,
          tabViewer: true,
          startIndex: 0,
          tabViewerTitle: 'Call',
          notesViewer: true,
          campaignViewer: true,
        }).build();
      });

    this.manager.setSafeList('SessionService.config');
  }

  get config() {
    return this.locals.config;
  }

  set config(obj) {
    this.locals.config = obj;
  }

  addToConfig(param) {
    const cfg = this.config;
    const keys = Object.keys(param);
    keys.forEach((key) => {
      cfg[key] = param[key];
    });

    this.locals.config = cfg;
  }
}

export default angular.module('CCAdaptor.App.Session', [])
  .service('SessionService', SessionService).name;
