import angular from 'angular';

const template = require('./outbound.directive.html');

const ipsOutbound = () => {
  'ngInject';

  class OutboundController {
    constructor($log, $rootScope, $scope, $injector, agent, CallService, agentFSM,
      PhoneService, SessionService) {
      'ngInject';

      const outbound = () => { return CallService.outboundInfo; };
      $scope.isDirty = () => { return CallService.previewCalled; };
      $scope.timer = () => { return CallService.autoPreview; };
      $scope.config = () => { return SessionService.config; };
      $scope.leadNumbers = () => { return CallService.leadNumbers; };
      $scope.onOutboundCall = () => { return agentFSM.isOnOutboundCall(); };

      $scope.outboundCall = (leadId) => {
        $rootScope.$broadcast('event.user', {
          action: 'outbound.preview.dialling',
          id: leadId,
        });
        const interactionId = outbound().agentInteractionId;
        CallService.autoPreviewTime = 0;

        agent.calls.previewCall(interactionId, leadId).then((data) => {
          // Cancel any running autowrap and reset
          CallService.resetAutowrap();
          agentFSM.outboundCall(data);
          // CallService.selectedOutcome = (!_.isNil(CallService.selectedOutcome))
          //   ? CallService.selectedOutcome : CallService.outcomes[0];
          CallService.contacts = [];
          CallService.selectedContact = {};
          CallService.items = [];
          CallService.selectedItem = {};
          CallService.selectedOutcome = CallService.outcomes[0];
          CallService.callback = {};
          CallService.callNotes = null;
          CallService.callInfo = data.result;
          CallService.callInfo.stateBeforeCall = agent.state();
          $scope.manualCallPhoneNumber = undefined;
          PhoneService.TRANSIT(1, 'activate');
          CallService.previewCalled = true;
        });
      };
    }
  }
  return {
    restrict: 'EA',
    scope: true,
    controller: OutboundController,
    controllerAs: 'Outbound',
    template,
  };
};

export default angular.module('CCAdaptor.App.Outbound', [])
  .directive('ipsOutbound', ipsOutbound).name;
