import angular from 'angular';
import _ from 'lodash';

const StateMachine = require('../../../../../node_modules/javascript-state-machine/lib/state-machine.js');

const agentFSM = ($log, $rootScope, integrationFactory, storagemanager, AgentStates, PhoneService,
  StatusService) => {
  'ngInject';

  // states
  const offPhone = 'offPhone';
  const onPhonePaused = 'onPhonePaused';
  const onPhonePausedOnLine1UnallocManCallDialling = 'onPhonePausedOnLine1UnallocManCallDialling';
  const onPhonePausedOnLine1UnallocManCall = 'onPhonePausedOnLine1UnallocManCall';
  const onPhonePausedOnLine1UnallocManCallFailed = 'onPhonePausedOnLine1UnallocManCallFailed';
  const onPhoneAvailable = 'onPhoneAvailable';
  const onPhoneAvailableOnLine1UnallocManCallDialling = 'onPhoneAvailableOnLine1UnallocManCallDialling';
  const onPhoneAvailableOnLine1UnallocManCall = 'onPhoneAvailableOnLine1UnallocManCall';
  const onPhoneAvailableOnLine1UnallocManCallFailed = 'onPhoneAvailableOnLine1UnallocManCallFailed';
  const onPhoneLine1ManCallDialling = 'onPhoneLine1ManCallDialling';
  const onPhoneLine1ManCallFailed = 'onPhoneLine1ManCallFailed';
  const onPhoneLine1ManCall = 'onPhoneLine1ManCall';
  const onPhoneLine1ManCallHold = 'onPhoneLine1ManCallHold';
  const onPhoneLine1InboundCall = 'onPhoneLine1InboundCall';
  const onPhoneLine1InboundHold = 'onPhoneLine1InboundHold';
  const onPhoneWrapping = 'onPhoneWrapping';
  const onManualPreview = 'onManualPreview';
  const logoutStates = [offPhone, onPhonePaused];

  const onOutboundPreview = 'onOutboundPreview';
  const onPhoneLine1OutboundCallDialling = 'onPhoneLine1OutboundCallDialling';
  const onPhoneLine1OutboundCallFailed = 'onPhoneLine1OutboundCallFailed';
  const onPhoneLine1OutboundCall = 'onPhoneLine1OutboundCall';
  const onPhoneLine1OutboundHold = 'onPhoneLine1OutboundHold';
  const onOutboundWrapping = 'onOutboundWrapping';

  // Setup watchers for localStorage
  const locals = {};
  const watchedAttributes = [];
  let fsm;

  const createStateMachine = (agentState) => {
    storagemanager.watchers()
      .withObj(locals)
      .withId('agent.fsm')
      .buildWithAttributes((attrInitialiser) => {
        watchedAttributes.push(
          attrInitialiser.withStringKey('currentAgentState')
            .withDefault(agentState || offPhone).build(),
        );
        watchedAttributes.push(
          attrInitialiser.withStringKey('previousAgentState')
            .withDefault(offPhone).build(),
        );
        watchedAttributes.push(
          attrInitialiser.withStringKey('currentStateDt')
            .withDefault(new Date().getTime().toString()).build(),
        );
        watchedAttributes.push(
          attrInitialiser.withStringKey('previousStateDt')
            .withDefault(new Date().getTime().toString()).build(),
        );
      });

    // finite state machine
    fsm = new StateMachine({
      init: locals.currentAgentState,
      transitions: [
        {
          name: 'connect',
          from: [offPhone],
          to: onPhonePaused,
        },
        {
          name: 'disconnect',
          from: [onPhonePaused],
          to: offPhone,
        },
        {
          name: 'pause',
          from: [onPhoneAvailable, onManualPreview],
          to: onPhonePaused,
        },
        {
          name: 'unpause',
          from: [onPhonePaused, onManualPreview],
          to: onPhoneAvailable,
        },
        {
          name: 'manualCall',
          from: [onPhonePaused, onPhoneAvailable, onManualPreview],
          to: onPhoneLine1ManCallDialling,
        },
        {
          name: 'manualCallPickup',
          from: onPhoneLine1ManCallDialling,
          to: onPhoneLine1ManCall,
        },
        {
          name: 'unallocatedManualCallFromPaused',
          from: [onPhonePaused, onManualPreview],
          to: onPhonePausedOnLine1UnallocManCallDialling,
        },
        {
          name: 'unallocatedManualCallFromAvailable',
          from: [onPhoneAvailable, onManualPreview],
          to: onPhoneAvailableOnLine1UnallocManCallDialling,
        },
        {
          name: 'hangupToPaused',
          from: [
            onPhonePausedOnLine1UnallocManCallDialling,
            onPhonePausedOnLine1UnallocManCall,
            onPhonePausedOnLine1UnallocManCallFailed,
          ],
          to: onPhonePaused,
        },
        {
          name: 'hangupToAvailable',
          from: [
            onPhoneAvailableOnLine1UnallocManCallDialling,
            onPhoneAvailableOnLine1UnallocManCall,
            onPhoneAvailableOnLine1UnallocManCallFailed,
          ],
          to: onPhoneAvailable,
        },
        {
          name: 'pickupFailedFromAvailable',
          from: [onPhoneAvailableOnLine1UnallocManCallDialling],
          to: onPhoneAvailableOnLine1UnallocManCallFailed,
        },
        {
          name: 'pickupFailedFromPaused',
          from: [onPhonePausedOnLine1UnallocManCallDialling],
          to: onPhonePausedOnLine1UnallocManCallFailed,
        },
        {
          name: 'manualCallPickupFromAvailable',
          from: onPhoneAvailableOnLine1UnallocManCallDialling,
          to: onPhoneAvailableOnLine1UnallocManCall,
        },
        {
          name: 'manualCallPickupFromPaused',
          from: onPhonePausedOnLine1UnallocManCallDialling,
          to: onPhonePausedOnLine1UnallocManCall,
        },
        {
          name: 'hold',
          from: onPhoneLine1ManCall,
          to: onPhoneLine1ManCallHold,
        },
        {
          name: 'unhold',
          from: onPhoneLine1ManCallHold,
          to: onPhoneLine1ManCall,
        },
        {
          name: 'pickupFailed',
          from: onPhoneLine1ManCallDialling,
          to: onPhoneLine1ManCallFailed,
        },
        {
          name: 'hangupFromFailedPickup',
          from: [onPhoneLine1ManCallFailed],
          to: onPhoneWrapping,
        },
        {
          name: 'inboundCallAllocated',
          from: [onPhoneAvailable, onPhonePaused],
          to: onPhoneLine1InboundCall,
        },
        {
          name: 'hold',
          from: onPhoneLine1InboundCall,
          to: onPhoneLine1InboundHold,
        },
        {
          name: 'unhold',
          from: onPhoneLine1InboundHold,
          to: onPhoneLine1InboundCall,
        },
        {
          // its possible for a customer to hangup while on hold during a call
          name: 'hangup',
          from: [
            onPhoneLine1ManCallDialling,
            onPhoneLine1InboundCall,
            onPhoneLine1ManCall,
            onPhoneLine1InboundHold,
            onPhoneLine1ManCallHold,
          ],
          to: onPhoneWrapping,
        },
        {
          name: 'transferComplete',
          from: [
            onPhoneLine1InboundCall,
            onPhoneLine1ManCall,
          ],
          to: onPhoneWrapping,
        },
        {
          name: 'wrapPause',
          from: [
            onPhoneLine1InboundCall,
            onPhoneLine1ManCall,
            onPhoneLine1OutboundCall,
            onOutboundPreview,
            onOutboundWrapping,
            onPhoneWrapping,
          ],
          to: onPhonePaused,
        },
        {
          name: 'wrapReady',
          from: [
            onPhoneLine1InboundCall,
            onPhoneLine1ManCall,
            onPhoneLine1OutboundCall,
            onOutboundPreview,
            onOutboundWrapping,
            onPhoneWrapping,
          ],
          to: onPhoneAvailable,
        },
        {
          name: 'manualPreview',
          from: [
            onPhonePaused,
            onPhoneAvailable,
          ],
          to: onManualPreview,
        },
        {
          name: 'outboundPreview',
          from: [onPhoneAvailable, onPhonePaused],
          to: onOutboundPreview,
        },
        {
          name: 'outboundCall',
          from: onOutboundPreview,
          to: onPhoneLine1OutboundCallDialling,
        },
        {
          name: 'pickupFailed',
          from: onPhoneLine1OutboundCallDialling,
          to: onPhoneLine1OutboundCallFailed,
        },
        {
          name: 'hangupFromFailedPickup',
          from: [onPhoneLine1OutboundCallFailed],
          to: onOutboundWrapping,
        },
        {
          name: 'outboundPickup',
          from: onPhoneLine1OutboundCallDialling,
          to: onPhoneLine1OutboundCall,
        },
        {
          name: 'hold',
          from: onPhoneLine1OutboundCall,
          to: onPhoneLine1OutboundHold,
        },
        {
          name: 'unhold',
          from: onPhoneLine1OutboundHold,
          to: onPhoneLine1OutboundCall,
        },
        {
          name: 'outboundHangup',
          from: [
            onPhoneLine1OutboundCall,
            onPhoneLine1OutboundCallDialling,
            onPhoneLine1OutboundHold,
          ],
          to: onOutboundWrapping,
        },
        {
          name: 'outboundWrap',
          from: onOutboundWrapping,
          to: onOutboundPreview,
        },
        {
          name: 'outboundWrapToAvailable',
          from: onOutboundWrapping,
          to: onPhoneAvailable,
        },
        {
          name: 'outboundWrapToPaused',
          from: onOutboundWrapping,
          to: onPhonePaused,
        },
        {
          name: 'hangup',
          from: [
            onPhoneLine1OutboundCall,
            onPhoneLine1OutboundCallDialling,
            onPhoneLine1OutboundHold,
          ],
          to: onOutboundWrapping,
        },
        {
          name: 'transferComplete',
          from: onPhoneLine1OutboundCall,
          to: onOutboundWrapping,
        },
      ],
      methods: {
        onBeforeTransition: (lifecycle) => {
          $log.info(`[FSM] transition: { transition: ${lifecycle.transition} from: ${lifecycle.from} to: ${lifecycle.to} }`);
        },
        onEnterState: (lifecycle, msg) => {
          if (lifecycle.to !== locals.currentAgentState) {
            locals.currentAgentState = lifecycle.to;
            locals.previousAgentState = (lifecycle.from !== 'none') ? lifecycle.from : locals.previousAgentState;
          }

          const params = {
            event: lifecycle.transition,
            from: lifecycle.from,
            to: lifecycle.to,
            msg,
          };

          if (lifecycle.transition === 'init') {
            integrationFactory.init();
          }

          if (lifecycle.from !== 'none') {
            // Toggle between hold/unhold a call resumes the total call time
            if (lifecycle.from === 'onPhoneLine1ManCallHold' || lifecycle.from === 'onPhoneLine1InboundHold') {
              locals.currentStateDt = locals.previousStateDt;
            } else {
              locals.previousStateDt = _.get(locals, 'currentStateDt', new Date().getTime().toString());
              locals.currentStateDt = new Date().getTime().toString();
              StatusService.currentStateDt = locals.currentStateDt;
            }
          }

          $rootScope.$broadcast('event.system', {
            action: 'agent.state.change',
            params,
          });
        },
        onEnterOnPhoneAvailable: (lifecycle, msg) => {
          if (StatusService.status.label !== 'available') {
            StatusService.status = { label: 'available', id: 0 };
          }
          integrationFactory.onEnterAvailable(msg);
        },
        onEnterOnPhonePaused: (lifecycle, msg) => {
          integrationFactory.onEnterPaused(msg);
          if (lifecycle.from === offPhone) {
            $rootScope.$broadcast('event.system', {
              action: 'agent.voice.connect',
              msg,
            });
          }
        },
        onLeaveOnPhoneAvailable: () => {
          integrationFactory.onLeaveAvailable();
        },
        onEnterOnPhoneWrapping: (lifecycle, msg) => {
          integrationFactory.onHangup(msg);
          $rootScope.$broadcast('event.system', {
            action: 'phone.wrapping',
            from: lifecycle.from,
            to: lifecycle.to,
            msg,
          });
        },
        onAfterInboundCallAllocated: (lifecycle, msg) => {
          $rootScope.$broadcast('event.system', {
            action: 'call.allocated',
            msg,
          });
        },
        onPickupFailed: (lifecycle, msg) => {
          $rootScope.$broadcast('event.notify', {
            action: 'info',
            tag: 'call.pickup.failed',
            message: 'The call has failed.',
            disableHangupWarningActive: false,
          });
        },
        onOutboundPreview: (lifecycle, msg) => {
          $rootScope.$broadcast('event.system', {
            action: 'call.outbound.preview',
            msg,
          });
        },
        onBeforeOutboundPickup: (lifecycle, msg) => {
          $rootScope.$broadcast('event.system', {
            action: 'call.outbound.pickup',
            msg,
          });
        },
        onAfterManualCallPickup: (lifecycle, msg) => {
          $rootScope.$broadcast('event.system', {
            action: 'call.manual.pickup',
            msg,
          });
        },
        onAfterManualCallPickupFromAvailable: (lifecycle, msg) => {
          $rootScope.$broadcast('event.system', {
            action: 'call.manual.pickup',
            msg,
          });
        },
        onAfterManualCallPickupFromPaused: (lifecycle, msg) => {
          $rootScope.$broadcast('event.system', {
            action: 'call.manual.pickup',
            msg,
          });
        },
        onHold: (lifecycle) => {
          PhoneService.TRANSIT(1, 'hold');
        },
        onUnhold: (lifecycle) => {
          PhoneService.TRANSIT(1, 'activate');
        },
        onHangup: () => {
          PhoneService.TRANSIT(1, 'disable');
        },
        onTransferComplete: () => {
          PhoneService.TRANSIT(1, 'disable');
        },
        onWrapPause: (lifecycle, msg) => {
          integrationFactory.onWrap(msg);
        },
        onWrapReady: (lifecycle, msg) => {
          integrationFactory.onWrap(msg);
        },
        onInvalidTransition: (lifecycle) => {
          throw new Error(`[FSM] Transition not allowed from that state: ${JSON.stringify(lifecycle)}`);
        },
      },
    });
  };

  const state = () => {
    return fsm.state;
  };

  const previousState = () => {
    return locals.previousAgentState;
  };

  const time = () => {
    return locals.currentStateDt;
  };

  const amI = (name) => {
    return () => {
      return state() === name;
    };
  };

  const event = (name) => {
    return (param) => {
      return fsm[name](param);
    };
  };

  const isStateIn = (list) => {
    return list.indexOf(fsm.state) >= 0;
  };

  const resave = () => {
    watchedAttributes.forEach((a) => { return a.save(); });
  };

  const readableState = () => {
    // For Conference Psuedo state
    if (_.hasIn(PhoneService, 'conferenceCall')) {
      if (isStateIn([onPhoneLine1InboundCall, onPhoneLine1OutboundCall, onPhoneLine1ManCall])) {
        if (PhoneService.conferenceCall) return 'In Conference';
      }
    }

    switch (fsm.state) {
      case offPhone:
        return AgentStates.OFF_PHONE.label;
      case onPhonePaused:
        return AgentStates.PAUSED.label;
      case onPhoneAvailable:
        return AgentStates.READY.label;
      case onPhoneLine1ManCallDialling:
      case onPhoneAvailableOnLine1UnallocManCallDialling:
      case onPhonePausedOnLine1UnallocManCallDialling:
      case onPhoneLine1OutboundCallDialling:
        return AgentStates.DIALLING.label;
      case onPhoneLine1InboundCall:
      case onPhoneAvailableOnLine1UnallocManCall:
      case onPhonePausedOnLine1UnallocManCall:
        return AgentStates.ON_CALL.label;
      case onPhoneLine1ManCallFailed:
      case onPhoneAvailableOnLine1UnallocManCallFailed:
      case onPhonePausedOnLine1UnallocManCallFailed:
      case onPhoneLine1OutboundCallFailed:
        return AgentStates.CALL_FAILED.label;
      case onPhoneLine1ManCall:
        return AgentStates.MANUAL_CALL.label;
      case onPhoneLine1ManCallHold:
      case onPhoneLine1InboundHold:
      case onPhoneLine1OutboundHold:
        return AgentStates.HOLD.label;
      case onPhoneWrapping:
        return AgentStates.WRAP.label;
      case onManualPreview:
        return AgentStates.MANUAL_PREVIEW.label;
      case onOutboundPreview:
        return AgentStates.OUTBOUND_PREVIEW.label;
      case onPhoneLine1OutboundCall:
        return AgentStates.OUTBOUND_CALL.label;
      case onOutboundWrapping:
        return AgentStates.OUTBOUND_WRAP.label;
      default:
        return false;
    }
  };

  const canLogout = () => {
    return logoutStates.indexOf(fsm.state) >= 0;
  };

  const canEvents = (list) => {
    return () => {
      for (let i = 0; i < list.length; i += 1) {
        if (fsm.can(list[i])) {
          return true;
        }
      }
      return false;
    };
  };

  const tryEvents = (list) => {
    return (msg) => {
      for (let i = 0; i < list.length; i += 1) {
        if (fsm.can(list[i])) {
          event(list[i])(msg);
          return;
        }
      }
    };
  };

  createStateMachine();

  return {
    // events
    connect: event('connect'),
    disconnect: event('disconnect'),
    inboundCallAllocated: (msg) => {
      event('inboundCallAllocated')(msg);
      integrationFactory.onInboundCall(msg);
    },
    outboundPreview: (msg) => {
      event('outboundPreview')(msg);
      integrationFactory.onOutboundPreview(msg);
    },
    outboundCall: (msg) => {
      event('outboundCall')(msg);
    },
    outboundCallPickup: (msg) => {
      event('outboundPickup')(msg);
      integrationFactory.onOutboundCall(msg);
    },
    manualCall: (msg) => {
      event('manualCall')(msg);
      integrationFactory.onManCall(msg);
    },
    pause: event('pause'),
    unpause: event('unpause'),
    wrapPause: event('wrapPause'),
    wrapReady: event('wrapReady'),
    wrapOutboundPreview: (msg) => {
      event('outboundWrap')(msg);
      integrationFactory.onWrap(msg);
    },
    manualPreview: event('manualPreview'),
    hold: event('hold'),
    unhold: event('unhold'),
    transferComplete: event('transferComplete'),
    canHangUp: canEvents(['hangup', 'hangupFromFailedPickup', 'hangupToAvailable', 'hangupToPaused', 'outboundHangup']),
    hangup: tryEvents(['hangup', 'hangupFromFailedPickup', 'hangupToAvailable', 'hangupToPaused', 'outboundHangup']),
    hangupDestinationAgentState: () => {
      if (fsm.can('hangup')) return AgentStates.WRAP;
      else if (fsm.can('hangupFromFailedPickup')) return AgentStates.WRAP;
      else if (fsm.can('hangupToAvailable')) return AgentStates.READY;
      else if (fsm.can('hangupToPaused')) return AgentStates.PAUSED;
      else if (fsm.can('outboundHangup')) return AgentStates.OUTBOUND_WRAP;
      return null;
    },
    pickupFailed: tryEvents(['pickupFailed', 'pickupFailedFromAvailable', 'pickupFailedFromPaused']),
    manualCallPickup: tryEvents(['manualCallPickup', 'manualCallPickupFromAvailable', 'manualCallPickupFromPaused']),
    unallocatedManualCall: tryEvents(['unallocatedManualCallFromPaused', 'unallocatedManualCallFromAvailable']),
    unallocatedManualCallFromPaused: tryEvents(['unallocatedManualCallFromPaused']),
    unallocatedManualCallFromAvailable: tryEvents(['unallocatedManualCallFromAvailable']),

    // questions
    canLogout,
    canPerform: (evt) => { return fsm.can(evt); },
    cannotPerform: (evt) => { return fsm.cannot(evt); },
    isAnyOf: (states) => { return states && isStateIn(states); },
    transitions: () => {
      return fsm.transitions();
    },

    // state
    // amI - send a single state
    // isStateIn - send an array
    isOffPhone: amI(offPhone),
    isOnAllocatedCall: () => {
      return isStateIn([onPhoneLine1ManCall, onPhoneLine1InboundCall, onPhoneLine1OutboundCall]);
    },
    isOnOutboundAllocatedCallorDialling: () => {
      return isStateIn([onPhoneLine1OutboundCall, onPhoneLine1OutboundCallDialling]);
    },
    isWaiting: () => {
      return isStateIn([
        onPhoneAvailable,
        onPhonePaused]);
    },
    isOnCall: () => {
      return isStateIn([
        onPhoneLine1ManCall,
        onPhoneLine1InboundCall,
        onPhoneLine1OutboundCall,
        onPhoneAvailableOnLine1UnallocManCall,
        onPhonePausedOnLine1UnallocManCall]);
    },
    isOnCallOrWrapOrDialling: () => {
      return isStateIn([
        onPhoneLine1ManCallDialling,
        onPhoneLine1ManCall,
        onPhoneAvailableOnLine1UnallocManCall,
        onPhonePausedOnLine1UnallocManCall,
        onPhoneAvailableOnLine1UnallocManCallDialling,
        onPhonePausedOnLine1UnallocManCallDialling,
        onPhoneLine1InboundCall,
        onPhoneLine1OutboundCallDialling,
        onPhoneLine1OutboundCall,
        onOutboundWrapping,
        onPhoneWrapping,
      ]);
    },
    isOnCallOrDiallingOrFailedOrHoldOrWrap: () => {
      return isStateIn([
        onPhoneLine1InboundCall,
        onPhoneLine1OutboundCall,
        onPhoneLine1InboundHold,
        onPhoneLine1ManCall,
        onPhoneLine1ManCallHold,
        onPhoneLine1ManCallDialling,
        onPhoneLine1ManCallFailed,
        onPhoneAvailableOnLine1UnallocManCall,
        onPhoneAvailableOnLine1UnallocManCallDialling,
        onPhoneAvailableOnLine1UnallocManCallFailed,
        onPhonePausedOnLine1UnallocManCall,
        onPhonePausedOnLine1UnallocManCallDialling,
        onPhonePausedOnLine1UnallocManCallFailed,
        onOutboundPreview,
        onPhoneLine1OutboundCallDialling,
        onPhoneLine1OutboundCallFailed,
        onPhoneLine1OutboundCall,
        onPhoneLine1OutboundHold,
        onOutboundWrapping,
        onPhoneWrapping,
      ]);
    },
    isOnHold: () => {
      return isStateIn([onPhoneLine1ManCallHold, onPhoneLine1InboundHold,
        onPhoneLine1OutboundHold]);
    },
    isOnFailedCall: () => {
      return isStateIn([
        onPhoneLine1ManCallFailed,
        onPhoneLine1OutboundCallFailed,
      ]);
    },
    isOnUnallocatedFailedCall: () => {
      return isStateIn([
        onPhonePausedOnLine1UnallocManCallFailed,
        onPhoneAvailableOnLine1UnallocManCallFailed,
      ]);
    },
    isOnPhonePaused: amI(onPhonePaused),
    isOnPhoneAvailable: amI(onPhoneAvailable),
    isOnPhoneLine1ManCallDialling: amI(onPhoneLine1ManCallDialling),
    isOnPhoneLine1ManCall: amI(onPhoneLine1ManCall),
    isOnPhoneLine1InboundCall: amI(onPhoneLine1InboundCall),
    isOnPhoneLine1OutboundCall: amI(onPhoneLine1OutboundCall),
    isOnPhoneWrapping: () => {
      return isStateIn([onPhoneWrapping, onOutboundWrapping]);
    },
    isOnManualPreview: amI(onManualPreview),
    isOnOutboundPreview: amI(onOutboundPreview),
    isOnOutboundCallDialling: amI(onPhoneLine1OutboundCallDialling),
    isOnOutboundCall: () => {
      return isStateIn([
        onPhoneLine1OutboundCallDialling,
        onPhoneLine1OutboundCall,
        onPhoneLine1OutboundHold,
        onOutboundWrapping,
      ]);
    },
    recreate: (destinationState, message) => {
      storagemanager.removeItem('agent.fsm.currentAgentState');
      createStateMachine(destinationState);
    },
    resave,
    state,
    previousState,
    time,
    readableState,
  };
};

export default angular.module('CCAdaptor.App.AgentFSM', [])
  .service('agentFSM', agentFSM).name;
