import angular from 'angular';
import _ from 'lodash';
import BroadcastChannel from 'broadcast-channel';

const chance = require('chance')();
const paymentsTemplate = require('./ipsPayments.component.html');

class IpsPaymentsController {
  constructor($scope, $rootScope, $log, $window, $compile, $interval, AuthService, ErrorService,
    PhoneService, CampaignService, CallService, PaymentsService,
    agentFSM, calls) {
    'ngInject';

    this.bc = new BroadcastChannel('ipscape.cti.pay');
    $scope.params = $scope.$ctrl.params;
    $scope.isLoading = true;
    $scope.isError = false;
    $scope.modalWarning = false;
    $scope.showModal = false;
    $scope.uuid = PaymentsService.uuid;
    $scope.errorMsg = null;
    $scope.btn = {
      class: 'btn-danger',
      label: 'Cancel',
    };

    this.checkUuid = undefined;
    this.useOldFormat = false;

    // Services
    this.PaymentsService = PaymentsService;
    this.AuthService = AuthService;
    this.agentFSM = agentFSM;
    this.PhoneService = PhoneService;
    this.CallService = CallService;
    this.ErrorService = ErrorService;
    this.CampaignService = CampaignService;

    // Define payDetails
    Object.defineProperty($scope, 'payDetails', {
      get: () => this.CampaignService.payDetails,
      configurable: true,
    });

    if (window.name === '') window.name = chance.guid();

    // Broadcast message $window.open
    this.bc.postMessage(
      { resource: { window: 'payments', action: 'open', uuid: window.name } },
    );

    this.PaymentsService.uuid = window.name;

    // make link element so we can capture the iframe hostname
    this.lnk = document.createElement('a');

    //----------------------------------------------------------------
    // Helper functions
    //----------------------------------------------------------------
    this.resizeViewPort = (width, height) => {
      if (window.outerWidth) {
        window.resizeTo(
          width + (window.outerWidth - window.innerWidth),
          height + (window.outerHeight - window.innerHeight),
        );
      } else {
        window.resizeTo(500, 500);
        window.resizeTo(
          width + (500 - document.body.offsetWidth),
          height + (500 - document.body.offsetHeight),
        );
      }
      // reset body style
      angular.element('body').attr('style', 'overflow:hidden');
    };

    // Check that the agent is on a call
    this.isOnCall = () => this.agentFSM.isOnAllocatedCall();

    // Check if the agent is in attended transfer/conference call
    this.isInTransfer = () => {
      if (this.PhoneService.attendedTransfer) return true;
      return !!this.PhoneService.conferenceCall;
    };

    // Check if caller is active on line #1 (i.e. not on hold)
    this.isCallerActive = () => this.PhoneService.lineStateOne === this.PhoneService.states.active;

    // Check that the selected campaign has "ipScapePay" enabled
    this.isPayEnabled = () => {
      if (!_.isNil($scope.payDetails)) {
        // For old data format
        if (_.hasIn($scope.payDetails, 'paymentButtonLabel')) {
          this.useOldFormat = true;
          return true;
        }
        // For new data format
        if ($scope.payDetails.length > 0) return true;
      }
      return false;
    };

    // Run through verification checks
    this.isAuthorised = () => {
      if (!this.AuthService.isLoggedIn()) {
        $scope.errorMsg = 'You need to be logged in to process payments.';
        return false;
      }
      if (!this.isPayEnabled()) {
        $scope.errorMsg = 'Please contact your Administrator to configure this service.';
        return false;
      }
      if (!this.isOnCall()) {
        $scope.errorMsg = 'Payments cannot be processed when you are not on call.';
        return false;
      }
      if (!this.isCallerActive()) {
        $scope.errorMsg = 'Cannot initiate payment when customer is on hold or not connected.';
        return false;
      }
      if (this.isInTransfer()) {
        $scope.errorMsg = 'Payments cannot be processed during a transfer or conference call.';
        return false;
      }

      return true;
    };

    // Listener fn to send message from iframe
    this.receiveMessage = (event) => {
      if (event.origin === `${this.lnk.protocol}//${this.lnk.hostname}`) {
        // set message service message
        this.bc.postMessage({ resource: { window: 'payments', action: 'pay', msg: event.data } });

        // set the transaction alert
        $scope.transactionMessage = {
          approved: event.data.approved,
          transaction_reference: (_.hasIn(event.data, 'transaction_reference')) ? event.data.transaction_reference : 'Transaction reference not found.',
          response_text: (_.hasIn(event.data, 'response_text')) ? event.data.response_text : 'Response text not available',
          receipt_number: (_.hasIn(event.data, 'receipt_number')) ? event.data.receipt_number : 'Receipt number not available',
        };

        PaymentsService.endCurrentPaymentTransaction().then(() => {
          // clear the payment details from localStorage
          this.PaymentsService.transactionId = null;
        });

        // Display the modal
        $scope.showModal = true;

        // Reset the footer button
        $scope.btn = { class: 'btn-success', label: 'Exit' };
      } else {
        $log.debug('wrong origin');
      }
    };

    this.checkUuid = $interval(() => {
      const uuid = localStorage.getItem('payments.uuid');
      if (uuid !== window.name) {
        $scope.uuid = uuid;
        $scope.modalWarning = true;
        window.removeEventListener('message', this.receiveMessage, false);
      }
    }, 1000);

    // resize the viewport to the desired dimensions
    this.resizeViewPort(773, 700);

    this.closeWindow = () => {
      angular.element('body').attr('style', 'overflow:auto');
      if (!$scope.modalWarning) {
        this.bc.postMessage({ resource: { window: 'payments', action: 'close', uuid: window.name } });
      }

      if (this.checkUuid) {
        $interval.cancel(this.checkUuid);
        this.checkUuid = undefined;
      }

      window.close();
    };

    // Start payment iframe
    $scope.initPaymentFrame = () => {
      if (this.isAuthorised()) {
        // Get the iframe
        calls.withActivityId(this.CallService.activityId).initiatePayment(
          $scope.params.amount,
          $scope.params.ref,
          (!this.useOldFormat) ? $scope.paySettingsId : null,
          $scope.params.readOnlyAmount,
          $scope.params.readOnlyReferenceId,
          this.PaymentsService.transactionId,
        ).then((response) => {
          if (response.data.result) {
            if (_.hasIn(response.data.result, 'paymentIFrameUrl')) {
              $scope.showModal = false;
              $scope.selectCampaign = false;
              $scope.isLoading = false;
              const iFrameUrl = response.data.result.paymentIFrameUrl;
              // Add href to link we created earlier
              this.lnk.href = iFrameUrl;
              // Add event listener for postMessage
              window.addEventListener('message', this.receiveMessage, false);
              this.PaymentsService.transactionId = response.data.result.payTransactionId;
              $scope.transactionId = this.PaymentsService.transactionId;
              const newIframe = angular.element(`<iframe src="${iFrameUrl}">`);
              angular.element('#body').append(newIframe);
              $compile(newIframe);
            }
          }
        }).catch((err) => {
          $log.error(err);
          $scope.errorMsg = err.data.errors[0].userMessage;
          ErrorService.report('Payment init transaction', err.data);
          $scope.isError = true;
          if ($scope.selectCampaign) {
            $scope.showModal = false;
          }
        });
      } else {
        this.ErrorService.warn('ipSCAPE Pay ', $scope.errorMsg);
        $scope.isError = true;
      }
    };

    $scope.endTransaction = () => {
      if (!this.PaymentsService.transactionId) this.closeWindow();

      PaymentsService.endCurrentPaymentTransaction().then((response) => {
        $log.info('End current transaction called:', response);
        this.closeWindow();
      });
    };

    window.onload = () => {
      // Initialise iframe
      if (this.isAuthorised()) {
        if (!_.isNil($scope.payDetails)) {
          if ($scope.payDetails.length > 1) {
            $scope.showModal = true;
            $scope.selectCampaign = true;
          } else if ($scope.payDetails.length === 1) {
            $scope.paySettingsId = $scope.payDetails[0].paySettingsId;
            $scope.initPaymentFrame();
          } else if (Object.keys($scope.payDetails).length > 0) {
            $scope.initPaymentFrame();
          }
        }
      }

      if ($scope.errorMsg) $scope.isError = true;

      $rootScope.$apply();
    };

    window.addEventListener('beforeunload', (e) => {
      this.bc.postMessage({ resource: { window: 'payments', action: 'close', uuid: window.name } });
      const confirmationMessage = 'Closing this window will terminate the current transaction.';
      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    });

    window.onclose = () => {
      if (this.checkUuid) {
        $interval.cancel(this.checkUuid);
        this.checkUuid = undefined;
      }
      this.bc.postMessage({ resource: { window: 'payments', action: 'close', uuid: window.name } });

      if (window.name === $scope.uuid) {
        this.PaymentsService.uuid = null;
      }
    };

    window.addEventListener('unload', (event) => {
      this.bc.postMessage({
        resource: {
          window: 'payments',
          action: 'close',
          uuid: window.name,
          event,
        },
      });

      if (window.name === $scope.uuid) {
        this.PaymentsService.uuid = null;
      }
    });
  }
}

export default angular.module('CCAdaptor.App.ipsPayments', [])
  .component('ipsPayments', {
    template: paymentsTemplate,
    controller: IpsPaymentsController,
    bindings: {
      params: '=',
    },
  }).name;
