import _ from 'lodash';

/**
 * @module Controller for page home of public section layout.
 * @class ToolbarController
 */
export default class ToolbarController {
  /**
   * @constructor
   * @param {object} TitleService - Controlling our title.
   */
  constructor($log, $scope, $state, $transition$,
    CallService, TitleService, storagemanager, util) {
    'ngInject';

    TitleService.title = { newTitle: 'Toolbar' };
    this.title = TitleService.title;

    //--------------------------------------------------------------

    storagemanager.watchers()
      .withObj($scope)
      .withId('toolbar.controller')
      .buildWithAttributes((attrInitialiser) => {
        attrInitialiser.withObjectKey('agent').build();
        attrInitialiser.withStringKey('manualCallPhoneNumber').build();
      });

    //---------------------------------------------------------------------------
    // events
    //---------------------------------------------------------------------------

    $scope.$on('event.system', (evt, data) => {
      if (_.hasIn(data, 'action')) {
        if (data.action === 'call.allocated') {
          CallService.clear();
          CallService.callInfo = data.msg.data;
          CallService.callNotes = data.msg.data.callNotes;
          $state.transitionTo('call', $transition$.params(), {
            reload: false,
            inherit: false,
            notify: true,
          });
        }
      }
    });

    $scope.$on('event.system', (evt, data) => {
      if (_.hasIn(data, 'action')) {
        if (data.action === 'call.outbound.preview') {
          CallService.clear();
          CallService.outboundInfo = data.msg.data;
          CallService.autoPreview = (_.hasIn(data.msg.data, 'autoPreviewTime')) ?
            data.msg.data.autoPreviewTime : null;
          $state.transitionTo('call', $transition$.params(), {
            reload: false,
            inherit: false,
            notify: true,
          });
        }
      }
    });

    //-----------------------------------------------------------------------
    // legacy bindings
    //-----------------------------------------------------------------------

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.inDevMode = () => {
      return util.inDevMode();
    };
  }
}
