import angular from 'angular';
import _ from 'lodash';
import moment from 'moment-timezone';
import BroadcastChannel from 'broadcast-channel';
import { isJSON } from '../../common/utilities';
import mapData from '../../common/mapData';

/* eslint no-underscore-dangle: 0 */
/* eslint no-param-reassign: 0 */

const SalesforceLightning = ($log, $http, $rootScope, $injector, $timeout, $interval,
  $window, CallService, TaskService, ConfigService, ErrorService, SalesforceService) => {
  'ngInject';

  let initTimer;
  // let version;
  const bc = new BroadcastChannel('ipscape.cti.pay');
  const sforce = (_.hasIn(window, 'sforce')) ? window.sforce : {};
  const ipscapeNameSpace = (_.hasIn(window, 'IPSCAPE')) ? window.IPSCAPE : {};
  const customFieldPrefix = 'ipSCAPE_';
  const customFieldSuffix = '__c';
  const relatedLists = ['Activity', 'History'];
  const service = {};

  // Cancel timer on page unload
  $window.addEventListener('beforeunload', () => {
    if (initTimer) {
      $interval.cancel(initTimer);
      initTimer = undefined;
    }
  }, false);

  // Utilities
  function report({ type = 'error', title, data }) {
    switch (type) {
      case 'info':
        ErrorService.info(`[SF Lightning] ${title}`, { data });
        break;
      case 'warn':
        ErrorService.warn(`[SF Lightning] ${title}`, { data });
        break;
      case 'error':
      default:
        ErrorService.report(`[SF Lightning] ${title}`, { data });
    }
  }

  function logger(key, value) {
    const message = (typeof value !== 'string') ? JSON.stringify(value) : value;
    $log.info(`[SF Lightning] ${key} | ${message}`);
  }

  function formatDate(dte, pattern) {
    return moment.utc(dte).format(pattern);
  }

  function makeCustomParam(field, value) {
    return `${customFieldPrefix}${field}${customFieldSuffix}=${value}`;
  }

  // Define properties & bind to services
  Object.defineProperty(service, 'isCurrentTab', {
    get: () => {
      logger('isCurrentTab', $rootScope.currentTab);
      return $rootScope.currentTab;
    },
    configurable: true,
  });

  Object.defineProperty(service, 'taskPop', {
    get: () => TaskService.pop,
    set: (value) => { TaskService.pop = value; },
    configurable: true,
  });

  Object.defineProperty(service, 'screenpop', {
    get: () => ConfigService.screenpop,
    set: (value) => { ConfigService.screenpop = value; },
    configurable: true,
  });

  Object.defineProperty(service, 'settings', {
    get: () => ConfigService.ipsSettings,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(service, 'isInConsole', {
    get: () => ConfigService.isInConsole,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(service, 'softPhoneLayout', {
    get: () => ConfigService.softPhoneLayout,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(service, 'apexCapabilities', {
    get: () => ConfigService.apexCapabilities,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(service, 'taskPop', {
    get: () => TaskService.pop,
    set: (value) => { TaskService.pop = value; },
    configurable: true,
  });

  Object.defineProperty(service, 'taskId', {
    get: () => TaskService.taskId,
    set: (id) => { TaskService.taskId = id; },
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(service, 'callInfo', {
    get: () => CallService.callInfo,
    configurable: true,
  });

  Object.defineProperty(service, 'isOnOutboundPreview', {
    get: () => $injector.get('agentFSM').isOnOutboundPreview(),
    configurable: true,
  });

  Object.defineProperty(service, 'isOnPhoneWrapping', {
    get: () => $injector.get('agentFSM').isOnPhoneWrapping(),
    configurable: true,
  });

  Object.defineProperty(service, 'outboundCallInfo', {
    get: () => CallService.outboundInfo,
    configurable: true,
  });

  Object.defineProperty(service, 'openTaskOnWrap', {
    get: () => TaskService.openTaskOnWrap,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(service, 'screenPopResult', {
    get: () => TaskService.screenPopResult,
    set: (obj) => { TaskService.screenPopResult = obj; },
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(service, 'selectedContact', {
    get: () => CallService.selectedContact,
    set: (contact) => { CallService.selectedContact = contact; },
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(service, 'contacts', {
    get: () => CallService.contacts,
    set: (contacts) => { CallService.contacts = contacts; },
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(service, 'selectedItem', {
    get: () => CallService.selectedItem,
    set: (item) => { CallService.selectedItem = item; },
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(service, 'items', {
    get: () => CallService.items,
    set: (items) => { CallService.items = items; },
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(service, 'callType', {
    get: () => CallService.callType,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(service, 'previewCalled', {
    get: () => CallService.previewCalled,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(service, 'previewData', {
    get: () => CallService.outboundInfo,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(service, 'callData', {
    get: () => (($injector.get('agentFSM').isOnOutboundPreview()
      && !service.previewCalled)
      ? service.outboundCallInfo : service.callInfo),
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(service, 'checkRelatedObjectsOnWrap', {
    get: () => {
      if (_.hasIn(service.settings, 'checkRelatedObjectsOnWrap')) {
        return !!service.settings.checkRelatedObjectsOnWrap;
      }
      return false;
    },
    configurable: true,
    enumerable: true,
  });

  // Open CTI API Methods
  const api = {
    getAppViewInfo: (callback) => {
      try {
        sforce.opencti.getAppViewInfo({ callback });
      } catch (error) {
        report({ type: 'error', title: 'OpenCTI failed (getAppViewInfo)', data: error });
      }
    },
    isSoftphonePanelVisible: (callback) => {
      try {
        sforce.opencti.isSoftphonePanelVisible({ callback });
      } catch (error) {
        report({ type: 'error', title: 'OpenCTI failed (isSoftphonePanelVisible)', data: error });
      }
    },
    onNavigationChange: (callback) => {
      try {
        sforce.opencti.onNavigationChange({ listener: callback });
      } catch (error) {
        report({ type: 'error', title: 'OpenCTI failed (onNavigationChange)', data: error });
      }
    },
    refreshView: (callback) => {
      try {
        sforce.opencti.refreshView({ callback });
      } catch (error) {
        report({ type: 'error', title: 'OpenCTI failed (refreshView)', data: error });
      }
    },
    saveLog: (saveObj, callback) => {
      const dataObj = saveObj;
      if (!dataObj.Id) dataObj.entityApiName = 'Task';
      try {
        sforce.opencti.saveLog({ value: dataObj, callback });
      } catch (error) {
        report({ type: 'error', title: 'OpenCTI failed (saveLog)', data: error });
      }
    },
    screenPop: (url, callback) => {
      try {
        sforce.opencti.screenPop({
          type: sforce.opencti.SCREENPOP_TYPE.SOBJECT,
          params: { recordId: url },
          callback,
        });
      } catch (error) {
        report({ type: 'error', title: 'OpenCTI failed (screenPop)', data: error });
      }
    },
    searchAndScreenPop: (query, params, defaultObj, callType, callback) => {
      const payload = {
        searchParams: params,
        queryParams: query,
        defaultFieldValues: defaultObj,
        callType,
        deferred: false,
        callback,
      };

      try {
        sforce.opencti.searchAndScreenPop(payload);
      } catch (error) {
        report({ type: 'error', title: 'OpenCTI failed (searchAndScreenPop)', data: error });
      }
    },
    setSoftphonePanelVisibility: (callback) => {
      try {
        sforce.opencti.setSoftphonePanelVisibility({ visible: true, callback });
      } catch (error) {
        report({ type: 'error', title: 'OpenCTI failed (setSoftphonePanelVisibility)', data: error });
      }
    },
    enableClickToDial: (callback) => {
      try {
        sforce.opencti.enableClickToDial({ callback });
      } catch (error) {
        report({ type: 'error', title: 'OpenCTI failed (enableClickToDial)', data: error });
      }
    },
    disableClickToDial: (callback) => {
      try {
        sforce.opencti.disableClickToDial({ callback });
      } catch (error) {
        report({ type: 'error', title: 'OpenCTI failed (disableClickToDial)', data: error });
      }
    },
    getCallCenterSettings: (callback) => {
      try {
        sforce.opencti.getCallCenterSettings({ callback });
      } catch (error) {
        report({ type: 'error', title: 'OpenCTI failed (getCallCenterSettings)', data: error });
      }
    },
    getSoftphoneLayout: (callback) => {
      try {
        sforce.opencti.getSoftphoneLayout({ callback });
      } catch (error) {
        report({ type: 'error', title: 'OpenCTI failed (getSoftphoneLayout)', data: error });
      }
    },
    onClickToDial: (listener) => {
      try {
        sforce.opencti.onClickToDial({ listener });
      } catch (error) {
        report({ type: 'error', title: 'OpenCTI failed (onClickToDial)', data: error });
      }
    },
    runApex: ({
      apexClass = 'IpscapeHelper',
      apexMethod = 'getCapabilities',
      methodParams = null,
      callback,
    }) => {
      try {
        sforce.opencti.runApex({
          apexClass,
          methodName: apexMethod,
          methodParams,
          callback,
        });
      } catch (error) {
        report({ type: 'error', title: 'OpenCTI failed (runApex)', data: error });
      }
    },
  };

  // Private methods
  function _apexOpportunityCallback(response) {
    if (response.errors) {
      report({
        type: 'error',
        title: 'sforce.interaction.runApex apexOpportunityCallback',
        data: response.errors,
      });
      return;
    }

    if (_.hasIn(response.returnValue, 'runApex')) {
      const apex = (isJSON(response.returnValue.runApex))
        ? JSON.parse(response.returnValue.runApex) : {};
      logger('apexOpportunityCallback', apex);
      if (apex.Id) {
        const opportunity = {
          url: apex.attributes.type,
          objectId: apex.Id,
          objectName: apex.Name,
          object: apex.attributes.type,
          displayName: apex.attributes.type,
        };
        // Clear related objects and select the Opportunity
        CallService.resetRelatedObjects();
        SalesforceService.updateRelated({
          event: 'event.focus',
          result: opportunity,
        });
        report({
          type: 'info',
          title: 'Update related for lead converted to opportunity',
          data: response,
        });
      }
    }
  }

  function _apexLeadCallback(response) {
    if (response.errors) {
      report({
        type: 'error',
        title: 'sforce.interaction.runApex apexLeadCallback',
        data: response.errors,
      });
      return;
    }
    if (_.hasIn(response.returnValue, 'runApex')) {
      const apex = (isJSON(response.returnValue.runApex))
        ? JSON.parse(response.returnValue.runApex) : {};
      const { getOpportunity } = service.apexCapabilities;
      logger('sforce.interaction.runApex apexLeadCallback', response);
      // Check if the selected Lead in the dropdown was converted to an Opportunity
      if (apex.IsConverted && apex.ConvertedOpportunityId) {
        // Get the Opportunity data
        api.runApex({
          apexClass: getOpportunity.class,
          apexMethod: getOpportunity.method,
          methodParams: `opportunityId=${apex.ConvertedOpportunityId}`,
          callback: _apexOpportunityCallback,
        });
      }
    }
  }

  function _handleConvertedObjects(response) {
    if (service.selectedContact.object === 'Lead') {
      // Check if the selected lead was converted to an opportunity
      if (_.hasIn(service.apexCapabilities, 'getLead')
        && _.hasIn(service.apexCapabilities, 'getOpportunity')) {
        logger('sforce.interaction.runApex getLead', response);
        const _apex = service.apexCapabilities.getLead;
        api.runApex({
          apexClass: _apex.class,
          apexMethod: _apex.method,
          methodParams: `leadId=${service.selectedContact.objectId}`,
          callback: _apexLeadCallback,
        });
      } else {
        logger('Apex Capabilities [getLead, getOpportunity] not found', service.apexCapabilities);
      }
    }
  }

  function _focusHandler(response) {
    if (!service.isCurrentTab) return;
    logger('onFocus', response);
    if (_.hasIn(response, 'returnValue')) {
      const res = response.returnValue;
      if (_.hasIn(res, 'recordId')) {
        SalesforceService.updateRelated({ event: 'event.focus', result: res });
      } else {
        logger('Ignoring unrelated focus event.', response);
      }
      // Check if the current page is not the same type as the selected item
      // In this case, the selected item may have been converted to another type
      if (_.hasIn(service.selectedContact, 'object') && service.selectedContact.object !== res.objectType) {
        _handleConvertedObjects(res);
      }
    }
  }

  function _refreshView() {
    api.refreshView((result) => {
      logger('Refresh result', JSON.stringify(result));
    });
  }

  function _refreshLists() {
    relatedLists.forEach((list) => {
      logger('Refresh List', list);
      _refreshView();
    });
  }

  function _handleUpdated(response) {
    logger('Task updated', JSON.stringify(response));
    service.taskId = response.returnValue.recordId;
    _refreshLists();
  }

  function _mapCallData() {
    const mappedData = [];
    const dataMappingRules = Object.keys(service.settings.dataMap);
    const typeOfCall = (service.callType)
      ? service.callType.toLowerCase() : null;
    const keys = ['default', typeOfCall];
    return new Promise((res) => {
      async function makeMap() {
        if (dataMappingRules.length > 0) {
          keys.forEach((key) => {
            dataMappingRules.forEach((rule) => {
              if (rule === key) {
                mapData({
                  map: service.settings.dataMap[rule],
                  data: service.callData,
                  timezone: CallService.campaignTimezone,
                }).then((response) => {
                  if (response) {
                    _.each(response, (data) => {
                      mappedData.push(data);
                    });
                  }
                }).catch((error) => {
                  report({ type: 'warn', title: 'Mapped Data Error', data: error });
                });
              }
            });
          });
          return mappedData;
        }
        return mappedData;
      }

      makeMap().then((response) => {
        res(response);
      });
    });
  }

  function _updatePopRecord(interactionId) {
    const popRecord = service.taskPop;
    popRecord.interactionId = (interactionId) || popRecord.interactionId;
    popRecord.event = service.callType;
    popRecord.popped = (popRecord.popped) || false;
    service.taskPop = popRecord;
  }

  function _writeToActivityLog(paramObj) {
    return new Promise((resolve, reject) => {
      const updateObj = paramObj;

      if (!_.isNil(service.taskId)) updateObj.Id = service.taskId;

      if (!service.isCurrentTab) {
        logger('Ignoring write to log', ipscapeNameSpace.name);
        reject();
      }

      const write = () => {
        // Remove any null value or empty string values
        const objKeys = Object.keys(updateObj);
        objKeys.forEach((key) => {
          if (_.isNil(updateObj[key]) || updateObj[key] === '') {
            delete updateObj[key];
          }
        });

        logger('Writing Activity Log', JSON.stringify(updateObj));

        const callback = (response) => {
          if (response.success) {
            _handleUpdated(response);
            resolve(response);
          } else {
            report({ type: 'error', title: 'Activity Log Error', data: response });
            reject(response);
          }
        };

        api.saveLog(updateObj, callback);
      };

      if (service.settings.dataMap) {
        _mapCallData()
          .then((response) => {
            response.forEach((mapItem) => {
              const itemKey = Object.keys(mapItem);

              // validate for timestamp
              if (mapItem[itemKey[0]].length >= 10 && Date.parse(mapItem[itemKey[0]]) > 0) {
                // eslint-disable-next-line no-param-reassign
                mapItem[itemKey[0]] = formatDate(mapItem[itemKey[0]], 'YYYY-MM-DDTHH:mm:ssZ');
              }
              updateObj[itemKey[0]] = mapItem[itemKey[0]];
            });
          })
          .then(() => write());
      } else {
        write();
      }
    });
  }

  function _appendToActivityLog(fields) {
    const additionalFields = [].concat(fields);
    const handleField = () => {
      if (!additionalFields.length) return;
      const field = additionalFields.shift();
      _writeToActivityLog(field)
        .then(() => {
          handleField();
        })
        .catch((error) => {
          report({ type: 'error', title: 'appendToActivityLog', data: error });
        });
    };
    handleField();
  }

  function _openActivityLog(param, onSuccess) {
    if (!param) {
      report({ type: 'error', title: 'Unable to open Activity Log: No task id found', data: null });
      return;
    }

    api.screenPop(param, (response) => {
      if (response.error) report({ type: 'error', title: 'Unable to open activity log.', data: response.error });
      else {
        logger('Pop Activity Log', param);
        if (onSuccess) onSuccess(response);
      }
    });
  }

  function _reportMismatchedRelatedRecords() {
    let selectedMatchedScreenPop;
    // Check if the current selection is different than the screenPop
    // If so, log a warning to Rollbar
    if (service.isCurrentTab && service.screenPopResult) {
      selectedMatchedScreenPop = false;
      const resultKeys = Object.keys(service.screenPopResult);
      // More than one screen pop record in results
      if (resultKeys.length > 1) selectedMatchedScreenPop = true;
      // Check selected contacts
      if (_.hasIn(service.selectedContact, 'objectId')) {
        if (resultKeys.indexOf(service.selectedContact.objectId) !== -1) {
          selectedMatchedScreenPop = true;
        }
      }
      // Check selected objects/items
      if (_.hasIn(service.selectedItem, 'objectId')) {
        if (resultKeys.indexOf(service.selectedItem.objectId) !== -1) {
          selectedMatchedScreenPop = true;
        }
      }
      // if related objects do not match pop results
      if (!selectedMatchedScreenPop) {
        report({
          type: 'warn',
          title: 'Mismatch Related Records',
          data: {
            selectedContact: service.selectedContact,
            selectedItem: service.selectedItem,
            screenPopResult: service.screenPopResult,
          },
        });
      }
    }
  }

  function _checkCurrentPageAgainstRelatedRecords() {
    return new Promise((resolve, reject) => {
      api.getAppViewInfo((response) => {
        if (_.hasIn(response, 'success')) {
          // Send response to the focusHandler
          _focusHandler(response);

          const relatedArr = [];
          const res = (typeof response.returnValue === 'object') ? response.returnValue : {};

          if (_.hasIn(service.selectedItem, 'objectId')) relatedArr.push(service.selectedItem.objectId);
          if (_.hasIn(service.selectedContact, 'objectId')) relatedArr.push(service.selectedContact.objectId);

          // Log related Objects
          logger('Task related objects: ', relatedArr);
          // Setup Modal
          $rootScope.modal = { title: 'activity warning' };

          // Modal Cancel Button Callback
          $rootScope.cancelModalBtn = () => {
            CallService.resumeAutoWrapCountdown();
            $rootScope.showBaseModal = false;
          };

          // Modal Continue Button Callback
          $rootScope.continueModalBtn = () => {
            CallService.resumeAutoWrapCountdown();
            report({ type: 'warn', title: $rootScope.modal.message, data: response });
            $rootScope.showBaseModal = false;
            resolve();
          };

          // If checkRelatedObjectsOnWrap is false or does not exist
          if (!service.checkRelatedObjectsOnWrap) {
            if (relatedArr.length < 1 || relatedArr.indexOf(res.objectId) === -1) {
              report({ type: 'warn', title: 'Mismatched Call Log', data: response });
            }
            resolve();
          } else if (service.isOnPhoneWrapping) {
            if (relatedArr.length < 1) {
              CallService.pauseAutoWrapCountdown();
              $rootScope.modal.message = 'This call log has not been matched to any entity. Do you wish to continue?';
              $rootScope.showBaseModal = true;
            }

            if (relatedArr.length > 0) {
              // Checking if the response objectId contains in the index 0 of the
              // selected item id. It checks if it contains in the string as the object
              // id returned from the Apex api contains extra characters appended. E.g. AAB
              const matched = relatedArr.indexOf(res.recordId) > -1;

              if (!matched) {
                CallService.pauseAutoWrapCountdown();
                $rootScope.modal.message = 'This call log has not been matched to the entity you are currently viewing. Do you wish to continue?';
                $rootScope.showBaseModal = true;
              } else {
                resolve();
              }
            }
          } else {
            resolve();
          }
        } else {
          reject(Error(response));
          report({ type: 'warn', title: '[SF] Check Page Against Related Records', data: response });
        }
      });
    });
  }

  function _setPageInfoListener() {
    try {
      api.getAppViewInfo(_focusHandler);
    } catch (error) {
      report({ type: 'error', title: 'SetPageInfoListener Error', data: error });
    }
  }

  function _getConfigurationSettings() {
    return new Promise((resolve, reject) => {
      const settings = {
        allowCustomObjects: 'false',
        ipsCustomObjs: '',
        showOpenLogButton: 'false',
        settingsUrl: '',
        displayWrapCodes: 'true',
        defaultWrapCode: '',
      };

      const customizer = (objValue, srcValue) => (_.isUndefined(objValue) ? srcValue : objValue);

      const parseApexClasses = (classes) => {
        const methods = {};

        _.forEach(classes, (value, key) => {
          const val = value.split('::');
          if (val.length !== 2) return;
          methods[key] = {
            class: val[0],
            method: val[1],
          };
        });
        return methods;
      };

      const callbackCallCenterSettings = (response) => {
        if (!response.success) {
          report({ type: 'error', title: 'Unable to fetch call center settings', data: response });
          reject();
        }

        const rs = response.returnValue;
        const adaptor = {
          identifier: 'sf',
        };

        const settingValues = Object.values(rs);
        Object.keys(rs).forEach((key, index) => {
          const k = key.split('/');
          if (
            k.indexOf('sortOrder') === -1
            && k.indexOf('label') === -1
            && k.length <= 3
          ) {
            k.splice(0, 1);
            _.set(adaptor, k, settingValues[index]);
          }
        });

        // Get data map if we have a url
        if (adaptor.ipsSettings.settingsUrl) {
          $http.get(adaptor.ipsSettings.settingsUrl, {
            headers: {
              Authorization: undefined,
            },
          }).then((result) => {
            if (_.hasIn(result, 'data')) {
              if (_.hasIn(result.data, 'map')) adaptor.ipsSettings.dataMap = result.data.map;
              if (_.hasIn(result.data, 'settings')) {
                angular.forEach(result.data.settings, (val, key) => {
                  adaptor.ipsSettings[key] = val;
                });
              }
              if (_.hasIn(result.data, 'tabs')) adaptor.ipsSettings.tabs = result.data.tabs;
              if (_.hasIn(result.data, 'screenPopConfig')) service.screenpop = result.data.screenPopConfig;
            }
            ConfigService.adaptor = adaptor;
            resolve(adaptor);
          }).catch((error) => {
            report({ type: 'error', title: 'Unable to read settings JSON file', data: error });
            reject();
          });
        } else {
          ConfigService.adaptor = adaptor;
          resolve(adaptor);
        }

        if (typeof adaptor.ipsSettings === 'undefined') adaptor.ipsSettings = settings;
        else _.assignInWith(adaptor.ipsSettings, settings, customizer);

        // Set the settings version
        // if (_.hasIn(adaptor, 'reqGeneralInfo')) {
        //   version = (_.hasIn(adaptor.reqGeneralInfo, 'reqInternalName'))
        //     ? adaptor.reqGeneralInfo.reqInternalName : '';
        // }
      };

      const callBackApexCapabilities = (response) => {
        try {
          if (!response.success) {
            report({ type: 'error', title: 'Unable to get Apex Capabilities', data: response.errors });
          } else {
            const classes = isJSON(response.returnValue.runApex)
              ? JSON.parse(response.returnValue.runApex)
              : response.returnValue.runApex;
            const methods = parseApexClasses(classes);
            ConfigService.apexCapabilities = Object.assign(
              {},
              ConfigService.apexCapabilities,
              methods,
            );
          }
        } catch (error) {
          report({
            type: 'error',
            title: '[SF] callBackApexCapabilities',
            data: {
              error,
              response,
            },
          });
        }
      };

      const callbackSoftPhoneLayout = (response) => {
        try {
          if (!response.success) {
            report({
              type: 'error',
              title: 'Unable to fetch soft phone layout',
              data: { error: response.errors },
            });
          } else {
            ConfigService.softPhoneLayout = response.returnValue;
          }
        } catch (error) {
          report({
            type: 'error',
            title: '[SF] callbackSoftPhoneLayout',
            data: {
              error,
              response,
            },
          });
        }
      };

      // Call Salesforce api for settings
      api.getCallCenterSettings(callbackCallCenterSettings);

      // soft phone layout
      api.getSoftphoneLayout(callbackSoftPhoneLayout);

      if (_.hasIn(service.settings, 'apex')) {
        // All the apex items in the JSON settings should contain a unique key that references
        // an Apex Class and Method. E.g.: getLeads: IpscapeHelper::getLeads
        const classes = service.settings.apex;
        const methods = parseApexClasses(classes);
        ConfigService.apexCapabilities = Object.assign(
          {},
          ConfigService.apexCapabilities,
          methods,
        );
        // This is Ipscape Apex method to provide a list of default Classes and Methods
        // that doesn't require to be declared in the JSON settings
        if (_.hasIn(methods, 'getCapabilities')) {
          api.runApex({
            apexClass: methods.getCapabilities.class,
            apexMethod: methods.getCapabilities.method,
            methodParams: null,
            callback: callBackApexCapabilities,
          });
        }
      }
    });
  }

  function _toggleAdaptorVisibility() {
    api.isSoftphonePanelVisible((response) => {
      if (response.success) {
        const isVisible = response.returnValue.visible;
        if (!isVisible) {
          api.setSoftphonePanelVisibility((rs) => {
            if (rs.success) logger('CTI visibility', JSON.stringify(rs));
          });
        }
      }
    });
  }

  function _enableClickToDial() {
    // called when click-to-dial-clicked
    const listener = (response) => {
      CallService.resetRelatedObjects();
      if (response) {
        $rootScope.$apply(() => {
          logger('ClickToDial', response.number);

          SalesforceService.updateRelated({ event: 'event.click-to-dial', result: response });

          $rootScope.$broadcast('event.user', {
            action: 'click-to-dial',
            clickToDialData: response,
          });

          _toggleAdaptorVisibility();
        });
      }
    };

    const callback = (response) => {
      // Called when attempting to enable click-to-dial
      if (response.success) {
        // Now register the listener
        api.onClickToDial(listener);
      } else {
        report({ type: 'warn', title: 'Enable click-to-dial:failed', data: response });
      }
    };

    $timeout(() => {
      api.enableClickToDial(callback);
    }, 50);
  }

  function _disableClickToDial() {
    api.disableClickToDial((response) => {
      if (response.success) {
        logger('Click-to-dial:disabled', JSON.stringify(response));
      } else {
        logger('Disable click-to-dial:failed', JSON.stringify(response));
      }
    });
  }

  function _searchAndPop(filter, defaultObj, callType) {
    const callback = (response) => {
      if (response.success) {
        logger('Screen-pop success', response);

        const res = response.returnValue;
        if (_.size(res) === 1) {
          _.toPairs(res).forEach(([key]) => {
            // Attempt pop if page allows
            api.screenPop(key, (rs) => {
              logger('Screen Pop Response:', rs);
            });
          });
        }
      } else {
        report({ type: 'error', title: 'Screen-pop failed', data: response });
      }
    };

    if (!service.isCurrentTab) return;

    if (_.hasIn(service.screenpop, 'default')) {
      SalesforceService.searchAndPop(service.callType);
    } else {
      api.searchAndScreenPop(null, filter, defaultObj, callType, callback);
    }
  }

  function _handleNewCall(agentInteractionId, callType, phone, activityStart,
    queueTime, agentName, campaignTitle) {
    if (!service.isCurrentTab) return;
    // // Standard Salesforce Task fields
    const saveParamObj = { CallObject: agentInteractionId.toString() };
    // Set callType to match Lightning CALL_TYPE
    switch (callType) {
      case 'Transfer':
        saveParamObj.CallType = sforce.opencti.CALL_TYPE.INTERNAL;
        break;
      case 'Outbound':
        saveParamObj.CallType = sforce.opencti.CALL_TYPE.OUTBOUND;
        break;
      case 'Inbound':
      default:
        saveParamObj.CallType = sforce.opencti.CALL_TYPE.INBOUND;
        break;
    }
    saveParamObj.Subject = `${callType} Call using ipSCAPE`;
    saveParamObj.ActivityDate = formatDate(activityStart, 'YYYY-MM-DDTHH:mm:ssZ');
    saveParamObj.Status = 'In Progress';
    saveParamObj.Type = 'Call';

    // Append the ipSCAPE Custom fields only if no JSON file
    if (!service.settings.dataMap) {
      if (queueTime) saveParamObj[`${customFieldPrefix}Time_In_Queue${customFieldSuffix}`] = queueTime;
      if (agentName) saveParamObj[`${customFieldPrefix}AgentName${customFieldSuffix}`] = agentName;
      if (campaignTitle) saveParamObj[`${customFieldPrefix}Campaign${customFieldSuffix}`] = campaignTitle;
    }

    _writeToActivityLog(saveParamObj)
      .then((response) => {
        logger('Write to Activity', {
          taskId: service.taskId,
          response,
        });
      })
      .catch((error) => {
        report({
          type: 'error',
          title: `New log error: ${agentInteractionId}`,
          data: error,
        });
      });
  }

  function _handleCallHangup() {
    if (!service.isCurrentTab) return;
    const params = {};
    _writeToActivityLog(params)
      .then((response) => {
        logger('handle onHangup:', response);
      })
      .catch((error) => {
        report({ type: 'error', title: 'Handle Hangup Error', data: error });
      });
  }

  function _handleCallWrap(msg) {
    return new Promise((resolve, reject) => {
      if (!service.isCurrentTab) reject(Error('Not current active tab'));
      _reportMismatchedRelatedRecords();
      // If there is no call info (i.e. we have not made a call)
      if (_.isEmpty(service.callInfo)) resolve();

      // CallService.selectedContact may be an empty object if there is no call record.
      const lastCaller = service.selectedContact || {};

      // Add the taskId
      lastCaller.taskId = service.taskId;
      TaskService.lastCaller = lastCaller;

      const params = {};
      params.CallDisposition = msg.data.wrapCodeDescription || '';
      params.Description = msg.data.notes || '';
      params.Status = 'Completed';

      // Get timestamps for call duration calculation
      const starttime = moment(CallService.callStartedDate).unix();
      const endtime = moment(CallService.callEndedDate).unix();

      params.CallDurationInSeconds = (_.isNaN(starttime) || _.isNaN(endtime))
        ? 0 : Math.floor(endtime - starttime);

      // Get related person
      if (msg.data.selectedRelatedPerson && typeof msg.data.selectedRelatedPerson.objectId !== 'undefined') {
        params.WhoId = msg.data.selectedRelatedPerson.objectId;
      }

      // Get related object
      if (msg.data.selectedRelatedObject) {
        params.WhatId = (typeof msg.data.selectedRelatedObject.objectId !== 'undefined')
          ? msg.data.selectedRelatedObject.objectId : '';
      }

      const writeLog = () => {
        _writeToActivityLog(params)
          .then((response) => {
            const additionalFields = [];
            if (msg.data.timeInWrap) additionalFields.push(makeCustomParam('WrapTime', msg.data.timeInWrap));
            _appendToActivityLog(additionalFields);

            if (TaskService.openTaskOnWrap) {
              _openActivityLog(service.taskId, () => {
                logger('openTask', service.taskId);
              });
            }
            resolve(response);
          }).catch((error) => {
            report({ type: 'error', title: 'Activity Update Error.', data: error });
            reject(error);
          });
      };

      _checkCurrentPageAgainstRelatedRecords()
        .then(() => {
          if (service.isOnOutboundPreview) {
            if (!service.previewCalled) writeLog();
            else resolve();
          } else {
            writeLog();
          }
        })
        .catch((error) => {
          reject(Error(error));
        });
    });
  }

  function _setNavigationChangeHandler() {
    api.onNavigationChange(_focusHandler);
    if (!initTimer) {
      initTimer = $interval(() => {
        _setPageInfoListener();
      }, 10000);
    }
    _setPageInfoListener();
  }

  // Event listener for Payment complete event message on broadcast channel
  bc.onmessage = (evt) => {
    if (_.hasIn(evt, 'resource')) {
      const source = evt.resource;
      if (source.window === 'payments') {
        if (source.action === 'pay') {
          const params = {};
          const { msg } = source;
          // get result
          if (_.hasIn(msg, 'approved')) {
            params[`transaction_result${customFieldSuffix}`] = (msg.approved) ? 'Approved' : 'Declined';
          }
          // get receipt number
          if (_.hasIn(msg, 'receipt_number')) {
            params[`receipt_number${customFieldSuffix}`] = msg.receipt_number;
          }
          // get response text
          if (_.hasIn(msg, 'response_text')) {
            params[`response_text${customFieldSuffix}`] = msg.response_text;
          }
          // get transaction reference
          if (_.hasIn(msg, 'transaction_reference')) {
            params[`transaction_reference${customFieldSuffix}`] = msg.transaction_reference;
          }
          // Send to activity log
          if (!_.isNil(params)) {
            SalesforceService.writeToLog({ fields: params })
              .then((response) => {
                logger('Task Updated', response);
              })
              .catch((error) => {
                report({ type: 'error', title: 'Payments _writeToActivityLog', data: error });
              });
          }
        }
      }
    }
  };

  function init() {
    _getConfigurationSettings()
      .catch((error) => {
        report({ type: 'error', title: 'Init: Error getConfigSettings', data: error });
      });
    _disableClickToDial();
    _toggleAdaptorVisibility();
    _setNavigationChangeHandler();
  }

  // Lightning Integration
  return {
    init,
    onEnterAvailable: () => {
      _enableClickToDial();
    },
    onEnterPaused: (msg) => {
      _enableClickToDial();
      if (msg && msg.op === 'agent.auto.pause') {
        _toggleAdaptorVisibility();
      }
    },
    onLeaveAvailable: () => {
      _disableClickToDial();
    },
    onLeavePaused: () => {
      _enableClickToDial();
    },
    onManCall: (msg) => {
      // no screen pop available for manual calls
      if (msg && msg.agentInteractionId) {
        logger('Manual Call:', JSON.stringify(msg));
        _handleNewCall(
          msg.agentInteractionId,
          'Outbound',
          msg.customerPhoneNumber,
          msg.activityStart,
          undefined,
          msg.agentFullName,
          msg.campaignTitle,
        );
      }
    },
    onInboundCall: (msg) => {
      _toggleAdaptorVisibility();
      const nationalPhoneNumber = msg.data.nationalCustomerPhoneNumber.replace(/[- )(]/g, '');
      const query = `${msg.data.customerPhoneNumber} OR ${nationalPhoneNumber} OR ${msg.data.internationalCustomerPhoneNumber}`;

      console.groupCollapsed('+-- onInboundCall --+');
      $log.debug(`- msg.data: ${JSON.stringify(msg.data)}`);
      $log.debug(`- parsed national: ${nationalPhoneNumber}`);
      $log.debug(`- after build phone number search filter: ${query}`);
      _searchAndPop(
        query,
        { Phone: nationalPhoneNumber },
        sforce.opencti.CALL_TYPE.INBOUND,
      );
      $log.debug('- after search screen pop');
      console.groupEnd();

      _handleNewCall(
        msg.data.agentInteractionId,
        msg.data.callType,
        msg.data.customerPhoneNumber,
        msg.data.activityStart,
        msg.data.timeInQueue,
        msg.data.agentFullName,
        msg.data.campaignTitle,
      );
    },
    onOutboundPreview: (msg) => {
      // Display adaptor
      _toggleAdaptorVisibility();
      _updatePopRecord(service.callData.agentInteractionId);
      // Prepare filter
      const numbers = [];
      let filter = '';
      const dataObj = msg.data || CallService.outboundInfo;
      let nationalPhoneNumber;

      if (!_.isNil(msg.data)) {
        if (dataObj.phoneNumber1) numbers.push(dataObj.phoneNumber1);
        if (dataObj.phoneNumber2) numbers.push(dataObj.phoneNumber2);
        if (dataObj.phoneNumber3) numbers.push(dataObj.phoneNumber3);
      }

      numbers.forEach((number) => {
        nationalPhoneNumber = number.replace(/[- )(]/g, '');
        const customerPhone = nationalPhoneNumber.replace(/[+][0-9][0-9]/, '0');
        filter += (filter === '')
          ? `${nationalPhoneNumber} OR ${customerPhone}`
          : ` OR ${nationalPhoneNumber} OR ${customerPhone}`;
      });
      // Create activity log
      SalesforceService.writeToLog({})
        .then(() => {
          logger('Preview log', {
            taskId: service.taskId,
            filter,
            PreviewData: service.previewData,
          });
          // Do search/pop for preview event
          _searchAndPop(filter, { Phone: nationalPhoneNumber },
            sforce.opencti.CALL_TYPE.OUTBOUND);
        })
        .catch((error) => {
          report({
            type: 'error',
            title: 'Preview Write Log',
            data: error,
          });
        });
    },
    onOutboundCall: (msg) => {
      logger('Outbound Call', msg.data);
      const nationalPhoneNumber = msg.data.nationalCustomerPhoneNumber.replace(/[- )(]/g, '');
      const filter = `${msg.data.customerPhoneNumber} OR ${nationalPhoneNumber} OR ${msg.data.internationalCustomerPhoneNumber}`;
      // Screen pop
      _searchAndPop(filter, { Phone: nationalPhoneNumber },
        sforce.opencti.CALL_TYPE.OUTBOUND);

      _handleNewCall(
        msg.data.agentInteractionId,
        'Outbound',
        msg.data.customerPhoneNumber,
        msg.data.activityStart,
        msg.data.timeInQueue,
        msg.data.agentFullName,
        msg.data.campaignTitle,
      );
    },
    onHangup: () => {
      _enableClickToDial();
      _toggleAdaptorVisibility();
      _handleCallHangup();
    },
    onBeforeOnWrap: msg => new Promise((resolve, reject) => {
      _handleCallWrap(msg)
        .then((response) => {
          resolve(response);
        })
        .then(() => {
          init();
        })
        .catch((error) => {
          reject(error);
        });
    }),
    onWrap: () => {
      _setNavigationChangeHandler();
      _enableClickToDial();
    },
  };
};

export default angular.module('CCAdaptor.App.SalesforceLightning', [])
  .service('salesforceLightning', SalesforceLightning).name;
