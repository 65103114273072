import AbstractWatcherBuilder from './abstractwatcher.builder';

export default class StringWatcherBuilder extends AbstractWatcherBuilder {
  constructor(watched, id, key) {
    super(watched, id, key);
  }

  get hasStoredValue() {
    return this.storageOp.isStringVal;
  }

  get storedValue() {
    return this.storageOp.stringVal;
  }

  save() {
    this.storageOp.stringVal = this.currentValue;
  }
}
