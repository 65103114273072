import angular from 'angular';
import _ from 'lodash';

class ApiService {
  constructor(storagemanager) {
    'ngInject';

    this.manager = storagemanager;
    this.locals = {};
    this.watchedAttributes = [];
    this.manager.watchers()
      .withObj(this.locals)
      .withId('api.service')
      .buildWithAttributes((a) => {
        this.watchedAttributes.push(
          a.withStringKey('version').withDefault('0').build(),
          a.withStringKey('build').withDefault('0').build(),
          a.withObjectKey('methods').withDefault([]).build(),
        );
      });
  }

  clear() {
    this.version = '0';
    this.build = '0';
    this.methods = [];
  }

  get version() { return parseFloat(this.locals.version); }

  set version(ver) { this.locals.version = ver; }

  get build() { return parseFloat(this.locals.build); }

  set build(str) {
    // Explode the string and use the first two parts
    const arr = str.split('.');
    const res = (arr.length > 1) ? `${arr[0]}.${arr[1]}` : 0;
    this.locals.build = res;
  }

  get methods() {
    let availMethods = [];
    if (_.hasIn(this.locals, 'methods')) {
      availMethods = this.locals.methods;
    }
    return availMethods;
  }

  set methods(list) {
    this.locals.methods = list;
  }

  hasMethod(endpoint) {
    if (!_.hasIn(this.locals, 'methods')) {
      return false;
    }

    const methods = this.locals.methods;
    let endpointExists;

    _.forEach(methods, (method) => {
      if (method.path === endpoint) {
        endpointExists = true;
      }
    });

    return endpointExists;
  }
}

export default angular.module('CCAdaptor.App.ApiService', [])
  .service('ApiService', ApiService).name;
