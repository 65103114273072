import angular from 'angular';
import _ from 'lodash';

const callInfoTemplate = require('./call-info-directive.html');

const IPSCallInfo = () => {
  class IPSCallInfoController {
    constructor($scope, $rootScope, CallService) {
      'ngInject';

      $scope.callInfo = CallService.callInfo || {};
      $scope.callData = CallService.ivrData || {};

      // Watch for changes to call info
      $rootScope.$watch(() => { return CallService.callInfo; }, (newVal, oldVal) => {
        if (newVal !== oldVal) {
          $scope.callInfo = newVal || {};
          // Update the display
          if (_.hasIn($scope.callInfo, 'callType')) {
            this.formatCallData();
          }
        }
      }, true);

      this.formatCallData = () => {
        $scope.tags = [];
        $scope.items = [];
        $scope.ivrData = [];
        // Sets up the array for displaying the IVR data
        angular.forEach($scope.callData, (value, key) => {
          angular.forEach($scope.objects.ivrData.data, (data) => {
            if (key === data.param) {
              if (data.asTag) {
                let tagClass;
                const numFields = data.outcomes.length;
                let i = 0;
                const rex = new RegExp(value, 'gi');

                do {
                  const str = data.outcomes[i].text;
                  if (str.match(rex)) {
                    tagClass = data.outcomes[i].class;
                  }
                  i += 1;
                }
                while (i < numFields);

                if (typeof tagClass === 'undefined') {
                  tagClass = data.class;
                }

                $scope.tags.push({ key, value, class: tagClass });
              } else {
                $scope.ivrData.push({ key, value, label: data.label });
              }
            }
          });
        });

        // Sets up the array for items to display from the call data
        angular.forEach($scope.objects, (value, key) => {
          if (key === 'ivrData' || key === 'default' || !value) {
            return;
          }
          const item = (_.hasIn($scope.callInfo, key)) ? $scope.callInfo[key] : null;

          // Split the key string & uppercase the first character
          const label = key.replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => { return str.toUpperCase(); });

          $scope.items.push({ key: label, value, item });
        });
      };

      // Private method to format timeInQueue string
      this.formatTimeInQueue = (secs) => {
        let timeStr = '';
        const h = Math.floor(secs / (60 * 60));
        const m = Math.floor((secs - h * (60 * 60)) / 60);
        const s = Math.floor((secs - h * (60 * 60)) - (m * 60));

        if (h > 0) {
          timeStr += `${h} hr `;
        }
        if (h > 0 || m !== 0) {
          timeStr += `${m} min `;
        }
        if (s) {
          timeStr += `${s} sec`;
        }

        if (timeStr.length <= 1) {
          timeStr = '0 sec';
        }

        return timeStr;
      };

      $scope.timeInQueue = this.formatTimeInQueue($scope.callInfo.timeInQueue);

      $scope.hasIvrData = () => {
        let display;
        let count = 0;

        if (_.hasIn($scope.callData, 'display')) {
          display = true;
        }

        if (!_.isNull($scope.callData)) {
          count = _.size($scope.callData);
        }

        if (count >= 1 && !_.isBoolean(display)) {
          return true;
        } else if (count >= 1 && display) {
          return true;
        }

        return false;
      };

      $scope.hasCustomObjects = () => {
        if (!_.isNil($scope.objects) && !$scope.objects.default) {
          return true;
        }
        return false;
      };

      this.formatCallData();
    }
  }

  return {
    restrict: 'EA',
    template: callInfoTemplate,
    controller: IPSCallInfoController,
    controllerAs: 'CallInfo',
    scope: {
      objects: '=objects',
    },
  };
};

export default angular.module('CCAdaptor.App.IPSCallInfo', [])
  .directive('ipsCallInfo', IPSCallInfo).name;
