/*
 * Javascripts
 * ========================================================================== */

import angular from 'angular';
import ToolbarController from './controller/ToolbarController';

// ------------
// DIALOGS
// ------------

// ------------
// DIRECTIVES
// ------------
import StatusDirective from './directives/info/ips-status.directive';
import ManualCallDirective from './directives/manualcall/manual-call.directive';

// -- actions
import ActionsDirective from './directives/actions/ips.toolbar.actions.directive';

/*
 * Stylesheets
 * ========================================================================== */

import './stylesheets/toolbar.scss';

/* ========================================================================== */

/**
 * Define home page module.
 * @param {String} moduleName.
 * @param {Array} dependencies.
 * @export Module name - name of this module.
 */
export default angular.module('CCAdaptor.App.Toolbar', [
  ActionsDirective,
  StatusDirective,
  ManualCallDirective,
]).controller('ToolbarController', ToolbarController).name;
