import angular from 'angular';

const IPSCampaignInfo = (CampaignService) => {
  'ngInject';

  class CampaignInfoController {
    constructor($scope, CallService) {
      'ngInject';

      // Bind $scope to CallService property campaignTitle
      Object.defineProperty($scope, 'campaignTitle', {
        get: () => { return CallService.campaignTitle; },
        configurable: true,
        enumerable: false,
      });
      // Bind $scope to CallService property getter hasCampaign
      Object.defineProperty($scope, 'hasCampaign', {
        get: () => { return CallService.hasCampaign; },
        configurable: true,
        enumerable: false,
      });
    }
  }

  return {
    restrict: 'E',
    template: require('./campaignInfo.view.html'),
    controller: CampaignInfoController,
    controllerAs: 'CampaignInfo',
    scope: {
      form: '=form',
      margin: '=margin',
    },
  };
};

export default angular.module('CCAdaptor.App.CampaignInfo', [])
  .directive('ipsCampaignInfo', IPSCampaignInfo).name;
