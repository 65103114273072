import angular from 'angular';
import _ from 'lodash';

// ---------------------------------------
// Socket Manager
// ---------------------------------------
class SocketMan {
  constructor($log, $interval, AgentInfoService, AuthService, IntegrationService, util,
    ErrorService, WebsocketStatus, MessageHandlerFactory) {
    'ngInject';

    this.apiHostWS = IntegrationService.apiHostWS;
    this.socketConnection = undefined;

    // Make the function wait until the connection is made...
    // ToDo: Fix this so that is no auth does not keep looping
    this.waitForSocketConnection = (callback) => {
      setTimeout(() => {
        if (this.isOpen()) {
          $log.info('*** IPS Websocket ***: connection ready');
          if (callback != null) {
            callback();
          }
        } else {
          $log.info('*** IPS Websocket ***: Waiting for connection...');
          this.waitForSocketConnection(callback);
        }
      }, 100);
    };

    this.isAuthorised = () => {
      return AuthService.isLoggedIn();
    };

    this.isOpen = () => {
      return this.socketConnection && this.socketConnection.readyState === WebSocket.OPEN;
    };

    this.isConnecting = () => {
      return this.socketConnection && this.socketConnection.readyState === WebSocket.CONNECTING;
    };

    this.isClosedOrClosing = () => {
      return this.socketConnection && (this.socketConnection.readyState === WebSocket.CLOSING
        || this.socketConnection.readyState === WebSocket.CLOSED);
    };

    this.login = () => {
      if (!this.isOpen() || !this.isAuthorised()) {
        return;
      }

      try {
        const msg = JSON.stringify({
          op: 'login',
          opId: util.guid(),
          auth: AuthService.sessionToken,
          org: AgentInfoService.organisationDetails.orgId,
        });

        // @ts-ignore
        this.waitForSocketConnection(this.socketConnection.send(msg, (error) => {
          if (error === undefined) {
            return;
          }

          ErrorService.report('Websocket login Async error', error);
        }));
      } catch (e) {
        ErrorService.report('Websocket login error', e);
      }
    };

    this.sync = () => {
      if (!this.isOpen() || !this.isAuthorised()) {
        return;
      }

      try {
        const msg = JSON.stringify({
          op: 'sync',
          opId: util.guid(),
        });

        // @ts-ignore
        this.waitForSocketConnection(this.socketConnection.send(msg, (error) => {
          if (error === undefined) {
            return;
          }

          ErrorService.report('Websocket sync error', error);
        }));
      } catch (e) {
        ErrorService.report('Websocket login error', e);
      }
    };

    this.disconnect = () => {
      // Close the socket and reset
      if (this.isOpen()) {
        this.socketConnection.close();
        this.socketConnection = undefined;
      }
    };

    this.connect = () => {
      // Check for any existing socket connections
      if (this.isOpen() || this.isConnecting() || !this.isAuthorised()) {
        return;
      }

      if (!_.hasIn(this.socketConnection, 'readyState') || this.isClosedOrClosing()) {
        this.socketConnection = new WebSocket(`${this.apiHostWS}/api/latest/socket`);
      }

      this.socketConnection.onopen = (response) => {
        // Added the conditional as the socket.onopen is firing when
        // the socket readyState is .CONNECTING and .OPEN
        if (this.isOpen()) {
          this.login();
        }
      };

      this.socketConnection.onerror = (event) => {
        const details = {
          event,
          readyState: this.socketConnection.readyState,
        };

        ErrorService.report('IPS WebSocket Error', { host: this.apiHostWS, details });
      };

      this.socketConnection.onclose = (event) => {
        WebsocketStatus.status = 'disconnected';

        if (ErrorService.error && ErrorService.error.status === 401) {
          WebsocketStatus.status = 'disconnected';
        }
      };

      this.socketConnection.onmessage = (event) => {
        const msg = JSON.parse(event.data);
        if (msg) {
          // Pass to message handler
          MessageHandlerFactory.messageHandler(msg);
        }
      };
    };

    this.reconnect = () => {
      if (!this.isOpen()) {
        this.connect();
        return;
      }
      this.sync();
    };

    const socketKeeper = () => {
      if (this.isOpen()) {
        if (this.socketConnection.bufferedAmount === 0) {
          try {
            const msg = JSON.stringify({
              op: 'ping',
              opId: Date.now(),
            });

            // @ts-ignore
            this.socketConnection.send(msg, (error) => {
              if (error === undefined) {
                return;
              }

              ErrorService.report('Ping Async  error', error);
            });
          } catch (e) {
            ErrorService.report('Ping error', e);
            this.reconnect();
          }
        }
      }
    };

    this.socketKeeperInterval = $interval(socketKeeper, 30000);

    this.reconnectInterval = $interval(() => {
      this.connect();
    }, 5000);

    this.currentAgentState = () => {
      if (this.isOpen()) {
        try {
          const msg = JSON.stringify({
            op: 'status',
            opId: util.guid(),
          });

          // @ts-ignore
          this.socketConnection.send(msg, (error) => {
            if (error === undefined) {
              return;
            }

            ErrorService.report('Agent State Async error', error);
          });
        } catch (e) {
          ErrorService.report('Agent State error', e);
        }
      }
    };
  }

  // ---------------------------------------
  // Service
  // ---------------------------------------

  connect() {
    this.connect();
  }

  disconnect() {
    this.disconnect();
  }

  reconnect() {
    this.reconnect();
  }

  currentAgentState() {
    this.currentAgentState();
  }
}

export default angular.module('CCAdaptor.App.SocketMan', [])
  .service('SocketMan', SocketMan).name;
