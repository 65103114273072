import angular from 'angular';

const IPSTaskLinkTemplate = require('./ips-task-link.directive.html');

const IPSTaskLink = ($log, $rootScope, agentFSM, IntegrationService,
  ConfigService, TaskService) => {
  'ngInject';

  class IPSTaskLinkController {
    constructor($scope) {
      'ngInject';

      const task = {};
      const ipsSettings = ConfigService.ipsSettings;

      $scope.showOpenLogButton = () => {
        if (IntegrationService.type !== 'sf') {
          return false;
        }

        if (agentFSM.isOnOutboundPreview()) {
          return false;
        }

        return ipsSettings.showOpenLogButton === 'true';
      };

      $scope.isDisabled = true;
      $scope.openTaskOnWrap = TaskService.openTaskOnWrap || false;

      task.handleError = (response, asWarning) => {
        if (asWarning) $log.warn(response.error);
        else $log.error(response.error);
      };

      $scope.$watch(() => { return TaskService.taskId; }, (newVal, oldVal) => {
        if (newVal) {
          $scope.isDisabled = false;
        }
      });

      $scope.$watch('openTaskOnWrap', (newValue, oldValue) => {
        if (newValue !== oldValue) {
          TaskService.openTaskOnWrap = newValue;
        }
      });
    }
  }

  return {
    restrict: 'E',
    template: IPSTaskLinkTemplate,
    controller: IPSTaskLinkController,
    controllerAs: 'taskLinkCtrl',
    scope: true,
  };
};

export default angular.module('CCAdaptor.App.TaskLink', [])
  .directive('ipsTaskLink', IPSTaskLink).name;
