import LocalStorageOps from './localstorage.ops';
import WatchJS from 'watchjs';
import _ from 'lodash';

export default class AbstractWatcherBuilder {
  constructor(watched, id, key) {
    this.locals = {};
    this.locals.watched = watched;
    this.locals.id = id;
    this.locals.key = key;
    this.locals.storageOp = new LocalStorageOps(id, key);
    this.locals.callback = null;
    this.locals.default = null;
  }

  build() {
    WatchJS.watch(this.locals.watched, this.locals.key, (prop, action, newVal, oldVal) => {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        // Removed temporarily to clean up Rollbar telemetry
        // This data should be provided grouped when logging an error
        //console.log(`watched: ${this.locals.id}.${this.locals.key} changed from ${oldVal} to ${JSON.stringify(newVal)}`);
        this.save();
        if (this.locals.callback) {
          this.locals.callback(newVal, oldVal);
        }
      }
    });

    this.init();
    return this;
  }

  init() {
    if (this.hasStoredValue) {
      this.currentValue = this.storedValue;
    } else if (this.defaultValue) {
      this.currentValue = this.defaultValue;
    }
  }

  get currentValue() {
    return _.get(this.locals.watched, this.locals.key);
  }

  set currentValue(newVal) {
    _.set(this.locals.watched, this.locals.key, newVal);
  }

  get defaultValue() {
    return _.isFunction(this.locals.default) ? this.locals.default() : this.locals.default;
  }

  get storageOp() {
    return this.locals.storageOp;
  }

  save() { /* Abstract */ }

  /**
   * @param f - a function(newVal)
   * @returns this
   */
  withCallBack(f) {
    this.locals.callback = f;
    return this;
  }

  /**
   * @param f - a scalar or function
   * @returns this
   */
  withDefault(f) {
    this.locals.default = f;
    return this;
  }
}
