import BroadcastChannel from 'broadcast-channel';
import _ from 'lodash';

const Prepare = ($log, $rootScope, $interval, $window, SyncStateService,
  AuthService, PaymentsService, SocketFactory) => {
  'ngInject';

  // Set guid to sessionStorage
  sessionStorage.setItem('guid', window.IPSCAPE.guid);
  // Set currentTab as default
  $rootScope.currentTab = true;

  const channel = new BroadcastChannel('ipscape');
  let isHidden = 'hidden';
  let currentTabChecker;
  let visibilityChange = 'visibilitychange';
  const instanceGuid = sessionStorage.getItem('guid');

  function checkCurrentTab() {
    if (localStorage.getItem('guid') === instanceGuid) {
      $rootScope.currentTab = true;
    } else {
      $rootScope.currentTab = false;
    }
  }

  currentTabChecker = $interval(() => {
    checkCurrentTab();
  }, 1000);

  // Window storage event handler for guid
  $window.onstorage = (e) => {
    if (e.key === 'guid') {
      checkCurrentTab();
      $log.info(`[GUID] changed from ${e.oldValue} to ${e.newValue}.`);
    }
  };

  function setStorageGuid(guid) {
    return new Promise(
      (resolve, reject) => {
        try {
          localStorage.setItem('guid', guid);
          resolve(true);
        } catch (error) {
          reject();
        }
      },
    );
  }

  function setAsVisible() {
    setStorageGuid(instanceGuid)
      .then(() => {
        if (!localStorage.getItem('auth.loggedIn')) AuthService.logout();
      }).then(() => {
        SyncStateService.syncState();
      });
  }

  if (typeof document.hidden !== 'undefined') {
    isHidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    isHidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    isHidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  function handleVisibilityChange() {
    if (!document[isHidden]) {
      setAsVisible();
    }
  }

  // Watcher for changes to currentTab
  $rootScope.$watch('currentTab', (newVal) => {
    if (newVal) setAsVisible();
  });

  // Handle page visibility change
  if (typeof document.addEventListener === 'undefined') {
    $log.error('This browser does not support page visibility; Synchronisation and cross-tab behaviour will fail');
  } else {
    document.addEventListener(visibilityChange, handleVisibilityChange, false);

    $window.addEventListener('beforeunload', () => {
      if (currentTabChecker) {
        $interval.cancel(currentTabChecker);
        currentTabChecker = undefined;
      }
      channel.postMessage({
        action: 'unload',
      });
    });
  }

  channel.onmessage = (ev) => {
    if (_.hasIn(ev, 'resource')) {
      const source = ev.resource;
      if (source.$window === 'payments') {
        if (source.action === 'open') {
          PaymentsService.isActive = true;
        }

        if (source.action === 'close') {
          if (source.uuid === localStorage.getItem('payments.uuid') || _.isNil(localStorage.getItem('payments.uuid'))) {
            PaymentsService.isActive = false;
          }

          if (AuthService.isLoggedIn()) {
            SocketFactory.reconnect();
          }
          SyncStateService.syncState();
        }
      }
    }

    if (ev.action === 'unload') {
      setStorageGuid(instanceGuid).then(() => {
        if (!localStorage.getItem('auth.loggedIn')) {
          AuthService.logout();
        }
      }).then(() => {
        SyncStateService.syncState();
      });
    }
  };
};

/** Export our runner */
export default Prepare;
