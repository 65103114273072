import angular from 'angular';
import _ from 'lodash';

const componentTemplate = require('./ServicesTab.component.html');

class ServicesTabController {
  constructor($scope, $rootScope, $log, $window, agentFSM, PaymentsService, ConfigService) {
    'ngInject';

    $scope.btnLabel = 'Make a Payment';

    $scope.initTransaction = () => {
      const adaptorOrigin = window.location.origin;
      const paymentUrl = `${adaptorOrigin}/resource/payment/?ref=&amount=&readOnlyReferenceId=0&readOnlyAmount=0`;
      const windowOpts = 'menubar=no,location=no,resizable=yes,scrollbars=yes,status=no,width=992,height=786';
      $window.open(paymentUrl, '', windowOpts);
    };

    // Check if payments are disabled in the settings
    const isPayDisabled = () => {
      // Check if there is a setting for disable btn
      const settings = ConfigService.ipsSettings;
      if (_.hasIn(settings, 'disablePaymentsBtn')) {
        PaymentsService.disable = (typeof settings.disablePaymentsBtn === 'string')
          ? settings.disablePaymentsBtn === 'true' : settings.disablePaymentsBtn;
      }
      return PaymentsService.disable || false;
    };

    $scope.disablePaymentsBtn = () => {
      if (PaymentsService.isActive) {
        return true;
      }

      return isPayDisabled();
    };
  }
}

export default angular.module('CCAdaptor.App.servicesTab', [])
  .component('servicesTab', {
    template: componentTemplate,
    controller: ServicesTabController,
  }).name;
