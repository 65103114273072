import angular from 'angular';

const Timer = () => {
  'ngInject';

  class TimerController {
    constructor($scope, $interval, agent) {
      'ngInject';

      $scope.time = null;
      $scope.format = null;

      $interval(() => {
        const fsmCurrentStateDt = parseInt(localStorage.getItem('agent.fsm.currentStateDt'), 10);
        const statusCurrentStateDt = parseInt(localStorage.getItem('status.service.currentStateDt'), 10);
        const timeDiff = !(statusCurrentStateDt)
          ? new Date().getTime() - fsmCurrentStateDt
          : new Date().getTime() - statusCurrentStateDt;

        $scope.format = parseInt(timeDiff / (1000 * 60 * 60), 10) ? 'hh:mm:ss' : 'mm:ss';
        $scope.time = timeDiff;
      }, 1000);
    }
  }

  const directive = {
    restrict: 'EA',
    controller: TimerController,
  };
  return directive;
};

export default angular.module('CCAdaptor.App.IPSTimer', [])
  .directive('ipsTimer', Timer).name;
