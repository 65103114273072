import angular from 'angular';

class AnalyticsService {
  constructor($injector, storagemanager, $log, IntegrationService, $interval) {
    'ngInject';

    this.GA_ID = `UA-${GA_TRACKING_ID}`;
    this.ga = window.ga || {};
    this.integrationService = IntegrationService;
    this.$injector = $injector;
    this.interval = $interval;
    this.log = $log;
    this.heatbeat = undefined;
    this.hostname = this.integrationService.apiUrl;
    this.listeners = [];
    this.manager = storagemanager;
    this.locals = {};
    this.watchedAttributes = [];
    this.manager.watchers()
      .withObj(this.locals)
      .withId('analytics')
      .buildWithAttributes((a) => {
        this.watchedAttributes.push(
          a.withStringKey('state')
            .build(),
        );
      });
  }

  get state() {
    return this.locals.state;
  }

  set state(state) {
    this.locals.state = state;
  }

  init() {
    try {
      this.ga('create', this.GA_ID, 'auto');
    } catch (err) { Error(`GA Init Error: ${err}`); }
  }

  send(agentState) {
    if (typeof ga === 'undefined') {
      return;
    }

    const AuthService = this.$injector.get('AuthService');
    if (!AuthService.isLoggedIn()) {
      return;
    }

    // Update localStorage
    this.state = agentState;

    // condense state to slug
    let slug = agentState.toLowerCase();
    slug = slug.replace(/\s/g, '-');
    try {
      this.ga('set', { page: `/ctiadaptor/${slug}`, title: agentState, dimension1: this.hostname });
      this.ga('send', 'pageview');
    } catch (err) {
      Error(`GA Send Error: ${err}`);
    }
  }

  heartbeat() {
    this.heatbeat = this.interval(() => {
      try {
        this.ga('send', {
          hitType: 'event',
          eventCategory: 'Heartbeat',
          eventAction: 'heartbeat',
          eventLabel: this.state,
          dimension1: this.hostname,
        });
      } catch (err) { Error(`GA Heartbeat Error: ${err}`); };
    }, 60 * 1000);
  }

  cancelHeartbeat() {
    if (!this.heatbeat) {
      return;
    }
    this.interval.cancel(this.heatbeat);
    this.heartbeat = undefined;
  }

  reset() {
    this.locals.eventState = '';
    this.cancelHeartbeat();
  }
}

export default angular.module('CCAdaptor.App.AnalyticsService', [])
  .service('AnalyticsService', AnalyticsService).name;
