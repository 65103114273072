import angular from 'angular';

const Campaigns = ($http, $httpParamSerializer, $q, $log, $timeout, $rootScope,
  ErrorService, IntegrationService, CampaignService) => {
  'ngInject';

  const forAgentId = (agentId) => {
    return {
      list: () => {
        const dfd = $q.defer();
        const endpoint = '/user/readallocatedcampaignslist';
        const params = { userId: agentId, perPage: 99 };

        $http.get(IntegrationService.latestApiUri(endpoint), {
          params,
        }).then((response) => {
          CampaignService.allocatedCampaigns = response.data.result.data;
          dfd.resolve(response.data);
        }).catch((response) => {
          ErrorService.handleError(endpoint, response);
          dfd.reject(response);
        });

        return dfd.promise;
      },
    };
  };

  const withId = (campaignId) => {
    return {
      loadWrapCodes: () => {
        const endpoint = '/campaign/readwrapcodeslist';
        const params = { campaignId };

        const dfd = $q.defer();
        $http.get(IntegrationService.latestApiUri(endpoint), {
          params,
        }).then((response) => {
          dfd.resolve(response);
        }).catch((response) => {
          ErrorService.handleError(endpoint, response);
          dfd.reject(response);
        });

        return dfd.promise;
      },
    };
  };

  return {
    forAgentId,
    withId,
  };
};

export default angular.module('CCAdaptor.App.Campaigns', [])
  .factory('campaigns', Campaigns).name;
