import angular from "angular";

const IPSStatus = (agentFSM) => {
  "ngInject";

  class IPSStatusController {
    constructor($scope, StatusService) {
      "ngInject";

      $scope.isOnPhonePaused = agentFSM.isOnPhonePaused;
      $scope.isOnPhoneAvailable = agentFSM.isOnPhoneAvailable;
      $scope.pauseReasonTitle = () => StatusService.pauseReasonTitle;
    }
  }

  return {
    restrict: 'EA',
    template: require('./ips-status.directive.html'),
    controller: IPSStatusController,
    controllerAs: 'Status'
  };
};

export default angular.module("CCAdaptor.App.IPSStatus", [])
  .directive('ipsStatus', IPSStatus).name;
