import angular from 'angular';
import _ from 'lodash';

const announcementsTemplate = require('./ipsAnnouncements.directive.html');

const IPSAnnouncements = () => {
  class IpsAnnouncementsController {
    constructor($scope, $rootScope, $log, ToastrService, calls, agentFSM, PhoneService,
      CallService) {
      'ngInject';

      $scope.announcement = () => {
        return CallService.announcement;
      };

      $scope.items = () => {
        return CallService.announcementList;
      };

      $scope.activityId = () => {
        return CallService.activityId;
      };

      $scope.campaignId = () => {
        return CallService.campaignId;
      };

      const setAnnouncement = (newValue) => {
        CallService.announcement = newValue;
      };

      // Have to reset list if campaign changes
      $scope.$watch('campaignId', (newValue, oldValue) => {
        if (newValue !== oldValue) {
          if (newValue === null) {
            CallService.announcementList = [];
          }
        }
      });

      $scope.disablePlayButton = () => {
        if (!agentFSM.isOnCall()) return true;
        return !$scope.announcement().selected;
      };

      $scope.play = () => {
        calls.withActivityId($scope.activityId()).playAnnouncement(
          CallService.announcement.selected,
        ).then((response) => {
          $log.info('[Play Announcement]', response);
          setAnnouncement({
            playing: true,
            title: 'Stop Announcement',
            selected: parseInt($scope.announcement().selected, 10),
          });
        });
      };

      $scope.stop = () => {
        calls.withActivityId($scope.activityId()).stopAnnouncement().then((response) => {
          $log.info('[Stop Announcement]', response);
          CallService.announcement.selected = null; // Reset
        });
      };

      $scope.do = () => {
        if (!$scope.announcement().playing) {
          $scope.play();
        } else {
          $scope.stop();
        }
      };

      $scope.announcementChanged = () => {
        if ($scope.announcement().selected) {
          CallService.announcement.selected = parseInt($scope.announcement().selected, 10); // Reset
        }
      };

      const readAnnouncementsList = () => {
        if ($scope.items().length <= 1) {
          calls.withCampaignId($scope.campaignId()).readAnnouncementsList().then((response) => {
            const list = response.data.result.data;
            const item = {
              announcementFilename: null,
              announcementId: '',
              announcementName: 'Select Announcement',
            };
            list.splice(0, 0, item);
            CallService.announcementList = list;
          });
        }
      };

      $rootScope.$on('event.system', (event, data) => {
        if (_.hasIn(data, 'action')) {
          if (data.action === 'announcement.end') {
            setAnnouncement({
              playing: false,
              title: 'Play Announcement',
              selected: null,
            });
            ToastrService.makeToast({
              type: 'info',
              message: 'The current announcement has finished playing.',
            });
          }
        }
      });

      if ($scope.enabled) {
        readAnnouncementsList();
      }
    }
  }

  return {
    restrict: 'EA',
    template: announcementsTemplate,
    controller: IpsAnnouncementsController,
    scope: {
      enabled: '=',
    },
    controllerAs: 'ipsAnnouncements',
  };
};

export default angular.module('CCAdaptor.App.ipsAnnouncements', [])
  .directive('ipsAnnouncements', IPSAnnouncements).name;
