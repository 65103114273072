/* global openFrameAPI */
import angular from 'angular';
import _ from 'lodash';
import SNTasks from './servicenow.tasks';

const ServicenowIntegration = ($q, $log, $rootScope, $state, AgentVoiceStatus,
  ConfigService, sntasks) => {
  'ngInject';

  $rootScope.$on('event.system', (event, data) => {
    if (_.hasIn(data, 'action')) {
      if (data.action === 'agent.state.change') {
        if (data.params.to === 'onPhonePaused') {
          openFrameAPI.show();
        }
      }
    }
  });

  AgentVoiceStatus.addListener((status) => {
    if (!$state.current.name || $state.current.name === 'login') return;
    if (status === 'disconnected') {
      openFrameAPI.show();
    }
  });

  const isJSON = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const doesNothing = () => {
    // Does nothing
  };

  return {
    init: () => {
      sntasks.loadAdaptorConfig();
    },
    onEnterAvailable: doesNothing,
    onLeaveAvailable: doesNothing,
    onEnterPaused: doesNothing,
    onLeavePaused: doesNothing,
    onOutboundPreview: doesNothing,
    onManCall: (msg) => {
      const ipsConfig = ConfigService.config;
      const outboundConfig = ipsConfig.adaptor.configuration.screenPopConfig.outbound;
      const createNewRecord = outboundConfig.createNewRecord;
      const entity = outboundConfig.recordType;
      const useCtiDo = (outboundConfig.useCtiDo === 'true');
      const ctiRule = outboundConfig.sysparm_cti_rule;

      let fMap = '';
      let queryStr = '';

      fMap = sntasks.mapFields('outbound', msg);

      if (useCtiDo) {
        queryStr = `sysparm_cti_rule=${ctiRule}${fMap}`;

        // create screen pop
        openFrameAPI.openServiceNowForm({ entity, query: queryStr });
      }

      if (!useCtiDo && createNewRecord === 1) {
        queryStr = `sys_id=-1${fMap}`;
        openFrameAPI.openServiceNowForm({ entity, query: queryStr });
      }

      openFrameAPI.show();
    },
    onOutboundCall: (msg) => {
      $log.debug(`onOutboundCall - msg.data: ${JSON.stringify(msg.data)}`);
      const ipsConfig = ConfigService.config;
      let transferJson = '';
      openFrameAPI.show();

      if (typeof ipsConfig.adaptor.configuration.screenPopConfig === 'undefined') {
        $rootScope.$broadcast('event.notify', {
          action: 'error',
          tag: 'ipsConfig.adaptor.configuration.screenPopConfig',
          message: 'ServiceNow configuration unavailable',
          details: [{
            debuggingInfo: 'ServiceNow integration settings error',
            description: 'Unable to retreive ServiceNow settings.',
            name: 'action.error.sn.settings',
          }],
        });
      }

      const config = ipsConfig.adaptor.configuration.screenPopConfig.outbound;
      const createNewRecord = config.createNewRecord;
      const entity = config.recordType;
      const useCtiDo = (config.useCtiDo === 'true');
      const ctiRule = config.sysparm_cti_rule;

      let map = '';
      let query = '';

      map = sntasks.mapFields('outbound', msg.data);

      if (useCtiDo) {
        query = `sysparm_cti_rule=${ctiRule}${map}`;
      }

      if (!useCtiDo && createNewRecord === 1) {
        query = `sys_id=-1${map}`;
      }

      openFrameAPI.openServiceNowForm({
        entity,
        query,
      });
    },
    onInboundCall: (msg) => {
      const ipsConfig = ConfigService.config;
      let transferJson = '';
      openFrameAPI.show();

      if (typeof ipsConfig.adaptor.configuration.screenPopConfig === 'undefined') {
        $rootScope.$broadcast('event.notify', {
          action: 'error',
          tag: 'ipsConfig.adaptor.configuration.screenPopConfig',
          message: 'ServiceNow configuration unavailable',
          details: [{
            debuggingInfo: 'ServiceNow integration settings error',
            description: 'Unable to retrieve ServiceNow settings.',
            name: 'action.error.sn.settings',
          }],
        });
      }

      if (msg.data.transferCall) {
        transferJson = msg.data.callNotes.substring(msg.data.callNotes.indexOf(':') + 1);

        if (isJSON(transferJson)) {
          const transferData = JSON.parse(transferJson);
          let queryStr = '';
          let firstIteration = true;

          const entity = ipsConfig.adaptor.configuration.screenPopConfig.transfer.recordType;

          let fMap = '';

          fMap = sntasks.mapFields('transfer', msg.data);

          angular.forEach(transferData, (value, key) => {
            if (value) {
              if (!firstIteration) {
                queryStr += '&';
              }
              queryStr += `${key}=${value}`;
            }
            firstIteration = false;
          });

          queryStr += `${fMap}`;

          openFrameAPI.openServiceNowForm({ entity, query: queryStr });
        }
      } else {
        const inboundConfig = ipsConfig.adaptor.configuration.screenPopConfig.inbound;
        const createNewRecord = inboundConfig.createNewRecord;
        const entity = inboundConfig.recordType;
        const useCtiDo = (inboundConfig.useCtiDo === 'true');
        const ctiRule = inboundConfig.sysparm_cti_rule;

        let fMap = '';
        let queryStr = '';

        fMap = sntasks.mapFields('inbound', msg.data);

        if (useCtiDo) {
          queryStr = `sysparm_cti_rule=${ctiRule}${fMap}`;
        }

        if (!useCtiDo && createNewRecord === 1) {
          queryStr = `sys_id=-1${fMap}`;
        }

        openFrameAPI.openServiceNowForm({ entity, query: queryStr });
      }
    },
    onHangup: () => {
      openFrameAPI.show();
    },
    onBeforeOnWrap: (msg) => {
      const dfd = $q.defer();
      dfd.resolve();
      return dfd.promise;
    },
    onWrap: (msg) => {
      sntasks.wrapCall(msg);
    },
  };
};

export default angular.module('CCAdaptor.App.ServicenowIntegration', [SNTasks])
  .service('servicenowIntegration', ServicenowIntegration).name;
