import angular from "angular";

/**
 * simple implementation of design by contract, that integrates with our error handling
 * strategy. Basically will throw a DbcX, which will get caught an handled in a global/common
 * handler of some sort.
 *
 * the hope is this will highlight errors earlier in the development process, and make it
 * easier to figure out the cause of errors that do occur.
 *
 * these functions should _only_ be used to catch errors that are the failing of a client call,
 * (ie programming errors)
 */
const DesignByContract = () => {
  "ngInject";

  function DbcX(message, context) {
    return {name: "DbcX", message: message, context: context};
  }

  function assume(message, expr, context) {
    if (!expr) {
      throw new DbcX(message, context);
    }
  }

  function assumeDefined() {
    for (var arg = 0; arg < arguments.length; ++arg) {
      var arr = arguments[arg];
      assume("argument " + arg + " must be defined", arr);
    }
  }

  function assumeDefinedAndNonNull() {
    for (var arg = 0; arg < arguments.length; ++arg) {
      var arr = arguments[arg];
      assume("argument (counting from 0) " + arg + " must be defined and not null", (typeof arr !== 'undefined') && (arr !== null));
    }
  }

  function ensure(message, expr, context) {
    if (message === undefined) {
      throw new DbcX("dbc.ensure() must be called with a message parameter", arguments);
    }

    if (typeof message !== "string") {
      throw new DbcX("dbc.ensure() must be called with a message parameter that evaluates to a string", arguments);
    }

    if (expr === undefined) {
      throw new DbcX("dbc.ensure() must be called with an expression", arguments);
    }

    if (typeof expr !== "boolean") {
      throw new DbcX("dbc.ensure() must be called with expr parameter that evaluates to a boolean", arguments);
    }

    if (!expr) {
      throw new DbcX(message, context);
    }
  }

  //todo mg:  maybe something like the following? (meh, should have used typescript...)
  //ensureTypes("string", "number") etc
  //ensureNonNullTypes("string", "number) etc

  //function ensureNonNull() {
  //  for (i = 0; i < arguments.length; i++) {
  //    var argType = typeof arguments[i]
  //    if (typeof arguments[i] === "undefined") {
  //      max = arguments[i];
  //    }
  //  }
  //}

  var service = {
    assume: assume,
    assumeDefined: assumeDefined,
    assumeDefinedAndNonNull: assumeDefinedAndNonNull,
    ensure: ensure
  };

  return service;
};


export default angular.module("CCAdaptor.App.DBC", []).factory("dbc", DesignByContract).name;
