import angular from 'angular';
import _ from 'lodash';

const ipsCallPanelTemplate = require('./ips-call-panel.html');

const IPSCallPanel = () => {
  class IPSCallPanelController {
    constructor($scope, $rootScope, $log, AgentStates, ConfigService, FeatureFlagService,
      CampaignService, CallService, agent, dbc, SessionService) {
      'ngInject';

      const ipsSettings = ConfigService.ipsSettings;
      const selectedCampaign = CampaignService.selectedCampaign;

      // Set defaults
      $scope.config = SessionService.config;
      $scope.callService = CallService;
      $scope.tabIndex = $scope.config.startIndex || 0;
      // Disable annoucements directive
      $scope.disableAnnouncements = () => {
        if (!FeatureFlagService.isEnabled('play_announcements')) {
          return true;
        }

        if (_.hasIn(selectedCampaign, 'hasAnnouncement')) {
          if (!selectedCampaign.hasAnnouncement) {
            return true;
          }
        }

        return false;
      };

      $scope.callTab = {
        index: 0,
        display: true,
      };
      $scope.infoTab = {
        index: 1,
        display: true,
        objects: {
          default: true,
          campaignTitle: true,
          agentInteractionId: true,
          callType: true,
          customerPhoneNumber: true,
          platformPhoneNumber: true,
          ivrText: true,
          ivrData: {
            display: true,
          },
        },
      };

      $scope.serviceTab = {
        index: 2,
        display: true,
      };

      // Load instance settings
      if (_.hasIn(ipsSettings, 'tabs')) {
        if (_.hasIn(ipsSettings.tabs, 'call')) {
          // Load call tab settings
          $scope.callTab = ipsSettings.tabs.call;
          $scope.config.tabViewer = true;
          $scope.config.tabViewerTitle = $scope.callTab.title;
          $scope.config.relatedObjectViewer = $scope.callTab.relatedObjects;
          $scope.config.campaignViewer = $scope.callTab.campaignInfo;
          $scope.config.notesViewer = $scope.callTab.notes;
        }

        // Info Tab
        if (_.hasIn(ipsSettings.tabs, 'info')) {
          $scope.infoTab = ipsSettings.tabs.info;
          $scope.infoTab.default = false;
        }
      }

      // If we are on an unallocated call we do not display the Call tab
      if (!CallService.hasCampaign) {
        $scope.callTab.display = false;
        $scope.tabIndex = $scope.infoTab.index;
      }

      //-----------------------------------------------
      // tabset
      //-----------------------------------------------

      $scope.callPanelChange = (idx) => {
        $scope.layoutChange();
      };

      $scope.disabled = false;

      $scope.hasCampaign = () => { return CallService.hasCampaign; };

      $scope.layoutChange = () => {
        setTimeout(() => { return $rootScope.$broadcast('event.callPanelChange'); }, 100);
      };

      $scope.$watch(() => { return CallService.selectedContact; }, (newVal, oldVal) => {
        // If no contact is selected we leave the related object select available
        if (!_.hasIn(newVal, 'objectId')) {
          $scope.disabled = false;
          return;
        }

        $scope.selectedContact = newVal;
        $scope.disabled = !newVal.relatedObjectViewer;
      });

      $scope.hasRelatedItem = () => {
        return SessionService.config.relatedObjectViewer && (
          _.get(CallService, 'contacts.length', 0) > 0
          || _.get(CallService, 'items.length', 0) > 0
        );
      };
    }
  }

  return {
    restrict: 'EA',
    template: ipsCallPanelTemplate,
    controller: IPSCallPanelController,
    controllerAs: 'CallPanel',
    scope: true,
  };
};

export default angular.module('CCAdaptor.App.IPSCallPanel', [])
  .directive('ipsCallPanel', IPSCallPanel).name;
