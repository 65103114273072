import _ from 'lodash';

const rollbar = require('rollbar');

// ---------------------------------------
// DependencyLoader Class
// ---------------------------------------

export default class DependencyLoader {
  constructor() {
    this.dependency = {};
    this.load = true;
    this.path = window.location.pathname;
    this.errorHandler = null;
    this.isValidPath = true;
    this.pathArray = this.path.split('/');

    this.pathArray.forEach((item) => {
      if (item === 'resource') {
        this.isValidPath = false;
      }
    });

    sessionStorage.setItem('isValidPath', this.isValidPath);

    this.guid = () => {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
    };

    this.setLocalStorageItem = (key, value) => {
      if (!value) return;
      const valueStr = (typeof value !== 'string') ? JSON.stringify(value) : value;
      localStorage.setItem(key, valueStr);
    };

    this.init();
  }

  get api() {
    return (this.dependency.api)
      ? this.dependency.api
      : localStorage.getItem('integration.apiUrl');
  }

  set api(str) {
    this.setLocalStorageItem('integration.api', str);
    this.dependency.api = str;
  }

  set apiKey(str) {
    this.setLocalStorageItem('integration.apiKey', str);
    this.dependency.apiKey = str;
  }

  get parent() {
    return (this.dependency.parent)
      ? this.dependency.parent
      : localStorage.getItem('integration.instance');
  }

  set parent(str) {
    this.setLocalStorageItem('integration.instance', str);
    this.dependency.parent = str;
  }

  get integrationType() {
    return (this.dependency.integrationType)
      ? this.dependency.integrationType
      : localStorage.getItem('integration.type');
  }

  set integrationType(str) {
    this.setLocalStorageItem('integration.type', str);
    this.dependency.integrationType = str;
  }

  get script() {
    return this.dependency.script;
  }

  set script(str) {
    this.dependency.script = str;
  }

  get integrationVersion() {
    return (this.dependency.integrationVersion)
      ? this.dependency.integrationVersion
      : localStorage.getItem('integration.version');
  }

  set integrationVersion(str) {
    this.setLocalStorageItem('integration.version', str);
    this.dependency.integrationVersion = str;
  }

  get reporting() {
    return (this.dependency.reporting)
      ? this.dependency.reporting
      : localStorage.getItem('integration.reporting');
  }

  set reporting(bool) {
    this.setLocalStorageItem('integration.reporting', bool);
    this.dependency.reporting = bool;
  }

  get connect() {
    return (this.dependency.connect)
      ? this.dependency.connect
      : localStorage.getItem('integration.connect');
  }

  set connect(bool) {
    this.setLocalStorageItem('integration.connect', bool);
    this.dependency.connect = bool;
  }

  get useWorker() {
    return (this.dependency.useWorker)
      ? this.dependency.useWorker
      : localStorage.getItem('integration.useWorker');
  }

  set useWorker(bool) {
    this.setLocalStorageItem('integration.useWorker', bool);
    this.dependency.useWorker = bool;
  }

  get devMode() {
    return (this.dependency.devMode)
      ? this.dependency.devMode
      : localStorage.getItem('integration.devMode');
  }

  set devMode(bool) {
    this.setLocalStorageItem('integration.devMode', bool);
    this.dependency.devMode = bool;
  }

  // Initiate Dependency Loader
  init() {
    return new Promise((resolve, reject) => {
      const searchParams = window.location.search.substring(1).split('&');
      let adalConfig;
      let ctiConfig;

      // Setup dependency object
      try {
        searchParams.forEach((element) => {
          const parms = element.split('=');
          this.dependency[decodeURIComponent(parms[0])] = decodeURIComponent(parms[1]);
        });

        if (_.hasIn(window.location, 'ancestorOrigins')) {
          const origins = window.location.ancestorOrigins;
          this.parent = (origins.length > 0) ? window.location.ancestorOrigins[0] : null;
        } else {
          const protomatch = /^(https?|ftp):\/\//;
          this.parent = (window.location !== window.parent.location)
            ? document.referrer : document.location.hostname;
          const urlStr = this.parent.replace(protomatch, '');
          const urlArr = urlStr.split('/');
          this.parent = `https://${urlArr[0]}`;
        }

        if (!_.hasIn(this.dependency, 'integrationType')) {
          this.integrationType = '';
        } else {
          this.integrationType = this.dependency.integrationType;
        }

        if (!_.hasIn(this.dependency, 'integrationVersion')) {
          this.integrationVersion = '';
        } else {
          this.integrationVersion = this.dependency.integrationVersion;
        }

        if (!_.hasIn(this.dependency, 'connect')) {
          this.connect = false;
        } else {
          this.connect = this.dependency.connect;
        }

        if (!_.hasIn(this.dependency, 'useWorker')) {
          this.useWorker = false;
        } else {
          this.useWorker = this.dependency.useWorker;
        }

        if (!_.hasIn(this.dependency, 'reporting') || (!_.hasIn(this.dependency, 'raygun'))) {
          this.reporting = false;
        } else if (_.hasIn(this.dependency, 'reporting')) {
          this.reporting = this.dependency.reporting;
        } else if (_.hasIn(this.dependency, 'raygun')) {
          this.reporting = this.dependency.raygun;
        }

        if (!_.hasIn(this.dependency, 'devMode')) {
          this.devMode = false;
        } else {
          this.devMode = this.dependency.devMode;
        }

        const setNameSpace = () => {
          // Set IPSCAPE namespace
          this.setNamespace({ adalConfig, config: ctiConfig }).then((response) => {
            resolve(response);
          }).catch((error) => {
            reject(Error(`Unable to set namespace: ${error}`));
          });
        };

        this.getConfiguration().then((response) => {
          ctiConfig = response;
          if (_.hasIn(response, 'authentication')) {
            if (response.authentication.length > 0) {
              response.authentication.forEach((obj) => {
                if (obj.name === 'Azure_AD_SSO') {
                  adalConfig = {
                    tenant: (_.hasIn(obj, 'directory_id')) ? obj.directory_id : null,
                    clientId: (_.hasIn(obj, 'app_id')) ? obj.app_id : null,
                    popUp: true,
                  };
                }
              });
            }
          }
        }).then(() => {
          setNameSpace();
        }).catch((error) => {
          console.warn('[CTI] Configuration', error);
          setNameSpace();
        });
      } catch (error) {
        reject(Error(error));
      }
    });
  }

  getConfiguration() {
    const self = this;
    return new Promise((res, rej) => {
      const httpRequest = new XMLHttpRequest();
      const endpoint = `https://${self.api}/api/latest/organisation/readcticonfiguration`;
      httpRequest.open('GET', endpoint, true);
      httpRequest.onreadystatechange = () => {
        if (httpRequest.readyState !== 4) return;

        if (httpRequest.status >= 200 && httpRequest.status < 300) {
          const response = JSON.parse(httpRequest.responseText);
          if (response.resultCode === 'success') {
            if (_.hasIn(response, 'result')) {
              res(response.result);
            }
          }
        } else {
          // If failed
          const errorMessage = JSON.stringify({
            status: httpRequest.status,
            statusText: httpRequest.statusText,
          });
          rej(Error(errorMessage));
        }
      };
      if (self.isValidPath) {
        httpRequest.send();
      } else {
        rej(Error('Not a valid path'));
      }
    });
  }

  setNamespace({
    dependency = this.dependency,
    adalConfig = {},
    config = {},
  }) {
    return new Promise((resolve, reject) => {
      try {
        ((IPSCAPE) => {
          const rollbarConfig = {
            accessToken: ROLLBAR_API_KEY,
            captureUncaught: true,
            captureUnhandledRejections: true,
            payload: {
              environment: ROLLBAR_ENV,
              client: {
                javascript: {
                  code_version: VERSION,
                },
              },
            },
            itemsPerMinute: 5,
            maxItems: 30,
          };

          // Only set the guid if the path is not 'resource'
          if (this.isValidPath) {
            IPSCAPE.guid = this.guid();
            localStorage.setItem('guid', IPSCAPE.guid);
          }

          if (_.hasIn(adalConfig, 'tenant')) {
            IPSCAPE.adalConfig = adalConfig;
          }

          if (Object.keys(config).length > 0) {
            IPSCAPE.config = config;
          }

          IPSCAPE.dependency = dependency;
          IPSCAPE.rollbar = rollbar;
          IPSCAPE.Rollbar = IPSCAPE.rollbar.init(rollbarConfig);
          this.errorHandler = IPSCAPE.rollbar;
        })(window.IPSCAPE = window.IPSCAPE || {});
        resolve({ result: 'success' });
      } catch (error) {
        reject(Error(error));
      }
    });
  }

  selectIntegrationDependency() {
    return new Promise((resolve, reject) => {
      try {
        switch (this.integrationType) {
          case 'sf': {
            this.script = (!_.isNil(this.parent))
              ? `${this.parent}/support/api/37.0/interaction.js`
              : 'https://ap2.salesforce.com/support/api/37.0/interaction.js';

            if (this.integrationVersion === 'lightning') {
              this.script = (!_.isNil(this.parent))
                ? `${this.parent}/support/api/43.0/lightning/opencti_min.js`
                : 'https://c.ap7.visual.force.com/support/api/43.0/lightning/opencti_min.js';
            }
            break;
          }
          case 'sn': {
            let apiVersion = '1.0.0';
            switch (this.integrationVersion) {
              case 'istanbul':
              case 'Istanbul':
                apiVersion = '1.0.0';
                break;
              default:
                apiVersion = 'latest';
                break;
            }
            this.script = `${this.parent}/scripts/openframe/${apiVersion}/openFrameAPI.min.js`;
            break;
          }
          case 'zd':
            this.script = 'https://assets.zendesk.com/apps/sdk/2.0/zaf_sdk.js';
            break;
          default: {
            this.load = false;
            console.warn('[CTI] Unable to determine dependency type');
            break;
          }
        }
        resolve();
      } catch (error) {
        this.load = false;
        reject(error);
      }
    });
  }

  setFallback() {
    return new Promise((resolve, reject) => {
      const fallback = document.createElement('script');
      fallback.type = 'text/javascript';

      switch (this.integrationType) {
        case 'sf':
          fallback.src = (this.integrationVersion === 'lightning')
            ? '/lib/salesforce/api/43.0/lightning/opencti_min.js'
            : '/lib/salesforce/api/37.0/interaction.js';
          break;
        case 'sn':
          reject(Error('[CTI] Error no fallback available.'));
          break;
        case 'zd':
          reject(Error('[CTI] Error no fallback available.'));
          break;
        default:
          resolve();
          break;
      }

      fallback.onload = () => {
        resolve();
      };

      fallback.onerror = () => {
        reject(Error('[CTI] The fallback source is not accessible.'));
      };

      if (this.load) {
        document.head.appendChild(fallback);
      } else {
        resolve();
      }
    });
  }

  setDependencyAPI() {
    return new Promise((resolve, reject) => {
      const request = document.createElement('script');
      request.src = this.script;
      request.type = 'text/javascript';

      // set WebConnect Dependency
      const tag = document.createElement('script');
      tag.src = './app/connect/api/ipscape.connect.min.js';
      tag.type = 'text/javascript';

      // Callback if dependency load error
      const loadError = (oError) => {
        if (this.errorHandler) {
          const cfg = {
            payload: {
              server: {
                host: this.api,
              },
            },
          };

          this.errorHandler.configure(cfg);

          this.errorHandler.error('[CTI] Dependency Load Error', { data: this.dependency }, (er, data) => {
            console.warn('Rollbar error: ', er + data);
          });
        }
        this.setFallback().then((resource) => {
          resolve(resource);
        }).catch(() => {
          reject(Error('[CTI] Unable to load integration dependency file - not available.'));
        });
      };

      // Callback test if dependency is loaded
      const callback = () => {
        switch (this.integrationType) {
          case 'sf':
            if (_.hasIn(window, 'sforce')) {
              resolve(this.dependency);
            } else {
              loadError();
            }
            break;
          case 'sn':
            if (_.hasIn(window, 'openFrameAPI')) {
              resolve(this.dependency);
            } else {
              reject(Error('[CTI] Unable to setup ServiceNow Integration. OpenFrame API is not available.'));
            }
            break;
          case 'zd':
            window.client = window.ZAFClient.init();
            if (_.hasIn(window, 'client')) {
              if (_.hasIn(window.client, 'metadata')) {
                window.client.metadata().then((metadata) => {
                  this.api = metadata.settings['API URL'];
                  this.apiKey = metadata.settings['API Key'];
                  const w = _.isInteger(parseInt(metadata.settings.Width, 10))
                    ? metadata.settings.Width
                    : '250';
                  const h = _.isInteger(parseInt(metadata.settings.Height, 10))
                    ? metadata.settings.Height
                    : '450';
                  window.client.invoke('resize', {
                    width: w + 'px',
                    height: h + 'px',
                  });
                  resolve(this.dependency);
                });
              } else {
                loadError(Error('[CTI] Unable to setup Zendesk Integration. ZAF client is not available.'));
                reject();
              }
            }
            break;
          default:
            resolve(this.dependency);
            break;
        }
      };

      if (this.isValidPath) {
        document.head.appendChild(tag);

        request.onload = () => {
          callback();
        };

        request.onerror = () => {
          loadError();
        };
      } else {
        resolve(this.dependency);
      }

      if (this.load) {
        document.head.appendChild(request);
      } else {
        resolve(this.dependency);
      }
    });
  }
}
