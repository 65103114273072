/*
 * Javascripts
 * ========================================================================== */

// --------------
// REQUIREMENTS
// --------------
import angular from 'angular';
import Cookies from 'angular-cookies';
import AngularBootstrap from 'angular-ui-bootstrap';
import AngularAnimate from 'angular-animate';
import MainController from './controller/MainController';

// ---------------
// DIRECTIVES
// ---------------
import ErrorDirective from './directives/error/error.directive';
import PointerEvents from './directives/PointerEvents/PointerEventsDirective';
import ThirdParties from './directives/ThirdParties/ThirdPartiesDirective';
import HeaderDirective from './directives/header/header.directive';
import Timer from './directives/timer/timer.directive';
import ConnectionPrompt from './directives/ConnectionPrompt/ConnectionPromptDirective';
import ApiStatusIndicator from './directives/ApiStatusIndicator/ApiStatusIndicator';

// ---------------
// FILTERS
// ---------------
import LimitToEllipse from './filters/limit-to-ellipse/limitToEllipse';

// ---------------
// SERVICES
// ---------------
import AgentInfoService from './services/AgentInfoService';
import AgentVoiceStatus from './services/AgentVoiceStatus';
import AuthService from './services/AuthService';
import CallService from './services/CallService';
import WebsocketStatus from './services/WebsocketStatus';
import StatusService from './services/StatusService';
import StateTranslateService from './services/StateTranslateService';
import ErrorTranslateService from './services/ErrorTranslateService';
import SyncStateService from './services/SyncStateService';
import ErrorService from './services/ErrorService';
import SessionService from './services/SessionService';
import ConfigService from './services/ConfigService';
import PhoneService from './services/PhoneService';
import TaskService from './services/TaskService';
import ApiService from './services/ApiService';
import SocketMan from './services/SocketMan';
import AnalyticsService from './services/AnalyticsService';
import CampaignService from './services/CampaignService';
import FeatureFlagService from './services/FeatureFlagService';
import WorkerService from './services/WorkerService';
import ToastrService from './services/ToastrService';

// ---------------
// FACTORIES
// ---------------

// connection
import Calls from './factories/connection/calls';
import Phone from './factories/connection/phone';
import SocketFactory from './factories/connection/SocketFactory';
import MessageHandlerFactory from './factories/connection/MessageHandlerFactory';

// domain
import Agent from './factories/domain/agent';
import Campaigns from './factories/domain/campaigns';

// session
import Base64 from './factories/session/base64.factory';

// integration
import IntegrationFactory from './factories/integration/integrationFactory';
import IntegrationService from './factories/integration/integration.service';
import EmptyIntegration from './factories/integration/integration.empty';

// Salesforce Integration
import SalesforceService from './factories/integration/salesforce/salesforce.service';
import SalesforceClassic from './factories/integration/salesforce/classic/integration.salesforce';
import SalesforceLightning from './factories/integration/salesforce/lightning/integration.salesforce';

// ServiceNow Integration
import ServicenowIntegration from './factories/integration/servicenow/integration.servicenow';

// Zendesk Integration
import ZendeskIntegration from './factories/integration/zendesk/integration.zendesk';

// API Integration
import ApiIntegration from './factories/integration/integrate/integration.api';

// state
import AgentFSM from './factories/state/agent.fsm';
import AgentStates from './factories/state/agent.states';
import StorageManager from './factories/state/storagemanager';

// ui
import TitleService from './factories/ui/TitleService/TitleService';
import SpinnerFactory from './factories/ui/Spinner/SpinnerFactory';

// util
import DesignByContract from './factories/util/dbc';
import Util from './factories/util/util';

/*
 * Stylesheets
 * ========================================================================== */

/* Base
 * ===================================== */

import '../../stylesheets/fonts.scss';
import './stylesheets/main.scss';

/* Layout
 * ===================================== */

import './stylesheets/layout.scss';

/* ========================================================================== */

/**
 * Define app module.
 * @param {String} moduleName.
 * @param {Array} dependencies.
 * @export Module name - name of root module
 */
export default angular.module('CCAdaptor.App', [
  SessionService,
  Cookies,
  AngularBootstrap,
  AngularAnimate,
  ErrorDirective,
  HeaderDirective,
  ConnectionPrompt,
  ApiStatusIndicator,
  Timer,
  PointerEvents,
  ThirdParties,
  AgentVoiceStatus,
  AgentInfoService,
  AuthService,
  CallService,
  WebsocketStatus,
  StatusService,
  ErrorService,
  SyncStateService,
  StateTranslateService,
  ErrorTranslateService,
  ConfigService,
  PhoneService,
  TaskService,
  ApiService,
  SocketMan,
  AnalyticsService,
  CampaignService,
  FeatureFlagService,
  WorkerService,
  ToastrService,
  Calls,
  Phone,
  SocketFactory,
  MessageHandlerFactory,
  Agent,
  Campaigns,
  Base64,
  ApiIntegration,
  AgentFSM,
  AgentStates,
  StorageManager,
  TitleService,
  SpinnerFactory,
  DesignByContract,
  Util,
  LimitToEllipse,
  IntegrationFactory,
  IntegrationService,
  EmptyIntegration,
  SalesforceService,
  SalesforceClassic,
  SalesforceLightning,
  ServicenowIntegration,
  ZendeskIntegration,
]).controller('MainController', MainController).name;
