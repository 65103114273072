/*
 * Javascripts
 * ========================================================================== */

import angular from 'angular';
import ConnectController from './controller/connect.controller';
import ConnectService from './services/connect.service';

/*
 * Stylesheets
 * ========================================================================== */

import './stylesheets/connect.scss';

/* ========================================================================== */

/**
 * Define home page module.
 * @param {String} moduleName.
 * @param {Array} dependencies.
 * @export Module name - name of this module.
 */
export default angular.module('CCAdaptor.App.ConnectController', [
  ConnectService,
])
  .controller('ConnectController', ConnectController).name;
