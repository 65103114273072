import WatchingAttributesBuilder from './watchingattributes.builder';

export default class WatcherBuilder {
  constructor() {
    this._watched = null;
    this._id = null;
  }

  withId(id) {
    this._id = id;
    return this;
  }

  withObj(watched) {
    this._watched = watched;
    return this;
  }

  buildWithAttributes(f) {
    f(new WatchingAttributesBuilder(this._watched, this._id));
  }
}
