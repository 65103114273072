import angular from 'angular';
import _ from 'lodash';

const ApiIntegration = ($rootScope, $injector, $state, $uiRouterGlobals, $log) => {
  'ngInject';

  const logStyle = 'background: red; color: white';
  let conn;

  class Controller {
    constructor() {
      'ngInject';

      this.listeners = [];
      this.errors = {
        101: { error: 'Not in call' },
        102: { error: 'Not allowed in current state' },
        103: { error: 'Invalid parameter supplied' },
        104: { error: 'Required parameter missing' },
        105: { error: 'Not Authorised. Agent must be logged in' },
        106: { error: 'You have attempted to subscribe to an invalid event' },
        107: { error: 'Unknown error' },
      };

      this.post = (data) => {
        try {
          window.top.postMessage(data, '*');
          for (let i = 0; i < window.top.frames.length; i++) {
            window.top.frames[i].postMessage(data, '*');
          }
        } catch (error) {
          $log.error('[INTEGRATION] Error: ', error);
        }
        return this;
      };

      this.done = (func) => {
        this.listeners.push(func);
        return this;
      };

      this.fire = (data) => {
        this.listeners.forEach((listener) => {
          listener(data);
        });
        this.listeners = [];
      };

      this.isAuthorised = () => {
        if (!$injector.get('AuthService').loggedIn) {
          return false;
        }
        return true;
      };

      this.isOnCall = () => {
        if ($injector.get('agentFSM').isOnAllocatedCall()) {
          return true;
        }
        return false;
      };

      this.isInTransfer = () => {
        if ($injector.get('PhoneService').attendedTransfer
          || $injector.get('PhoneService').conferenceCall) {
          return true;
        }

        return false;
      };
    }

    initialise(obj) {
      let settings = null;
      try {
        settings = { result: $injector.get('IntegrationService').getSettings() };
      } catch (error) {
        settings = { error };
      }
      this.post({
        result: settings,
        type: 'callback',
        cid: obj.cid,
      });
    }

    onEnterAvailable(msg) {
      this.post({
        cmd: 'onEnterAvailable',
        type: 'callback',
        msg,
      });
    }

    getAgentState(obj) {
      let result;
      try {
        if (this.isAuthorised()) {
          const state = $injector.get('agentFSM').readableState();
          let reason = null;
          result = { result: state };

          if (state === 'Paused') {
            const agent = $injector.get('agent');
            reason = agent.currentPauseReason();
            result = { result: state, reason };
          }
        } else {
          result = { result: 'Logged Out' };
        }
      } catch (error) {
        result = { error };
      }
      this.post({
        result,
        type: 'callback',
        cid: obj.cid,
      });
    }

    getPauseReasons(obj) {
      let result = null;
      try {
        if (this.isAuthorised()) {
          result = { result: $injector.get('AgentInfoService').pauseReasons };
        } else {
          result = this.errors[105];
        }
      } catch (error) {
        result = { error };
      }
      this.post({
        result,
        type: 'callback',
        cid: obj.cid,
      });
    }

    getAgentDetails(obj) {
      let result = null;
      try {
        if (this.isAuthorised()) {
          result = { result: $injector.get('agent').details() };
        } else {
          result = this.errors[105];
        }
      } catch (error) {
        result = { error };
      }
      this.post({
        result,
        type: 'callback',
        cid: obj.cid,
      });
    }

    getAllocatedCampaigns(obj) {
      let result = null;
      try {
        if (this.isAuthorised()) {
          result = { result: $injector.get('CampaignService').allocatedCampaigns };
        } else {
          result = this.errors[105];
        }
      } catch (error) {
        result = { error };
      }
      this.post({
        result,
        type: 'callback',
        cid: obj.cid,
      });
    }

    login(obj) {
      const report = (result) => {
        this.post({
          result: (typeof result === 'string') ? JSON.parse(result) : result,
          type: 'callback',
          cid: obj.cid,
        });
      };

      $injector.get('AuthService').login(obj.data.user, obj.data.pass)
        .then((response) => {
          if (response.data.resultCode === 'success') {
            $injector.get('AgentInfoService').fetchAll().then(() => {
              $injector.get('calls').apiDetails().then((rs) => {
                $injector.get('ApiService').version = rs.data.result.currentVersion;
                $injector.get('ApiService').build = rs.data.result.buildNumber;
                $injector.get('ApiService').methods = rs.data.result.availableMethods;
              }).then(() => {
                // Check the endpoint exists before attempting to call it
                const enabledFeatureFlags = $injector.get('ApiService').hasMethod('organisation/getenabledfeatureflags');
                if (enabledFeatureFlags) {
                  $injector.get('FeatureFlagService').getFeatureFlags();
                }
              });
              if ($injector.get('IntegrationService').reporting) {
                const id = $injector.get('AgentInfoService').agentId;
                const name = $injector.get('AgentInfoService').agentFullName;
                const email = `${$injector.get('AgentInfoService').agentUserName}@${$injector.get('IntegrationService').apiUrl}`;
                const cfg = {
                  payload: {
                    person: {
                      id,
                      username: name,
                      email,
                    },
                    server: {
                      host: $injector.get('IntegrationService').apiUrl,
                    },
                  },
                };
                window.IPSCAPE.Rollbar.configure(cfg);
              }
              $injector.get('SocketFactory').connect();
              $state.transitionTo('connect', $uiRouterGlobals.params, {
                reload: false,
                inherit: false,
                notify: true,
              });
            }).then(() => {
              try {
                $injector.get('AnalyticsService').heartbeat();
              } catch (error) {
                window.IPSCAPE.Rollbar.error(`GA Error: ${$injector.get('IntegrationService').apiUrl}`,
                  { message: error });
              }
              const result = {
                result: {
                  userId: $injector.get('agent').agentId,
                  agentStatusInfo: {
                    agentStatus: $injector.get('agentFSM').readableState(),
                    previousAgentStatus: $injector.get('agentFSM').previousState(),
                    currentPauseReason: null,
                    connectionMode: $injector.get('AgentInfoService').connectionMode,
                    connectionPersistence: $injector.get('AgentInfoService').connectionPersistence,
                    currentStateDt: $injector.get('agentFSM').time(),
                    voiceConnected: $injector.get('AgentVoiceStatus').status,
                    announcementPlaying: false,
                  },
                },
              };
              report(result);
            });
          }
        }).catch((response) => {
          report(JSON.stringify({ error: { data: response.data, status: response.status } }));
        });
    }

    logout(obj) {
      let result = null;
      try {
        if (!this.isAuthorised()) {
          result = this.errors[105];
        } else if (!$injector.get('agent').canLogout()) {
          result = this.errors[102];
        } else {
          $injector.get('AuthService').logout();
          result = { result: 'success' };
        }
      } catch (error) {
        result = { error };
      }

      this.post({
        result,
        type: 'callback',
        cid: obj.cid,
      });
    }

    subscribe(obj) {
      let result;
      const items = obj.data;
      const EVENTS = ['system', 'user', 'notify'];

      const postMessage = (data) => {
        this.post({
          result: data,
          type: 'listener',
          cid: obj.cid,
        });
      };

      items.forEach((item) => {
        if (EVENTS.indexOf(item) === -1) {
          result = this.errors[106];
          postMessage(result);
          return;
        }

        $rootScope.$on(`event.${item}`, (event, data) => {
          if (!data) {
            return;
          }

          if (_.hasIn(data, 'params')) {
            if (_.hasIn(data.params, 'to')) {
              if (data.params.to === 'onPhonePaused') {
                const reason = $injector.get('agent').currentPauseReason();
                data.params.pauseReason = reason;
              }
            }
          }

          data.event = event.name;
          result = { result: data };
          postMessage(result);
        });
      });
    }

    clickToDial(obj) {
      let result = null;
      try {
        if (this.isAuthorised()) {
          $rootScope.$broadcast('event.user', {
            action: 'click-to-dial',
            clickToDialData: obj.data,
          });
          result = { result: 'success' };
        } else {
          result = this.errors[105];
        }
      } catch (error) {
        result = { error };
      }
      this.post({
        result,
        type: 'callback',
        cid: obj.cid,
      });
    }
  }

  function eventHandler(event) {
    // If it is an empty event
    if (!_.hasIn(event, 'data')) {
      $log.warn('%c [INTEGRATION] - No Data Ignoring Message', logStyle);
      return;
    }

    const dataPackage = event.data;
    let commandObj;

    try {
      if (typeof dataPackage === 'string') {
        if (dataPackage.search('cmd') !== -1) {
          commandObj = JSON.parse(dataPackage);
        } else {
          $log.info('[INTEGRATION]: Unexpected string.');
          return;
        }
      } else if (typeof dataPackage === 'object') {
        commandObj = dataPackage;
      }
    } catch (error) {
      $log.warn('[INTEGRATION]: %s', error);
      return;
    }

    if (_.hasIn(commandObj, 'cmd')) {
      switch (commandObj.cmd) {
        case 'init':
          conn.initialise(commandObj);
          break;
        case 'getAgentState':
          conn.getAgentState(commandObj);
          break;
        case 'getPauseReasons':
          conn.getPauseReasons(commandObj);
          break;
        case 'getAgentDetails':
          conn.getAgentDetails(commandObj);
          break;
        case 'getAllocatedCampaigns':
          conn.getAllocatedCampaigns(commandObj);
          break;
        case 'clickToDial':
          conn.clickToDial(commandObj);
          break;
        case 'subscribe':
          conn.subscribe(commandObj);
          break;
        case 'login':
          conn.login(commandObj);
          break;
        case 'logout':
          conn.logout(commandObj);
          break;
        default:
          $log.debug('[INTEGRATION]: Not a valid command: ', commandObj.cmd);
          break;
      }
    } else {
      $log.info('[INTEGRATION] No command');
    }
  }

  function init() {
    conn = new Controller();
    const message = { resource: 'ipscape.cti.ready' };
    window.top.postMessage(message , '*');
    for (let i = 0; i < window.top.frames.length; i++) {
      window.top.frames[i].postMessage(message, '*');
    }
    window.addEventListener('message', eventHandler, false);
  }

  return {
    init,
  };
};

export default angular.module('CCAdaptor.App.ApiIntegration', [])
  .service('ApiIntegration', ApiIntegration).name;
