import angular from 'angular';
import _ from 'lodash';

const StateTranslateService = ($log, StatusService, agent) => {
  'ngInject';

  class CallStateInterpreter {
    constructor(callData, previewLeadData) {
      'ngInject';

      this.callData = callData;
      this.previewLeadData = previewLeadData;
      this.statusService = StatusService;
    }

    get hasCampaign() {
      if (_.isNil(this.callData.campaignId)) {
        return false;
      }
      // @CTI-375 Fix when campaignId = 0
      if (this.callData.campaignId === 0) {
        return false;
      }

      return true;
    }

    get wasPaused() {
      if (this.statusService.status.label === 'paused') {
        return true;
      }
      return false;
    }

    get isInbound() {
      if (_.hasIn(this.callData, 'callDirection')) {
        return _.get(this.callData, 'callDirection') === 'IN';
      }
      return _.get(this.callData, 'callType') === 'IN';
    }

    get isOutbound() {
      if (_.hasIn(this.callData, 'callDirection')) {
        return _.get(this.callData, 'callDirection') === 'OUT';
      }
      return _.get(this.callData, 'callType') === 'OUT';
    }

    get isOutboundPreview() {
      if (_.hasIn(this.previewLeadData, 'leadId') && this.isOutbound) {
        return true;
      }

      return false;
    }

    get diallingState() {
      if (this.hasCampaign) {
        return 'onPhoneLine1ManCallDialling';
      } else if (this.wasPaused) {
        return 'onPhonePausedOnLine1UnallocManCallDialling';
      }
      return 'onPhoneAvailableOnLine1UnallocManCallDialling';
    }

    get talkingState() {
      if (!this.hasCampaign) {
        return this.wasPaused
          ? 'onPhonePausedOnLine1UnallocManCall'
          : 'onPhoneAvailableOnLine1UnallocManCall';
      } else if (this.isInbound) {
        return 'onPhoneLine1InboundCall';
      } else if (this.isOutboundPreview) {
        return 'onPhoneLine1OutboundCall';
      }
      return 'onPhoneLine1ManCall';
    }

    get holdingState() {
      if (this.isInbound) {
        return 'onPhoneLine1InboundHold';
      } else if (this.isOutboundPreview && this.hasCampaign) {
        return 'onPhoneLine1OutboundHold';
      }
      return 'onPhoneLine1ManCallHold';
    }

    get wrapState() {
      if (this.isOutboundPreview && this.hasCampaign) {
        return 'onOutboundWrapping';
      }
      return 'onPhoneWrapping';
    }
  }

  function getAdapterTargetState(apiTargetState, callData, previewLeadData) {
    const interpreter = new CallStateInterpreter(callData, previewLeadData);
    switch (apiTargetState) {
      case 'Available':
        return 'onPhoneAvailable';
      case 'Paused':
        return 'onPhonePaused';
      case 'Dial':
        return interpreter.diallingState;
      case 'Talk':
        return interpreter.talkingState;
      case 'Hold':
        return interpreter.holdingState;
      case 'Wrap':
        return interpreter.wrapState;
      case 'ManualPreview':
        return 'onManualPreview';
      case 'Preview':
        return 'onOutboundPreview';
      case 'Login':
      case 'AutoLogout':
      case 'Logout':
      case 'NotLoggedIn':
      default:
        return 'offPhone';
    }
  }

  function isLoggedOutState(apiTargetState) {
    return [
      'Available',
      'Paused',
      'Dial',
      'Talk',
      'Hold',
      'Wrap',
      'Login',
      'ManualPreview',
      'Preview',
    ].indexOf(apiTargetState) === -1;
  }

  return {
    getAdapterTargetState,
    isLoggedOutState,
  };
};

export default angular.module('CCAdaptor.App.StateTranslateService', [])
  .factory('StateTranslateService', StateTranslateService).name;
