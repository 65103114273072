// integration/salesforce/salesforce.service.js

import _ from 'lodash';
import angular from 'angular';
import { encodeHTML, formatDate, isJSON, makeParam } from '../common/utilities';
import searchData from '../common/searchData';
import mapData from '../common/mapData';

class SalesforceService {
  constructor($http, $injector, $log, $rootScope, $timeout, CallService, ConfigService,
    TaskService, CampaignService, AgentInfoService, ErrorService, SpinnerFactory, $window) {
    'ngInject';

    // Dependencies
    this.$http = $http;
    this.$injector = $injector;
    this.$log = $log;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.CallService = CallService;
    this.ConfigService = ConfigService;
    this.TaskService = TaskService;
    this.CampaignService = CampaignService;
    this.AgentInfoService = AgentInfoService;
    this.ErrorService = ErrorService;
    this.sforce = (_.hasIn($window, 'sforce')) ? $window.sforce : {};
    this.customFieldPrefix = 'ipSCAPE_';
    this.customFieldSuffix = '__c';
    this.relatedLists = ['Activity', 'History'];
    this.spinner = SpinnerFactory;
    this.searchAndScreenPopParams = null;

    Object.defineProperty(this, 'settings', {
      get: () => this.ConfigService.ipsSettings,
      configurable: true,
      enumerable: true,
    });
  }

  callData() {
    if (this.$injector.get('agentFSM').isOnOutboundPreview() && !this.CallService.previewCalled) {
      return this.CallService.outboundInfo;
    }
    return this.CallService.callInfo;
  }

  outboundCallData() { return this.CallService.outboundInfo; }

  callType() {
    if (_.hasIn(this.callData(), 'callType')) {
      return this.callData().callType;
    }
    return (_.hasIn(this.CallService, 'callType') && !_.isNil(this.CallService.callType))
      ? this.CallService.callType : '';
  }

  selectedContact() { return this.CallService.selectedContact; }

  selectedItem() { return this.CallService.selectedItem; }

  relatedDataHandler({ type = 'contact', data = null }) {
    const canAddSelected = () => {
      if (type === 'contact') {
        if (this.selectedContact() && Object.keys(this.selectedContact()).length > 0) return false;
        return !_.hasIn(this.selectedItem(), 'clickToDial');
      }
      if (this.selectedItem() && Object.keys(this.selectedItem()).length > 0) return false;
      return !_.hasIn(this.selectedContact(), 'clickToDial');
    };

    if (type === 'contact') {
      this.CallService.contacts.push(data);
      if (canAddSelected()) {
        this.CallService.selectedContact = data;
        if (!this.selectedContact().relatedObjectViewer) {
          this.CallService.selectedItem = null;
        }
      }
    } else {
      this.CallService.items.push(data);
      if (canAddSelected()) this.CallService.selectedItem = data;
    }
  }

  refreshView({ list = null }) {
    const callback = (result) => {
      try {
        this.$log.info(`[SF] ${list} refreshView success:`, result);
      } catch (error) {
        this.ErrorService.report('[SF] refreshView', error);
      }
    };

    if (_.hasIn(this.sforce, 'opencti')) {
      this.sforce.opencti.refreshView({ callback });
    } else if (_.hasIn(this.sforce, 'interaction')) {
      this.sforce.interaction.refreshRelatedList(list, (result) => {
        try {
          this.$log.info(`[SF] ${list} refresh success:`, result);
        } catch (error) {
          this.ErrorService.report('[SF] refreshRelatedList', error);
        }
      });
    } else {
      this.$log.error('Salesforce API not available');
    }
  }

  refreshLists() {
    this.relatedLists.forEach((value) => {
      this.refreshView({ list: value });
    });
  }

  handleUpdated(response) {
    this.$log.info(`[SF] Task Updated: ${JSON.stringify(response)}`);
    this.TaskService.taskId = (response.returnValue)
      ? response.returnValue.recordId : response.result;
    this.refreshLists();
  }

  updateRelated({ event = null, result = null }) {
    if (!this.$rootScope.currentTab) return;

    this.$log.info(`[SF] updateRelated: { event: ${event}, result: ${JSON.stringify(result)} }`);

    const related = result;
    let sfObjectArr = ['Contract', 'Order', 'Campaign', 'Account', 'Opportunity',
      'Product', 'Asset', 'Case', 'Solution', 'Coaching', 'Goal', 'Metric',
    ];

    const allowCustomObjects = this.settings.allowCustomObjects === 'true';

    if (allowCustomObjects && (this.settings.ipsCustomObjs.length > 0)) {
      const customObjsStr = this.settings.ipsCustomObjs;
      const customObjsArr = customObjsStr.split(',');
      const customObjs = customObjsArr.map(Function.prototype.call, String.prototype.trim);
      sfObjectArr = _.union(customObjs, sfObjectArr);
    }

    let eventType = '';
    let eventData = null;

    if (related.personAccount && (related.objectType === 'Account' || related.object === 'Account')) {
      // Transform object to a contact
      related.object = 'Contact';
      related.displayName = 'Contact';
      related.objectId = related.contactId;
    }

    if (related.objectType === 'Contact' || related.object === 'Contact') {
      eventType = 'relatedPersonBrowsed';
      eventData = {
        object: (_.hasIn(related, 'objectType')) ? related.objectType : related.object,
        objectId: (_.hasIn(related, 'recordId')) ? related.recordId : related.objectId,
        name: (_.hasIn(related, 'recordName')) ? related.recordName : related.objectName,
        relatedObjectViewer: true,
      };
    } else if (related.objectType === 'Lead' || related.object === 'Lead') {
      eventType = 'relatedPersonBrowsed';
      eventData = {
        object: (_.hasIn(related, 'objectType')) ? related.objectType : related.object,
        objectId: (_.hasIn(related, 'recordId')) ? related.recordId : related.objectId,
        name: (_.hasIn(related, 'recordName')) ? related.recordName : related.objectName,
        relatedObjectViewer: false,
      };
    } else if ((sfObjectArr.indexOf(related.objectType) >= 0)
        || (sfObjectArr.indexOf(related.object) >= 0)) {
      eventType = 'relatedObjectBrowsed';
      eventData = {
        object: (_.hasIn(related, 'objectType')) ? related.objectType : related.object,
        objectId: (_.hasIn(related, 'recordId')) ? related.recordId : related.objectId,
        name: (_.hasIn(related, 'recordName')) ? related.recordName : related.objectName,
      };
    }

    if (!_.isNil(eventType) && !_.isNil(eventData)) {
      if (event === 'event.click-to-dial') {
        const data = eventData;
        data.clickToDial = true;
        if (eventType === 'relatedPersonBrowsed') {
          this.relatedDataHandler({ type: 'contact', data });
        } else if (eventType === 'relatedObjectBrowsed') {
          this.relatedDataHandler({ type: 'item', data });
        }
        this.$log.info(`[SF] Handling click-to-dial event for ${eventType}, ${JSON.stringify(eventData)}`);
      } else {
        if (this.CallService.isObjectAlreadyBrowsed(eventType, eventData)) {
          this.$log.info(`${eventType} already browsed`);
          return;
        }

        if (eventType === 'relatedPersonBrowsed') {
          this.relatedDataHandler({ type: 'contact', data: eventData });
        } else {
          this.relatedDataHandler({ type: 'item', data: eventData });
        }
        this.$timeout(() => {
          this.$rootScope.$apply();
        });
      }
    } else {
      this.$log.info(`[SF] Ignoring focus event: ${JSON.stringify(related)}`);
    }
  }

  focusHandler(response) {
    this.$log.info(`[SF] onFocus event: ${JSON.stringify(response)}`);
    if (_.hasIn(response, 'recordId') || _.hasIn(response, 'objectId')) {
      this.updateRelated({ event: 'event.focus', result: response });
    }
  }

  writeToLog({ fields = null }) {
    return new Promise((resolve, reject) => {
      const dataPackage = {};
      const startTs = Date.now();
      this.spinner.start();

      // Services
      const callNotes = () => this.CallService.callNotes;
      const selectedOutcome = () => this.CallService.selectedOutcome;
      const agentName = () => this.AgentInfoService.agentFullName;

      // If there is a taskId we add it to make an update
      if (!_.isNil(this.TaskService.taskId)) {
        dataPackage.Id = this.TaskService.taskId;
      }

      const callback = (response) => {
        this.spinner.stop();
        if ((_.hasIn(response, 'result') && response.result) || (_.hasIn(response, 'success') && response.success)) {
          this.handleUpdated(response);
          resolve(response);
        } else {
          this.ErrorService.report('[SF] writeToLog unexpected response', {
            data: dataPackage,
            response,
            telemetry: {
              start: startTs,
              end: Date.now(),
              duration: Math.floor(Date.now() - startTs),
            },
          });
          reject(response);
        }
      };

      const makeParamString = data => new Promise(
        (solve, eject) => {
          let paramString;
          async function makeString() {
            _.forEach(data, (value, key) => {
              paramString = (paramString)
                ? paramString += `&${makeParam(key, value)}` : makeParam(key, value);
            });
            return paramString;
          }

          makeString().then((response) => {
            if (!response) eject();
            solve(response);
          });
        },
      );

      const writeLog = (data) => {
        this.$log.info(`[SF] Update activity log: ${JSON.stringify(data)}`);
        // Load into dataPackage
        const dataKeys = Object.keys(data);
        dataKeys.forEach((key) => {
          dataPackage[key] = data[key];
        });

        if (_.hasIn(this.sforce, 'opencti')) {
          // Salesforce Docs: To create using this method, include entityApiName
          if (_.isNil(data.Id)) dataPackage.entityApiName = 'Task';

          try {
            this.$log.info('[SF] saveLog opencti', JSON.stringify(dataPackage));
            this.sforce.opencti.saveLog({ value: dataPackage, callback });
          } catch (error) {
            this.ErrorService.report('Salesforce OpenCTI failed: saveLog', error);
          }
        } else if (_.hasIn(this.sforce, 'interaction')) {
          // Salesforce Classic
          try {
            makeParamString(dataPackage).then((result) => {
              this.$log.info('[SF] saveLog sforce.interaction', result);
              this.sforce.interaction.saveLog('Task', result, callback);
            }).catch((error) => {
              this.ErrorService.report('[SF] Write to log failed', error);
            });
          } catch (error) {
            this.ErrorService.report('Salesforce Interaction failed: saveLog', error);
          }
        } else {
          this.$log.error('[SF] Write to log failed: API not available');
          callback({ error: 'API not available' });
        }
      };

      const getDefaultParams = () => {
        // Standard Salesforce Task fields
        if (!_.hasIn(dataPackage, 'callObject')) {
          dataPackage.callObject = this.callData().agentInteractionId;
        }

        if (!_.hasIn(dataPackage, 'CallType')) {
          dataPackage.CallType = this.callType();
        }

        if (!_.hasIn(dataPackage, 'Subject')) {
          if (this.$injector.get('agentFSM').isOnOutboundPreview() && !this.CallService.previewCalled) {
            dataPackage.Subject = 'Preview Event using ipSCAPE';
          } else {
            dataPackage.Subject = `${this.callType()} Call using ipSCAPE`;
          }
        }

        if (!_.hasIn(dataPackage, 'ActivityDate')) {
          const activityYear = new Date().getUTCFullYear();
          const activityMonth = new Date().getUTCMonth();
          const activityDay = new Date().getUTCDate();
          const activityDateString = (this.callData().activityStart)
            ? new Date(this.callData().activityStart).toISOString()
            : new Date(`${activityYear}-${activityMonth + 1}-${activityDay}`).toISOString();

          dataPackage.ActivityDate = formatDate({
            dte: activityDateString,
            pattern: 'YYYY-MM-DDTHH:mm:ssZ',
            timezone: this.callData().campaignTimezone,
          });
        }

        if (!_.hasIn(dataPackage, 'Status')) {
          // If there is a taskId, this is the second update so mark as complete
          // otherwise check the agent state. If wrapping, mark as complete
          if (this.TaskService.taskId) dataPackage.Status = 'Completed';
          else {
            dataPackage.Status = (this.$injector.get('agentFSM').isOnPhoneWrapping() || this.$injector.get('agentFSM').isOnOutboundPreview())
              ? 'Completed' : 'In Progress';
          }
        }

        if (!_.hasIn(dataPackage, 'Type')) dataPackage.Type = 'Call';

        if (!_.hasIn(dataPackage, 'CallDisposition')) {
          if (!_.isNil(selectedOutcome())
            && (_.hasIn(selectedOutcome(), 'id'))) {
            dataPackage.CallDisposition = selectedOutcome().description;
          }
        }

        if (!_.hasIn(dataPackage, 'CallDurationInSeconds')) {
          const startDt = () => ((!_.isNil(this.CallService.callStartedDate))
            ? new Date(this.callData().callStartedDate).getTime() / 1000 : null);
          const endDt = () => ((!_.isNil(this.CallService.callEndedDate))
            ? new Date(this.callData().callEndedDate).getTime() / 1000 : null);
          // Get timestamps for call duration calculation
          const callDuration = Math.floor(endDt() - startDt());
          if (callDuration < 0) dataPackage.CallDurationInSeconds = 0;
          else dataPackage.CallDurationInSeconds = callDuration;
        }

        if (!_.hasIn(dataPackage, 'WhoId')) {
          if (_.hasIn(this.selectedContact(), 'objectId')) {
            if (!_.isNil(this.selectedContact().objectId)) {
              dataPackage.WhoId = this.selectedContact().objectId;
            }
          }
        }

        if (!_.hasIn(dataPackage, 'WhatId')) {
          if (_.hasIn(this.selectedItem(), 'objectId')) {
            if (!_.isNil(this.selectedItem().objectId)) {
              dataPackage.WhatId = this.selectedItem().objectId;
            }
          }
        }

        if (!_.hasIn(dataPackage, 'Description')) {
          // Prepare call notes
          const note = (!_.isNil(callNotes())) ? encodeURI(callNotes()) : callNotes();
          if (!_.isNil(note)) dataPackage.Description = encodeHTML(note);
        }
      };

      const getMappedData = () => {
        const that = this;
        const mappedData = [];
        const dataMappingRules = Object.keys(that.settings.dataMap);
        const typeOfCall = (that.callType())
          ? that.callType().toLowerCase() : null;
        const keys = ['default', typeOfCall];
        const tz = () => that.CallService.campaignTimezone;
        return new Promise((res) => {
          async function makeMap() {
            if (dataMappingRules.length > 0) {
              keys.forEach((key) => {
                dataMappingRules.forEach((rule) => {
                  if (rule === key) {
                    mapData({
                      map: that.settings.dataMap[rule],
                      data: that.callData(),
                      timezone: tz(),
                    }).then((response) => {
                      if (response) {
                        _.each(response, (data) => {
                          mappedData.push(data);
                        });
                      }
                    }).catch((error) => {
                      that.$log.warn('[SF] Mapped Data Error: ', error);
                    });
                  }
                });
              });
              return mappedData;
            }
            return mappedData;
          }

          makeMap().then((response) => {
            res(response);
          });
        });
      };

      // Add additional fields
      if (fields) {
        this.$log.info('[SS] Writing Additional Fields', JSON.stringify(fields));
        const additionalFields = [].concat(fields);
        const takeOneField = () => {
          if (!additionalFields.length) return;
          const field = additionalFields.shift();
          writeLog(field);
          takeOneField();
        };
        takeOneField();
      } else if (this.settings.dataMap) {
        this.$log.info('[SS] Writing Log with Mapping', JSON.stringify(this.settings.dataMap));
        getMappedData().then((response) => {
          _.forEach(response, (value) => {
            _.forIn(value, (val, key) => {
              dataPackage[key] = val;
            });
          });
        }).then(() => {
          getDefaultParams();
        }).then(() => {
          writeLog(dataPackage);
        })
          .catch((error) => {
            this.$log.error('[SF] MAPPED DATA:', error);
          });
      }

      // Only write these fields if the org is not expecting data mapping
      if (!this.settings.settingsUrl) {
        this.$log.info('[SS] Writing Log No JSON', JSON.stringify(this.settings));
        // Append the ipSCAPE Custom fields
        if (_.hasIn(this.callData(), 'timInQueue')) {
          dataPackage[`${this.customFieldPrefix}Time_In_Queue${this.customFieldSuffix}`] = this.callData().timeInQueue;
        }

        if (!_.isNil(agentName())) {
          dataPackage[`${this.customFieldPrefix}AgentName${this.customFieldSuffix}`] = agentName();
        }

        if (_.hasIn(this.callData(), 'campaignTitle')) {
          dataPackage[`${this.customFieldPrefix}Campaign${this.customFieldSuffix}`] = this.callData().campaignTitle;
        }
        getDefaultParams();
        writeLog(dataPackage);
      }
    });
  }

  getSettings() {
    return new Promise((resolve, reject) => {
      let resultSet;
      const settings = {
        allowCustomObjects: 'false',
        ipsCustomObjs: '',
        showOpenLogButton: 'false',
        settingsUrl: '',
        displayWrapCodes: 'true',
        defaultWrapCode: '',
      };

      const customizer = (objValue, srcValue) => (_.isUndefined(objValue) ? srcValue : objValue);

      const parseApexClasses = (classes) => {
        const methods = {};
        _.forEach(classes, (value, key) => {
          const val = value.split('::');
          if (val.length !== 2) {
            return;
          }
          methods[key] = {
            class: val[0],
            method: val[1],
          };
        });
        return methods;
      };

      const callbackCallCenterSettings = (response) => {
        try {
          if (_.hasIn(response, 'result')) {
            resultSet = JSON.parse(response.result);
          } else if (_.hasIn(response, 'returnValue')) {
            resultSet = response.returnValue;
          }

          const adaptor = { identifier: 'sf' };

          _.forEach(resultSet, (value, key) => {
            const k = key.split('/');
            k.splice(0, 1);
            _.set(adaptor, k, value);
          });

          // Get data map if we have a url
          if (_.hasIn(adaptor, 'ipsSettings')) {
            if (_.hasIn(adaptor.ipsSettings, 'settingsUrl')) {
              this.$http.get(adaptor.ipsSettings.settingsUrl, {
                headers: {
                  Authorization: undefined,
                },
              })
                .then((result) => {
                  if (_.hasIn(result, 'data')) {
                    if (_.hasIn(result.data, 'map')) {
                      adaptor.ipsSettings.dataMap = result.data.map;
                    }
                    if (_.hasIn(result.data, 'settings')) {
                      angular.forEach(result.data.settings, (val, key) => {
                        adaptor.ipsSettings[key] = val;
                      });
                    }
                    if (_.hasIn(result.data, 'tabs')) {
                      adaptor.ipsSettings.tabs = result.data.tabs;
                    }
                    if (_.hasIn(result.data, 'screenPopConfig')) {
                      this.ConfigService.screenpop = result.data.screenPopConfig;
                    }
                  }
                  this.ConfigService.adaptor = adaptor;
                  resolve(adaptor);
                })
                .catch((error) => {
                  this.ErrorService.report('[SF] Settings JSON', error);
                  reject(error);
                });
            }
          } else {
            this.ConfigService.adaptor = adaptor;
            resolve(adaptor);
          }

          if (typeof adaptor.ipsSettings === 'undefined') {
            adaptor.ipsSettings = settings;
          } else {
            _.assignInWith(adaptor.ipsSettings, settings, customizer);
          }

          // Set the settings version
          if (_.hasIn(adaptor, 'reqGeneralInfo')) {
            this.version = (_.hasIn(adaptor.reqGeneralInfo, 'reqInternalName'))
              ? adaptor.reqGeneralInfo.reqInternalName : '';
          }
        } catch (error) {
          this.ErrorService.report('[SF] getCallCentreSettings', error);
          reject(error);
        }
      };

      const callBackApexCapabilities = (response) => {
        try {
          const classes = JSON.parse(response.result);
          const methods = parseApexClasses(classes);
          this.ConfigService.apexCapabilities = Object.assign(
            {},
            this.ConfigService.apexCapabilities,
            methods,
          );
        } catch (error) {
          this.ErrorService.report('[SF] callBackApexCapabilities', {
            error,
            response,
          });
        }
      };

      const callbackSoftPhoneLayout = (response) => {
        try {
          this.ConfigService.softPhoneLayout = JSON.parse(response.result);
        } catch (error) {
          this.ErrorService.report('[SF] callbackSoftPhoneLayout', error);
        }
      };

      const callbackIsInConsole = (response) => {
        if (_.hasIn(response, 'result')) {
          this.ConfigService.isInConsole = !!response.result;
        } else {
          this.ErrorService.report('[SF] setVisible unexpected response', response);
        }
      };

      // Salesforce Classic
      try {
        this.sforce.interaction.cti.getCallCenterSettings(callbackCallCenterSettings);
        this.sforce.interaction.isInConsole(callbackIsInConsole);
        // Set the softPhone layout settings
        // Contains three elements: Internal, Inbound and Outbound
        // Reference: https://developer.salesforce.com/docs/atlas.en-us.api_cti.meta/api_cti/sforce_api_cti_getsoftphonelayout.htm
        this.sforce.interaction.cti.getSoftphoneLayout(callbackSoftPhoneLayout);
        // Get apex methods and classes if set in the JSON settings
        if (_.hasIn(this.settings, 'apex')) {
          // All the apex items in the JSON settings should contain a unique key that references
          // an Apex Class and Method. E.g.: getLeads: IpscapeHelper::getLeads
          const classes = this.settings.apex;
          const methods = parseApexClasses(classes);
          this.ConfigService.apexCapabilities = Object.assign(
            {},
            this.ConfigService.apexCapabilities,
            methods,
          );
          // This is Ipscape Apex method to provide a list of default Classes and Methods
          // that doesn't require to be declared in the JSON settings
          if (_.hasIn(methods, 'getCapabilities')) {
            this.sforce.interaction.runApex(
              methods.getCapabilities.class,
              methods.getCapabilities.method,
              null,
              callBackApexCapabilities,
            );
          }
        }
      } catch (error) {
        this.$log.error('[SF] Interaction getCallCenterSettings', error);
      }
    });
  }

  getDefaultKey() {
    return new Promise((resolve, reject) => {
      // Get screenPop config
      const cfg = () => this.ConfigService.screenpop;

      if (_.isNil(cfg())) reject(Error('No configuration found'));

      try {
        if (_.hasIn(cfg(), 'default')) {
          if (_.hasIn(cfg().default, 'ref')) {
            resolve(cfg().default.ref);
          }
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  doPop({ data = null, onSuccess = null }) {
    // Screen
    const callback = () => {
      try {
        // This will really flood Rollbar
        this.$log.info('[SF] screenPop success', { data });
        if (onSuccess) onSuccess();
      } catch (error) {
        this.ErrorService.report('[SF] screenPop error', error);
      }
    };

    if (_.hasIn(this.sforce, 'opencti')) {
      // Salesforce lightning
      try {
        this.sforce.opencti.screenPop({
          type: this.sforce.opencti.SCREENPOP_TYPE.SOBJECT,
          params: { recordId: data },
          callback,
        });
      } catch (error) {
        this.$log.error('Salesforce OpenCTI failed (searchAndScreenPop)', error);
      }
    } else {
      // Get current screen info
      // If current screen matches pop data cancel command
      this.sforce.interaction.getPageInfo((response) => {
        try {
          if (_.hasIn(response, 'result')) {
            const res = (isJSON(response.result)) ? JSON.parse(response.result) : {};
            if (_.hasIn(res, 'objectId') && res.objectId !== data) {
              // Salesforce Classic
              try {
                // This will really flood Rollbar
                this.ErrorService.info('[SF] Pop', { data });
                this.sforce.interaction.screenPop(data, true, callback);
              } catch (error) {
                this.$log.error('Salesforce Interaction failed: searchAndScreenPop', error);
              }
            } else {
              this.$log.info('[SF] Ignoring screen pop as page already in current view.', response);
            }
          }
        } catch (error) {
          this.$log.error('[SF] Screen pop failed', error);
        }
      });
    }
  }

  doSearch({ filter = null, data = null, callType = '' }) {
    const createFallbackFilter = () => {
      let filterStr = '';
      let customerNumber;
      let nationalNumber;
      let internationalCustomerNumber;
      const leadNums = () => this.CallService.leadNumbers;

      if (this.$injector.get('agentFSM').isOnCall()) {
        nationalNumber = this.callData().nationalCustomerPhoneNumber.replace(/[- )(]/g, '');
        customerNumber = this.callData().customerPhoneNumber;
        internationalCustomerNumber = this.callData().internationalCustomerPhoneNumber;
        filterStr = `${customerNumber} OR ${nationalNumber} OR ${internationalCustomerNumber}`;
      } else if (this.$injector.get('agentFSM').isOnOutboundPreview()) {
        if (!_.isNil(leadNums())) {
          leadNums().forEach((lead) => {
            nationalNumber = lead.number.replace(/[- )(]/g, '');
            customerNumber = nationalNumber.replace(/[+][0-9][0-9]/, '0');
            filterStr += (filterStr === '')
              ? `${nationalNumber} OR ${customerNumber}`
              : ` OR ${nationalNumber} OR ${customerNumber}`;
          });
        }
      }

      // Do search with filter
      if (!_.isNil(filterStr)) this.doSearch({ filter: filterStr, callType });
    };

    // Search callback
    const callback = (response) => {
      try {
        if (_.hasIn(response, 'result') || _.hasIn(response, 'success')) {
          const rs = response.result || response.returnValue;
          this.TaskService.screenPopResult = rs;
          this.$log.info('[SF] searchAndScreenPop result: ', rs);
          // If more or less than one record is found
          const logData = (isJSON(rs)) ? JSON.parse(rs) : rs;
          if (_.size(logData) < 1) {
            this.ErrorService.warn('[SF] searchAndScreenPop empty result set', { response: logData, data, filter });
          } else if (_.size(logData) > 1) {
            this.ErrorService.warn('[SF] searchAndScreenPop result', { response: logData, searchAndScreenPopParams: this.searchAndScreenPopParams });
          } else {
            this.ErrorService.info('[SF] searchAndScreenPop single result', { response: logData, searchAndScreenPopParams: this.searchAndScreenPopParams });
          }
          this.searchAndScreenPopParams = null;
        } else {
          this.ErrorService.report('[SF] searchAndScreenPop unexpected response', response);
        }
      } catch (error) {
        this.ErrorService.report('[SF] searchAndScreenPop', { error, response });
      }
    };

    const search = (params) => {
      this.$log.info('[SF] Search', JSON.stringify(params));
      this.searchAndScreenPopParams = params;
      if (_.hasIn(this.sforce, 'opencti')) {
        // Salesforce lightning
        try {
          this.sforce.opencti.searchAndScreenPop({
            searchParams: params,
            queryParams: null,
            defaultFieldValues: {},
            callType,
            deferred: false,
            callback,
          });
        } catch (error) {
          this.$log.error('Salesforce OpenCTI failed (searchAndScreenPop)', error);
        }
      } else {
        // Salesforce Classic
        try {
          // This will really flood Rollbar
          this.ErrorService.info('[SF] attempting searchAndScreenPop', { params, data });
          this.sforce.interaction.searchAndScreenPop(params, null, 'inbound', callback);
        } catch (error) {
          this.$log.error('Salesforce Interaction failed: searchAndScreenPop', error);
        }
      }
    };

    if (data) {
      search(data);
    } else if (filter) {
      search(filter);
    } else {
      createFallbackFilter();
    }
  }

  getScreenPopRule(callType) {
    return new Promise((resolve, reject) => {
      let query;
      let i = 0;

      // Get screenPop config
      const cfg = () => this.ConfigService.screenpop;

      if (_.isNil(cfg())) {
        // This will really flood Rollbar
        this.ErrorService.warn('[SF] No screen pop configuration', { data: this.callData() });
        reject(Error('No screen pop configuration found'));
      }

      const conditions = {
        callType,
        campaignId: (_.hasIn(this.callData(), 'campaignId'))
          ? this.callData().campaignId : this.outboundCallData().campaignId,
      };

      do {
        if (_.hasIn(cfg().rules[i], 'campaignId')) {
          if (!_.isNil(conditions.campaignId)) {
            if (cfg().rules[i].campaignId === conditions.campaignId) {
              // We have a match
              query = cfg().rules[i];
            }
          }
        } else if (_.hasIn(cfg().rules[i], 'callType')) {
          if (!_.isNil(conditions.callType)) {
            if (cfg().rules[i].callType === conditions.callType) {
              // We have a match
              query = cfg().rules[i];
            }
          }
        }
        i += 1;
      } while (!query && i < cfg().rules.length);

      try {
        // if query is still not assigned, set to default
        if (!_.hasIn(query, 'ref')) {
          resolve(cfg().default);
        } else {
          resolve(query);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  searchAndPop(callDirection) {
    let param;

    this.getScreenPopRule(callDirection).then((response) => {
      let type;
      let search = (_.hasIn(response, 'search')) ? response.search : true;

      switch (callDirection) {
        case 'Outbound':
          type = (_.hasIn(this.sforce, 'opencti')) ? this.sforce.opencti.CALL_TYPE.OUTBOUND : callDirection;
          break;
        case 'Transfer':
          type = (_.hasIn(this.sforce, 'opencti')) ? this.sforce.opencti.CALL_TYPE.INTERNAL : callDirection;
          break;
        case 'Inbound':
        default:
          type = (_.hasIn(this.sforce, 'opencti')) ? this.sforce.opencti.CALL_TYPE.INBOUND : callDirection;
          break;
      }

      if (!_.isNil(response.ref) && typeof response.ref === 'object') {
        let i = 0;
        do {
          param = searchData(this.outboundCallData(), response.ref[i]);
          if (!param) param = searchData(this.callData(), response.ref[i]);
          i += 1;
        } while (!param && i < response.ref.length);
      } else {
        switch (callDirection) {
          case 'Outbound':
            if (!param) param = searchData(this.outboundCallData(), response.ref);
            if (!param) param = searchData(this.callData(), response.ref);
            break;
          case 'Transfer':
            if (!param) param = searchData(this.callData(), response.ref);
            break;
          case 'Inbound':
          default:
            if (!param) param = searchData(this.callData(), response.ref);
            break;
        }
      }

      // If param is null ie: the data has not been found
      if (_.isNil(param) || !param) {
        // We should set the search parameter to 'true' and get the data for the default
        search = true;
        this.getDefaultKey().then((ref) => {
          param = searchData(this.callData(), ref);
          if (!param) param = searchData(this.outboundCallData(), ref);
        }).then(() => {
          this.doSearch({ data: param, callType: type });
        });
      } else {
        // eslint-disable-next-line no-lonely-if
        if (search) {
          this.doSearch({ data: param, callType: type });
        } else {
          this.doPop({ data: param });
        }
      }
    });
  }
}

export default angular.module('CCAdaptor.App.SalesforceService', [])
  .service('SalesforceService', SalesforceService).name;
