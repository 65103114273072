import angular from 'angular';
import _ from 'lodash';
import moment from 'moment-timezone';

class CallService {
  constructor($injector, $log, $timeout, $rootScope, campaigns, CampaignService,
    ErrorService, AgentInfoService, TaskService, storagemanager) {
    'ngInject';

    this.manager = storagemanager;
    this.campaignService = CampaignService;
    this.errorService = ErrorService;
    this.agentInfoService = AgentInfoService;
    this.injector = $injector;
    this.$timeout = $timeout;
    this.$log = $log;
    this.rootScope = $rootScope;
    this.campaigns = campaigns;
    this.TaskService = TaskService;

    // Returns current date/time as UTC
    this.dateTime = () => moment().utc().format('YYYY-MM-DD HH:mm:ss');
    this.timeStamp = () => moment().unix();

    this.sendToConsole = (key, value) => {
      console.log(`[CS] ${key}:`, value);
    };

    this.locals = {};
    this.manager.watchers()
      .withObj(this.locals)
      .withId('CallService')
      .buildWithAttributes((a) => {
        // server data
        a.withObjectKey('callInfo').build();
        a.withObjectKey('outboundInfo')
          .withCallBack((newValue) => {
            this.callType = 'Outbound';
            if (_.hasIn(newValue, 'campaignId')) this.campaignService.createCampaignObject(newValue);
          })
          .build();

        // client-side data
        a.withStringKey('callType').withDefault(null).build();
        a.withStringKey('callNotes').withDefault(null).build();
        a.withStringKey('callQuality').withDefault(5).build();
        a.withBooleanKey('callRecording').withDefault(false).build();
        a.withStringKey('recordingStart').build();
        a.withBooleanKey('controlRecording').build();
        a.withObjectKey('contacts')
          .withDefault([])
          .withCallBack((newValue) => {
            this.sendToConsole('Contacts', JSON.stringify(newValue));
          })
          .build();
        a.withObjectKey('contact')
          .withDefault({})
          .withCallBack((newValue) => {
            this.sendToConsole('Selected Contact', JSON.stringify(newValue));
          })
          .build();
        a.withObjectKey('items')
          .withDefault([])
          .withCallBack((newValue) => {
            this.sendToConsole('Items', JSON.stringify(newValue));
          })
          .build();
        a.withObjectKey('item')
          .withDefault({})
          .withCallBack((newValue) => {
            this.sendToConsole('Selected Item', JSON.stringify(newValue));
          })
          .build();
        a.withStringKey('campaignId').build();
        a.withObjectKey('outcomes').withDefault([]).build();
        a.withObjectKey('selectedOutcome').build();
        a.withObjectKey('leads').withDefault([]).build();
        a.withObjectKey('callback').withDefault({}).build();
        a.withBooleanKey('previewCalled').withDefault(false).build();
        a.withStringKey('autoPreview').build();
        a.withBooleanKey('autowrap').withDefault(false).build();
        a.withStringKey('autowrapDuration').build();
        a.withStringKey('autowrapWrapCode').build();
        a.withStringKey('autowrapExtension').build();
        a.withObjectKey('announcement').withDefault({
          title: 'Play Announcement',
          playing: false,
          selected: null,
        }).build();
        a.withObjectKey('announcementList').withDefault([]).build();
      });
  }

  clear() {
    this.callInfo = {};
    this.outboundInfo = {};
    this.callType = null;
    this.callNotes = null;
    this.callQuality = 5;
    this.callRecording = false;
    this.controlCallRecording = false;
    this.recordingStart = null;
    this.contacts = [];
    this.selectedContact = {};
    this.items = [];
    this.selectedItem = {};
    this.campaignId = null;
    this.outcomes = [];
    this.selectedOutcome = null;
    this.leadNumbers = [];
    this.callback = {};
    this.previewCalled = false;
    this.autoPreview = null;
    this.autowrap = false;
    this.autowrapDuration = null;
    this.autowrapWrapCode = null;
    this.autowrapExtension = null;
    this.autoWrapTimeout = undefined;
    this.announcement = {
      title: 'Play Announcement',
      playing: false,
      selected: null,
    };
    this.announcementList = [];
  }

  resetRelatedObjects() {
    this.contacts = [];
    this.selectedContact = {};
    this.items = [];
    this.selectedItem = {};
    this.$log.info('[CS] Related Objects reset');
  }

  getAgentFullName() {
    return this.agentInfoService.agentFullName;
  }

  wrap(reason) {
    return new Promise((resolve, reject) => {
      const calls = this.injector.get('calls');
      const AgentStates = this.injector.get('AgentStates');
      const AgentInfoService = this.injector.get('AgentInfoService');
      const integrationFactory = this.injector.get('integrationFactory');
      // Is Valid Pause Reasons enforced?
      const orgDetails = AgentInfoService.organisationDetails;
      const enforceValidPauseReasons = orgDetails.enforceValidPauseReason;

      let destinationState = _.hasIn(reason, 'pauseReasonId') ? AgentStates.PAUSED : AgentStates.READY;
      let pauseReasonId = _.get(reason, 'pauseReasonId');
      let agentInteractionId;
      const { selectedOutcome } = this;
      let isCallback = false;
      const callbackData = this.callback;
      const callData = this.callInfo;
      const getCallbackPhone = () => {
        // User added other number
        if (callbackData.phone) {
          return callbackData.phone;
        }

        // If manual call should send a number
        if (_.hasIn(callData, 'leadId') && callData.leadId === 0) {
          if (_.hasIn(callData, 'customerPhoneNumber')) {
            return callData.customerPhoneNumber;
          }
        }

        return null;
      };
      const callbackTimezone = callbackData.timezone || null;
      const callbackDatetime = callbackData.dateTime || null;
      const callbackPhone = getCallbackPhone();
      const callQuality = (_.hasIn(this.callInfo, 'enableCallRating') && this.callInfo.enableCallRating)
        ? this.callQuality : null;
      const PaymentsService = this.injector.get('PaymentsService');

      if (selectedOutcome.callbackAgent || selectedOutcome.callbackQueue) {
        isCallback = true;
      }

      if (typeof reason !== 'undefined') {
        if (reason.pauseReasonId === 0) {
          pauseReasonId = null;
          destinationState = (enforceValidPauseReasons) ? AgentStates.READY : AgentStates.PAUSED;
        }
      }

      if (_.hasIn(this.callInfo, 'agentInteractionId')) {
        ({ agentInteractionId } = this.callInfo);
      } else if (this.outboundInfo) {
        ({ agentInteractionId } = this.outboundInfo);
      }

      const activityId = this.callInfo.activityId || null;

      const msg = {
        data: {
          wrapCodeId: selectedOutcome.id,
          wrapCodeDescription: selectedOutcome.description,
          notes: this.callNotes,
          isCallback,
          callbackDateTime: callbackDatetime,
          callbackTimezone,
          callbackPhone,
          selectedRelatedPerson: this.selectedContact,
          selectedRelatedObject: this.selectedItem,
        },
      };

      const doWrap = () => {
        if (!activityId) console.log('Interaction wrapped with no activityId');
        calls.withAgentInteractionId(agentInteractionId).wrap(
          selectedOutcome.id,
          selectedOutcome.description,
          isCallback,
          destinationState,
          pauseReasonId,
          this.callNotes,
          callQuality,
          callbackDatetime,
          callbackTimezone,
          callbackPhone,
          this.selectedContact,
          this.selectedItem,
        ).then((response) => {
          // Reset data
          this.resetRelatedObjects();
          this.previewCalled = false;
          PaymentsService.reset();
          // Reset task data
          this.TaskService.reset();
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      };

      integrationFactory.onBeforeOnWrap(msg).then(() => {
        doWrap();
      }).catch((error) => {
        this.errorService.report('[CS] onBeforeOnWrap:', error);
        doWrap();
      });
    });
  }

  activityWrap() {
    return new Promise((resolve, reject) => {
      try {
        const integrationFactory = this.injector.get('integrationFactory');
        const calls = this.injector.get('calls');
        const AgentStates = this.injector.get('AgentStates');
        const destinationState = AgentStates.OUTBOUND_PREVIEW;
        let activityId;
        const { selectedOutcome } = this;
        let isCallback = false;
        const callbackData = this.callback;
        const callbackTimezone = callbackData.timezone || null;
        const callbackDatetime = callbackData.dateTime || null;
        let callbackPhone = null;
        const PaymentsService = this.injector.get('PaymentsService');

        if (selectedOutcome.callbackAgent || selectedOutcome.callbackQueue) {
          isCallback = true;
        }

        if (this.callInfo) {
          ({ activityId } = this.callInfo);
          callbackPhone = _.get(this.callInfo, 'callbackPhone');
        }

        const msg = {
          data: {
            wrapCodeId: selectedOutcome.id,
            wrapCodeDescription: selectedOutcome.description,
            notes: this.callNotes,
            isCallback,
            callbackDateTime: callbackDatetime,
            callbackTimezone,
            callbackPhone,
            selectedRelatedPerson: this.selectedContact,
            selectedRelatedObject: this.selectedItem,
          },
        };

        const wrapActivity = () => {
          calls.withActivityId(activityId).activityWrap(
            selectedOutcome.id,
            selectedOutcome.description,
            selectedOutcome.isCallback,
            callbackDatetime,
            callbackTimezone,
            callbackPhone,
            this.callNotes,
            destinationState.state,
            this.selectedContact,
            this.selectedItem,
          ).then((response) => {
            this.TaskService.reset(); // ??? Should we do this here?
            PaymentsService.reset();
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        };

        integrationFactory.onBeforeOnWrap(msg).then(() => {
          wrapActivity();
        }).catch((error) => {
          this.errorService.report('[CS] Activity Wrap:', error);
          wrapActivity();
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  autoWrapInit() {
    const callData = this.callInfo;
    if (!callData) {
      return;
    }

    this.autowrapWrapCode = (this.locals.autowrapWrapCode)
      ? this.locals.autowrapWrapCode : null;
    this.autowrapDuration = (this.locals.autowrapDuration)
      ? this.locals.autowrapDuration : null;
    this.autowrapExtension = (this.locals.autowrapExtension)
      ? this.locals.autowrapExtension : null;

    if (_.isNil(this.locals.autowrapWrapCode) && _.hasIn(callData.autoWrap, 'wrapCodeId')) {
      this.autowrapWrapCode = callData.autoWrap.wrapCodeId;
      this.autowrapDuration = callData.autoWrap.duration;
      this.autowrapExtension = callData.autoWrap.extendedAutoWrapTime;
    }

    if (this.autowrapDuration && this.autowrapWrapCode) {
      this.autowrap = true;
      this.startCountdown();
    }
  }

  startCountdown() {
    if (!this.rootScope.currentTab) return;
    if (!this.autoWrapTimeout) {
      if (this.autowrapDuration === 0 || !this.autowrapDuration) {
        return;
      }
      // Start timeout
      this.autoWrapTimeout = this.$timeout(this.onTimeout.bind(this), 1000);
    }
  }

  pauseAutoWrapCountdown() {
    if (!this.rootScope.currentTab) return;
    this.$timeout.cancel(this.autoWrapTimeout);
  }

  resumeAutoWrapCountdown() {
    this.startCountdown();
  }

  stopCountdown() {
    if (this.autoWrapTimeout) {
      this.$timeout.cancel(this.autoWrapTimeout);
      // Reset
      this.autoWrapTimeout = undefined;
    }
  }

  resetAutowrap() {
    this.stopCountdown();
    this.autowrap = false;
    this.autowrapWrapCode = null;
    this.autowrapDuration = null;
    this.autowrapExtension = null;
  }

  onTimeout() {
    if (!this.rootScope.currentTab) return;
    if (this.autowrapDuration > 0) {
      this.autowrapDuration -= 1;
    }

    if (this.autowrapDuration >= 1) {
      this.autoWrapTimeout = this.$timeout(this.onTimeout.bind(this), 1000);
    } else {
      this.triggerAutowrap();
    }
  }

  triggerAutowrap() {
    const statusService = this.injector.get('StatusService');
    this.stopCountdown();

    if (this.autowrap) {
      this.autowrap = false;
      let reason;

      this.selectedOutcome = {
        id: this.autowrapWrapCode,
        description: 'Autowrap',
        complete: false,
        contact: false,
        qa: false,
        callbackAgent: false,
        callbackQueue: false,
      };

      const callback = () => {
        this.$log.info('Autowrap complete');
        this.campaignService.selectedCampaign = {};
        this.clear();
      };

      if (statusService.status.label === 'paused') {
        reason = { pauseReasonId: statusService.status.id };
      }

      // Trigger autowrap
      this.wrap(reason, callback);
    }
  }

  extendAutowrap() {
    this.stopCountdown();
    this.autowrapDuration = this.autowrapDuration + parseInt(this.autowrapExtension, 10);
    this.autowrapExtension = null;
    this.autoWrapInit();
  }

  loadWrapCodes(campaignId) {
    let outcomes;
    if (!campaignId) {
      return;
    }

    const callback = (data) => {
      outcomes = data;

      // order
      outcomes = _.orderBy(outcomes, (o) => {
        return (_.hasIn(o, 'description')) ? o.description.toLowerCase() : '';
      });

      if (outcomes.length > 1 && !_.isNil(outcomes[0].id)) {
        outcomes.unshift({ description: 'Choose a wrap code' });
      }

      // Make selection index 0 as default
      this.selectedOutcome = (!_.isNil(this.selectedOutcome)) ? this.selectedOutcome : outcomes[0];
      // Add to localStorage
      this.outcomes = outcomes;
    };

    this.campaigns.withId(campaignId).loadWrapCodes().then((response) => {
      callback(response.data.result);
    });
  }

  validateCallback() {
    const calls = this.injector.get('calls');
    const { callInfo } = this;
    const { outboundInfo } = this;

    // If no callback params
    if (_.isNil(this.locals.callback)) {
      return;
    }

    const interactionId = (_.hasIn(callInfo, 'agentInteractionId'))
      ? callInfo.agentInteractionId : outboundInfo.agentInteractionId;

    let data;
    calls.withAgentInteractionId(interactionId)
      .validateCallback(this.callback).then(() => {
        data = true;
      }).catch(() => {
        data = false;
      })
      .finally(() => {
        this.pushToCallback('valid', data);
      });
  }

  isObjectAlreadyBrowsed(item, obj) {
    const list = (item === 'relatedPersonBrowsed') ? this.contacts : this.items;
    return _.some(list, (i) => { return i.objectId === obj.objectId; });
  }

  setCallRecording(permission, status) {
    this.callRecording = status;
    this.controlCallRecording = permission;
  }

  /* --------------------------------
   | Setters & Getters
   | ------------------------------ */
  get hasCampaign() {
    const campaignId = _.get(this, 'callInfo.campaignId') || _.get(this, 'outboundInfo.campaignId');
    return !_.isNil(campaignId) && !_.isEqual(`${campaignId}`, '0');
  }

  get campaignTitle() {
    const campaignId = _.get(this, 'callInfo.campaignId') || _.get(this, 'outboundInfo.campaignId');
    return this.campaignService.campaignTitleById(campaignId);
  }

  get leadNumbers() {
    return this.locals.leads;
  }

  set leadNumbers(leads) {
    this.locals.leads = leads;
  }

  get outboundInfo() {
    return this.locals.outboundInfo;
  }

  set outboundInfo(info) {
    if (_.isNull(info)) {
      this.locals.outboundInfo = null;
      this.rootScope.agentInteractionId = null;
      return;
    }
    const payload = info;

    // Add agentInteractionId to $rootScope so it can be picked up by ErrorService
    this.rootScope.agentInteractionId = info.agentInteractionId;

    // add agent full name for data mapping
    payload.agentFullName = this.getAgentFullName();

    this.locals.outboundInfo = payload;

    // Check if campaignId has changed
    if (payload.campaignId !== this.locals.campaignId) {
      this.loadWrapCodes(payload.campaignId);
      this.locals.campaignId = payload.campaignId || null;
    }

    const leadData = [];

    // Populate lead data
    if (payload.phoneNumber1) {
      leadData.push({ id: 1, number: payload.phoneNumber1 });
    }

    if (payload.phoneNumber2) {
      leadData.push({ id: 2, number: payload.phoneNumber2 });
    }

    if (payload.phoneNumber3) {
      leadData.push({ id: 3, number: payload.phoneNumber3 });
    }

    this.leadNumbers = leadData;
  }

  get leadTimezone() {
    const { callInfo, outboundInfo } = this.locals;
    if (callInfo) {
      return (_.hasIn(callInfo, 'leadTimezone')) ? callInfo.leadTimezone : '';
    }

    return (_.hasIn(outboundInfo, 'leadTimezone')) ? outboundInfo.leadTimezone : '';
  }

  get previewCalled() {
    return this.locals.previewCalled;
  }

  set previewCalled(bool) {
    this.locals.previewCalled = bool;
  }

  get callback() {
    return this.locals.callback;
  }

  set callback(obj) {
    this.locals.callback = obj;
  }

  pushToCallback(key, value) {
    const cbd = this.locals.callback || {};
    cbd[key] = value;

    // Reset callback
    this.callback = cbd;
  }

  get campaignTimezone() {
    const { callInfo, outboundInfo } = this.locals;
    const tz = moment.tz.guess();
    if (callInfo) {
      return (_.hasIn(callInfo, 'campaignTimezone')) ? callInfo.campaignTimezone : tz;
    }

    return (_.hasIn(outboundInfo, 'campaignTimezone')) ? outboundInfo.campaignTimezone : tz;
  }

  get ivrData() {
    return (_.hasIn(this.locals.callInfo, 'ivrData')) ? this.locals.callInfo.ivrData : {};
  }

  get callInfo() { return this.locals.callInfo; }

  set callInfo(info) {
    if (_.isEmpty(info)) {
      this.locals.callInfo = info;
      this.rootScope.agentInteractionId = null;
      return;
    }

    const payload = info;

    // Add agentInteractionId to $rootScope so it can be picked up by ErrorService
    this.rootScope.agentInteractionId = info.agentInteractionId;

    if (_.hasIn(info, 'activityStart')) {
      payload.callStartedDate = info.activityStart;
    } else {
      payload.callStartedDate = this.dateTime();
    }

    // add agent full name for data mapping
    payload.agentFullName = this.getAgentFullName();
    // add call end date/time
    payload.callEndedDate = (_.hasIn(info, 'activityEnd')) ? info.activityEnd : null;

    this.locals.callInfo = payload;

    // Check if campaignId has changed
    if (info.campaignId !== this.locals.campaignId) {
      this.locals.campaignId = info.campaignId || null;
      this.loadWrapCodes(info.campaignId);
    }
  }

  pushToCallInfo(key, value) {
    const info = this.locals.callInfo || {};
    info[key] = value;

    // Reset callback
    this.callInfo = info;
  }

  get callStartedDate() {
    return (_.hasIn(this.callInfo, 'activityStart')) ? this.callInfo.activityStart : null;
  }

  setCallStartedDate(msg = null) {
    if (_.hasIn(this.locals, 'callInfo')) {
      this.locals.callInfo.callStartedDate = (msg) ? msg.data.activityStart : this.dateTime();
    }
  }

  get callEndedDate() {
    return (_.hasIn(this.callInfo, 'activityEnd')) ? this.callInfo.activityEnd : null;
  }

  setCallEndedDate(msg = null) {
    if (_.hasIn(this.locals, 'callInfo')) {
      this.locals.callInfo.activityEnd = (msg) ? msg.data.activityEnd : this.dateTime();
      this.locals.callInfo.callEndedDate = (msg) ? msg.data.activityEnd : this.dateTime();
    }
  }

  get agentInteractionId() {
    if (_.hasIn(this.outboundInfo, 'agentInteractionId')) {
      return this.outboundInfo.agentInteractionId;
    }

    return this.callInfo.agentInteractionId;
  }

  get callType() {
    return this.locals.callType;
  }

  set callType(type) {
    this.locals.callType = type;
  }

  get callNotes() { return this.locals.callNotes; }

  set callNotes(notes) { this.locals.callNotes = notes; }

  get callQuality() { return this.locals.callQuality; }

  set callQuality(val) { this.locals.callQuality = val; }

  get callRecording() { return this.locals.callRecording; }

  set callRecording(flag) {
    // Set the timestamp if true
    if (flag) {
      this.recordingStart = this.timeStamp();
    }
    this.locals.callRecording = flag;
  }

  get recordingStart() { return this.locals.recordingStart; }

  set recordingStart(ts) { this.locals.recordingStart = ts; }

  get controlCallRecording() { return this.locals.controlRecording; }

  set controlCallRecording(flag) { this.locals.controlRecording = flag; }

  get contacts() { return this.locals.contacts; }

  set contacts(newContacts) {
    if (newContacts.length === 0) {
      this.locals.contacts = [];
      return;
    }

    angular.forEach(newContacts, (newContact) => {
      if (!this.isObjectAlreadyBrowsed('relatedPersonBrowsed', newContact)) {
        this.locals.contacts.push(newContact);
      }
    });
  }

  get selectedContact() { return this.locals.contact; }

  set selectedContact(contact) {
    this.locals.contact = contact;
  }

  get items() {
    if (!_.hasIn(this.locals, 'items')) {
      this.items = [];
    }
    return this.locals.items;
  }

  set items(newItems) {
    if (newItems.length === 0) {
      this.locals.items = [];
      return;
    }

    angular.forEach(newItems, (newItem) => {
      if (!this.isObjectAlreadyBrowsed('relatedObjectBrowsed', newItem)) {
        this.locals.items.push(newItem);
      }
    });
  }

  get selectedItem() { return this.locals.item; }

  set selectedItem(item) { this.locals.item = item; }

  get activityId() {
    const callInfo = (this.callInfo) ? this.callInfo : null;
    if (_.hasIn(callInfo, 'activityId')) {
      return callInfo.activityId;
    }
    return null;
  }

  get campaignId() { return this.locals.campaignId; }

  set campaignId(id) { this.locals.campaignId = id; }

  get outcomes() { return this.locals.outcomes; }

  set outcomes(list) { this.locals.outcomes = list; }

  get selectedOutcome() {
    return this.locals.selectedOutcome;
  }

  set selectedOutcome(outcome) { this.locals.selectedOutcome = outcome; }

  get autowrap() { return this.locals.autowrap; }

  set autowrap(bool) { this.locals.autowrap = bool; }

  get autoPreview() { return this.locals.autoPreview; }

  set autoPreview(val) { this.locals.autoPreview = val; }

  get autowrapDuration() { return this.locals.autowrapDuration; }

  set autowrapDuration(val) { this.locals.autowrapDuration = val; }

  get autowrapWrapCode() { return this.locals.autowrapWrapCode; }

  set autowrapWrapCode(val) { this.locals.autowrapWrapCode = val; }

  get autowrapExtension() { return this.locals.autowrapExtension; }

  set autowrapExtension(num) { this.locals.autowrapExtension = num; }

  get announcement() { return this.locals.announcement; }

  set announcement(obj) { this.locals.announcement = obj; }

  get announcementList() { return this.locals.announcementList; }

  set announcementList(data) { this.locals.announcementList = data; }

  resetAnnouncementStatus(op) {
    const status = this.announcement;
    let newStatus;
    if (op) {
      newStatus = {
        title: 'Stop Announcement',
        playing: true,
        selected: status.selected,
      };
    } else {
      newStatus = {
        title: 'Play Announcement',
        playing: false,
        selected: null,
      };
    }

    this.announcement = newStatus;
  }
}

export default angular.module('CCAdaptor.App.CallService', [])
  .service('CallService', CallService).name;
