import angular from 'angular';
import _ from 'lodash';
import uiRouter from '@uirouter/angularjs';
import uiRouterStateHelper from 'angular-ui-router.statehelper';
import toastr from 'angular-toastr';
import moment from 'moment';
import adalAngular from 'modular-adal-angular';
import Prepare from './Prepare';
import Config from './Config';
import Runners from './Runners';
import DependencyLoader from './DependencyLoader';
import MainControllers from './modules/Main/Controllers';

window['moment'] = moment;
require('angular-moment-picker');

moment.locale('en');

// App and root module name
const appname = 'App';
const pathname = window.location.pathname;
const pathParamsArr = pathname.substring(1).split('/');

// All global dependencies
const deps = [uiRouter, toastr, adalAngular, 'ui.router.stateHelper', 'moment-picker'];
// All app dependencies
const modules = [MainControllers];

// Declare root module
angular.module(appname, deps.concat(modules)).run(Prepare).config(Config).run(Runners);

const loader = new DependencyLoader();

const createWarningMessage = (message) => {
  // Create a warning
  const el = document.getElementById('app-container');
  const childNode = el.firstChild;
  const bodyEl = document.getElementsByTagName('body');
  const txt = document.createElement('p');

  txt.appendChild(document.createTextNode(message));
  txt.setAttribute('id', 'system-error');
  txt.classList = 'system warning';
  bodyEl[0].removeAttribute('ng-cloak');
  el.insertBefore(txt, childNode);
};

const start = () => {
  // Bootstrap Angular
  angular.bootstrap(document, [appname], {
    strictDi: true,
  });
};

loader.init().then(() => {
  loader.selectIntegrationDependency().then(() => {
    loader.setDependencyAPI().then((response) => {
      if (!Object.prototype.hasOwnProperty.call(response, 'api')
        || !Object.prototype.hasOwnProperty.call(response, 'apiKey')) {
        // Should not check when looking for a resource
        // i.e. Call Recording or ipscape-pay
        if (pathParamsArr[0] !== 'resource') {
          // Create a warning
          createWarningMessage('Error: Unable to proceed without minimum requirements: [api, apiKey]');
        } else {
          start();
        }
      } else {
        start();
      }
    }).catch((error) => {
      console.log('Loading Error: ', error);
      start();
    });
  });
});

export default appname;
