import angular from 'angular';
import _ from 'lodash';

// Pollyfill isArray
if (!Array.isArray) {
  Array.isArray = (arg) => {
    return Object.prototype.toString.call(arg) === '[object Array]';
  };
}

class ErrorService {
  constructor($log, $rootScope, IntegrationService) {
    'ngInject';

    this.errors = [];
    this.integrationService = IntegrationService;
    this.rollbar = window.IPSCAPE.Rollbar;
    this.$log = $log;
    this.rootScope = $rootScope;
    this.config = {
      payload: {
        server: {
          host: window.IPSCAPE.dependency.apiUrl,
        },
      },
    };

    // Add user to Rollbar config
    if (localStorage.getItem('auth.loggedIn') === 'true') {
      const org = localStorage.getItem('integration.apiUrl');
      const AgentInfo = (localStorage.getItem('agentinfo.details'))
        ? JSON.parse(localStorage.getItem('agentinfo.details')) : {};
      const id = (_.hasIn(AgentInfo, 'agentId')) ? AgentInfo.agentId : '';
      const name = (_.hasIn(AgentInfo, 'displayName')) ? AgentInfo.displayName : '';
      const email = (_.hasIn(AgentInfo, 'userName'))
        ? `${AgentInfo.userName}@${org}` : null;
      this.config.payload.person = {
        id,
        username: name,
        email,
      };
    }

    window.IPSCAPE.Rollbar.configure(this.config);

    this.isValid = response => !!response.data;

    this.broadcast = (error) => {
      this.rootScope.$broadcast('event.notify', {
        action: 'error',
        details: error.details,
        interactionId: error.interactionId,
        message: error.message,
        result: {
          resultCode: 'error',
          response: {
            status: error.response.status,
            tag: error.response.tag,
          },
        },
      });
    };
  }

  get error() { return this.errors[0]; }

  set error(error) { this.errors.unshift(error); }

  createPayload(data) {
    const payload = {};
    if (typeof data === 'string') payload.param = data;
    else {
      const keys = Object.keys(data);
      keys.forEach((key) => {
        payload[key] = data[key];
      });
    }
    payload.state = localStorage.getItem('agent.fsm.currentAgentState') || 'offPhone';
    payload.agentInteractionId = this.rootScope.agentInteractionId;
    payload.isCurrentTab = this.rootScope.currentTab;
    return payload;
  }

  report(msg, error) {
    const payload = this.createPayload(error);
    if (this.integrationService.reporting) {
      this.$log.error(msg);
      this.rollbar.error(msg, payload, (err) => {
        if (err) {
          this.$log.warn('Error reporting to Rollbar: ', err);
        }
      });
    }
  }

  warn(msg, error) {
    const payload = this.createPayload(error);
    if (this.integrationService.reporting) {
      this.$log.warn(`${msg}`, payload);
      this.rollbar.warning(msg, payload, (err, data) => {
        if (err) {
          this.$log.warn('Error reporting to Rollbar: ', err);
        }
      });
    }
  }

  info(msg, error) {
    const payload = this.createPayload(error);
    if (this.integrationService.reporting) {
      this.$log.info(`${msg}`, payload);
      this.rollbar.info(msg, payload, (err, data) => {
        if (err) {
          this.$log.warn('Error reporting to Rollbar: ', err);
        }
      });
    }
  }

  handleError(path, response) {
    let errorMsg;
    if (!this.isValid(response) && response.status < 0) {
      errorMsg = {
        tag: 'error.response',
        message: 'Network error',
        details: [{
          debuggingInfo: 'action.error.network',
          description: 'Request timed out. Check your connection and try again.',
          name: path,
        }],
        response,
        interactionId: 'na',
      };
    } else if (response.data.errors) { // for errors with proposed format
      const errArr = response.data.errors;
      errArr.forEach((error) => {
        errorMsg = {
          tag: error.code,
          message: error.developerMessage,
          details: [{
            debuggingInfo: JSON.stringify(error.errorData),
            description: error.userMessage,
            name: path,
          }],
          response,
          interactionId: response.data.interactionId,
        };
      }, this);
    } else if (!response.data.errorDetails) { // if errorDetails is null
      errorMsg = {
        tag: 'error.response',
        message: response.data.errorDescription,
        details: [{
          debuggingInfo: response.data.errorCode,
          description: response.data.errorDescription,
          name: path,
        }],
        response,
        interactionId: response.data.interactionId,
      };
    } else if (typeof response.data.errorDetails === 'string') { // if errorDetails is string
      errorMsg = {
        tag: response.data.errorCode,
        message: response.data.errorDescription,
        details: [{
          debuggingInfo: response.data.errorDetails,
          description: response.data.errorDescription,
          name: path,
        }],
        response,
        interactionId: response.data.interactionId,
      };
    } else if (Array.isArray(response.data.errorDetails)) { // if errorDetails is an array
      const errArr = response.data.errorDetails;
      errArr.forEach((error) => {
        errorMsg = {
          tag: response.data.errorCode,
          message: response.data.errorDescription,
          details: [{
            debuggingInfo: error.name,
            description: error.description,
            name: path,
          }],
          response,
          interactionId: response.data.interactionId,
        };
      });
    } else {
      errorMsg = {
        tag: response.data.errorCode,
        message: response.data.errorDescription,
        details: [response.data.errorDetails],
        response,
        interactionId: response.data.interactionId,
      };
    }

    if (typeof errorMsg === 'object') this.broadcast(errorMsg);
  }
}

export default angular.module('CCAdaptor.App.ErrorService', [])
  .service('ErrorService', ErrorService).name;
