import AbstractWatcherBuilder from './abstractwatcher.builder';

export default class ObjectWatcherBuilder extends AbstractWatcherBuilder {
  constructor(watched, id, key) {
    super(watched, id, key);
  }

  get hasStoredValue() {
    return this.storageOp.isObjectVal;
  }

  get storedValue() {
    return this.storageOp.objectVal;
  }

  save() {
    this.storageOp.objectVal = this.currentValue;
  }
}
