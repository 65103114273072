import angular from 'angular';
import _ from 'lodash';
import WatcherBuilder from './watcher.builder';

const StorageManager = ($window, $rootScope, $log) => {
  'ngInject';

  // test to ensure that the session state object is supported
  if (!$window.localStorage) {
    $log.error('This browser does not support localState - meaning that synchronisation and cross tab behaviour will fail');
    $rootScope.$broadcast('event.notify', {
      action: 'error',
      tag: 'browser.localStateNotSupported',
      message: 'Your browser does not support html 5\'s local storage.',
      details: [{
        debuggingInfo: 'Old browser: localStorage unsupported error',
        description: 'Please upgrade to a more modern browser that supports localStorage.',
        name: 'browser.error.localStorage.support',
      }],
    });
  }

  class StateOp {
    constructor() {
      'ngInject';

      // Item keys are protected from removal
      this.safeList = [
        'api',
        'apiKey',
        'guid',
        'instance',
        'integrationType',
        'integrationVersion',
        'connect',
        'useWorker',
      ];
    }

    getSafeList() {
      if (this.safeList.length === 0) return undefined;
      return this.safeList;
    }

    setSafeList(key) {
      this.safeList.push(key);
    }

    removeItem(key) {
      $window.localStorage.removeItem(key);
    }

    crop() {
      const localStorageKeys = Object.keys(localStorage);
      const exposed = _.difference(localStorageKeys, this.safeList);

      exposed.forEach((Key, Value) => {
        localStorage.removeItem(Key);
      });
    }

    clear() {
      $window.localStorage.clear();
    }

    watchers() {
      return new WatcherBuilder();
    }
  }

  // public API
  return new StateOp();
};

export default angular.module("CCAdaptor.App.StorageManager", []).factory("storagemanager", StorageManager).name;
