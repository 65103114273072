import angular from 'angular';

const SocketFactory = ($injector, $log, IntegrationService) => {
  'ngInject';

  let socketConnection;

  // Test for shared worker support
  if (window.SharedWorker && IntegrationService.worker) {
    $log.info('[SKF] Using shared socket');
    socketConnection = $injector.get('WorkerService');
  } else {
    $log.info('[SKF] Using normal socket');
    socketConnection = $injector.get('SocketMan');
  }

  return {
    connect: () => {
      $log.info('[SKF] connect');
      socketConnection.connect();
    },
    disconnect: () => {
      $log.info('[SKF] disconnect');
      socketConnection.disconnect();
    },
    reconnect: () => {
      $log.info('[SKF] reconnect');
      socketConnection.reconnect();
    },
    currentAgentState: () => {
      $log.info('[SKF] currentAgentState');
      socketConnection.currentAgentState();
    },
    disableHangupWarningActive: () => {
      $injector.get('MessageHandlerFactory').disableHangupWarningActive();
    },
  };
};

export default angular.module('CCAdaptor.App.SocketFactory', [])
  .factory('SocketFactory', SocketFactory).name;
