import angular from 'angular';
import _ from 'lodash';

class FeatureFlagService {
  constructor($http, $log, $q, storagemanager, IntegrationService, ErrorService) {
    'ngInject';

    this.manager = storagemanager;
    this.$http = $http;
    this.$log = $log;
    this.$q = $q;
    this.integrationService = IntegrationService;
    this.errorService = ErrorService;

    this.locals = {};
    this.watchedAttributes = [];
    this.manager.watchers()
      .withObj(this.locals)
      .withId('flags')
      .buildWithAttributes((a) => {
        this.watchedAttributes.push(
          a.withObjectKey('enabled')
            .withDefault({})
            .build(),
        );
      });
  }

  reset() {
    this.enabledFlags = {};
  }

  getFeatureFlags() {
    const endpoint = '/organisation/getEnabledFeatureFlags';
    this.$http.get(this.integrationService.latestApiUri(endpoint))
      .then((response) => {
        if (_.hasIn(response, 'data')) {
          if (response.data.resultCode === 'success') {
            const flags = response.data.result.enabledFlags;
            const keys = _.keysIn(flags);
            this.locals.enabled = (keys.length > 0) ? flags : {};
          } else {
            this.errorService.handleError(endpoint, response);
          }
        }
      }).catch((error) => {
        this.errorService.handleError(endpoint, error);
      });
  }

  isEnabled(test) {
    const flags = this.locals.enabled;
    if (_.hasIn(flags, test)) {
      return true;
    }
    return false;
  }

  get enabledFlags() {
    return this.locals.enabled;
  }

  set enabledFlags(obj) {
    this.locals.enabled = obj;
  }
}

export default angular.module('CCAdaptor.App.FeatureFlagService', [])
  .service('FeatureFlagService', FeatureFlagService).name;
