/**
  * a global handler for all http calls, that broadcasts event.notify from the rootScope.
  */
export default class ipsHttpInterceptor {
  static registerHandlers($httpProvider) {
    $httpProvider.interceptors.push(($log, $rootScope, $q, $injector, SpinnerFactory,
      ErrorService) => {
      'ngInject';

      return {
        request: (config) => {
          config.timeout = process.env.REQUEST_TIMEOUT || 10000;
          SpinnerFactory.start();
          if ($injector.get('AuthService').isLoggedIn()) {
            $injector.get('AuthService').resetAuthHeader();
          }
          return config;
        },
        requestError: (rejection) => {
          SpinnerFactory.stop();
          return $q.reject(rejection);
        },
        response: (response) => {
          SpinnerFactory.stop();
          return response;
        },
        responseError: (rejection) => {
          SpinnerFactory.stop();
          ErrorService.error = rejection;
          // handle timeouts centrally regardless (status === 0 is a timeout)
          if (rejection.status === 0) {
            $rootScope.$broadcast('event.notify', {
              action: 'error',
              tag: 'error.request.timeout',
              message: 'Your last action has timed out, please try again later',
              details: [{
                debuggingInfo: 'action.timeout',
                description: 'Your last action has timed out',
                name: rejection.errorCode,
              }],
            });
            return $q.reject(rejection);
          }

          // handle 401 errors from api server that have a sensible error code and description
          if (rejection.status === 401) {
            if (rejection.data.errorCode === 'user.login.authorization.failed') {
              $rootScope.$broadcast('event.notify', {
                action: 'error',
                tag: 'error.request.unauthorized',
                message: 'Unauthorized',
                details: [{
                  debuggingInfo: 'action.error.login.authorization.',
                  description: 'Authorisation Error.',
                  name: rejection.status,
                }],
              });
            }

            if (rejection.data.errorCode === 'session.expired') {
              $rootScope.$broadcast('event.notify', {
                action: 'error',
                tag: 'error.request.unauthorized',
                message: 'Session Expired',
                details: [{
                  debuggingInfo: 'action.error.session.expired',
                  description: 'Session Expired.',
                  name: rejection.status,
                }],
              });
            }

            $injector.get('AuthService').closeError();
          }

          return $q.reject(rejection);
        },
      };
    });
  }
}
