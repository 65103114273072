import angular from 'angular';

class TaskService {
  constructor(storagemanager, $log, ErrorService) {
    'ngInject';

    this.manager = storagemanager;
    this.log = $log;
    this.errorService = ErrorService;
    this.locals = {};
    this.watchedAttributes = [];

    this.logToConsole = (key, value) => {
      const message = (typeof value !== 'string') ? JSON.stringify(value) : value;
      this.log.info(`[TS] ${key}:`, message);
    };

    this.manager.watchers()
      .withObj(this.locals)
      .withId('task')
      .buildWithAttributes((a) => {
        this.watchedAttributes.push(
          a.withBooleanKey('openTaskOnWrap')
            .withCallBack((newValue) => {
              this.logToConsole('openTaskOnWrap', newValue);
            })
            .withDefault(false)
            .build(),
        );
        this.watchedAttributes.push(
          a.withStringKey('task_id')
            .withCallBack((newValue) => {
              this.logToConsole('TaskId', newValue);
            })
            .build(),
        );
        this.watchedAttributes.push(
          a.withObjectKey('screen_pop')
            .withCallBack((newValue) => {
              if (newValue.attempts > 1) {
                this.errorService.warn('[TS] Multiple pop attempts', { event: newValue });
              }
            })
            .withDefault({
              interactionId: 0,
              event: '',
              popped: false,
              attempts: 0,
            })
            .build(),
        );
        this.watchedAttributes.push(
          a.withObjectKey('task_params')
            .withDefault([])
            .build(),
        );
        this.watchedAttributes.push(
          a.withObjectKey('last_caller')
            .withDefault({})
            .build(),
        );
        this.watchedAttributes.push(
          a.withObjectKey('screenPopResult')
            .withCallBack((newValue) => {
              this.logToConsole('screenPopResult', newValue);
            })
            .withDefault({})
            .build(),
        );
      });
  }

  get openTaskOnWrap() {
    return this.locals.openTaskOnWrap;
  }

  set openTaskOnWrap(state) {
    this.locals.openTaskOnWrap = state;
  }

  get taskId() {
    return this.locals.task_id;
  }

  set taskId(id) {
    this.locals.task_id = id;
  }

  get pop() {
    return this.locals.screen_pop;
  }

  set pop(val) {
    this.locals.screen_pop = val;
  }

  set screenPopResult(result) {
    this.locals.screenPopResult = result;
  }

  get screenPopResult() {
    return this.locals.screenPopResult;
  }

  get taskParams() {
    return this.locals.task_params;
  }

  set taskParams(arr) {
    this.locals.task_params = arr;
  }

  get lastCaller() {
    return this.locals.last_caller;
  }

  set lastCaller(obj) {
    this.locals.last_caller = obj;
  }

  reset() {
    this.taskId = null;
    this.taskParams = [];
    this.screenPopResult = {};
  }

  pushToCallLog(param) {
    const arr = this.locals.task_params;
    arr.push(param);
  }
}

export default angular.module('CCAdaptor.App.TaskService', [])
  .service('TaskService', TaskService).name;
