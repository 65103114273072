// integration/common/mapData.js
import _ from 'lodash';

export default function searchData(data, q) {
  const mapKeys = Object.keys(data);
  let i = 0;
  let found = false;

  const findParam = (block, callback) => {
    let result;
    do {
      if (!_.isNil(block) && typeof block === 'object') {
        // eslint-disable-next-line no-loop-func
        _.forEach(block, (value, key) => {
          if (!_.isNil(value) && typeof value === 'object') findParam(value, callback);
          if (key === q) callback(value);
        });
      }
    } while (!result && i < block.length);
  };

  if (_.hasIn(data, q)) return data[q];

  do {
    const objKey = mapKeys[i];
    if (!_.isNil(data[objKey]) && typeof data[objKey] === 'object') {
      // eslint-disable-next-line no-loop-func
      findParam(data[objKey], (response) => {
        if (!_.isNil(response)) found = response;
      });
    }
    i += 1;
  } while (!found && i < mapKeys.length);

  return found;
}
