import angular from "angular";

import CallCompletionDirective from './ips-call-completion.directive';
import OutcomeListDirective from '../outcomelist/outcomelist';

const IPSCallActions = () => {
  'ngInject';

  class IPSCallActionsController {
    constructor($log, $scope, CallService, agent) {
      'ngInject';

      $scope.agentDetails = agent.details();

      $scope.hasCampaign = () => { return CallService.hasCampaign; };
    }
  }

  return {
    restrict: 'EA',
    template: require('./ips-call-actions.directive.html'),
    controller: IPSCallActionsController,
    controllerAs: 'CallActions',
    transclude: true,
    scope: true,
  };
};

export default angular.module('CCAdaptor.Toolbar.IPSCallActions', [
  CallCompletionDirective,
  OutcomeListDirective,
]).directive('ipsCallActions', IPSCallActions).name;
