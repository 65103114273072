import angular from 'angular';

class WebsocketStatus {
  constructor(storagemanager) {
    'ngInject';

    this.listeners = [];
    this.manager = storagemanager;
    this.locals = {};
    this.watchedAttributes = [];
    this.manager.watchers()
      .withObj(this.locals)
      .withId('status')
      .buildWithAttributes((a) => {
        this.watchedAttributes.push(
          a.withStringKey('socket').withDefault('disconnected').build(),
        );
      });
  }

  get status() {
    return this.locals.socket;
  }

  set status(str) {
    this.locals.socket = str;
  }
}

export default angular.module('CCAdaptor.App.WebsocketStatus', [])
  .service('WebsocketStatus', WebsocketStatus).name;
