import angular from 'angular';
import _ from 'lodash';
import SFTasks from './salesforce.tasks';

const SalesforceClassic = ($log, $rootScope, sftasks, CallService) => {
  'ngInject';

  $rootScope.$on('event.system', (event, data) => {
    if (_.hasIn(data, 'action')) {
      if (data.action === 'agent.state.change') {
        if (data.params.event === 'connect') {
          sftasks.loadAdaptorConfig();
          sftasks.disableClickToDial();
        }
      }
    }
  });

  return {
    init: () => {
      sftasks.loadAdaptorConfig();
      sftasks.disableClickToDial();
      sftasks.toggleVisibility(true);
      sftasks.init();
    },
    onEnterAvailable: () => {
      sftasks.enableClickToDial();
    },
    onEnterPaused: (msg) => {
      sftasks.enableClickToDial();
      if (msg && msg.op === 'agent.auto.pause') {
        sftasks.toggleVisibility(true);
      }
    },
    onLeaveAvailable: () => {
      sftasks.disableClickToDial();
    },
    onLeavePaused: () => {
      sftasks.enableClickToDial();
    },
    onManCall: (msg) => {
      $log.info(`[SFI] onManualCall: ${JSON.stringify(msg)}`);
      if (_.hasIn(msg, 'agentInteractionId')) {
        sftasks.callCreated({ data: msg, screenPop: false });
      }
    },
    onInboundCall: (msg) => {
      sftasks.toggleVisibility(true);
      $log.info(`[SFI] onInboundCall: ${JSON.stringify(msg.data)}`);
      sftasks.callCreated({ data: msg.data });
    },
    onOutboundPreview: (msg) => {
      $log.info(`[SFI] onOutboundPreview: ${JSON.stringify(msg.data)}`);
      sftasks.toggleVisibility(true);
      const numbers = [];
      const outboundCallData = () => CallService.outboundInfo;
      let filter = '';
      const dataObj = msg.data || outboundCallData();

      if (!_.isNil(msg.data)) {
        if (dataObj.phoneNumber1) {
          numbers.push(dataObj.phoneNumber1);
        }

        if (dataObj.phoneNumber2) {
          numbers.push(dataObj.phoneNumber2);
        }

        if (dataObj.phoneNumber3) {
          numbers.push(dataObj.phoneNumber3);
        }
      }

      numbers.forEach((number) => {
        const nationalPhoneNumber = number.replace(/[- )(]/g, '');
        const customerPhone = nationalPhoneNumber.replace(/[+][0-9][0-9]/, '0');
        filter += (filter === '') ? `${nationalPhoneNumber} OR ${customerPhone}` : ` OR ${nationalPhoneNumber} OR ${customerPhone}`;
      });

      sftasks.onOutboundPreview({ callType: 'Outbound', filter });
    },
    onOutboundCall: (msg) => {
      $log.info(`[SFI] onOutboundCall: ${JSON.stringify(msg.data)}`);
      sftasks.toggleVisibility(true);
      sftasks.callCreated({ data: msg.data });
    },
    onHangup: () => {
      $log.info('[SFI] onHangup');
      sftasks.enableClickToDial();
      sftasks.toggleVisibility(true);
      sftasks.hangupCall();
    },
    onBeforeOnWrap: (msg) => {
      $log.info(`[SFI] onBeforeOnWrap: ${JSON.stringify(msg.data)}`);
      return new Promise((resolve, reject) => {
        sftasks.wrapCall(msg).then((response) => {
          resolve(response);
        }).then(() => {
          sftasks.init();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    onWrap: (msg) => {
      console.log(`[SF] onWrap: ${JSON.stringify(msg.data)}`);
      sftasks.init();
      sftasks.enableClickToDial();
    },
  };
};

export default angular.module('CCAdaptor.App.SalesforceClassic', [SFTasks])
  .service('salesforceClassic', SalesforceClassic).name;
