import angular from 'angular';
import _ from 'lodash';

export default class ResourceController {
  constructor($log, $scope, $rootScope, $state, $transition$, $timeout) {
    'ngInject';

    $rootScope.view = 'resource';
    $scope.target = $transition$.params().target;

    switch ($scope.target) {
      case 'voice_recording': {
        $scope.activityId = (!_.isNil($transition$.params().activityId)) ?
          $transition$.params().activityId : null;
        break;
      }
      case 'payment': {
        $scope.params = $transition$.params();
        break;
      }
      default : {
        $log.debug($transition$.params());
      }
    }
  }
}
