import angular from 'angular';
import _ from 'lodash';

const Calls = ($http, $httpParamSerializer, $q, $injector, agentFSM, CallService,
  AgentInfoService, AgentStates, IntegrationService, PhoneService, ErrorService,
  StatusService, ApiService) => {
  'ngInject';

  const nativeIsArray = Array.isArray;
  const nativeMap = Array.prototype.map;
  const apiBuild = () => {
    return ApiService.build;
  };

  const lib = {
    settings: {
      currency: {
        symbol: '$', // default currency symbol is '$'
        format: '%s%v', // controls output: %s = symbol, %v = value (can be object, see docs)
        decimal: '.', // decimal point separator
        thousand: ',', // thousands separator
        precision: 2, // decimal places
        grouping: 3, // digit grouping (not implemented yet)
      },
      number: {
        precision: 0, // default precision on numbers is 0
        grouping: 3, // digit grouping (not implemented yet)
        thousand: ',',
        decimal: '.',
      },
    },
  };

  const isArray = (obj) => {
    return nativeIsArray ? nativeIsArray(obj) : toString.call(obj) === '[object Array]';
  };

  function map(obj, iterator, context) {
    const results = [];
    let i;
    let j;

    if (!obj) return results;

    // Use native .map method if it exists:
    if (nativeMap && obj.map === nativeMap) return obj.map(iterator, context);

    // Fallback for native .map:
    for (i = 0, j = obj.length; i < j; i += 1) {
      results[i] = iterator.call(context, obj[i], i, obj);
    }
    return results;
  }

  const unformat = (value, decimal) => {
    // Recursively unformat arrays:
    if (isArray(value)) {
      return map(value, (val) => {
        return unformat(val, decimal);
      });
    }

    // Fails silently (need decent errors):
    value = value || 0;

    // Return the value as-is if it's already a number:
    if (typeof value === 'number') return value;

    // Default decimal point comes from settings, but could be set to eg. "," in opts:
    decimal = decimal || lib.settings.number.decimal;

    // Build regex to strip out everything except digits, decimal point and minus sign:
    const regex = new RegExp(`[^0-9-${decimal}]`, ['g']);
    const unformatted = parseFloat(
      (`${value}`)
        .replace(/\((?=\d+)(.*)\)/, '-$1') // replace bracketed values with negatives
        .replace(regex, '') // strip out any cruft
        .replace(decimal, '.'), // make sure decimal point is standard
    );

    // This will fail silently which may cause trouble, let's wait and see:
    return !isNaN(unformatted) ? unformatted : 0;
  };

  const syncOnError = () => {
    $injector.get('SyncStateService').syncState();
  };

  return {
    dial: (phoneNumber, campaignId) => {
      const dfd = $q.defer();
      const endpoint = '/agent/call';
      const params = {
        number: phoneNumber,
        campaignId,
      };

      $http.post(IntegrationService.latestApiUri(endpoint), $httpParamSerializer(params), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then((response) => {
        let data = { agentFullName: AgentInfoService.agentFullName };
        data = _.merge(data, response.data.result);
        CallService.callInfo = data;

        if (campaignId) {
          agentFSM.manualCall(data);
        } else {
          switch (StatusService.status.label) {
            case 'available': {
              agentFSM.unallocatedManualCallFromAvailable(data);
              break;
            }
            case 'paused': {
              agentFSM.unallocatedManualCallFromPaused(data);
              break;
            }
            default: {
              agentFSM.unallocatedManualCall(data);
            }
          }
        }
        dfd.resolve(data);
      }).catch((response) => {
        ErrorService.handleError(endpoint, response);
        dfd.reject(response);
      });

      return dfd.promise;
    },
    manualPreview: (set = true) => {
      const dfd = $q.defer();
      const endpoint = (set) ? '/agent/manualpreview' : '/agent/cancelmanualpreview';

      $http.post(IntegrationService.latestApiUri(endpoint), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then((response) => {
        dfd.resolve(response.data);
      }).catch((response) => {
        ErrorService.handleError(endpoint, response);
        dfd.reject(response);
      });

      return dfd.promise;
    },
    previewCall: (interactionId, phoneNumber) => {
      const dfd = $q.defer();
      const endpoint = '/agent/previewcall';
      const params = {
        interactionId,
        phoneNumberId: phoneNumber,
      };

      $http.post(IntegrationService.latestApiUri(endpoint), $httpParamSerializer(params), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then((response) => {
        dfd.resolve(response.data);
      }).catch((response) => {
        ErrorService.handleError(endpoint, response);
        dfd.reject(response);
      });

      return dfd.promise;
    },
    withActivityId: (activityId) => {
      return {
        transfer: (transferDestinationType, transferDestination, isAttended, allowInvalidPhoneNumber = false, transferNotes = '') => {
          return new Promise((resolve, reject) => {
            const endpoint = '/agent/transfer';
            const params = {
              activityId,
              transferDestinationType,
              transferDestination,
              transferNotes,
              isAttended,
              allowInvalidPhoneNumber: (allowInvalidPhoneNumber) ? '1' : '0',
            };

            $http.post(IntegrationService.latestApiUri(endpoint), $httpParamSerializer(params), {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            }).then((response) => {
              resolve(response.data);
            }).catch((response) => {
              if (_.hasIn(response, 'data')) {
                if (_.hasIn(response.data, 'errorCode')) {
                  if (response.data.errorCode !== 'phoneNumber.invalid') {
                    ErrorService.handleError(endpoint, response);
                  }
                }
              }
              reject(response);
            });
          });
        },
        releaseCall: () => {
          const dfd = $q.defer();
          const endpoint = '/agent/releasecall';
          const params = {
            activityId,
          };

          $http.post(IntegrationService.latestApiUri(endpoint), $httpParamSerializer(params), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then((response) => {
            dfd.resolve(response.data);
          }).catch((response) => {
            ErrorService.handleError(endpoint, response);
            dfd.reject(response);
          });

          return dfd.promise;
        },
        switchLine: (line) => {
          const dfd = $q.defer();
          const endpoint = '/agent/switchline';
          const call = (line === 1) ? 'customer' : 'target';
          const params = {
            activityId,
            line: call,
          };

          $http.post(IntegrationService.latestApiUri(endpoint), $httpParamSerializer(params), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then((response) => {
            dfd.resolve(response.data);
          }).catch((response) => {
            ErrorService.handleError(endpoint, response);
            dfd.reject(response);
          });

          return dfd.promise;
        },
        retrieveCall: () => {
          const dfd = $q.defer();
          const endpoint = '/agent/retrievecall';
          const params = {
            activityId,
          };

          $http.post(IntegrationService.latestApiUri(endpoint), $httpParamSerializer(params), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then((response) => {
            dfd.resolve(response.data);
          }).catch((response) => {
            ErrorService.handleError(endpoint, response);
            dfd.reject(response);
          });

          return dfd.promise;
        },
        conferenceCall: () => {
          const dfd = $q.defer();
          const endpoint = '/agent/conferencecall';
          const params = {
            activityId,
          };

          $http.post(IntegrationService.latestApiUri(endpoint), $httpParamSerializer(params), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then((response) => {
            dfd.resolve(response.data);
          }).catch((response) => {
            ErrorService.handleError(endpoint, response);
            dfd.reject(response);
          });

          return dfd.promise;
        },
        hangup: ({ destinationAgentState, conferenceEndType = null }) => {
          const dfd = $q.defer();
          const endpoint = '/agent/hangup';

          if (apiBuild() < 7.20) {
            // eslint-disable-next-line no-param-reassign
            conferenceEndType = null;
          }

          if(!destinationAgentState) {
            ErrorService.report('[calls.js] hangup destinationAgentState null or undefined', { callInfo: CallService.callInfo, destinationAgentState: destinationAgentState });
          }

          const params = {
            activityId,
            destinationState: destinationAgentState.state,
            conferenceEndType,
          };

          $http.post(IntegrationService.latestApiUri(endpoint), $httpParamSerializer(params), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then((response) => {
            // For mapping the hangup event to a CRM
            CallService.pushToCallInfo('hangupOrigin', 'Agent');
            agentFSM.hangup();
            dfd.resolve(response.data);
          }).catch((response) => {
            ErrorService.handleError(endpoint, response);
            dfd.reject(response);
          });

          return dfd.promise;
        },
        toggleHold: () => {
          const dfd = $q.defer();
          const endpoint = (agentFSM.isOnHold()) ? '/agent/unhold' : '/agent/hold';
          const params = {
            activityId,
          };

          $http.post(IntegrationService.latestApiUri(endpoint), $httpParamSerializer(params), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then((response) => {
            dfd.resolve(response.data);
          }).catch((response) => {
            ErrorService.handleError(endpoint, response);
            dfd.reject(response);
          });

          return dfd.promise;
        },
        toggleRecord: (op) => {
          const dfd = $q.defer();
          // @param op - either 'start' or 'stop'
          const endpoint = `/agent/${op}VoiceRecording`;
          const params = {
            activityId,
          };

          $http.post(IntegrationService.latestApiUri(endpoint), $httpParamSerializer(params), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then((response) => {
            dfd.resolve(response.data);
          }).catch((response) => {
            ErrorService.handleError(endpoint, response);
            dfd.reject(response);
          });

          return dfd.promise;
        },
        activityWrap: (wrapCodeId, wrapCodeDescription, isCallback, callbackDateTime,
          callbackTimezone, callbackPhone, notes, destinationState, selectedRelatedPerson,
          selectedRelatedObject) => {
          const dfd = $q.defer();
          const endpoint = '/agent/wrapactivity';
          const msg = {
            data: {
              wrapCodeId,
              wrapCodeDescription,
              notes,
              isCallback,
              callbackDateTime,
              callbackTimezone,
              callbackPhone,
              selectedRelatedPerson,
              selectedRelatedObject,
            },
          };
          const params = {
            activityId,
            wrapCodeId,
            callNotes: notes,
            destinationState,
          };

          $http.post(IntegrationService.latestApiUri(endpoint), $httpParamSerializer(params), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then((response) => {
            agentFSM.wrapOutboundPreview(msg);
            dfd.resolve(response.data);
          }).catch((response) => {
            ErrorService.handleError(endpoint, response);
            dfd.reject(response);
          });

          return dfd.promise;
        },
        tagVoiceRecording: () => {
          const dfd = $q.defer();
          const endpoint = '/agent/tagvoicerecording';
          const nowStamp = Math.ceil(new Date().getTime() / 1000);
          const recordingSeconds = (!_.isNil(CallService.recordingStart))
            ? (nowStamp - CallService.recordingStart) : 0;

          const parameters = {
            activityId,
            recordingseconds: recordingSeconds,
          };

          $http.post(IntegrationService.latestApiUri(endpoint), $httpParamSerializer(parameters), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then((response) => {
            dfd.resolve(response);
          }).catch((response) => {
            ErrorService.handleError(endpoint, response);
            dfd.reject(response);
          });

          return dfd.promise;
        },
        initiatePayment: (amount, referenceId, paySettingsId, readOnlyAmount = 0,
          readOnlyReferenceId = 0, payTransactionId = null) => {
          const dfd = $q.defer();
          const endpoint = '/payment';

          // Strip currency symbol from amount
          const unformattedAmount = unformat(amount).toFixed(2);

          const parameters = {
            activityId,
            amount: unformattedAmount * 100,
            referenceId,
            paySettingsId,
            readOnlyAmount,
            readOnlyReferenceId,
            payTransactionId,
          };

          $http.post(IntegrationService.latestApiUri(endpoint), $httpParamSerializer(parameters), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then((response) => {
            dfd.resolve(response);
          }).catch((response) => {
            ErrorService.handleError(endpoint, response);
            dfd.reject(response);
          });

          return dfd.promise;
        },
        endPayment: (payTransactionId) => {
          const dfd = $q.defer();
          const endpoint = '/payment/end';

          const parameters = {
            activityId,
            payTransactionId,
          };

          $http.post(IntegrationService.latestApiUri(endpoint), $httpParamSerializer(parameters), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then((response) => {
            dfd.resolve(response);
          }).catch((response) => {
            ErrorService.handleError(endpoint, response);
            dfd.reject(response);
          });

          return dfd.promise;
        },
        playAnnouncement: (announcementId) => {
          const dfd = $q.defer();
          const endpoint = '/agent/playannouncement';
          const params = {
            activityId,
            announcementId,
          };

          $http.post(IntegrationService.latestApiUri(endpoint), $httpParamSerializer(params), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then((response) => {
            dfd.resolve(response.data);
          }).catch((response) => {
            ErrorService.handleError(endpoint, response);
            dfd.reject(response);
          });

          return dfd.promise;
        },
        stopAnnouncement: () => {
          const dfd = $q.defer();
          const endpoint = '/agent/stopannouncement';
          const params = {
            activityId,
          };

          $http.post(IntegrationService.latestApiUri(endpoint), $httpParamSerializer(params), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then((response) => {
            dfd.resolve(response.data);
          }).catch((response) => {
            ErrorService.handleError(endpoint, response);
            dfd.reject(response);
          });

          return dfd.promise;
        },
      };
    },
    withAgentInteractionId: (agentInteractionId) => {
      return {
        wrap: (wrapCodeId, wrapCodeDescription, isCallback, destinationState, pauseReasonId,
          notes, callQuality, callbackDateTime, callbackTimezone, callbackPhone,
          selectedRelatedPerson, selectedRelatedObject) => {
          const dfd = $q.defer();

          const endpoint = '/call/wrap';
          const msg = {
            data: {
              wrapCodeId,
              wrapCodeDescription,
              notes,
              isCallback,
              callbackDateTime,
              callbackTimezone,
              callbackPhone,
              selectedRelatedPerson,
              selectedRelatedObject,
            },
          };

          // only include pauseReasonId in params if not null
          if (pauseReasonId) {
            msg.data.pauseReasonId = pauseReasonId;
          }

          const params = {
            agentInteractionId,
            wrapCodeId,
            destinationState: destinationState.state,
            pauseReasonId,
            callNotes: notes,
            callQuality,
          };

          if (isCallback) {
            params.callbackDatetime = callbackDateTime;
            params.callbackTimezone = callbackTimezone;
            params.callbackPhone = callbackPhone;
          }

          $http.post(IntegrationService.latestApiUri(endpoint), $httpParamSerializer(params), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then((response) => {
            switch (destinationState) {
              case AgentStates.PAUSED:
                agentFSM.wrapPause(msg);
                break;
              case AgentStates.OUTBOUND_PREVIEW:
                agentFSM.wrapOutboundPreview(msg);
                break;
              case AgentStates.READY:
              default:
                agentFSM.wrapReady(msg);
                break;
            }
            PhoneService.reset();
            dfd.resolve(response);
          }).catch((response) => {
            ErrorService.handleError(endpoint, response);
            dfd.reject(response);
          });

          return dfd.promise;
        },
        validateCallback: (callback) => {
          const dfd = $q.defer();
          const endpoint = '/call/callback/time';

          const parameters = {
            agentInteractionId,
            wrapCodeId: callback.wrapCodeId,
            callbackDatetime: callback.dateTime,
            callbackTimezone: callback.timezone,
          };

          $http.post(IntegrationService.latestApiUri(endpoint), $httpParamSerializer(parameters), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then((response) => {
            dfd.resolve(response);
          }).catch((response) => {
            ErrorService.handleError(endpoint, response);
            dfd.reject(response);
          });

          return dfd.promise;
        },
      };
    },
    withCampaignId: (campaignId) => {
      return {
        loadTransferTarget: () => {
          const dfd = $q.defer();
          const endpoint = '/campaign/readtransfertargetslist';
          $http.get(IntegrationService.latestApiUri(endpoint), {
            params: { campaignId },
            handleErrorsLocally: true,
          }).then((data, status, headers, config) => {
            dfd.resolve(data);
          }).catch((response) => {
            syncOnError();
            ErrorService.handleError(endpoint, response);
          });

          return dfd.promise;
        },
        readAnnouncementsList: () => {
          const dfd = $q.defer();
          const endpoint = '/campaign/readannouncementslist';
          $http.get(IntegrationService.latestApiUri(endpoint), {
            params: { campaignId },
            handleErrorsLocally: true,
          }).then((data, status, headers, config) => {
            dfd.resolve(data);
          }).catch((response) => {
            syncOnError();
            ErrorService.handleError(endpoint, response);
          });

          return dfd.promise;
        },
      };
    },
    allWorkflows: () => {
      const dfd = $q.defer();
      const endpoint = '/workflow/readworkflowslist';
      $http.get(
        IntegrationService.latestApiUri(endpoint),
        {
          handleErrorsLocally: true,
        }).then((data, status, headers, config) => {
        dfd.resolve(data);
      }).catch((response) => {
        ErrorService.handleError(endpoint, response);
      });
      return dfd.promise;
    },
    allAgents: () => {
      const dfd = $q.defer();
      const endpoint = '/agent/readagentsavailablefortransfer';
      $http.get(
        IntegrationService.latestApiUri(endpoint),
        {
          params: {},
          handleErrorsLocally: true,
        })
        .then((data, status, headers, config) => {
          dfd.resolve(data);
        }).catch((response) => {
          ErrorService.handleError(endpoint, response);
        });
      return dfd.promise;
    },
    apiDetails: () => {
      const dfd = $q.defer();
      const endpoint = '/readapidetails';
      $http.get(IntegrationService.latestApiUri(endpoint),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }).then((response) => {
        dfd.resolve(response);
      }).catch((response) => {
        ErrorService.handleError(endpoint, response);
        dfd.reject(response);
      });
      return dfd.promise;
    },
  };
};

export default angular.module('CCAdaptor.App.Calls', []).factory('calls', Calls).name;
