import BooleanWatcherBuilder from './booleanwatcher.builder';
import ObjectWatcherBuilder from './objectwatcher.builder';
import StringWatcherBuilder from './stringwatcher.builder';

export default class WatchingAttributesBuilder {
  constructor(watched, id) {
    this._watched = watched;
    this._id = id;
  }

  withBooleanKey(key) {
    return new BooleanWatcherBuilder(this._watched, this._id, key);
  }

  withObjectKey(key) {
    return new ObjectWatcherBuilder(this._watched, this._id, key);
  }

  withStringKey(key) {
    return new StringWatcherBuilder(this._watched, this._id, key);
  }
}
