import angular from 'angular';

const notesTemplate = require('./notes.view.html');

const IPSNotes = () => {
  'ngInject';

  class NotesController {
    constructor(CallService, $scope) {
      'ngInject';

      $scope.$watch(() => { return CallService.callNotes; }, (newVal, oldVal) => {
        $scope.notes = newVal;
      });

      // Keep call notes up to date
      $scope.updated = () => {
        CallService.callNotes = $scope.notes;
      };
    }
  }

  return {
    restrict: 'E',
    template: notesTemplate,
    controller: NotesController,
    controllerAs: 'Notes',
    scope: true,
    bindToController: {
      notes: '=',
    },
  };
};

export default angular.module('CCAdaptor.App.Notes', [])
  .directive('ipsNotes', IPSNotes).name;
