import angular from 'angular';
import _ from 'lodash';

class CampaignService {
  constructor($injector, storagemanager) {
    'ngInject';

    this.manager = storagemanager;
    this.injector = $injector;
    this.locals = {};
    this.manager.watchers()
      .withObj(this.locals)
      .withId('campaign.service')
      .buildWithAttributes((a) => {
        // server data
        a.withObjectKey('allocated').withDefault([]).build();
        // client data
        a.withObjectKey('selected').withDefault({}).build();
      });
  }

  reset() {
    this.allocatedCampaigns = [];
    this.selectedCampaign = {};
  }

  get allocatedCampaigns() {
    return this.locals.allocated;
  }

  set allocatedCampaigns(campaigns) {
    this.locals.allocated = campaigns;
  }

  get selectedCampaign() {
    return this.locals.selected;
  }

  set selectedCampaign(campaign) {
    this.locals.selected = campaign;
  }

  get payDetails() {
    if (!_.isNil(this.locals.selected)) {
      const { selected } = this.locals;
      if (_.hasIn(selected, 'ipScapePayDetails')) {
        return selected.ipScapePayDetails;
      }
    }
    return [];
  }

  campaignTitleById(id) {
    let title = 'Unable to read Campaign Name';
    this.allocatedCampaigns.forEach((campaign) => {
      if (campaign.campaignId === id) title = campaign.campaignTitle;
    });
    return title;
  }

  createCampaignObject(msg) {
    let newCampaign;

    // Check if we are assigned to the campaign
    _.forEach(this.allocatedCampaigns, (value) => {
      if (_.hasIn(value, 'campaignId')) {
        if (value.campaignId === msg.campaignId) {
          const keys = Object.keys(value);
          keys.forEach((key) => {
            if (_.hasIn(msg, key)) {
              value[key] = msg[key];
            }
          });
          newCampaign = value.campaignId;
          // Select the campaign as normal
          this.selectedCampaign = value;
        }
      }
    });

    if (!newCampaign) {
      this.selectedCampaign = {
        campaignId: msg.campaignId,
        campaignTitle: msg.campaignTitle,
        allowTransferToAnyPhone: msg.allowTransferToAnyPhone,
        campaignDescription: null,
        campaignGroupName: null,
        campaignType: (msg.callDirection === 'IN') ? 'Inbound' : 'Outbound',
        hasAnnouncement: msg.hasAnnouncement,
        ipScapePayDetails: msg.ipScapePayDetails,
      };
    }
  }

  allowTransferToAnyPhone() {
    return this.locals.selected.allowTransferToAnyPhone || false;
  }
}

export default angular.module('CCAdaptor.App.CampaignService', [])
  .service('CampaignService', CampaignService).name;
