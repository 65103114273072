import angular from 'angular';
import ZDTasks from './zendesk.tasks';
/* global client */

const ZendeskIntegration = ($log, $q, $state, AgentVoiceStatus, zdtasks) => {
  'ngInject';

  // Pop adaptor if agent disconnected
  AgentVoiceStatus.addListener((status) => {
    if (!$state.current.name || $state.current.name === 'login') return;

    if (status === 'disconnected') {
      client.invoke('popover');
    }
  });

  const doesNothing = () => {
    // Do nothing
  };

  return {
    init: () => {
      $log.debug('zd integration: init');
      zdtasks.loadAdaptorConfig().then(() => {
        zdtasks.getCurrentUser();
        zdtasks.enableClickToDial();
      });
    },
    onEnterAvailable: doesNothing,
    onLeaveAvailable: doesNothing,
    onEnterPaused: (msg) => {
      if (msg && msg.op === 'agent.auto.pause') {
        client.invoke('popover');
      }
    },
    onLeavePaused: doesNothing,
    onManCall: doesNothing,
    onOutboundCall: doesNothing,
    onOutboundPreview: doesNothing,
    onInboundCall: (msg) => {
      const messageType = msg.data.transferCall ? 'transfer' : 'inbound';
      zdtasks.notify('success', 'Incoming Call');
      client.invoke('popover');
      zdtasks.createCall(msg, messageType);
    },
    onHangup: () => {
      client.invoke('popover');
      zdtasks.hangUp();
    },
    onBeforeOnWrap: msg => new Promise((resolve, reject) => {
      zdtasks.wrapCall(msg).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    }),
    onWrap: (msg) => {
      client.invoke('popover');
      console.log(`[ZD] onWrap: ${JSON.stringify(msg.data)}`);
    },
  };
};

export default angular.module('CCAdaptor.App.ZendeskIntegration', [ZDTasks])
  .service('zendeskIntegration', ZendeskIntegration).name;
