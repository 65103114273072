import angular from 'angular';
import _ from 'lodash';

const CustomSharedWorker = require('shared-worker-loader!../../../Worker.js');

class WorkerService {
  constructor($log, $window, $q, $interval, IntegrationService, AuthService, AgentInfoService, util,
    MessageHandlerFactory, ErrorService) {
    'ngInject';

    this.wsHost = IntegrationService.apiHostWS;
    this.id = null;
    this.pingPending = undefined;
    let iterations = 0;
    this.pingTimer = undefined;

    this.errorHandler = (err) => {
      if (typeof err === 'string') {
        ErrorService.handleError(`Error: ${err}`);
      } else if (err.message) {
        ErrorService.handleError(`Message: ${err.message} - File name: ${err.filename} Line number: ${err.lineno}`);
      }
    };

    this.isConnected = () => {
      if (this.worker) {
        $log.info('[WS] Connected: %s', this.worker.port);
        return !_.isNil(this.worker.port);
      }
      return false;
    };

    this.killWorker = () => {
      this.worker.port.close();
    };

    this.messageHandler = (event) => {
      const msg = event.data;
      if (_.hasIn(event.data, 'cmd')) {
        if (event.data.cmd === 'connected') {
          this.id = event.data.id;
        } else if (event.data.cmd === 'ping') {
          if (this.pingPending) {
            if (event.data.data.start === this.pingPending) {
              this.pingPending = undefined;
            }
          }
        }
      }

      if (_.hasIn(event.data, 'op')) {
        if (event.data.op === 'open') {
          if (AuthService.loggedIn) {
            this.postMessage({
              cmd: 'login',
              value: JSON.stringify({
                op: 'login',
                opId: util.guid(),
                auth: AuthService.sessionToken,
                org: AgentInfoService.organisationDetails.orgId,
              }),
            });
          }
        } else {
          MessageHandlerFactory.messageHandler(msg);
        }
      }
    };

    this.postMessage = (msg) => {
      $log.info(`[WS] Post Message: ${JSON.stringify(msg)}`);
      try {
        this.worker.port.postMessage(msg);
      } catch (error) {
        ErrorService.warn('[WS] PostMessage Error:', error);
      }
    };

    this.resolvePingTimer = () => {
      if (this.pingPending === undefined) {
        $interval.cancel(this.pingTimer);
        iterations = 0;
      } else {
        iterations += 1;
        if (iterations >= 6) {
          iterations = 0;
          $interval.cancel(this.pingTimer);
          this.killWorker();
          this.init().then(() => {
            this.reconnect();
          });
        }
      }
    };

    this.sendPing = () => {
      const dfd = $q.defer();
      this.pingPending = Date.now();
      // Start interval
      this.pingTimer = $interval(() => {
        this.resolvePingTimer();
      }, 100);
      // Post PING to worker
      this.postMessage({
        cmd: 'ping',
        data: {
          id: this.id,
          start: this.pingPending,
        },
      });

      if (!this.pingTimer.cancelled) {
        dfd.resolve();
      } else {
        dfd.reject();
      }
      return dfd.promise;
    };

    this.connect = () => {
      try {
        if (!this.worker) this.init();
        this.postMessage({
          cmd: 'connect',
          value: JSON.stringify({
            apiHostws: this.wsHost,
          }),
        });
      } catch (error) {
        ErrorService.warn('[WS] Connect Error:', error);
      }
    };

    this.disconnect = () => {
      if (!this.worker) this.init();
      try {
        this.postMessage({
          cmd: 'disconnect',
        });
      } catch (error) {
        ErrorService.warn('[WS] Disconnect Error:', error);
      }
    };

    this.reconnect = () => {
      try {
        this.sendPing().then(() => {
          this.postMessage({
            cmd: 'sync',
            value: JSON.stringify({ op: 'sync' }),
          });
        });
      } catch (error) {
        ErrorService.warn('[WS] Reconnect Error:', error);
      }
    };

    this.currentAgentState = () => {
      if (AuthService.loggedIn) {
        this.postMessage({
          cmd: 'status',
          value: JSON.stringify({
            op: 'status',
          }),
        });
      }
    };

    this.init = () => {
      const dfd = $q.defer();
      if (this.worker) {
        this.killWorker();
      }
      try {
        this.worker = new CustomSharedWorker('worker.js');
        this.worker.onerror = this.errorHandler;
        this.worker.port.start();
        this.worker.port.addEventListener('message', this.messageHandler, false);

        $window.addEventListener('beforeunload', (e) => {
          this.postMessage({ cmd: 'closing', id: this.id });
        });
        dfd.resolve();
      } catch (error) {
        dfd.reject();
        ErrorService.warn('[WS] Init Error:', error);
      }
      return dfd.promise;
    };

    this.init();
  }


  // ---------------------------------------
  // Service
  // ---------------------------------------

  connect() {
    this.connect();
  }

  disconnect() {
    this.disconnect();
  }

  reconnect() {
    this.reconnect();
  }

  currentAgentState() {
    this.currentAgentState();
  }
}

export default angular.module('CCAdaptor.App.WorkerService', [])
  .service('WorkerService', WorkerService).name;
