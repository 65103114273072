/*
 * Javascripts
 * ========================================================================== */

import angular from 'angular';
import LoginController from './controller/LoginController';

/*
 * Stylesheets
 * ========================================================================== */

import './stylesheets/login.scss';

/* ========================================================================== */

/**
 * Define home page module.
 * @param {String} moduleName
 * @param {Array} dependencies
 * @export Module name - name of this module.
 */
export default angular.module('CCAdaptor.App.LoginController', [])
  .controller('LoginController', LoginController).name;
