import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';

const SNTasks = ($log, $rootScope, IntegrationService, TaskService,
  ErrorService, ConfigService, CallService) => {
  'ngInject';

  const api = {};
  let version;

  //--------------------------------------------------------------
  // helper functions
  //--------------------------------------------------------------
  const numDigits = (x) => {
    return Math.max(Math.floor(Math.log10(Math.abs(x))), 0) + 1;
  };

  const formatDate = (dte, pattern) => {
    return moment.utc(dte).format(pattern);
  };

  const clickToDial = (result) => {
    if (result) {
      $rootScope.$broadcast('event.user', {
        action: 'click-to-dial',
        clickToDialData: result,
      });
      openFrameAPI.show();
    }
  };

  const handleCommunicationEvent = (context) => {
    $log.debug('Communication from Topframe', context);
    if (context.type === 'CLICK_TO_DIAL') {
      $log.debug('Communication context type is CLICK_TO_DIAL');
      const result = {
        number: context.data.phoneNumber,
      };
      clickToDial(result);
    }
    if (context.type === 'TRANSFER_CALL_DATA') {
      $log.debug('Communication context type is TRANSFER_CALL_DATA');
      CallService.callNotes = JSON.stringify(context.data);
    }
  };

  const loadAdaptorConfig = () => {
    const settings = {
      allowCustomObjects: 'false',
      ipsCustomObjs: '',
      showOpenLogButton: 'false',
      displayWrapCodes: 'true',
      defaultWrapCode: '',
    };

    const snConfig = {
      instance: IntegrationService.instance,
    };

    if (!version) {
      const customizer = (objValue, srcValue) => {
        return _.isUndefined(objValue) ? srcValue : objValue;
      };

      const initSuccess = (adaptorConfig) => {
        openFrameAPI.subscribe(openFrameAPI.EVENTS.COMMUNICATION_EVENT, handleCommunicationEvent);

        if (adaptorConfig) {
          adaptorConfig.configuration = JSON.parse(adaptorConfig.configuration);

          if (typeof adaptorConfig.ipsSettings === 'undefined') {
            adaptorConfig.ipsSettings = settings;
          } else {
            _.assignInWith(adaptorConfig.ipsSettings, settings, customizer);
          }

          if (!_.get(adaptorConfig.configuration, 'screenPopConfig')) {
            ErrorService.report('event.screenpop', 'Unable to get call centre settings.');
          } else {
            if (!_.get(adaptorConfig.configuration.screenPopConfig, 'inbound')) {
              ErrorService.report('event.inbound', 'Unable to get call centre settings.');
            }

            if (!_.get(adaptorConfig.configuration.screenPopConfig, 'outbound')) {
              ErrorService.report('event.outbound', 'Unable to get call centre settings.');
            }

            if (!_.get(adaptorConfig.configuration.screenPopConfig, 'transfer')) {
              ErrorService.report('event.transfer', 'Unable to get call centre settings.');
            }
          }

          ConfigService.adaptor = adaptorConfig;
          version = openFrameAPI.version();
        } else {
          ErrorService.report('event.snConfig', 'Unable to get call centre settings.');
        }
      };

      const initFailure = (error) => {
        $log.debug('OpenFrame init failed..', error);
      };

      openFrameAPI.init(snConfig, initSuccess, initFailure);
    }
  };

  const mapFields = (call, callData) => {
    const adaptor = ConfigService.adaptor;
    let map = '';

    if (!callData) {
      ErrorService.report('call.info.error', 'Fatal Error: Call information not available.');
      return false;
    }

    const makeMap = (src) => {
      angular.forEach(src, (v, k) => {
        // check for string values
        if (v.startsWith("'")) {
          const value = v.replace(/'/g, '');
          map += `&${k}=${value}`;
        }

        // search through the callData to see if we can find the match
        if (Object.prototype.hasOwnProperty.call(callData, v)) {
          let val = callData[v];

          // Check if unix time stamp and add format
          if (typeof val === 'number' && numDigits(val) >= 13) {
            val = formatDate(val, 'YYYY-MM-DD HH:mm:ss');
          }
          map += `&${k}=${val}`;
        }
        // we need to look through the ivrData as well
        if (Object.prototype.hasOwnProperty.call(callData.ivrData, v)) {
          map += `&${k}=${callData.ivrData[v]}`;
        }
      });
    };

    switch (call) {
      case 'outbound': {
        const config = adaptor.configuration.screenPopConfig.outbound;
        makeMap(config.fieldMapping);
        break;
      }
      case 'inbound': {
        const config = adaptor.configuration.screenPopConfig.inbound;
        makeMap(config.fieldMapping);
        break;
      }
      case 'transfer': {
        const config = adaptor.configuration.screenPopConfig.transfer;
        makeMap(config.fieldMapping);
        break;
      }
      default: {
        $log.warning('No data map');
        break;
      }
    }

    return map;
  };

  //--------------------------------------------------------------
  // instance functions
  //--------------------------------------------------------------

  api.init = () => {
    $log.debug('snTasks: init called');
  };

  //--------------------------------------------------------------
  // call actions
  //--------------------------------------------------------------

  api.callCreated = () => {
    $log.debug('get call started task param');
  };

  api.callHungup = () => {
    $log.debug('sn: hangupCall: ');
  };

  api.callWrapped = () => {
    $log.info('Call wrapped');
    $rootScope.$broadcast('event.system', {
      action: 'call.wrap.complete',
    });
  };

  return {
    init: api.init,
    loadAdaptorConfig,
    mapFields,
    createCall: api.callCreated,
    hangupCall: api.callHungup,
    wrapCall: api.callWrapped,
  };
};

export default angular.module('CCAdaptor.App.SNTasks', [])
  .factory('sntasks', SNTasks).name;
