import angular from 'angular';
import _ from 'lodash';
import ipsHttpInterceptor from './IpsHttpInterceptor';

// Require templates as global
const loginTemplate = require('./modules/Login/views/login.html');
const connectTemplate = require('./modules/Connect/views/connect.html');
const toolbarTemplate = require('./modules/Toolbar/views/toolbar.html');
const callTemplate = require('./modules/Call/views/call.html');
const transferTemplate = require('./modules/Transfer/views/transfer.html');
const resourceTemplate = require('./modules/Resource/views/resource.html');
const notfoundTemplate = require('./modules/Main/views/404.jade')();

const Config = (stateHelperProvider, $urlRouterProvider, $locationProvider, $injector,
  $httpProvider, $logProvider, toastrConfig, $provide) => {
  'ngInject';

  $logProvider.debugEnabled(true); // Turn debug mode on/off
  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false,
    hashPrefix: '!',
  });

  $urlRouterProvider.when('', '/');

  // Modify toastr target
  angular.extend(toastrConfig, {
    positionClass: 'toast-top-full-width',
    containerId: 'toast-container',
    allowHtml: true,
    preventDuplicates: false,
    preventOpenDuplicates: true,
    progressBar: false,
    newestOnTop: false,
    maxOpened: 1,
    target: 'body',
  });

  $urlRouterProvider.when('/resource', () => {
    // Modify toastr target
    angular.extend(toastrConfig, {
      target: 'header',
    });
  });

  // Added for Azure SSO
  if (_.hasIn(window.IPSCAPE, 'adalConfig')) {
    $injector.get('adalAuthenticationServiceProvider').init(window.IPSCAPE.adalConfig, $httpProvider);
  }

  ipsHttpInterceptor.registerHandlers($httpProvider);

  /** If current route not in routes then it's a 404 */
  $urlRouterProvider.otherwise(() => {
    $injector.invoke(['$state', ($state) => {
      $state.transitionTo('404', {
        param: 'Error: 404 page missing',
      }, {
        reload: false,
      });
    }]);
  });

  /** If route like as /home/ then /home */
  // eslint-disable-next-line no-shadow
  $urlRouterProvider.rule(($injector, $location) => {
    const path = $location.path();
    $location.path(path[path.length - 1] === '/' ? path.slice(0, -1) : path).replace();
  });

  const transitionToIf = (name, bool, $q, $state, $transition$) => {
    const defer = $q.defer();
    if (bool) {
      return $state.transitionTo(name, $transition$.params(), {
        reload: false,
        inherit: false,
        notify: true,
      });
    }
    defer.resolve();
    return defer.promise;
  };

  const skipIfAuthenticated = (AuthService, $q, $state, $transition$) => {
    'ngInject';

    return transitionToIf('toolbar', AuthService.isLoggedIn(), $q, $state, $transition$);
  };

  const redirectIfNotAuthenticated = (AuthService, $q, $state, $transition$) => {
    'ngInject';

    return transitionToIf('login', !AuthService.isLoggedIn(), $q, $state, $transition$);
  };

  const skipConnectIfOnPhone = (agentFSM, $q, $state, $transition$) => {
    'ngInject';

    return transitionToIf('toolbar', !agentFSM.isOffPhone(), $q, $state, $transition$);
  };

  const redirectIfNotConnected = (agentFSM, $q, $state, $transition$) => {
    'ngInject';

    return transitionToIf('connect', agentFSM.isOffPhone(), $q, $state, $transition$);
  };

  const redirectIfNotOnCallOrDialling = (agentFSM, $q, $state, $transition$) => {
    'ngInject';

    return transitionToIf('toolbar', !agentFSM.isOnCallOrDiallingOrFailedOrHoldOrWrap(), $q, $state, $transition$);
  };

  const redirectIfOnCallOrDialling = (agentFSM, $q, $state, $transition$) => {
    'ngInject';

    return transitionToIf('call', agentFSM.isOnCallOrDiallingOrFailedOrHoldOrWrap(), $q, $state, $transition$);
  };

  const allowedParams = '?api&integrationType&apiKey&raygun&reporting&devMode&integrationVersion&host&connect&useWorker';
  const resourceParams = '?activityId&interactionId&ref&amount&readOnlyAmount&readOnlyReferenceId';

  /** Describe our states */
  stateHelperProvider
    .state({
      url: `/${allowedParams}`,
      name: 'login',
      resolve: {
        skipIfAuthenticated,
      },
      controller: 'LoginController',
      controllerAs: 'login',
      template: loginTemplate,
    })
    .state({
      url: `/connect${allowedParams}`,
      name: 'connect',
      resolve: {
        redirectIfNotAuthenticated,
        redirectIfConnected: skipConnectIfOnPhone,
      },
      controller: 'ConnectController',
      controllerAs: 'connect',
      template: connectTemplate,
    })
    .state({
      url: `/toolbar${allowedParams}`,
      name: 'toolbar',
      resolve: {
        redirectIfNotAuthenticated,
        redirectIfNotConnected,
        redirectIfOnCall: redirectIfOnCallOrDialling,
      },
      controller: 'ToolbarController',
      controllerAs: 'Toolbar',
      template: toolbarTemplate,
    })
    .state({
      url: `/call${allowedParams}`,
      name: 'call',
      resolve: {
        redirectIfNotAuthenticated,
        redirectIfNotConnected,
        redirectIfNotOnCall: redirectIfNotOnCallOrDialling,
      },
      controller: 'CallController',
      controllerAs: 'Call',
      template: callTemplate,
    })
    .state({
      url: '/transfer',
      name: 'call.transfer',
      resolve: {
        redirectIfNotAuthenticated,
        redirectIfNotConnected,
        redirectIfNotOnCall: redirectIfNotOnCallOrDialling,
      },
      views: {
        modal: {
          controller: 'TransferController',
          controllerAs: 'Transfer',
          template: transferTemplate,
        },
      },
    })
    .state({
      url: `/resource/:target${resourceParams}`,
      name: 'resource',
      controller: 'ResourceController',
      controllerAs: 'resource',
      template: resourceTemplate,
    })
    .state({
      name: '404',
      url: '/*path',
      template: notfoundTemplate,
      controller: ($rootScope, $location) => {
        $rootScope.currentUrl = $location.url();
        $rootScope.is404 = true;
      },
      controllerAs: 'Home',
    });

  /* Register a decorator for the `$interval` service */
  $provide.decorator('$interval', ($delegate) => {
    /* Keep a reference to the original `cancel()` method */
    const originalCancel = $delegate.cancel;

    /* Define a new `cancel()` method */
    $delegate.cancel = (intervalPromise) => {
      /* First, call the original `cancel()` method */
      const retValue = originalCancel(intervalPromise);

      /* If the promise has been successfully cancelled,
         * add a `cancelled` property (with value `true`) */
      if (retValue && intervalPromise) {
        intervalPromise.cancelled = true;
      }

      /* Return the value returned by the original method */
      return retValue;
    };

    /* Return the original (but "augmented") service */
    return $delegate;
  });

  $provide.decorator('$exceptionHandler', ($injector, $delegate) => {
    return (exception, cause) => {
      const enableRollbar = window.IPSCAPE.dependency.reporting;

      if (enableRollbar) {
        const cfg = {
          payload: {
            server: {
              host: window.IPSCAPE.dependency.apiUrl,
            },
          },
        };

        window.IPSCAPE.Rollbar.configure(cfg);

        window.IPSCAPE.Rollbar.error(exception, { cause }, (er, data) => {
          const $rootScope = $injector.get('$rootScope');
          $rootScope.$emit('rollbar:exception', {
            exception,
            er,
            data: data.result,
          });
        });
      }

      $delegate(exception, cause);
    };
  });
};

/** Export our config */
export default Config;
