/*
 * Javascripts
 * ========================================================================== */

import angular from 'angular';
import ResourceController from './controller/ResourceController';

/*
 * Directives
 * ========================================================================== */

import VoiceRecordingDirective from './directives/actions/voiceRecording.directive';

/*
 * Components
 * ========================================================================== */

import IpsPaymentsComponent from './components/ipsPayments.component';

/*
 * Services
 * ========================================================================== */

import PaymentsService from './service/PaymentsService';

/* Layout
 * ===================================== */

import './stylesheets/resource.scss';

export default angular.module('CCAdaptor.App.ResourceController', [
  VoiceRecordingDirective,
  IpsPaymentsComponent,
  PaymentsService,
]).controller('ResourceController', ResourceController).name;
