import angular from 'angular';
import _ from 'lodash';

const ErrorTranslateService = ($log, SyncStateService) => {
  'ngInject';

  /**
   * getTranslatedError - used for user feedback
   *
   * @param {Object} data
   * data should contain the following params
   * - tag {String} - unique to identify the error
   * - message {String} - Additional detail for logging
   * - details {Array} - of Objects
   * -- debuggingInfo: Message that can be appended to user message
   * -- description: Displayed to the user
   * -- name: Error identifier
   * @returns translatedError {String}
   */

  function getTranslatedError(data) {
    let translatedError;

    if (_.hasIn(data, 'details')) {
      let errDetails = '';
      _.forEach(data.details, (value) => {
        if (_.hasIn(value, 'name')) {
          switch (value.name) {
            case 'agent.transferBlind': {
              if (value.description === '.agent.not.talking') {
                errDetails += 'The contact is disconnected. ';
              } else if (value.description === 'agent.unavailable') {
                errDetails += 'The selected agent is unavailable.';
              } else if (value.description === '.destination.invalid') {
                errDetails += 'Phone number is in invalid format.';
              } else {
                errDetails += 'The selected agent is unavailable.';
              }
              break;
            }
            case 'agent.transfer': {
              if (value.description === '.agent.not.talking') {
                errDetails += 'The contact is disconnected. ';
              } else if (value.description === 'agent.unavailable') {
                errDetails += 'The selected agent is unavailable.';
              } else if (value.description === '.destination.invalid') {
                errDetails += 'The phone number format is invalid.';
              } else {
                errDetails += 'The selected agent is unavailable.';
              }
              break;
            }
            case 'user.login.user.doesNotExist':
            case 'oauth.token.user.doesNotExist': {
              errDetails += 'The username or password entered is incorrect.';
              break;
            }
            case 'user.login.username.invalidChars': {
              errDetails += 'Use only A-Z, a-z, 0-9, and []{}<>&#92;/|;:\',.?-_~*@ ';
              break;
            }
            case 'parameter.missingRequired': {
              errDetails += 'One or more required parameters are missing.';
              break;
            }
            default: {
              errDetails += `${value.description} `;
            }
          }
        } else {
          errDetails = 'Oops! We\'ve encountered an unexpected external error.';
        }

        // Exception added for callback validation
        if (_.hasIn(value, 'suggestedTime')) {
          errDetails = `Callback date/time is outside business hours. Next available time is ${value.suggestedTime}.`;
        }
      });
      translatedError = errDetails;
    } else if (_.hasIn(data, 'tag')) {
      switch (data.tag) {
        case 'status.notInStatus': {
          translatedError = 'Agent has incorrect status.';
          break;
        }
        case 'status.pause.agentIsAllocated': {
          translatedError = 'Pausing is not available at this time.';
          break;
        }
        case 'validation.inputFailure': {
          translatedError = 'Invalid characters have been entered. Use only A-Z, a-z and 0-9';
          break;
        }
        case 'authentication.error':
        case 'oauth.token.authorization.failed': {
          translatedError = 'The username or password entered is incorrect.';
          break;
        }
        case 'error.unknown': {
          translatedError = 'An unknown error has occurred.';
          break;
        }
        case 'contact.call.hangup': {
          // translatedError = 'The contact has disconnected.';
          translatedError = 'Call Ended.';
          break;
        }
        case 'agent.call.hangup': {
          translatedError = 'The call has been disconnected.';
          break;
        }
        default: {
          translatedError = data.message;
        }
      }
    }

    // Added here as Oauth endpoint error format is different
    if (data.tag === 'oauth.token.authorization.failed') {
      translatedError = 'The username or password entered is incorrect.';
    }

    if (data.tag === 'status.notInStatus' || data.tag === 'status.pause.agentIsAllocated') {
      if (data.details.currentAgentStatus !== 'AutoLogout' ||
        data.details.currentAgentStatus !== 'Login' ||
        data.details.currentAgentStatus !== 'Available' ||
        data.details.currentAgentStatus !== 'Talk'
      ) {
        SyncStateService.getSyncState(data);
      }
    }

    if (_.isNil(translatedError)) {
      translatedError = 'Not in Status';
    }

    return translatedError;
  }

  function getErrorTitle(data) {
    return '';
  }

  function getNotificationType(data) {
    const tag = data.tag;
    if (typeof tag === 'string' && tag.indexOf('hangup') > -1) {
      return 'info';
    }
    return 'error';
  }

  return {
    getTranslatedError,
    getErrorTitle,
    getNotificationType,
  };
};

export default angular.module('CCAdaptor.App.ErrorTranslateService', [])
  .factory('ErrorTranslateService', ErrorTranslateService).name;
