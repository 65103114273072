import angular from 'angular';

const ConnectionPromptTemplate = require('./../ConnectionPrompt/ConnectionPrompt.html');

class ConnectionPromptController {
  constructor($log, $scope, $rootScope, $state, $cookies, agent, ErrorService,
    AgentVoiceStatus, AgentInfoService) {
    'ngInject';

    const getCookie = () => {
      const pref = ($cookies.get('allowInvalidPhoneNumber'))
        ? $cookies.get('allowInvalidPhoneNumber') : false;
      return pref;
    };

    // Check cookie for previous preferences
    $scope.allowInvalidPhoneNumber = getCookie();

    // Agent State Name
    $scope.agentStateName = () => {
      return $state.current.name;
    };

    // Connection Persistence
    $scope.connectionPersistence = () => {
      return AgentInfoService.connectionPersistence;
    };

    // Agent Voice Status
    $scope.agentVoiceStatus = () => {
      return AgentVoiceStatus.status;
    };

    // Return agent details
    $scope.agentDetails = () => {
      return AgentInfoService.details;
    };

    // Return connection mode ['systemCallsAgent', 'agentCallsSystem', 'ipscapeWebrtc']
    $scope.connectionMode = () => {
      return AgentInfoService.connectionMode;
    };

    // Return agentId
    $scope.agentId = () => {
      return AgentInfoService.agentId;
    };

    // Display reconnect button
    $scope.displayReconnectButton = () => {
      return ['systemCallsAgent', 'ipscapeWebrtc'].indexOf($scope.connectionMode()) !== -1;
    };

    // Display Reconnect Prompt
    $scope.showPrompt = ({ toggle = null }) => {
      if (typeof toggle === 'boolean') return toggle;
      if (['login', 'connect'].indexOf($scope.agentStateName()) !== -1) return false;
      if (!$scope.connectionPersistence()) return false;
      if ($scope.agentVoiceStatus() === 'disconnected') {
        return true;
      }

      $scope.expand = true;
      $scope.modalHeight = '100%';
      return false;
    };

    $scope.toggleExpand = () => {
      if (!$scope.expand) {
        $scope.expand = true;
        $scope.modalHeight = '100%';
      } else {
        $scope.expand = false;
        $scope.modalHeight = false;
      }
    };

    $scope.reconnect = () => {
      agent.phone.connect(
        $scope.allowInvalidPhoneNumber,
        $scope.agentDetails().connectionMode,
        $scope.agentDetails().countryCode,
        $scope.agentDetails().persistentConnection,
        $scope.agentDetails().remotePhone,
      ).then(() => {
        $scope.showPrompt({ toggle: false });
      }).catch((response) => {
        ErrorService.handleError('phone.reconnect', response);
      });
    };

    $scope.$on('$destroy', () => {
      $scope.showPrompt({ toggle: false });
    });
  }
}

const ConnectionPrompt = () => {
  'ngInject';

  const directive = {
    restrict: 'E',
    template: ConnectionPromptTemplate,
    scope: {
      agentConnectedStatus: '=',
      isOnLoginScreen: '=',
    },
    controller: ConnectionPromptController,
  };

  return directive;
};

export default angular.module('CCAdaptor.App.IPSConnectionPrompt', [])
  .directive('ipsConnectionPrompt', ConnectionPrompt).name;
