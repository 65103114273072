/*
 * Javascripts
 * ========================================================================== */

import angular from 'angular';
import Layout from './Index';
import LoginController from '../Login/Index';
import ConnectController from '../Connect/Index';
import ToolbarController from '../Toolbar/Index';
import CallController from '../Call/Index';
import TransferController from '../Transfer/Index';
import ResourceController from '../Resource/Index';

/**
 * Define app module.
 * @param {String} moduleName
 * @param {Array} dependencies
 * @export Controllers module
 */
export default angular.module('CCAdaptor.App.controllers', [
  Layout,
  LoginController,
  ConnectController,
  ToolbarController,
  CallController,
  TransferController,
  ResourceController,
]).name;
