import angular from 'angular';

class ToastrService {
  constructor($log, toastr) {
    'ngInject';

    this.toastr = toastr;
    const pathname = window.location.pathname;
    this.pathParamsArr = pathname.substring(1).split('/');
  }

  makeToast({ type = 'success', message = '', heading = '', opts = null }) {
    if (this.pathParamsArr[0] === 'resource') return;
    this.toastr[type](message, heading, opts);
  }

  cleanUp() {
    this.toastr.clear();
  }
}

export default angular.module('CCAdaptor.App.ToastrService', [])
  .service('ToastrService', ToastrService).name;
