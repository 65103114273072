import angular from 'angular';
import _ from 'lodash';

const HeaderDirectiveTemplate = require('./header.view.html');

const HeaderDirective = () => {
  'ngInject';

  class HeaderController {
    constructor($log, $rootScope, $state, $scope, CallService, StatusService, agent,
      AuthService, ToastrService, ConfigService) {
      'ngInject';

      $scope.pauseReasons = [];

      //-------------------------------------------------
      // UI functions
      //-------------------------------------------------
      $scope.currentAgentState = agent.state;
      $scope.currentAgentReadableState = agent.readableState;

      $scope.isValidPauseReasonEnforced = () => {
        return _.get(agent.organisationDetails(), 'enforceValidPauseReason', true);
      };
      $scope.isAvailableOrPaused = () => {
        return agent.isOnPhoneAvailable() || agent.isOnPhonePaused();
      };
      $scope.isOnCallWithSelectedWrapCode = () => {
        return agent.isOnCallOrWrapOrDialling() && !_.isNil(CallService.callOutcome);
      };
      $scope.isOnPhonePausedWithReason = () => {
        return agent.isOnPhonePaused() && StatusService.hasPauseReason;
      };
      $scope.isVisible = () => {
        if ($state.current.name === 'resource') {
          return false;
        }
        return AuthService.isLoggedIn();
      };
      $scope.showReady = () => {
        return agent.isOnPhonePaused() || $scope.isOnCallWithSelectedWrapCode();
      };
      $scope.showPauseNoReason = () => {
        if ($scope.isValidPauseReasonEnforced()) {
          return false;
        }

        if (agent.isOnPhonePaused() && !StatusService.hasPauseReason) {
          return false;
        }
        return (agent.isOnPhonePaused()
        || agent.isOnPhoneAvailable()
        || $scope.isOnCallWithSelectedWrapCode());
      };
      $scope.topMenu = false;

      //-------------------------------------------------
      // style
      //-------------------------------------------------
      $scope.getToolbarClasses = () => {
        let classes = `status-${$scope.currentAgentState()}`;
        if ($scope.isOnPhonePausedWithReason()) {
          classes += ' status-description';
        }
        return classes;
      };

      //-------------------------------------------------
      // pause reasons
      //-------------------------------------------------
      $scope.availablePauseReasons = () => {
        let reasons = [];
        if (AuthService.isLoggedIn() && (agent.isOnPhoneAvailable()
          || agent.isOnPhonePaused()
          || $scope.isOnCallWithSelectedWrapCode())) {
          if (_.get(agent.details(), 'enablePauseReason', true)) {
            reasons = reasons.concat(agent.pauseReasons());
          }
        }

        return _.filter(reasons, (pr) => {
          return !agent.isOnPhonePaused()
          || !_.isEqual(StatusService.pauseReasonTitle, pr.pauseReasonTitle);
        });
      };

      $scope.pauseReasonTitle = () => { return StatusService.pauseReasonTitle; };

      $scope.toPauseClickNoReason = () => {
        StatusService.status = { label: 'paused', id: 0 };
        $scope.toUpdatedPauseClick({ pauseReasonTitle: 'Pause' });
      };

      $scope.toUpdatedPauseClick = (pauseReason) => {
        const saveReason = () => {
          StatusService.pauseReasonTitle = pauseReason.pauseReasonTitle;
        };
        if (agent.isOnPhoneAvailable()) {
          agent.pause(pauseReason.pauseReasonId, saveReason);
        } else if (agent.isOnPhonePaused()) {
          agent.updatePause(pauseReason.pauseReasonId, saveReason);
        } else if (agent.isOnCallOrWrapOrDialling()) {
          $rootScope.$broadcast('event.user', {
            action: 'wrap',
            tag: 'wrapToPaused',
            pauseReason,
          });
        } else {
          $log.error('Unhandled pause click case');
        }
      };

      $scope.unpauseClick = () => {
        const clearPauseReason = () => {
          StatusService.pauseReasonTitle = undefined;
        };
        if ($scope.isOnCallWithSelectedWrapCode()) {
          $rootScope.$broadcast('event.user', {
            action: 'wrap',
            tag: 'wrapToAvailable',
          });
        } else {
          $scope.runningPromise = agent.unpause(clearPauseReason);
        }
      };

      $scope.updateStatus = () => {
      };

      //--------------------------------------------------
      // logout
      //--------------------------------------------------
      $scope.isLogoutPossible = agent.canLogout;
      $scope.logoutClick = () => {
        const ts = new Date();
        if ($scope.isLogoutPossible()) {
          $scope.runningPromise = AuthService.logout();
          $rootScope.$broadcast('event.user', {
            action: 'user.logout',
            timestamp: ts.toUTCString(),
          });
        } else {
          const toastrLogoutOptions = {
            closeButton: false,
            escapeHtml: true,
            extendedTimeOut: 0,
            timeOut: 0,
            debug: true,
            preventDuplicates: true,
            onShown: () => {
              angular.element('.toast-message #confirmLogout').on('click', () => {
                $scope.runningPromise = AuthService.logout();
              });
              angular.element('.toast-message #cancelLogout').on('click', () => {
                ToastrService.cleanUp();
              });
            },
          };
          let logoutMessage = 'Logging out only when paused is recommended, logout anyway?<br/>';
          logoutMessage += '<button id="cancelLogout" type="button" class="btn btn-default">No</button>&nbsp;';
          logoutMessage += '<button id="confirmLogout" type="button" class="btn btn-default">Yes</button>';
          ToastrService.makeToast({ type: 'warning', message: logoutMessage, opts: toastrLogoutOptions });
        }
      };

      //--------------------------------------------------
      // help centre
      //--------------------------------------------------
      $scope.helpCentreUrl = () => {
        const { config } = ConfigService;
        return config.helpCentreUrl;
      };
    }
  }

  return {
    restrict: 'EA',
    template: HeaderDirectiveTemplate,
    controller: HeaderController,
    controllerAs: 'Header',
    bindToController: true,
    scope: {},
  };
};

export default angular.module('CCAdaptor.App.IPSHeader', [])
  .directive('ipsHeader', HeaderDirective).name;
