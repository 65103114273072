/* Copyright (C) Simply.info
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Pavel Zinovev <zombiqwerty@yandex.ru>, March 2016
 */

import angular from 'angular';

const Service = () => {
  const DefaultTitle = 'ipSCAPE adaptor';
  const NewTitle = '';
  const Divider = ' –– ';

  class TitleUtil {
    constructor() {
      this.title = {};
    }

    get title() {
      return this._title;
    }

    set title(params) {
      params = params || {};
      const defaultTitle = params.default  || DefaultTitle;
      const newTitle     = params.newTitle || NewTitle;
      const divider      = params.divider  || Divider;
      this._title = newTitle + (newTitle ? divider : '') + defaultTitle;
      document.title = this.title;
    }
  }

  return new TitleUtil();
};

export default angular.module('CCAdaptor.App.TitleService', []).factory('TitleService', Service).name;
