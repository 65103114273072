import angular from 'angular';
import _ from 'lodash';

const configTemplate = require('../json/config.json');

class ConfigService {
  constructor(storagemanager) {
    'ngInject';

    this.manager = storagemanager;
    this.locals = {};
    this.watchedAttributes = [];

    this.sendToConsole = (key, value) => {
      const message = (typeof value !== 'string') ? JSON.stringify(value) : value;
      console.log(`[Config] ${key}:`, message);
    };

    this.manager.watchers()
      .withObj(this.locals)
      .withId('ips')
      .buildWithAttributes((a) => {
        this.watchedAttributes.push(
          a.withObjectKey('config')
            .withDefault(configTemplate)
            .withCallBack((newValue) => {
              this.sendToConsole('config', newValue);
            })
            .build(),
          a.withObjectKey('screenpop')
            .withDefault({})
            .withCallBack((newValue) => {
              this.sendToConsole('screenpop', newValue);
            })
            .build(),
          a.withObjectKey('apexCapabilities')
            .withDefault({})
            .withCallBack((newValue) => {
              this.sendToConsole('apexCapabilities', newValue);
            })
            .build(),
          a.withObjectKey('softPhoneLayout')
            .withDefault({})
            .withCallBack((newValue) => {
              this.sendToConsole('softPhoneLayout', newValue);
            })
            .build(),
        );
      });

    this.checkAdaptor = () => {
      const { config } = this;
      const { adaptor } = config;
      let adaptorKeys = 0;

      if (_.isNil(config)) return false;

      if (typeof config === 'object') {
        if (Object.keys(config).length > 0) {
          adaptorKeys = Object.keys(adaptor).length;
        }
      }

      return adaptorKeys > 0;
    };

    this.manager.setSafeList('ips.config');
  }

  get config() {
    return this.locals.config;
  }

  set config(confObj) {
    this.locals.config = confObj;
  }

  get adaptor() {
    const cfg = this.config;
    return cfg.adaptor || {};
  }

  set adaptor(adaptorObj) {
    const cfg = this.config;
    cfg.adaptor = adaptorObj;
    this.locals.config = cfg;
  }

  get ipsSettings() {
    const cfg = this.config;
    return cfg.adaptor.ipsSettings || {};
  }

  set ipsSettings(settingsObj) {
    if (this.checkAdaptor) {
      const cfg = this.config;
      cfg.adaptor.ipsSettings = settingsObj;
      this.locals.config = cfg;
    }
  }

  get softPhoneLayout() {
    return this.locals.softPhoneLayout;
  }

  set softPhoneLayout(obj) {
    this.locals.softPhoneLayout = obj;
  }

  get apexCapabilities() {
    return this.locals.apexCapabilities;
  }

  set apexCapabilities(obj) {
    this.locals.apexCapabilities = obj;
  }

  get isInConsole() {
    return this.locals.isInConsole;
  }

  set isInConsole(bol) {
    this.locals.isInConsole = bol;
  }

  get screenpop() {
    return this.locals.screenpop;
  }

  set screenpop(obj) {
    this.locals.screenpop = obj;
  }
}

export default angular.module('CCAdaptor.App.ConfigService', [])
  .service('ConfigService', ConfigService).name;
