import angular from 'angular';
import _ from 'lodash';

const ipsCallCompletionTemplate = require('./ips-call-completion.directive.html');

const IPSCallCompletion = ($log, $rootScope, $state, $uiRouterGlobals, $timeout, AgentInfoService,
  AgentStates, CallService, StatusService, CampaignService, PaymentsService, agentFSM, agent,
  ErrorService, calls) => {
  'ngInject';

  class IPSCallCompletionController {
    constructor($scope) {
      'ngInject';

      $scope.isClicked = false;
      $scope.callback = () => { return CallService.callback; };
      $scope.isOnOutboundCall = () => { return agentFSM.isOnOutboundCall(); };
      $scope.isOnOutboundPreview = () => { return agentFSM.isOnOutboundPreview(); };
      $scope.activityId = () => { return CallService.activityId; };

      const goToToolbar = () => {
        $state.transitionTo('toolbar', $uiRouterGlobals.params, {
          reload: false,
          inherit: false,
          notify: true,
        });
      };

      const savePauseReason = (reason) => {
        StatusService.pauseReasonTitle = _.get(reason, 'pauseReasonTitle');
      };

      const resetServices = () => {
        CallService.clear();
        CampaignService.selectedCampaign = {};
      };

      const wrapper = (reason) => {
        return new Promise((resolve, reject) => {
          try {
            $rootScope.$broadcast('event.user', {
              action: 'wrap',
              reason,
            });

            if (PaymentsService.transactionId) {
              PaymentsService.endCurrentPaymentTransaction();
            }

            const callWrap = () => {
              CallService.wrap(reason)
                .then((msg) => {
                  resolve(msg);
                })
                .catch((error) => {
                  ErrorService.report('Call Wrap', error);
                  reject();
                });
            };

            if (agent.canHangUp()) {
              if (CallService.callInfo) {
                CallService.setCallEndedDate();
              }

              calls.withActivityId($scope.activityId())
                .hangup({ destinationAgentState: AgentStates.WRAP })
                .then((data) => {
                  if (data.resultCode === 'success') {
                    $timeout(() => {
                      callWrap();
                      $scope.isClicked = false;
                    }, 2000);
                  }
                })
                .catch(() => {
                  $scope.isClicked = false;
                });
            } else {
              callWrap();
            }
          } catch (error) {
            $log.error('Wrapper: ', error);
          }
        });
      };

      const activityWrapper = (reason) => {
        return new Promise((resolve, reject) => {
          try {
            if (PaymentsService.transactionId) {
              PaymentsService.endCurrentPaymentTransaction();
            }

            const wrapActivity = () => {
              CallService.activityWrap()
                .then((response) => {
                  $log.info('[CC] Activity wrap complete', response);
                  $scope.isClicked = false;
                  resolve();
                }).catch((error) => {
                  ErrorService.report('Activity Wrap', error);
                  reject();
                });
            };

            if (agent.canHangUp()) {
              // todo: to be removed > left in place to support API to 6.0.30
              if (CallService.callInfo) CallService.setCallEndedDate();

              calls.withActivityId($scope.activityId())
                .hangup({ destinationAgentState: AgentStates.WRAP })
                .then((data) => {
                  if (data.resultCode === 'success') {
                    wrapActivity();
                    $scope.isClicked = false;
                  }
                }).catch((error) => {
                  ErrorService.report('Activity Hangup', error);
                  $scope.isClicked = false;
                });
            } else {
              wrapActivity();
            }
          } catch (error) {
            $log.error('Activity Wrapper: ', error);
          }
        });
      };

      $scope.showContinueButton = () => {
        if ($scope.isOnOutboundCall()) {
          return !_.hasIn($scope.callback(), 'wrapCodeId');
        }

        return false;
      };

      //--------------------------------
      // Wrap call
      //--------------------------------
      $scope.wrapCall = ({ destination, reason = undefined }) => {
        $scope.isClicked = true;
        // Make button available after 1sec
        setTimeout(() => {
          $scope.isClicked = false;
        }, 1000);

        if (destination === 'outboundPreview') {
          activityWrapper().then(() => {
            $log.info('[CC] Activity wrap complete.');
            $scope.isClicked = false;
          });
          return;
        }

        wrapper(reason).then(() => {
          if (reason) savePauseReason(reason);
          $scope.isClicked = false;
          resetServices();
          goToToolbar();
        });
      };

      $rootScope.$on('event.user', (evt, data) => {
        if (data.action === 'wrap') {
          if (data.tag === 'wrapToAvailable') {
            $scope.wrapCall({ destination: 'ready' });
          } else if (data.tag === 'wrapToPaused') {
            $scope.wrapCall({ destination: 'pause', reason: data.pauseReason });
          }
        }
      });

      //--------------------------------
      // questions
      //--------------------------------
      $scope.disableReadyClick = () => {
        if ($scope.isClicked) return true;
        if (PaymentsService.isActive) return true;

        const { callback } = CallService;
        if (_.hasIn(callback, 'valid')) {
          if (!callback.valid) {
            return true;
          }
        }

        const { selectedOutcome } = CallService;
        return !_.hasIn(selectedOutcome, 'id');
      };

      $scope.disablePausedClick = () => {
        return $scope.disableReadyClick();
      };

      $scope.disableOutboundClick = () => {
        return $scope.disableReadyClick();
      };

      //---------------------------------
      // init pause reasons (if enabled)
      //---------------------------------
      $scope.pauseReasons = [{ pauseReasonTitle: 'Pause' }];
      if (_.get(agent.details(), 'enablePauseReason', true)) {
        if (_.get(agent.organisationDetails(), 'enforceValidPauseReason', true)) {
          $scope.pauseReasons = AgentInfoService.pauseReasons;
        } else {
          $scope.pauseReasons = [{ pauseReasonTitle: 'Pause' }].concat(AgentInfoService.pauseReasons);
        }
      }
    }
  }

  return {
    restrict: 'E',
    template: ipsCallCompletionTemplate,
    controller: IPSCallCompletionController,
    controllerAs: 'completionCtrl',
    scope: true,
  };
};

export default angular.module('CCAdaptor.App.CallCompletion', [])
  .directive('ipsCallCompletion', IPSCallCompletion).name;
