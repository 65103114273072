import angular from "angular";

/*
 * empty integration adaptor selector
 */
const EmptyIntegration = ($rootScope, $q) => {
  'ngInject';

  function doesNothing() {
    // Does nothing
    return false;
  }

  function onWrapIml() {
    $rootScope.$broadcast('event.system', {
      action: 'call.wrap.complete',
    });
  }

  return {
    onEnterAvailable: doesNothing,
    onLeaveAvailable: doesNothing,
    onEnterPaused: doesNothing,
    onLeavePaused: doesNothing,
    onManCall: doesNothing,
    onInboundCall: doesNothing,
    onOutboundPreview: doesNothing,
    onOutboundCall: doesNothing,
    onHangup: doesNothing,
    onBeforeOnWrap: () => {
      const dfd = $q.defer();
      dfd.resolve();
      return dfd.promise;
    },
    onWrap: onWrapIml,
    init: doesNothing,
  };
};

export default angular.module('CCAdaptor.App.EmptyIntegration', [])
  .service('emptyIntegration', EmptyIntegration).name;
