import angular from 'angular';
import { Spinner } from 'spin.js';

const SpinnerFactory = ($rootScope) => {
  'ngInject';

  class SpinLoader {
    constructor() {
      'ngInject';

      $rootScope.loading = false;
      this.target = document.getElementById('spinner');
      this.spinner = new Spinner();
    }

    // Start spinner
    start() {
      $rootScope.loading = true;
      this.spinner.spin(this.target);
    }

    // Stop Spinner
    stop() {
      $rootScope.loading = false;
      this.spinner.stop();
    }
  }

  return new SpinLoader();
};

export default angular.module('CCAdaptor.App.SpinnerFactory', [])
  .factory('SpinnerFactory', SpinnerFactory).name;
