import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';

const template = require('./outcomelist.html');

const IPSOutcomeList = (campaigns, $rootScope) => {
  'ngInject';

  class OutcomeListController {
    constructor($scope, agent, CallService, agentFSM, AgentInfoService, PhoneService,
      ConfigService, SessionService) {
      'ngInject';

      // toDo: Needs refactoring
      const settings = () => { return ConfigService.ipsSettings; };
      const cfg = {
        wrapCodeView: true,
        wrapCodeId: '',
        displayWrapCodes: true,
      };

      SessionService.addToConfig(cfg);

      $scope.config = SessionService.config;

      const getCallbackData = () => {
        return CallService.callback;
      };

      const getTimezones = () => {
        const arr = [{
          value: AgentInfoService.agentTimezone,
          description: `Agent Timezone (${AgentInfoService.agentTimezone})`,
        }];

        // Insert the campaign timezone
        if (CallService.campaignTimezone) {
          arr.unshift({
            value: CallService.campaignTimezone,
            description: `Campaign Timezone ( ${CallService.campaignTimezone} )`,
          });
        }

        // If we have a lead insert the lead timezone
        if (CallService.leadTimezone) {
          arr.unshift({
            value: CallService.leadTimezone,
            description: `Lead Timezone ( ${CallService.leadTimezone} )`,
          });
        }

        // Set our default value
        CallService.pushToCallback('timezone', arr[0].value);
        return arr;
      };

      $scope.callQuality = CallService.callQuality;
      $scope.outcomes = () => { return CallService.outcomes; };
      $scope.selectedOutcome = CallService.selectedOutcome;
      $scope.format = 'MMM Do YYYY, h:mm a';
      $scope.callback = getCallbackData();

      $scope.showCallbackForm = () => {
        if ($scope.selectedOutcome) {
          return !!($scope.selectedOutcome.callbackAgent || $scope.selectedOutcome.callbackQueue);
        }

        return false;
      };

      $scope.updateTz = (idx) => {
        CallService.pushToCallback('timezone', $scope.callback.timezone);
        // Validate
        CallService.validateCallback();
      };

      $scope.updatePhone = () => {
        CallService.pushToCallback('phone', $scope.callbackPhone);
      };

      $scope.timezones = getTimezones();

      $scope.isValid = () => {
        const { callback } = CallService;
        if (_.hasIn(callback, 'valid')) {
          if (callback.valid) {
            return true;
          }
        }

        return false;
      };

      // Select wrap code
      $scope.selectOutcome = (outcome) => {
        $scope.selectedOutcome = outcome;
        CallService.selectedOutcome = outcome;
        // Check selected outcome for id so we know it isn't the placeholder
        if (_.hasIn(outcome, 'id')) {
          if (outcome.callbackAgent || outcome.callbackQueue) {
            // Check to see if we already have made a selection
            if (_.hasIn(CallService.callback, 'dateTime')) {
              $scope.dt = moment(CallService.callback.dateTime);
            } else {
              $scope.dt = moment().add(10, 'minutes');
            }

            const selectedDt = moment($scope.dt).format('YYYY-MM-DD HH:mm:ss');

            // Insert values into CallService.callback
            CallService.pushToCallback('wrapCodeId', outcome.id);
            CallService.pushToCallback('phone', $scope.callbackPhone);
            CallService.pushToCallback('dateTime', selectedDt);
            CallService.pushToCallback('timezone', $scope.timezones[0].value);
            // Validate
            CallService.validateCallback();
          } else {
            CallService.callback = {};
          }
        }

        setTimeout(() => {
          $rootScope.$broadcast('event.callPanelChange');
        }, 100);
      };

      $scope.onCallbackChange = (newVal, oldVal) => {
        if (newVal !== oldVal) {
          // lets validate
          const selectedDt = moment(newVal).format('YYYY-MM-DD HH:mm:ss');
          CallService.pushToCallback('dateTime', selectedDt);
          CallService.validateCallback();
        }
      };

      // Check if an outcome is already selected on localStorage
      if (CallService.selectedOutcome) {
        $scope.selectOutcome(CallService.selectedOutcome);
      }

      // Check for default config values in settings
      if (!_.isNil(settings().defaultWrapCode)) {
        if (settings().defaultWrapCode !== '') {
          const defaultWrapId = parseInt(settings().defaultWrapCode, 10);

          $scope.outcomes().forEach((element) => {
            if (element.id === defaultWrapId) {
              $scope.selectOutcome(element);
            }
          }, this);
        }
      }

      // Check if wrapcodes are meant to be hidden
      if (!_.isNil(settings().displayWrapCodes)) {
        $scope.config.displayWrapCodes = (typeof settings().displayWrapCodes === 'string')
          ? settings().displayWrapCodes === 'true' : settings().displayWrapCodes;
      }

      // Autowrap config
      $scope.autoWrapSpec = {
        active: CallService.autowrap || false,
        time: CallService.autowrapDuration || 0,
        wrapCodeId: CallService.autowrapWrapCode || 0,
        extension: CallService.autowrapExtension || null,
      };

      // Extend Autowrap duration
      $scope.extendAutowrap = () => {
        CallService.extendAutowrap();
        $scope.autoWrapSpec.extension = null;
      };

      if (CallService.autowrap) {
        CallService.autoWrapInit();
      }

      //-----------------------------------------------
      // Listeners & watchers
      //-----------------------------------------------

      // Call Quality watch local scope
      $scope.$watch('callQuality', (newVal, oldVal) => {
        CallService.callQuality = newVal;
      });

      // Callback watch local scope
      $scope.$watch('callback', (newVal, oldVal) => {
        CallService.callback = newVal;
      });

      // Call Quality watch service value
      $rootScope.$watch(() => { return CallService.callQuality; }, (newVal, oldVal) => {
        if (newVal !== oldVal) {
          $scope.callQuality = newVal;
        }
      });

      // Watch and update autowrap params
      $rootScope.$watch(() => { return CallService.autowrap; }, (newVal, oldVal) => {
        if (newVal !== oldVal) {
          $scope.autoWrapSpec.active = newVal;
        }
      });

      $rootScope.$watch(() => { return CallService.autowrapDuration; }, (newVal, oldVal) => {
        if (newVal !== oldVal) {
          $scope.autoWrapSpec.time = newVal;
        }
      });

      $rootScope.$watch(() => { return CallService.autowrapExtension; }, (newVal, oldVal) => {
        if (newVal !== oldVal) {
          $scope.autoWrapSpec.extension = newVal;
        }
      });

      $rootScope.$watch(() => { return CallService.selectedOutcome; }, (newVal, oldVal) => {
        if (newVal !== oldVal) {
          $scope.selectOutcome(newVal);
        }
      });

      // Set call info to $scope
      $rootScope.$watch(() => { return CallService.callInfo; }, (newVal, oldVal) => {
        if (newVal !== oldVal) {
          $scope.callInfo = newVal || {};
        }
      }, true);

      // Set outbound info to $scope
      $rootScope.$watch(() => { return CallService.outboundInfo; }, (newVal, oldVal) => {
        if (newVal !== oldVal) {
          $scope.outboundInfo = newVal || {};
        }
      }, true);

      $rootScope.$watch(() => { return CallService.callback; }, (newVal, oldVal) => {
        if (newVal !== oldVal) {
          $scope.isValid();
        }
      });

      $scope.$on('$destroy', () => {
        CallService.stopCountdown();
      });

      // This event fired when agent enters wrap
      $rootScope.$on('event.system', (event, data) => {
        if (data.action === 'phone.wrapping') {
          if ($scope.autoWrapSpec.active) {
            return;
          }

          // Check if we are in consult call
          if (PhoneService.lineStateTwo !== PhoneService.states.disabled) {
            return;
          }

          CallService.autoWrapInit();
        }
      });

      const startAutoWrap = () => {
        if ($scope.autoWrapSpec.active) return;
        if (agent.isOnPhoneWrapping()) {
          CallService.autoWrapInit();
        }
      };

      $rootScope.$on('event.info', (event, data) => {
        if (data.tag === 'call.hangup') {
          if (PhoneService.lineStateTwo !== PhoneService.states.disabled) return;
          startAutoWrap();
        }
      });

      // This event fires when consult call hangs up in attended transfer
      $scope.$on('event.notify', (event, data) => {
        if (data.action === 'info') {
          switch (data.tag) {
            case 'call.hangup': {
              if (PhoneService.lineStateTwo !== PhoneService.states.disabled) {
                break;
              }
              startAutoWrap();
              break;
            }
            case 'consult.call.hangup':
            default: {
              startAutoWrap();
              break;
            }
          }
        }
      });

      // if the user presses the "Ready" or "Pause" button stop the countdown
      $scope.$on('event.user', (event, data) => {
        if (data.action === 'wrap') {
          CallService.stopCountdown();
        }
      });
    }
  }

  return {
    restrict: 'E',
    template,
    controller: OutcomeListController,
    controllerAs: 'OutcomeList',
    transclude: true,
    scope: true,
  };
};

export default angular.module('CCAdaptor.App.OutcomeList', []).directive('ipsOutcomeList', IPSOutcomeList).name;
