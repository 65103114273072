import angular from 'angular';
import _ from 'lodash';

const AgentFactory = ($http, $httpParamSerializer, $log, $rootScope, AgentStates, ErrorService,
  AgentInfoService, agentFSM, StatusService, calls, campaigns, IntegrationService, dbc, phone) => {
  'ngInject';

  const details = () => { return AgentInfoService.details; };

  const myCampaigns = () => { return campaigns.forAgentId(details().agentId); };

  const $pause = (op, uriPath, pauseReasonId, updateState, onSuccess) => {
    const params = {};
    if (pauseReasonId) {
      params.pauseReasonId = pauseReasonId;
      StatusService.status = { label: 'paused', id: pauseReasonId };
    }
    return op(
      IntegrationService.latestApiUri(uriPath),
      undefined,
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        params,
      },
    ).then((response) => {
      if (response.data.resultCode !== 'success') {
        $rootScope.$broadcast('event.notify', {
          action: 'error',
          tag: response.data.errorCode,
          message: response.data.errorDescription,
          details: response.data.errorDetails,
        });
      } else {
        updateState();
        onSuccess();
      }
    });
  };

  const currentPauseReason = () => {
    const { status } = StatusService;
    const { pauseReasons } = AgentInfoService;
    let response;

    if (!_.hasIn(status, 'id')) return null;

    if (status.id === 0) return { pauseReasonId: 0, pauseReasonTitle: 'Paused' };

    pauseReasons.forEach((reason) => {
      if (reason.pauseReasonId === status.id) {
        response = { id: reason.pauseReasonId, title: reason.pauseReasonTitle };
      }
    });

    return response;
  };

  return {
    // getters
    details,
    campaigns: myCampaigns,
    organisationDetails: () => { return AgentInfoService.organisationDetails; },
    pauseReasons: () => { return AgentInfoService.pauseReasons; },
    currentPauseReason,
    readableState: agentFSM.readableState,
    state: agentFSM.state,
    previousState: agentFSM.previousState,

    // collaborators
    calls,
    phone,

    // activities
    connect: agentFSM.connect,
    hangup: agentFSM.hangup,
    pause: (pauseReasonId, onSuccess) => {
      const updateState = () => { return agentFSM.pause(); };
      $pause($http.post, '/agent/pause', pauseReasonId, updateState, onSuccess);
    },
    unpause: (onSuccess) => {
      const endpoint = '/agent/unpause';
      $http.post(IntegrationService.latestApiUri(endpoint))
        .then((response) => {
          if (response.data.resultCode !== 'success') {
            ErrorService.handleError(endpoint, response);
          } else {
            agentFSM.unpause();
            StatusService.status = { label: 'available', id: 0 };
            if (onSuccess) {
              onSuccess(response.data.result);
            }
          }
        }).catch((response) => {
          ErrorService.handleError(endpoint, response);
        });
    },
    updatePause: (pauseReasonId, onSuccess) => {
      const updateState = () => {
        StatusService.currentStateDt = new Date().getTime().toString();
      };
      $pause($http.put, '/agent/updatepausereason', pauseReasonId, updateState, onSuccess);
    },

    // questions
    canHangUp: agentFSM.canHangUp,
    canLogout: agentFSM.canLogout,
    isOffPhone: agentFSM.isOffPhone,
    isOnAllocatedCall: agentFSM.isOnAllocatedCall,
    isOnCall: agentFSM.isOnCall,
    isWaiting: agentFSM.isWaiting,
    isOnCallOrWrapOrDialling: agentFSM.isOnCallOrWrapOrDialling,
    isOnPhoneAvailable: agentFSM.isOnPhoneAvailable,
    isOnPhonePaused: agentFSM.isOnPhonePaused,
    isOnPhoneWrapping: agentFSM.isOnPhoneWrapping,
    isOnHold: agentFSM.isOnHold,
    isOnOutboundCall: agentFSM.isOnOutboundCall,
    isOnOutboundPreview: agentFSM.isOnOutboundPreview,
  };
};

export default angular.module('CCAdaptor.App.AgentFactory', []).factory('agent', AgentFactory).name;
