import angular from 'angular';
import moment from 'moment-timezone';
import _ from 'lodash';

/* global client */

function ZDTasks($log, $rootScope, $timeout, TaskService, ConfigService,
  IntegrationService, CallService, AgentInfoService, ErrorService) {
  'ngInject';

  this.agentInfoService = AgentInfoService;
  this.callService = CallService;
  this.configService = ConfigService;
  this.errorService = ErrorService;
  this.integrationService = IntegrationService;
  this.log = $log;
  this.rootScope = $rootScope;
  this.taskService = TaskService;
  this.timeout = $timeout;

  const service = {};
  const locals = {};
  const instance = {};
  let version;

  Object.defineProperty(locals, 'callInfo', {
    get: () => this.callService.callInfo,
    configurable: true,
  });

  Object.defineProperty(locals, 'settings', {
    get: () => this.configService.ipsSettings,
    configurable: true,
  });


  //--------------------------------------------------------------
  // helper functions
  //--------------------------------------------------------------

  const numDigits = x => Math.max(Math.floor(Math.log10(Math.abs(x))), 0) + 1;

  const formatDate = (dte, pattern) => moment.tz(dte, this.callService.campaignTimezone)
    .format(pattern);

  const callStartedDate = () => {
    if (Object.keys(locals.callInfo).length > 1) {
      if (!this.callService.callStartedDate) this.callService.setCallStartedDate();
      return moment.utc(this.callService.callStartedDate);
    }
    return moment.utc();
  };

  const callEndedDate = () => {
    if (this.callService.callInfo) {
      if (!this.callService.callEndedDate) this.callService.setCallEndedDate();
      return moment.utc(this.callService.callEndedDate);
    }
    return moment.utc();
  };

  const handleError = (response, asWarning) => {
    if (asWarning) {
      this.log.warn(response.error);
      service.notify('alert', response.error);
      this.errorService.warn('Zendesk Warning: ', { details: response.error });
    } else {
      this.log.error(response.error);
      service.notify('error', response.error);
      this.errorService.report('Zendesk Error: ', { details: response.error });
    }
  };

  const mapFields = callType => new Promise((resolve) => {
    const map = {};
    let dataMap;

    switch (callType) {
      case 'outbound':
        dataMap = locals.settings.calls.outbound.fieldMapping;
        break;
      case 'inbound':
        dataMap = locals.settings.calls.inbound.fieldMapping;
        break;
      case 'transfer':
        dataMap = locals.settings.calls.transfer.fieldMapping;
        break;
      default:
        dataMap = {
          fieldMapping: {},
        };
        break;
    }

    Object.keys(dataMap).forEach((key, index) => {
      const value = Object.values(dataMap)[index];
      // Check call data for key/value pair
      if (locals.callInfo[value]) {
        let val = locals.callInfo[value];
        if (typeof locals.callInfo[val] === 'number' && numDigits(val) >= 13) {
          val = formatDate(val, 'YYYY-MM-DD hh:mm:ss');
        }
        map[key] = val;
      }

      // check ivrData as well
      if (Object.keys(locals.callInfo.ivrData).indexOf(value.toString()) !== -1) {
        map[key] = locals.callInfo.ivrData[value];
      }
    });

    resolve(map);
  });

  /**
   * Zendesk API get ticket field detail by id
   * @param {Number} id ticket id
   * @returns {Promise} A promise to the ticket field
   */
  const getTicketField = id => new Promise((resolve) => {
    const bundle = {
      url: `/api/v2/ticket_fields/${id}.json`,
      type: 'GET',
    };

    // If we have already retrieved this information resolve promise
    if (locals.ticketField) {
      const numFields = locals.ticketField.length;
      let i = 0;
      do {
        if (locals.ticketField[i].id === id) {
          resolve(locals.ticketField[i]);
        }
        i += 1;
      }
      while (i < numFields);
    }

    client.request(bundle).then((result) => {
      locals.ticketField = [];
      locals.ticketField.push(result.ticket_field);
      resolve(result.ticket_field);
    });
  });

  /**
   * Zendesk API get organisation ticket forms
   * @returns {Promise} A promise to the organisation forms
   */
  const getTicketForms = () => new Promise((resolve) => {
    const bundle = {
      url: '/api/v2/ticket_forms.json',
      type: 'GET',
    };

    // If we have already retrieved this information resolve promise
    if (locals.ticketForms) {
      resolve(locals.ticketForms);
    }

    client.request(bundle).then((result) => {
      locals.ticketForms = result.ticket_forms;
      resolve(result.ticket_forms);
    });
  });

  /**
   * Zendesk API get form details (incl. fields) by form name
   * @param {String} frm
   * @returns {Promise} A promise to the ticket data
   */
  const formDetails = frm => new Promise((resolve) => {
    let form;
    const fields = [];

    // get form data for this path
    getTicketForms().then((data) => {
      const numFields = data.length;
      let i = 0;
      do {
        if (data[i].raw_name === frm) {
          form = data[i];
        }
        i += 1;
      }
      while (i < numFields);
    }).then(() => {
      // for this form, get ticket_fields
      const numFields = form.ticket_field_ids.length;
      let i = 0;
      do {
        getTicketField(form.ticket_field_ids[i])
          .then((result) => {
            fields.push(result);
          });
        i += 1;
      }
      while (i < numFields);
    }).then(() => {
      form.fields = fields;
      resolve(form);
    });
  });

  const listLocales = () => new Promise((resolve) => {
    const bundle = {
      url: '/api/v2/locales.json',
      type: 'GET',
    };

    client.request(bundle)
      .then((result) => {
        resolve(result.locales);
      });
  });

  /**
   * Zendesk search for user by phone number OR create new user
   * @param {Object} data
   * @returns {Promise} A promise to the user
   */
  const findOrCreateUser = data => new Promise((resolve) => {
    const { users } = locals.settings;
    let userName = `${data.agentInteractionId}_${moment.utc()}`;
    let verificationEmail = false;
    let searchString = '';
    let locales;
    // Get ORG locales
    listLocales().then((response) => {
      locales = response;
    });

    // Check for organisation settings
    if (Object.keys(users).length > 0) {
      // Check if there is a setting for sendVerificationEmail
      if (Object.keys(users).indexOf('sendVerificationEmail') !== -1) {
        verificationEmail = users.sendVerificationEmail;
      }

      // Check if there is a setting for defaultName
      if (Object.keys(users).indexOf('defaultName') !== -1) {
        // Check if the default name is a string
        if (users.defaultName.startsWith("'")) {
          userName = users.defaultName.replace("'", '');
        } else if (users.defaultName.startsWith('<%')) {
          // eslint-disable-next-line no-use-before-define
          userName = templateMerge(users.defaultName);
        } else {
          // Map to data
          angular.forEach(data, (value, key) => {
            if (key === users.defaultName) {
              userName = value;
            }
          });
        }
      }
    }

    if (Object.keys(data).indexOf('customerPhoneNumber') !== -1) {
      // Create search string and search for user if found, resolve
      searchString = data.customerPhoneNumber;

      const bundle = {
        url: `/api/v2/users/search.json?query=${encodeURIComponent(searchString)}`,
        type: 'GET',
      };

      client.request(bundle)
        .then((result) => {
          if (result.users.length >= 1) {
            resolve(result.users[0]);
          } else {
            resolve({
              name: userName,
              locale_id: locales[0].id,
              phone: data.customerPhoneNumber,
              verified: verificationEmail,
            });
          }
        });
    }
  });

  const templateMerge = (template) => {
    const merge = (objects) => {
      const out = {};

      for (let i = 0; i < objects.length; i += 1) {
        for (const p in objects[i]) {
          out[p] = objects[i][p];
        }
      }
      return out;
    };
    const flatten = (obj, name, stem) => {
      let out = {};
      const newStem = (typeof stem !== 'undefined' && stem !== '')
        ? `${stem}'_'${name}` : name;

      if (typeof obj !== 'object') {
        out[newStem] = obj;
        return out;
      }

      for (const p in obj) {
        const prop = flatten(obj[p], p, newStem);
        out = merge([out, prop]);
      }

      return out;
    };

    const re = /<%([^%>]+)?%>/g;
    const callData = flatten(locals.callInfo);
    let mergedStr = template;
    let match;
    const findStr = '<%';
    let lastIndex = 0;
    let count = 0;

    while (lastIndex !== -1) {
      lastIndex = mergedStr.indexOf(findStr, lastIndex);
      if (lastIndex !== -1) {
        count += 1;
        lastIndex += findStr.length;
      }
    }

    do {
      while (match = re.exec(mergedStr)) {
        mergedStr = mergedStr.replace(match[0], callData[match[1]]);
      }
      count -= 1;
    }
    while (count >= 0);

    return mergedStr;
  };

  /**
   * Start Zendesk task public service
   */
  service.loadAdaptorConfig = () => new Promise((resolve) => {
    const config = {
      allowCustomObjects: 'false',
      ipsCustomObjs: '',
      showOpenLogButton: 'false',
      displayWrapCodes: 'true',
      defaultWrapCode: '',
    };

    const customizer = (objValue, srcValue) => (_.isUndefined(objValue) ? srcValue : objValue);

    const callback = (response) => {
      if (response.error) {
        handleError(response);
      } else {
        const adaptor = {};
        const keys = Object.keys(response);
        let i = keys.length - 1;
        do {
          adaptor[keys[i]] = response[keys[i]];
          i -= 1;
        }
        while (i >= 0);

        adaptor.ipsSettings = JSON.parse(adaptor.Settings);
        delete adaptor.Settings;

        if (typeof adaptor.ipsSettings === 'undefined') {
          adaptor.ipsSettings = config;
        } else {
          _.assignInWith(adaptor.ipsSettings, locals.settings, customizer);
        }

        version = adaptor.name;
        this.configService.adaptor = adaptor;
        resolve();
      }
    };

    if (version) {
      resolve();
    } else {
      client.metadata().then((metadata) => {
        callback(metadata.settings);
      });
    }
  });

  service.enableClickToDial = () => {
    const messageReader = (event) => {
      if (event.data) {
        const dt = event.data;
        if (dt.event === 'click-to-dial') {
          this.rootScope.$broadcast('event.user', {
            action: 'click-to-dial',
            clickToDialData: dt,
          });
          client.invoke('popover');
        }
      }
    };

    window.addEventListener('message', messageReader, false);
  };

  service.getCurrentUser = () => new Promise((resolve) => {
    const bundle = {
      url: '/api/v2/users/me.json',
      type: 'GET',
      dataType: 'json',
    };

    client.request(bundle).then((data) => {
      this.agentInfoService.integration = data.user;
      resolve(data.user);
    }, (response) => {
      this.log.warning(response.responseText);
    });
  });

  service.notify = (action, msg) => {
    switch (action) {
      case 'success':
        client.invoke('notify', msg, 'notice');
        break;
      case 'alert':
        client.invoke('notify', msg, 'alert');
        break;
      case 'error':
        client.invoke('notify', msg, 'error');
        break;
      default:
        client.invoke('notify', msg);
        break;
    }
  };

  service.createTicket = (params, requester, dataMap) => new Promise((resolve) => {
    const customFieldsArr = [];
    instance.priority = 'normal';
    instance.subject = 'New Call';
    let formId;
    let bodyHtml;

    if (Object.keys(locals.settings).indexOf('users') !== -1) {
      if (Object.keys(locals.settings.users).indexOf('mailDomain') !== -1) {
        instance.domain = locals.settings.users.mailDomain;
      }
    }

    // Check for ticket settings
    if (Object.keys(params).indexOf('settings') !== -1) {
      if (Object.keys(params.settings).indexOf('tickets') !== -1) {
        if (Object.keys(params.settings.tickets).indexOf('public') !== -1) {
          instance.makePublic = params.settings.tickets.public;
        }

        if (Object.keys(params.settings.tickets).indexOf('subject') !== -1) {
          const tmpl = params.settings.tickets.subject;
          instance.subject = templateMerge(tmpl);
        }

        if (Object.keys(params.settings.tickets).indexOf('priority') !== -1) {
          instance.priority = params.settings.tickets.priority;
        }
      }
    }

    if (Object.keys(params).indexOf('form') !== -1) {
      if (Object.keys(params.form).indexOf('id') !== -1) {
        formId = params.form.id;
      }
    }

    if (Object.keys(params).indexOf('data') !== -1) {
      // Prepare html table for comment
      bodyHtml = '<table cellspacing="0" cellpadding="4">';
      bodyHtml += '<tr><th></th><th align="left">Description</th></tr>';
      bodyHtml += `<tr><th align="right" width="180px">Activity Id </th><td align="left"> ${params.data.activityId}</td></tr>`;
      bodyHtml += `<tr><th align="right">Agent </th><td align="left"> ${this.agentInfoService.agentFullName}</td></tr>`;
      bodyHtml += `<tr><th align="right">Customer Phone </th><td align="left"> ${params.data.customerPhoneNumber}</td></tr>`;
      bodyHtml += `<tr><th align="right">Platform Phone </th><td align="left"> ${params.data.platformPhoneNumber}</td></tr>`;
      bodyHtml += `<tr><th align="right">Call Date </th><td align="left"> ${formatDate(callStartedDate(), 'YYYY-MM-DD')} </td></tr>`;
      bodyHtml += `<tr><th align="right">Call Start Time </th><td align="left"> ${formatDate(callStartedDate(), 'h:mm:ss A')} (${this.callService.campaignTimezone})</td></tr>`;
      bodyHtml += `<tr><th align="right">Campaign </th><td align="left"> ${params.data.campaignTitle}</td></tr>`;
      bodyHtml += `<tr><th align="right">Call type </th><td align="left"> ${params.data.callType}</td></tr>`;
      bodyHtml += `<tr><th align="right">Voice Recording </th><td align="left"> <a href="${this.integrationService.apiHost}/workspace/xws_callRecording?activityId=${params.data.activityId}" target="_blank" class="audio-link">Click here for recording</a></td></tr>`;
      bodyHtml += '</table>';

      if (Object.keys(params).indexOf('form') !== -1) {
        if (Object.keys(params.form).indexOf('fields') !== -1) {
          params.form.fields.forEach((field) => {
            Object.keys(dataMap).forEach((key, index) => {
              let val = Object.values(dataMap)[index];
              if (field.raw_title === key) {
                // Check if this is a drop-down options list
                if (field.system_field_options) {
                  field.system_field_options.forEach((option) => {
                    if (val.toLowerCase() === option.value) {
                      val = val.toLowerCase();
                    }
                  });
                }
                const tempObj = {};
                tempObj.id = field.id;
                tempObj.value = val;
                customFieldsArr.push(tempObj);
                // check if there is a priority value within the IVR data
                if (key.toLowerCase() === 'priority') {
                  instance.priority = val.toLowerCase();
                }
              }
            });
          });
        }
      }
    }

    const payload = {
      ticket: {
        via: {
          channel: `phone_call_${params.data.callType.toLowerCase()}`,
          source: {
            to: {
              phone: params.data.platformPhoneNumber,
              campaign: params.data.campaignTitle,
            },
            from: {
              phone: params.data.customerPhoneNumber,
            },
            rel: params.data.callType.toLowerCase(),
          },
        },
        subject: instance.subject,
        comment: {
          public: instance.makePublic,
          type: 'Voice_Comment',
          html_body: bodyHtml,
        },
        ticket_form_id: formId,
        custom_fields: customFieldsArr,
        priority: instance.priority,
      },
    };

    payload.ticket.requester = {
      locale_id: requester.locale_id || 1,
      name: requester.name,
      phone: params.data.customerPhoneNumber,
      email: (requester.email) ? requester.email : `${_.snakeCase(requester.name)}@${instance.domain}`,
    };

    if (requester && _.hasIn(requester, 'id')) {
      payload.ticket.requester.id = requester.id;
    }

    const bundle = {
      url: '/api/v2/tickets.json',
      type: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify(payload),
    };

    client.request(bundle).then((response) => {
      if (response.error) handleError(response, true);
      else {
        const { ticket } = response;
        this.taskService.taskId = ticket.id;
        this.log.info('Zendesk ticket created:', ticket.id);
        resolve(ticket);
      }
    });
  });

  service.updateTicket = msg => new Promise((resolve) => {
    const bundle = {
      url: `/api/v2/tickets/${this.taskService.taskId}.json`,
      type: 'PUT',
      dataType: 'json',
      data: { ticket: msg },
    };

    client.request(bundle).then((response) => {
      if (response.error) handleError(response, true);
      else {
        resolve(response.ticket);
      }
    });
  });

  service.showUser = userId => new Promise((resolve) => {
    client.invoke('routeTo', 'user', userId).then((response) => {
      if (response.error) handleError(response, true);
      else {
        this.log.debug(`User Open: ${response}`);
        resolve(response);
      }
    });
  });

  service.openTicket = ticketId => new Promise((resolve) => {
    client.invoke('routeTo', 'ticket', ticketId).then((response) => {
      if (response.error) handleError(response, true);
      else {
        resolve(response);
      }
    });
  });

  service.createCall = (msg, callType) => {
    const message = Object.assign({}, msg);
    this.log.debug('zd: call started');

    const callSettings = locals.settings.calls[callType];
    const { form } = callSettings;
    message.settings = callSettings;

    formDetails(form).then((formData) => {
      message.form = formData;
    }).then(() => {
      // create our field mapping
      mapFields(callType).then((dataMap) => {
        // search for the user by phone number
        findOrCreateUser(message.data).then((user) => {
          // Create & open our ticket
          service.createTicket(message, user, dataMap).then((ticket) => {
            service.openTicket(ticket.id).then(() => {
              // client.invoke('routeTo', 'user', requester);
              this.log.info(`Ticket #${ticket.id} now open.`);
            });
          }).catch((error) => {
            this.log.error('openTicket Promise', error);
          });
        }).catch((error) => {
          this.log.error('findOrCreateUser Promise', error);
        });
      });
    });
  };

  service.hangUp = () => {
    this.log.debug('CTI: hangupCall: ');
    if (!this.taskService.taskId) {
      this.log.warn('zd: hangupCall - no ticket id. Will not save activity to ticket history.');
    }
  };

  service.wrapCall = msg => new Promise((resolve, reject) => {
    let bodyHtml;
    const callback = () => {
      this.timeout(() => {
        this.taskService.reset();
      }, 10);
      this.rootScope.$broadcast('event.system', {
        action: 'call.wrap.complete',
      });
      resolve();
    };

    if (!this.taskService.taskId) {
      this.log.warn('zd: wrap - no ticket id. Will not save activity to ticket history.');
      callback();
      return;
    }

    // Get timestamps for call duration calculation
    const startTs = moment(this.callService.callStartedDate).unix();
    const endTs = moment(this.callService.callEndedDate).unix();

    const callDurationInSeconds = Math.floor(endTs - startTs);

    const callDuration = {
      h: moment.duration(callDurationInSeconds, 'seconds').get('hours'),
      m: moment.duration(callDurationInSeconds, 'seconds').get('minutes'),
      s: moment.duration(callDurationInSeconds, 'seconds').get('seconds'),
    };

    let timeInCall = '';

    if (callDuration.h) {
      timeInCall += `${callDuration.h} hr `;
    }
    if (callDuration.m) {
      timeInCall += `${callDuration.m} min `;
    }
    if (callDuration.s) {
      timeInCall += `${callDuration.s} sec`;
    }

    // Prepare html table for comment
    bodyHtml = '<table cellspacing="0" cellpadding="4">';
    bodyHtml += '<tr><th></th><th align="left">Description</th></tr>';
    bodyHtml += `<tr><th align="right" width="180px">Call Duration </th><td> ${timeInCall}</td></tr>`;
    bodyHtml += `<tr><th align="right">Call End Time </th><td align="left"> ${formatDate(callEndedDate(), 'h:mm:ss A')} (${this.callService.campaignTimezone})</td></tr>`;
    bodyHtml += `<tr><th align="right" width="180px">Call Wrap Code </th><td> ${msg.data.wrapCodeDescription || ''}</td></tr>`;
    bodyHtml += `<tr><th align="right">Call Note </th><td> ${msg.data.notes || ''}</td></tr>`;
    bodyHtml += '</table>';

    const wrapMsg = {
      metadata: {
        wrap_code: msg.data.wrapCodeDescription,
      },
      comment: {
        public: instance.makePublic,
        type: 'Voice_Comment',
        html_body: bodyHtml,
      },
    };

    service.updateTicket(wrapMsg).then(() => {
      callback();
    }).catch((error) => {
      this.log('Failed to update ticket: ', error);
      reject(Error(`Unable to update ticket: ${error}`));
    });
  });

  return service;
}

export default angular.module('CCAdaptor.App.ZDTasks', [])
  .factory('zdtasks', ZDTasks).name;
