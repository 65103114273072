import angular from 'angular';
import _ from 'lodash';

class AgentVoiceStatus {
  constructor(storagemanager) {
    'ngInject';

    this.listeners = [];
    this.locals = {};
    storagemanager.watchers()
      .withObj(this.locals)
      .withId('agent')
      .buildWithAttributes((a) => {
        a.withStringKey('voice')
          .withDefault('disconnected')
          .withCallBack((status) => {
            this.listeners.forEach((l) => {
              l(status);
            });
          })
          .build();
      });
  }

  addListener(f) {
    this.listeners.push(f);
    f(this.status);
  }

  get status() {
    return this.locals.voice;
  }

  set status(s) {
    this.locals.voice = s;
  }
}

export default angular.module('CCAdaptor.App.AgentVoiceStatus', [])
  .service('AgentVoiceStatus', AgentVoiceStatus).name;
