import angular from 'angular';
import _ from 'lodash';

class IntegrationService {
  constructor($log, $rootScope, $injector, storagemanager,
    $location) {
    'ngInject';

    const port = ($location.port() === 80 || $location.port() === 443) ? '' : $location.port();
    const hostAndPort = $location.host() + (port ? `:${port}` : '');
    const apiHost = $location.search().api || hostAndPort;
    const protomatch = /^(https?|ftp):\/\//;

    this.$log = $log;
    this.$injector = $injector;
    this.hostAndPort = hostAndPort;

    this.proto = (protocol) => {
      return ($location.protocol() === 'https' || _.startsWith(apiHost, 'https:')
        ? protocol.replace(':', 's:') : protocol);
    };

    this.location = (url) => {
      const bits = url.split(/^https?:\/\//);
      if (bits.length === 2) {
        return bits[1];
      }
      return _.startsWith(url, '/') ? hostAndPort + url : url;
    };

    this.searchParams = () => {
      return JSON.stringify($location.search());
    };

    this.uri = (protocol, url) => {
      return `${this.proto(protocol)}//${this.location(url)}`;
    };

    this.param = (name) => {
      const storage = localStorage.getItem(name);
      if (storage) {
        return localStorage.getItem(name);
      }

      if (name === 'api') {
        // Added the 'localhost' fallback for the unit tests only
        return $location.search()[name] || 'localhost';
      }

      return $location.search()[name];
    };

    this.report = () => {
      if (this.param('raygun')) {
        return this.param('raygun') === 'true';
      }

      if (this.param('reporting')) {
        return this.param('reporting') === 'true';
      }

      return true;
    };

    this.useWorker = () => {
      if (this.param('useWorker')) {
        return this.param('useWorker') === 'true';
      }
      return false;
    };

    this.setSession = (type) => {
      switch (type) {
        case 'sf': {
          this.$injector.get('SessionService').config = {
            relatedObjectViewer: true,
            tabViewer: true,
            startIndex: 0,
            tabViewerTitle: 'Call',
            notesViewer: true,
            campaignViewer: true,
          };
          break;
        }
        case 'sn': {
          this.$injector.get('SessionService').config = {
            tabViewer: true,
            tabViewerTitle: 'ServiceNow',
            notesViewer: false,
          };
          break;
        }
        case 'zd': {
          this.$injector.get('SessionService').config = {
            tabViewer: true,
            startIndex: 1,
            tabViewerTitle: 'Zendesk',
            notesViewer: true,
          };
          break;
        }
        default: {
          this.$injector.get('SessionService').config = {
            notesViewer: true,
            startIndex: 0,
            relatedObjectViewer: false,
            tabViewer: false,
            tabViewerTitle: 'Call',
          };
          break;
        }
      }
    };

    let srcUrl = (window.location !== window.parent.location)
      ? document.referrer : document.location.hostname;

    srcUrl = srcUrl.replace(protomatch, '');
    this.urlParms = srcUrl.split('.');

    this.locals = {};
    this.listeners = [];
    this.manager = storagemanager;
    this.watchedAttributes = [];
    this.manager.watchers()
      .withObj(this.locals)
      .withId('integration')
      .buildWithAttributes((a) => {
        this.watchedAttributes.push(
          a.withStringKey('adaptorUrl')
            .withDefault(this.uri('http:', hostAndPort))
            .build(),
        );
        this.watchedAttributes.push(
          a.withStringKey('type')
            .withDefault(this.param('integration.type') || null)
            .withCallBack((newValue) => {
              this.setSession(newValue);
            })
            .build(),
        );
        this.watchedAttributes.push(
          a.withBooleanKey('connect')
            .withDefault(this.param('integration.connect') || false)
            .build(),
        );
        this.watchedAttributes.push(
          a.withStringKey('version')
            .withDefault(this.param('integration.version') || null)
            .build(),
        );
        this.watchedAttributes.push(
          a.withStringKey('apiHost')
            .withDefault(this.uri('http:', this.param('api')))
            .build(),
        );
        this.watchedAttributes.push(
          a.withStringKey('apiHostWS')
            .withDefault(this.uri('ws:', this.param('api')))
            .build(),
        );
        this.watchedAttributes.push(
          a.withStringKey('apiUrl')
            .withDefault(this.param('api'))
            .withCallBack((uri) => {
              window.IPSCAPE.Rollbar.configure({
                payload: {
                  server: {
                    host: uri,
                  },
                },
              });
            }).build(),
        );
        this.watchedAttributes.push(
          a.withStringKey('apiKey')
            .withDefault(this.param('integration.apiKey'))
            .build(),
        );
        this.watchedAttributes.push(
          a.withObjectKey('params')
            .withDefault(this.searchParams)
            .build(),
        );
        this.watchedAttributes.push(
          a.withStringKey('instance')
            .withDefault(this.param('integration.instance'))
            .build(),
        );
        this.watchedAttributes.push(
          a.withBooleanKey('reporting')
            .withDefault(this.param('integration.reporting') || true)
            .build(),
        );
        this.watchedAttributes.push(
          a.withBooleanKey('useWorker')
            .withDefault(this.param('integration.useWorker') || false)
            .build(),
        );
      });

    this.manager.setSafeList('integration.adaptorUrl');
    this.manager.setSafeList('integration.apiHost');
    this.manager.setSafeList('integration.apiHostWS');
    this.manager.setSafeList('integration.apiUrl');
    this.manager.setSafeList('integration.apiKey');
    this.manager.setSafeList('integration.type');
    this.manager.setSafeList('integration.version');
    this.manager.setSafeList('integration.instance');
    this.manager.setSafeList('integration.reporting');
    this.manager.setSafeList('integration.connect');
    this.manager.setSafeList('integration.useWorker');
  }

  get adaptorUrl() {
    return this.locals.adaptorUrl;
  }

  set adaptorUrl(param) {
    this.locals.adaptorUrl = param;
  }

  get type() {
    return this.locals.type;
  }

  set type(type) {
    this.locals.type = type;
  }

  get connect() {
    return this.locals.connect;
  }

  set connect(bool) {
    this.locals.connect = bool;
  }

  get version() {
    return this.locals.version;
  }

  set version(ver) {
    this.locals.version = ver;
  }

  get apiHost() {
    return this.locals.apiHost;
  }

  set apiHost(host) {
    this.locals.apiHost = host;
  }

  get apiHostWS() {
    return this.locals.apiHostWS;
  }

  set apiHostWS(host) {
    this.locals.apiHostWS = host;
  }

  get apiUrl() {
    return this.locals.apiUrl;
  }

  set apiUrl(urlStr) {
    this.locals.apiUrl = urlStr;
    this.apiHost = this.uri('http:', urlStr);
    this.apiHostWS = this.uri('ws:', urlStr);
  }

  get apiKey() {
    return this.locals.apiKey;
  }

  set apiKey(key) {
    this.locals.apiKey = key;
  }

  get params() {
    return this.locals.params;
  }

  set params(data) {
    this.locals.params = data;
  }

  get instance() {
    return this.locals.instance;
  }

  set instance(data) {
    this.locals.instance = data;
  }

  get reporting() {
    return this.locals.reporting;
  }

  set reporting(data) {
    this.locals.reporting = data;
  }

  get worker() {
    return this.locals.useWorker;
  }

  set worker(data) {
    this.locals.useWorker = data;
  }

  latestApiUri(path, version = '/api/latest') {
    return `${this.apiHost}${version}${path}`;
  }

  loginAppId() {
    let appId;
    switch (this.type) {
      case ('sf'):
        appId = 2;
        break;
      case ('zd'):
        appId = 3;
        break;
      case ('sn'):
        appId = 4;
        break;
      default:
        appId = 1;
        break;
    }
    return appId;
  }

  getSettings() {
    return {
      apiHost: this.apiHost,
      apiHostWS: this.apiHostWS,
      integration: this.type,
      apiKey: this.apiKey,
    };
  }

  displayWarningMessage(title) {
    const toastrOpts = {
      closeButton: false,
      escapeHtml: true,
      extendedTimeOut: 0,
      timeOut: 0,
      preventDuplicates: true,
      onShown: () => {
        angular.element('.toast-message #confirm').on('click', () => {
          this.$log.warn('Salesforce connection lost, user confirmed');
        });
      },
    };

    let warningMessage = `Unable to integrate with ${title}. Please refresh your browser.`;

    this.$injector.get('ErrorService').report(`${title} Integration Error`, { message: warningMessage, integration: this.locals });

    warningMessage += '<br/><button id="confirm" type="button" class="btn btn-default" style="float: right;">Confirm</button>';

    this.$injector.get('ToastrService').makeToast({ type: 'warning', message: warningMessage, opts: toastrOpts });
  }

  checkDependency() {
    if (this.type) {
      if (this.type === 'sf') {
        if (!_.hasIn(window, 'sforce')) {
          if ((this.version) && (this.version === 'lightning')) {
            if (!_.hasIn(window.sforce, 'opencti')) {
              this.displayWarningMessage('Salesforce');
            }
          } else if (!_.hasIn(window.sforce, 'interaction')) {
            this.displayWarningMessage('Salesforce');
          }
        }
      } else if (this.type === 'sn') {
        if (!_.hasIn(window, 'openFrameAPI') || _.isNil(window.openFrameAPI)) {
          this.displayWarningMessage('ServiceNow');
        }
      } else if (this.type === 'zd') {
        if (!_.hasIn(window, 'client')) {
          this.displayWarningMessage('ZenDesk');
        }
      } else {
        // Do nothing
      }
    }
  }

  reset() {
    this.adaptorUrl = this.uri('http:', this.hostAndPort);
    this.type = (window.IPSCAPE.dependency.type) ? window.IPSCAPE.dependency.type : this.param('integration.type') || null;
    this.connect = (window.IPSCAPE.dependency.connect) ? window.IPSCAPE.dependency.connect : this.param('integration.connect') || false;
    this.version = (window.IPSCAPE.dependency.integrationVersion) ? window.IPSCAPE.dependency.integrationVersion : this.param('integration.version') || null;
    this.apiHost = this.uri('http:', this.param('api'));
    this.apiHostWS = this.uri('ws:', this.param('api'));
    this.apiUrl = (window.IPSCAPE.dependency.api) ? window.IPSCAPE.dependency.api : this.param('api');
    this.apiKey = (window.IPSCAPE.dependency.apiKey) ? window.IPSCAPE.dependency.apiKey : this.param('integration.apiKey');
    this.params = (window.IPSCAPE.dependency) ? window.IPSCAPE.dependency : this.searchParams;
    this.instance = (window.IPSCAPE.dependency.parent) ? window.IPSCAPE.dependency.parent : this.param('integration.instance') || null;
    this.reporting = (window.IPSCAPE.dependency.reporting) ? window.IPSCAPE.dependency.reporting : this.param('integration.reporting') || false;
    this.worker = (window.IPSCAPE.dependency.useWorker) ? window.IPSCAPE.dependency.useWorker : this.param('integration.useWorker') || false;
  }
}

export default angular.module('CCAdaptor.App.IntegrationService', [])
  .service('IntegrationService', IntegrationService).name;
