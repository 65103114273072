import angular from 'angular';

const template = require('./ips-call-auxactions.directive.html');

const IPSCallAuxActions = () => {
  'ngInject';

  class IPSCallAuxActionsController {
    constructor($log, $rootScope, $scope, $state, $uiRouterGlobals, CallService, ErrorService,
      AgentStates, agent, agentFSM, calls, ToastrService, StatusService, PhoneService, ApiService,
      PaymentsService, FeatureFlagService) {
      'ngInject';

      $scope.activeLine = () => { return PhoneService.activeLine; };
      $scope.inAttendedTransfer = () => { return PhoneService.attendedTransfer; };
      $scope.inConference = () => { return PhoneService.conferenceCall; };
      $scope.lineStateOne = () => { return PhoneService.lineStateOne; };
      $scope.lineStateTwo = () => { return PhoneService.lineStateTwo; };
      $scope.transferActionsDisabled = () => { return PhoneService.transferActionsDisabled; };
      $scope.callInfo = () => { return CallService.callInfo; };
      const activityId = () => { return CallService.activityId; };
      $scope.callNotes = () => { return CallService.callNotes; };
      $scope.callRecording = () => { return CallService.callRecording; };
      $scope.controlCallRecording = () => { return CallService.controlCallRecording; };
      const apiBuild = () => { return ApiService.build; };
      $scope.conferenceHangupPrompt = false;

      //-----------------------------------------------------
      // booleans
      //-----------------------------------------------------

      $scope.disableHold = () => {
        if (PaymentsService.isActive) return true;

        if (agentFSM.isOnCall() || agentFSM.isOnHold()) {
          if (!CallService.hasCampaign) return true;
          if ($scope.inAttendedTransfer()) return true;
          return false;
        }

        return agent.isOnPhoneWrapping();
      };

      $scope.disableTransfer = () => {
        if (PaymentsService.isActive) return true;
        if (agentFSM.isOnHold()) return true;
        if (!agentFSM.isOnAllocatedCall()) return true;
        if ($scope.inAttendedTransfer()) return true;

        return agent.isOnPhoneWrapping();
      };

      $scope.disableRecord = () => {
        if (PaymentsService.isActive) return true;
        if (agentFSM.isOnHold()) return true;
        if (agentFSM.isOnCall()) return !$scope.controlCallRecording();

        return !$scope.controlCallRecording() || agent.isOnPhoneWrapping();
      };

      $scope.disableRecordDropdown = () => {
        if (agentFSM.isOnCall()) return false;
        return agent.isOnPhoneWrapping();
      };

      $scope.disableSetTag = () => {
        return !$scope.callRecording() || agent.isOnPhoneWrapping();
      };

      $scope.disableHUP = () => {
        if (PaymentsService.isActive) return true;
        if (agentFSM.isOnHold()) return true;

        return agent.isOnPhoneWrapping();
      };

      $scope.disableReleaseCall = () => {
        if (PaymentsService.isActive) return true;
        if ($scope.inConference()) return true;
        return !agent.isOnCall();
      };

      $scope.disableMergeCall = () => {
        if (PaymentsService.isActive) return true;
        if ($scope.inConference()) return true;
        return !agent.isOnCall();
      };

      $scope.isOnHold = () => {
        return agentFSM.isOnHold();
      };

      $scope.isRecording = () => {
        return $scope.callRecording();
      };

      $scope.canTagVoiceRecordings = FeatureFlagService.isEnabled('tag_voice_recording');

      //------------------------------------------------------
      // listeners
      //------------------------------------------------------

      $scope.$on('event.user', (evt, data) => {
        if (data.action === 'start.transfer.attended') {
          PhoneService.attendedTransfer = true;
          $scope.activityId = data.activityId;
          $scope.transferType = data.transferType;
          $scope.transferDestination = data.transferDestination;
        }
      });

      $scope.$on('event.notify', (event, data) => {
        if (data.action === 'info') {
          if ($scope.inAttendedTransfer()) {
            if (data.tag.indexOf('hangup') > -1) {
              PhoneService.TRANSIT(1, 'disabled');
            }
          }
        }
      });

      //------------------------------------------------------
      // actions
      //------------------------------------------------------

      $scope.cancelModal = () => {
        $scope.conferenceHangupPrompt = false;
      };

      $scope.toggleHoldClick = () => {
        calls.withActivityId(activityId()).toggleHold().then((data) => {
          if (data.resultCode === 'success') {
            if (agentFSM.isOnHold()) {
              agentFSM.unhold();
              $rootScope.$broadcast('event.user', {
                action: 'call.unhold',
              });
              PhoneService.TRANSIT(1, 'active');
            } else {
              $rootScope.$broadcast('event.user', {
                action: 'call.hold',
              });
              agentFSM.hold();
            }
          }
        });
      };

      $scope.toggleRecordClick = () => {
        const op = $scope.isRecording() ? 'stop' : 'start';
        $rootScope.$broadcast('event.user', {
          action: `voice.recording.${op}`,
        });
        calls.withActivityId(activityId())
          .toggleRecord(op).then((data) => {
            CallService.callRecording = !CallService.callRecording;
          });
      };

      $scope.tagRecording = () => {
        const toastrOptions = {
          closeButton: false,
          escapeHtml: true,
          extendedTimeOut: 0,
          timeOut: 300,
          debug: true,
          preventDuplicates: true,
        };
        const tagMsg = 'Call recording tag added';
        calls.withActivityId(activityId())
          .tagVoiceRecording().then(() => {
            $rootScope.$broadcast('event.user', {
              action: 'voice.recording.tag',
            });
            ToastrService.makeToast({ type: 'info', message: tagMsg, opts: toastrOptions });
          }).catch((e) => {
            $log.warn('Error adding tag to voice recording: ', e);
          });
      };

      $scope.transferClick = () => {
        $state.transitionTo('call.transfer', $uiRouterGlobals.params, {
          reload: false,
          inherit: false,
          notify: true,
        });
      };

      $scope.releaseCall = () => {
        // Assign 'customer' call to transfer and drop out of call to wrap
        $scope.attemptingTransfer = true;

        calls.withActivityId(activityId()).releaseCall().then((data) => {
          if (data.resultCode === 'success') {
            $rootScope.$broadcast('event.user', {
              action: 'transfer.call.release',
              data: {
                activityId: activityId(),
                CallNotes: $scope.callNotes(),
              },
            });
            PhoneService.reset();
            agentFSM.transferComplete();
            CallService.autoWrapInit();

            if ($scope.callInfo()) {
              CallService.setCallEndedDate();
            }

            $rootScope.$broadcast('event.notify', {
              action: 'info',
              tag: 'call.release',
              message: 'Call released.',
            });
          }
          $scope.attemptingTransfer = false;
        });
      };

      // Add 'attended call' to 'customer call' to create a conference
      $scope.conferenceCall = () => {
        calls.withActivityId(activityId()).conferenceCall().then((data) => {
          if (data.resultCode === 'success') {
            $rootScope.$broadcast('event.user', {
              action: 'conference.call.start',
            });
            PhoneService.cancelTransferStateToActiveLine1Call();
            PhoneService.CONFERENCE('start');
          }
        });
      };

      $scope.hangupActivity = ({ conferenceEndType = null }) => {
        return new Promise((resolve, reject) => {
          let destinationState = agentFSM.hangupDestinationAgentState();

          if (agentFSM.unallocatedManualCall()) {
            destinationState = (StatusService.status.label === 'paused')
              ? AgentStates.PAUSED : AgentStates.READY;
          }

          if (agentFSM.isOnOutboundAllocatedCallorDialling()) {
            destinationState = AgentStates.OUTBOUND_WRAP;
          }

          calls.withActivityId(activityId())
            .hangup({
              destinationAgentState: destinationState,
              conferenceEndType,
            }).then((result) => {
              resolve(result);
              $rootScope.$broadcast('event.info', {
                tag: 'call.hangup',
                message: 'Call complete.',
                destination: destinationState,
                disableHangupWarningActive: true,
              });
              if ($scope.callInfo()) {
                CallService.setCallEndedDate();
              }
            }).catch((error) => {
              reject(error);
            });
        });
      };

      $scope.endConference = ({ action = 'TERMINATE' }) => {
        $scope.hangupActivity({ conferenceEndType: action }).then((response) => {
          if (response.resultCode === 'success') {
            PhoneService.CONFERENCE('stop');
            $scope.cancelModal();
          }
        });
      };

      $scope.hangup = () => {
        if (!$scope.inConference() || (apiBuild() < 7.20)) {
          $scope.hangupActivity({});
          return;
        }

        if (PhoneService.lineStateOne !== PhoneService.states.disabled) {
          // Show modal
          $scope.conferenceHangupPrompt = true;
        } else {
          $scope.endConference({ action: 'TERMINATE' });
        }
      };

      $scope.retrieveCall = () => {
        try {
          if ($scope.inAttendedTransfer()) {
            if (PhoneService.lineStateTwo === PhoneService.states.active) {
              calls.withActivityId(activityId()).retrieveCall().then((data) => {
                // Line#1 is hold & Line#2 is active
                // (Action: hangup line#2 and return to customer call)
                if ($scope.lineStateOne() !== PhoneService.states.disabled) {
                  PhoneService.cancelTransferStateToActiveLine1Call();
                }
                // Line#1 is disabled & Line#2 is active/hold
                // (Action: hangup line#2 and wrap)
                if (($scope.lineStateTwo() !== PhoneService.states.disabled)
                  && ($scope.lineStateOne() === PhoneService.states.disabled)) {
                  agentFSM.hangup();
                }

                PhoneService.ATTENDED('stop');
                $rootScope.$broadcast('event.info',
                  { tag: 'consult.call.hangup', message: 'Line disconnected.' });
              }).catch((response) => {
                if (response.data.errorCode === 'agent.retrieveCall.notAttendedTransfer') {
                  PhoneService.cancelTransferStateToActiveLine1Call();
                }
              });
            } else {
              ErrorService.report('Unable to retrieve call. Line #2 is not active.', {
                event: 'error',
                data: 'Unable to retrieve call.',
              });
            }
          }
        } catch (error) {
          ErrorService.report('Unable to retrieve call.', error);
        }
      };

      $scope.hangupLine = (activeLine) => {
        if (activeLine === 2) {
          $scope.retrieveCall();
          return;
        }
        $scope.hangup();
      };

      $scope.switchLine = (line) => {
        if (!agentFSM.isOnCall() || !$scope.inAttendedTransfer()) {
          return;
        }

        calls.withActivityId(activityId()).switchLine(line).then((data) => {
          if (line === 1) {
            PhoneService.TRANSIT(1, 'activate');
            PhoneService.TRANSIT(2, 'hold');
          } else {
            PhoneService.TRANSIT(1, 'hold');
            PhoneService.TRANSIT(2, 'activate');
          }
          $rootScope.$broadcast('event.user', {
            action: `switch.line.${line}`,
            data: {
              lineState: {
                one: $scope.lineStateOne(),
                two: $scope.lineStateTwo(),
              },
            },
          });
        });
      };
    }
  }

  return {
    restrict: 'EA',
    template,
    controller: IPSCallAuxActionsController,
    controllerAs: 'CallAuxActions',
    scope: true,
  };
};

export default angular.module('CCAdaptor.Toolbar.IPSCallAuxActions', [])
  .directive('ipsCallAuxActions', IPSCallAuxActions).name;
