import angular from 'angular';
import _ from 'lodash';
import DialPadInput from './dialpad-only-input';

const ManualCallDirectiveTemplate = require('./manual-call.directive.html');

const ManualCallDirective = () => {
  'ngInject';

  const StandaloneManualCallEnum = {
    CAMPAIGN_NOT_ALLOWED: 0,
    CAMPAIGN_OPTIONAL: 1,
    CAMPAIGN_REQUIRED: 2,
  };

  class ManualCallController {
    constructor($log, $rootScope, $scope, $state, $uiRouterGlobals, agent, calls,
      CallService, agentFSM, $timeout, $document, $animate, PhoneService,
      ApiService, FeatureFlagService) {
      'ngInject';

      // @CTI-353 listen for event so we can record "from" event
      let previousState = localStorage.getItem('agent.fsm.previousAgentState');

      $scope.$on('event.system', (event, data) => {
        if (_.hasIn(data, 'action')) {
          if (data.action === 'agent.state.change') {
            previousState = localStorage.getItem('agent.fsm.previousAgentState');
          }
        }
      });

      const telInput = angular.element('[type="tel"]');
      let campaignListActive = 0;

      // clear out any previous errors
      $scope.campaignsWarningMessage = null;
      $scope.campaignsErrorMessage = null;

      $scope.reset = () => {
        $scope.manualCallForm.$setPristine();
        $scope.manualCallPhoneNumber = '';
        telInput.focus();
        CallService.clear();
      };

      $scope.backspace = () => {
        if (_.get($scope.manualCallPhoneNumber, 'length', 0) === 0) {
          return;
        }
        $scope.manualCallPhoneNumber = $scope.manualCallPhoneNumber
          .substring(0, $scope.manualCallPhoneNumber.length - 1);
        telInput.focus();
        CallService.clear();
      };

      $scope.dialpadEvent = (e) => {
        if (e.target.hasAttribute('data-value')) {
          const value = e.target.getAttribute('data-value');
          if (value) {
            if (!$scope.manualCallPhoneNumber) {
              $scope.manualCallPhoneNumber = '';
            }
            $scope.manualCallPhoneNumber += value;
          }
          CallService.clear();
        } else {
          $scope.dialpadEvent({ target: e.target.parentNode });
        }
      };

      $scope.manualCallPhoneNumberEdited = () => {
        // populated on click-to-dial; must be unset for manual edits
        CallService.clear();
      };

      $scope.campaignSelectionIsAllowed = () => {
        return agent.organisationDetails().standaloneManualCall
          !== StandaloneManualCallEnum.CAMPAIGN_NOT_ALLOWED;
      };

      $scope.campaignSelectionIsOptional = () => {
        return agent.organisationDetails().standaloneManualCall
          === StandaloneManualCallEnum.CAMPAIGN_OPTIONAL;
      };

      $scope.campaignSelectionIsRequired = () => {
        return agent.organisationDetails().standaloneManualCall
          === StandaloneManualCallEnum.CAMPAIGN_REQUIRED;
      };

      $scope.getCampaignClass = (campaignType) => {
        return (!_.isNil(campaignType)) ? `type-${String(campaignType).toLowerCase()}` : '';
      };

      $scope.toggleCampaignSearch = () => {
        $scope.campaignSearch = !$scope.campaignSearch;
        if ($scope.campaignSearch) {
          setTimeout(() => {
            angular.element('#campaignSearch').focus();
          }, 100);
        } else {
          $scope.campaignSearchText = '';
          $scope.activeSearchBind = false;
        }
      };

      const searchInput = angular.element('#campaignSearch');

      $scope.selectCampaign = () => {
        $scope.campaignSearchText = '';
        $scope.campaignSearch = false;
        $scope.initBindKey = false;
        $scope.selectingCampaign = true;

        $document.bind('keydown', (event) => {
          if (!$scope.campaignSearch && (event.key !== 'ArrowUp'
            && event.key !== 'ArrowDown'
            && event.key !== 'ArrowLeft'
            && event.key !== 'ArrowRight'
            && event.key !== 'Shift'
            && event.key !== 'Escape'
            && event.key !== 'Enter')) {
            if (!$scope.campaignSearch) {
              $scope.$apply(() => {
                $animate.removeClass(searchInput, 'ng-hide').then(() => {
                  $scope.campaignSearch = true;
                  searchInput.focus();
                });
              });
            }
          }
          $scope.$broadcast('keypress', event, event.key);
        });

        const removeHoverCss = (listElements) => {
          _.forEach(listElements, (el) => {
            angular.element(el).css('background-color', '').removeClass('active-selection');
          });
        };

        const addHoverCss = (element, listElements) => {
          removeHoverCss(listElements);
          element.addClass('active-selection');
        };

        const keyBoardListener = $scope.$on('keypress', (event, args, key) => {
          if (args.key && !$scope.keyListenActive) {
            $scope.keyListenActive = true;
            const campaignList = angular.element('.modal-menu li[ng-repeat]');
            campaignList.bind('mouseenter', () => {
              removeHoverCss(campaignList);
            });

            const lettersPattern = /^[a-zA-Z0-9_]$/;

            switch (args.key) {
              case 'ArrowUp':
                if (campaignListActive > 0) {
                  campaignListActive -= 1;
                }
                addHoverCss(angular.element(campaignList[campaignListActive]), campaignList);
                $scope.arrowSelectionDirty = true;
                break;
              case 'ArrowDown':
                if ($scope.arrowSelectionDirty && campaignListActive < campaignList.length - 1) {
                  campaignListActive += 1;
                }
                addHoverCss(angular.element(campaignList[campaignListActive]), campaignList);
                $scope.arrowSelectionDirty = true;
                break;
              case 'Enter':
                angular.element('.modal-menu li[ng-repeat].active-selection').click();
                break;
              case 'Escape':
                $scope.selectingCampaign = false;
                $scope.reset();
                break;
              case 'ArrowLeft':
              case 'ArrowRight':
                break;
              default:
                if (lettersPattern.test(args.key)) {
                  searchInput.trigger('keydown', {
                    keyCode: key,
                    which: key,
                    charCode: key,
                  });
                  if (!$scope.initBindKey) {
                    searchInput.val(searchInput.val() + args.key);
                    $scope.initBindKey = true;
                  }
                }
            }
            $timeout(() => {
              $scope.keyListenActive = false;
            }, 100);
          }
        });

        $scope.$on('$destroy', () => {
          if (typeof keyBoardListener !== 'undefined') {
            keyBoardListener();
          }
        });
      };

      $scope.$watch('selectingCampaign', (newValue, oldValue) => {
        if (oldValue.toString() !== newValue.toString()) {
          campaignListActive = 0;
          $scope.arrowSelectionDirty = false;
        }

        // @CTI-353 Toggle manual preview when selecting campaign
        if ((oldValue !== newValue) && (agentFSM.isOnManualPreview)) {
          if (!newValue) {
            calls.manualPreview(false).then((response) => {
              if (response.resultCode === 'success') {
                $log.info('Manual preview: cancel', previousState);
                if (previousState === 'onPhonePaused') {
                  agentFSM.pause();
                } else if (previousState === 'onPhoneAvailable') {
                  agentFSM.unpause();
                }
              }
            });
          } else {
            calls.manualPreview(true).then((response) => {
              if (response.resultCode === 'success') {
                agentFSM.manualPreview();
              }
            });
          }
        }
      });

      $scope.$on('event.user', (evt, data) => {
        if (data.action === 'click-to-dial') {
          $log.debug('actions: handling event click-to-dial', data);
          $scope.manualCallPhoneNumber = data.clickToDialData.number;
        }
      });

      $scope.campaigns = [];
      $scope.outboundCampaigns = [];
      $scope.inboundCampaigns = [];
      $scope.selectingCampaign = false;
      $scope.campaignSearch = false;
      $scope.campaignSearchText = '';
      $scope.showSpecialKeys = false;
      $scope.specialKeys = ['#', '*'];

      const filtered = (pagedCampaigns) => {
        if (pagedCampaigns.data) {
          pagedCampaigns.data = pagedCampaigns.data.filter((campaign) => {
            return ['Inbound', 'Outbound'].indexOf(campaign.campaignType) >= 0;
          });
        }
        return pagedCampaigns;
      };

      const sorted = (pagedCampaigns) => {
        if (pagedCampaigns.data) {
          pagedCampaigns.data.sort((a, b) => {
            const campaignA = a.campaignTitle.toUpperCase(); // ignore upper and lowercase
            const campaignB = b.campaignTitle.toUpperCase(); // ignore upper and lowercase

            if (campaignA < campaignB) {
              return -1;
            }
            if (campaignA > campaignB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
        }
        return pagedCampaigns;
      };

      const updateCampaigns = (response) => {
        if (response.data.length === 0) {
          if ($scope.campaignSelectionIsOptional()) {
            $scope.campaignsWarningMessage = `
              No outbound campaigns are assigned to this user,
              the manual call will be placed without a campaign.
              If this is not the intended behaviour please contact your workspace administrator`;
          } else if ($scope.campaignSelectionIsRequired()) {
            $scope.campaignsErrorMessage = `
              An outbound campaign must be assigned to you before you can make a manual call.
              Please contact your workspace administrator for assistance`;
          }
        } else {
          // Filter non-running campaigns for agents with that setting enabled
          $scope.campaigns = $scope.campaigns.concat(_.filter(response.data, (c) => {
            return !(agent.details().onlyCallRunningOutboundCampaigns && !c.isRunning);
          }));
          $scope.outboundCampaigns = $scope.outboundCampaigns.concat(_.filter(response.data,
            (c) => { return c.campaignType === 'Outbound'; }));
          $scope.inboundCampaigns = $scope.inboundCampaigns.concat(_.filter(response.data,
            (c) => { return c.campaignType === 'Inbound'; }));
        }
      };

      // Check the endpoint exists before attempting to call it
      const enabledFeatureFlags = ApiService.hasMethod('organisation/getenabledfeatureflags');
      if (enabledFeatureFlags) {
        FeatureFlagService.getFeatureFlags();
      }

      agent.campaigns().list().then((response) => {
        const pagedData = filtered(response.result);
        const sortedCampaigns = sorted(pagedData);
        updateCampaigns(sortedCampaigns);
      });

      $scope.callWithCampaignId = (campaignId) => {
        $rootScope.$broadcast('event.user', {
          action: 'campaign.selected',
          data: {
            campaignId,
          },
        });
        agent.calls.dial($scope.manualCallPhoneNumber, campaignId).then((data) => {
          CallService.callNotes = null;
          CallService.callInfo = data;
          CallService.callInfo.stateBeforeCall = agent.state();
          $scope.manualCallPhoneNumber = undefined;
          PhoneService.TRANSIT(1, 'activate');
          $state.transitionTo('call', $uiRouterGlobals.params, {
            reload: false,
            inherit: false,
            notify: true,
          });
        }).catch((response) => {
          // What do we do here?
          $log.error(response);
        });
      };
    }
  }

  return {
    restrict: 'EA',
    scope: true,
    controller: ManualCallController,
    controllerAs: 'ManualCall',
    template: ManualCallDirectiveTemplate,
  };
};

export default angular.module('CCAdaptor.App.IPSManualCall', [DialPadInput])
  .directive('ipsManualCall', ManualCallDirective).name;
