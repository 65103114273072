/*
 * Javascripts
 * ========================================================================== */

import angular from 'angular';
import TransferController from './controller/transfer.controller';

/*
 * Directives
 * ========================================================================== */
// import TransferActions      from './directives/ips-transfer.actions.directive';

/*
 * Stylesheets
 * ========================================================================== */

import './stylesheets/transfer.scss';

/* ========================================================================== */

/**
 * Define home page module.
 * @param {String} moduleName.
 * @param {Array} dependencies.
 * @export Module name - name of this module.
 */
export default angular.module('CCAdaptor.App.TransferController', [
  // TransferActions
]).controller('TransferController', TransferController).name;
