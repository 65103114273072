/**
 * @module Root controller
 * @class MainController
 */
export default class MainController {
  constructor($log) {
    'ngInject';

    $log.debug('MainController');
  }
}
