import angular from 'angular';
import _ from 'lodash';
import BroadcastChannel from 'broadcast-channel';
import { isJSON } from '../../common/utilities';

const SFTasks = ($injector, $log, $http, $interval, $timeout, $rootScope, ToastrService, $window,
  $stateParams, TaskService, CallService, ConfigService, ErrorService) => {
  'ngInject';

  const that = {};
  that.injector = $injector;
  that.log = $log;
  that.http = $http;
  that.interval = $interval;
  that.timeout = $timeout;
  that.rootScope = $rootScope;
  that.toastrService = ToastrService;
  that.window = $window;
  that.stateParams = $stateParams;
  that.taskService = TaskService;
  that.callService = CallService;
  that.configService = ConfigService;
  that.errorService = ErrorService;

  that.initTimer = undefined;
  // Salesforce classic namespace
  that.sforce = (_.hasIn($window, 'sforce')) ? $window.sforce : {};

  // Channel used by ipSCAPE pay
  that.bc = new BroadcastChannel('ipscape.cti.pay');
  // Writes comments to console
  that.writeToConsole = (key, value) => {
    // Todo: change if statement to use devmode in stateParams
    if (process.env.NODE_ENV === 'development') that.log.info(`[SF] ${key}`, value);
  };

  // Reports events to Rollbar and writes to console
  that.createReport = ({ type = 'error', title, data }) => {
    switch (type) {
      case 'info':
        that.errorService.info(`[SF] ${title}`, { data });
        break;
      case 'warn':
        that.errorService.warn(`[SF] ${title}`, { data });
        break;
      case 'error':
      default:
        that.errorService.report(`[SF] ${title}`, { data });
    }
  };

  // Define properties & bind to services
  Object.defineProperty(that, 'screenpop', {
    get: () => that.configService.screenpop,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(that, 'taskPop', {
    get: () => that.taskService.pop,
    set: (value) => { that.taskService.pop = value; },
    configurable: true,
  });

  Object.defineProperty(that, 'settings', {
    get: () => that.configService.ipsSettings,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(that, 'isInConsole', {
    get: () => that.configService.isInConsole,
    set: (bool) => {
      that.configService.isInConsole = bool;
    },
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(that, 'softPhoneLayout', {
    get: () => that.configService.softPhoneLayout,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(that, 'apexCapabilities', {
    get: () => that.configService.apexCapabilities,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(that, 'selectedContact', {
    get: () => that.callService.selectedContact,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(that, 'taskId', {
    get: () => that.taskService.taskId,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(that, 'openTaskOnWrap', {
    get: () => that.taskService.openTaskOnWrap,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(that, 'screenPopResult', {
    get: () => that.taskService.screenPopResult,
    set: (obj) => { that.taskService.screenPopResult = obj; },
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(that, 'selectedItem', {
    get: () => that.callService.selectedItem,
    set: (item) => { that.callService.selectedItem = item; },
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(that, 'callType', {
    get: () => that.callService.callType,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(that, 'previewData', {
    get: () => that.callService.outboundInfo,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(that, 'outboundCallInfo', {
    get: () => that.callService.outboundInfo,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(that, 'previewCalled', {
    get: () => that.callService.previewCalled,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(that, 'callInfo', {
    get: () => that.callService.callInfo,
    configurable: true,
    enumerable: true,
  });

  Object.defineProperty(that, 'isOnOutboundPreview', {
    get: () => that.injector.get('agentFSM').isOnOutboundPreview(),
    configurable: true,
  });

  Object.defineProperty(that, 'isCurrentTab', {
    get: () => that.rootScope.currentTab,
    configurable: true,
  });

  Object.defineProperty(that, 'callData', {
    get: () => {
      if (that.isOnOutboundPreview) {
        if (!that.previewCalled) return that.previewData;
        return that.outboundCallInfo;
      }
      return that.callInfo;
    },
    configurable: true,
    enumerable: true,
  });

  that.checkRelatedObjectsOnWrap = () => {
    if (_.hasIn(that.settings, 'checkRelatedObjectsOnWrap')) {
      return !!that.settings.checkRelatedObjectsOnWrap;
    }
    return false;
  };

  // Listener for Payment complete event message
  that.bc.onmessage = (evt) => {
    if (_.hasIn(evt, 'resource')) {
      const source = evt.resource;
      if (source.window === 'payments') {
        if (source.action === 'pay') {
          const additionalFields = [];
          const { msg } = source;
          // get result
          if (_.hasIn(msg, 'approved')) {
            const parm = (msg.approved) ? 'Approved' : 'Declined';
            additionalFields.push({ transaction_result__c: parm });
          }
          // get receipt number
          if (_.hasIn(msg, 'receipt_number')) {
            additionalFields.push({ receipt_number__c: msg.receipt_number });
          }
          // get response text
          if (_.hasIn(msg, 'response_text')) {
            additionalFields.push({ response_text__c: msg.response_text });
          }
          // get transaction reference
          if (_.hasIn(msg, 'transaction_reference')) {
            additionalFields.push({ transaction_reference__c: msg.transaction_reference });
          }
          // Send to activity log
          if (additionalFields.length > 0) {
            that.injector.get('SalesforceService').writeToLog({
              fields: additionalFields,
            }).then((response) => {
              that.writeToConsole('Task Updated: ', response);
            });
          }
        }
      }
    }
  };

  function apexOpportunityCallback(response) {
    const res = (isJSON(response.result)) ? JSON.parse(response.result) : {};
    that.writeToConsole('apexOpportunityCallback', response);
    // Parse the response to the format used by SalesforceService updateRelated
    if (res.Id) {
      const opportunity = {
        url: res.attributes.type,
        objectId: res.Id,
        objectName: res.Name,
        object: res.attributes.type,
        displayName: res.attributes.type,
      };
      // Clear related objects and select the Opportunity
      that.callService.resetRelatedObjects();
      that.injector.get('SalesforceService')
        .updateRelated({ event: 'event.focus', result: opportunity });
      that.createReport({
        type: 'info',
        title: 'Update related for lead converted to opportunity',
        data: response,
      });
    } else {
      that.createReport({
        type: 'error',
        title: 'sforce.interaction.runApex apexOpportunityCallback',
        data: response,
      });
    }
  }

  function apexLeadCallback(response) {
    const res = (isJSON(response.result)) ? JSON.parse(response.result) : {};
    const { getOpportunity } = that.apexCapabilities;
    that.writeToConsole('sforce.interaction.runApex apexLeadCallback', response);
    // Check if the selected Lead in the dropdown was converted to an Opportunity
    if (res.IsConverted && res.ConvertedOpportunityId) {
      // Get the Opportunity data
      that.sforce.interaction.runApex(
        getOpportunity.class,
        getOpportunity.method,
        `opportunityId=${res.ConvertedOpportunityId}`,
        apexOpportunityCallback,
      );
    }
  }

  function handleConvertedObjects(response) {
    if (that.selectedContact.object === 'Lead') {
      // Check if the selected lead was converted to an opportunity
      if (_.hasIn(that.apexCapabilities, 'getLead')
        && _.hasIn(that.apexCapabilities, 'getOpportunity')) {
        that.writeToConsole('sforce.interaction.runApex getLead', response);
        // eslint-disable-next-line no-underscore-dangle
        const _apex = that.apexCapabilities.getLead;
        that.sforce.interaction.runApex(
          _apex.class,
          _apex.method,
          `leadId=${that.selectedContact.objectId}`,
          apexLeadCallback,
        );
      } else {
        that.writeToConsole('apex capabilities [getLead, getOpportunity] not found', that.apexCapabilities);
      }
    }
  }

  function focusHandler(response) {
    that.writeToConsole('focusHandler event', response);
    // Update GUID to set tab as current
    localStorage.setItem('guid', sessionStorage.getItem('guid'));
    if (_.hasIn(response, 'result')) {
      const res = (isJSON(response.result)) ? JSON.parse(response.result) : {};
      if (!res.objectId) {
        that.writeToConsole('Ignoring unrelated focus event.', response.result);
      } else {
        that.injector.get('SalesforceService').updateRelated({ event: 'event.focus', result: res });
      }
      // Check if the current page is not the same type as the selected item
      // In this case, the selected item may have been converted to another type
      if (_.hasIn(that.selectedContact, 'object') && that.selectedContact.object !== res.object) {
        handleConvertedObjects(response);
      }
    } else {
      that.createReport({ type: 'error', title: 'FocusHandler unexpected response', data: response });
    }
  }

  function setFocusHandler() {
    that.sforce.interaction.onFocus(focusHandler);
  }

  function setPageInfoListener() {
    try {
      that.sforce.interaction.getPageInfo(focusHandler);
    } catch (error) {
      that.createReport({ type: 'error', title: 'SetPageInfoListener Error', data: error });
    }
  }

  function toggleVisibility(toggle) {
    if (that.isInConsole) {
      that.sforce.interaction.setVisible(toggle, (response) => {
        that.writeToConsole('toggleVisibility', response);
      });
    }
  }

  function updatePopRecord(interactionId) {
    const popRecord = that.taskPop;
    popRecord.interactionId = (interactionId) || popRecord.interactionId;
    popRecord.event = that.callType;
    popRecord.popped = (popRecord.popped) || false;
    that.taskPop = popRecord;
  }

  function attemptScreenPop(data) {
    if (_.hasIn(that.screenpop, 'default')) {
      that.injector.get('SalesforceService').searchAndPop(data.callType);
    } else {
      that.createReport({ type: 'warn', title: 'ScreenPop config not found', data: { config: that.screenpop } });
      const nationalPhoneNumber = data.nationalCustomerPhoneNumber.replace(/[- )(]/g, '');
      const filter = `${data.customerPhoneNumber} OR ${nationalPhoneNumber} OR ${data.internationalCustomerPhoneNumber}`;
      that.injector.get('SalesforceService').doSearch({ filter });
    }
    // Delay to get related person/object from the current page as page reload
    // can be triggered from the screen pop. If setPageInfoListener() is executed
    // before the screen pop, the current page (person/object) may be selected in the dropdown
    // and this selection could be related to the previous call
    // Todo: refactor to callback from SalesforceService.searchAndPop & doSearch
    setTimeout(() => {
      setPageInfoListener();
    }, 2000);
  }

  function reportMismatchedRelatedRecords() {
    let selectedMatchedScreenPop;
    // Check if the current selection is different than the screenPop
    // If so, log a warning to Rollbar
    if (that.screenPopResult) {
      selectedMatchedScreenPop = false;
      const resultKeys = Object.keys(that.screenPopResult);
      // More than one screen pop record in results
      if (resultKeys.length > 1) {
        // Search has returned more than one result
        selectedMatchedScreenPop = true;
      }
      // Check selected contacts
      if (_.hasIn(that.selectedContact, 'objectId')) {
        if (resultKeys.indexOf(that.selectedContact.objectId) !== -1) {
          selectedMatchedScreenPop = true;
        }
      }
      // Check selected objects/items
      if (_.hasIn(that.selectedItem, 'objectId')) {
        if (resultKeys.indexOf(that.selectedItem.objectId) !== -1) {
          selectedMatchedScreenPop = true;
        }
      }
      // if related objects do not match pop results
      if (!selectedMatchedScreenPop) {
        that.createReport({
          type: 'warn',
          title: 'Agent Wrap Mismatch with ScreenPop',
          data: {
            selectedContact: that.selectedContact,
            selectedItem: that.selectedItem,
            screenPopResult: that.screenPopResult,
          },
        });
      }
    }
  }

  // Checks that the current entity matches the selected record whoId/whatId
  function checkCurrentPageAgainstRelatedRecords() {
    return new Promise((resolve, reject) => {
      that.sforce.interaction.getPageInfo((response) => {
        if (_.hasIn(response, 'result')) {
          // Send response to the focusHandler
          focusHandler(response);

          const relatedArr = [];
          const res = (isJSON(response.result)) ? JSON.parse(response.result) : {};

          if (_.hasIn(that.selectedItem, 'objectId')) relatedArr.push(that.selectedItem.objectId);
          if (_.hasIn(that.selectedContact, 'objectId')) relatedArr.push(that.selectedContact.objectId);

          // Log related Objects
          that.writeToConsole('Task related objects: ', relatedArr);
          // Setup Modal
          that.rootScope.modal = { title: 'activity warning' };

          // Modal Cancel Button Callback
          that.rootScope.cancelModalBtn = () => {
            that.callService.resumeAutoWrapCountdown();
            that.rootScope.showBaseModal = false;
          };

          // Modal Continue Button Callback
          that.rootScope.continueModalBtn = () => {
            that.callService.resumeAutoWrapCountdown();
            that.createReport({ type: 'warn', title: that.rootScope.modal.message, data: response });
            that.rootScope.showBaseModal = false;
            resolve();
          };

          // If checkRelatedObjectsOnWrap is false or does not exist
          if (!that.checkRelatedObjectsOnWrap()) {
            if (relatedArr.length < 1 || relatedArr.indexOf(res.objectId) === -1) {
              that.createReport({ type: 'warn', title: 'Mismatched Call Log', data: response });
            }
            resolve();
          } else {
            if (relatedArr.length < 1) {
              that.callService.pauseAutoWrapCountdown();
              that.rootScope.modal.message = 'This call log has not been matched to any entity. Do you wish to continue?';
              that.rootScope.showBaseModal = true;
            }

            if (relatedArr.length > 0) {
              // Checking if the response objectId contains in the index 0 of the
              // selected item id. It checks if it contains in the string as the object
              // id returned from the Apex api contains extra characters appended. E.g. AAB
              const matched = relatedArr.indexOf(res.objectId) > -1;

              if (!matched) {
                that.callService.pauseAutoWrapCountdown();
                that.rootScope.modal.message = 'This call log has not been matched to the entity you are currently viewing. Do you wish to continue?';
                that.rootScope.showBaseModal = true;
              } else {
                resolve();
              }
            }
          }
        } else {
          reject(Error(response));
          that.createReport({ type: 'warn', title: '[SF] Check Page Against Related Records', data: response });
        }
      });
    });
  }

  window.addEventListener('beforeunload', () => {
    if (that.initTimer) {
      that.interval.cancel(that.initTimer);
      that.initTimer = undefined;
    }
  }, false);

  return {
    init: () => {
      try {
        that.sforce.interaction.isInConsole((response) => {
          that.isInConsole = !!response.result;
          that.writeToConsole('Salesforce Classic Console: ', that.isInConsole);
        });
        // that.sforce.interaction.onFocus(focusHandler);
        if (!that.initTimer) {
          that.initTimer = that.interval(() => {
            setFocusHandler();
          }, 60000);
        }
      } catch (error) {
        that.createReport({ type: 'error', title: 'Init error', data: error });
      }
      setPageInfoListener();
    },
    loadAdaptorConfig: () => new Promise((resolve, reject) => {
      that.injector.get('SalesforceService').getSettings()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(Error(error));
          that.createReport({ type: 'error', title: 'Adaptor Config Error', data: error });
        });
    }),
    enableClickToDial: () => {
      const listener = (response) => {
        try {
          if (_.hasIn(response, 'result')) {
            // reset related contacts & items
            that.callService.resetRelatedObjects();
            that.rootScope.$apply(() => {
              const json = JSON.parse(response.result);
              if (json.displayName === 'Task') {
                that.log.warn('[SF] Click-to-dial not available from tasks');
                return;
              }

              that.injector.get('SalesforceService').updateRelated({
                event: 'event.click-to-dial',
                result: json,
              });

              that.rootScope.$broadcast('event.user', {
                action: 'click-to-dial',
                clickToDialData: json,
              });
              toggleVisibility(true);
            });
          } else {
            that.createReport({ type: 'error', title: 'onClickToDial unexpected response', data: response });
          }
        } catch (error) {
          that.createReport({ type: 'error', title: 'onClickToDial Error', data: error });
        }
      };
      const callback = (response) => {
        if (_.hasIn(response, 'result')) {
          try {
            that.sforce.interaction.cti.onClickToDial(listener);
          } catch (error) {
            that.createReport({ type: 'error', title: 'onClickToDial', data: error });
          }
        } else {
          that.createReport({ type: 'error', title: 'enableClickToDial unexpected response', data: response });
        }
      };

      that.timeout(() => {
        that.sforce.interaction.cti.enableClickToDial(callback);
      }, 50);
    },
    disableClickToDial: () => {
      that.sforce.interaction.cti.disableClickToDial((response) => {
        try {
          if (_.hasIn(response, 'result')) {
            that.writeToConsole('click-to-dial disabled', response.result);
          } else {
            that.createReport({ type: 'error', title: 'disableClickToDial unexpected response', data: response });
          }
        } catch (error) {
          that.createReport({ type: 'error', title: 'disableClickToDial Error', data: error });
        }
      });
    },
    toggleVisibility,
    onOutboundPreview: ({ filter = null }) => {
      const SalesforceService = that.injector.get('SalesforceService');
      updatePopRecord(that.callData.agentInteractionId);
      // Create a call log
      SalesforceService.writeToLog({})
        .then(() => {
          that.writeToConsole('Create Preview log', {
            taskId: that.taskId,
            filter,
            PreviewData: that.previewData,
          });
        });
      if (_.hasIn(that.screenpop, 'default')) {
        SalesforceService.searchAndPop('Outbound');
      } else if (filter) {
        SalesforceService.doSearch({ filter });
      }
    },
    callCreated: ({ data, screenPop = true }) => {
      if (!that.isCurrentTab) return;

      that.log.info('[SF] Call created', data);
      updatePopRecord(data.agentInteractionId);

      that.writeToConsole('Call created', { taskId: that.taskId, data });

      const SalesforceService = that.injector.get('SalesforceService');
      SalesforceService.writeToLog({})
        .then(() => {
          that.writeToConsole('Write to log', {
            taskId: that.taskId,
            data,
            SalesforceServiceCallData: SalesforceService.callData(),
            screenPop,
          });
          if (screenPop) attemptScreenPop(data);
          else setPageInfoListener();
        });
    },
    hangupCall: () => {
      if (!that.isCurrentTab) return;
      that.writeToConsole('Call Hangup', JSON.stringify(that.callData.agentInteractionId));
    },
    wrapCall: msg => new Promise((resolve, reject) => {
      if (!that.isCurrentTab) reject(Error('Not current active tab'));
      reportMismatchedRelatedRecords();

      // Write Salesforce Log and continue
      const writeLog = () => {
        that.injector.get('SalesforceService').writeToLog({})
          .then(() => {
            if (that.openTaskOnWrap && !_.isNil(that.taskId)) {
              let openTaskDelay = 0;
              let checkNewCall = false;
              // Check if it's classic/embedded view and if the screenPop setting is to open in
              // the same tab. If so, a timeout to openTaskOnWrap will be set to allow the next
              // call to come in within 2 seconds. This delay will avoid the openTaskOnWrap on
              // wrap to override the screenPop, and also avoid the instant page reload that
              // stops the code execution before clearing the callData, which sometimes were
              // presented in the next call
              if (!that.isInConsole && _.hasIn(that.softPhoneLayout, 'Inbound')
                && that.softPhoneLayout.Inbound.screenPopSettings.screenPopsOpenWithin === 'ExistingWindow') {
                openTaskDelay = 3000;
                checkNewCall = true;
              }

              $log.info('[SF][salesforce.tasks.js] setTimeout openTaskOnWrap()', that.taskId, openTaskDelay);

              const LeadId = (_.hasIn(that.callData, 'leadId')) ? that.callData.leadId : null;

              // Timeout to enable resetting all services before the reload in
              // Salesforce Classic/Embedded view. Without this delay, some call
              // data was presented in the next call
              setTimeout((taskId, checkCall, LeadID) => {
                $log.info('[SF][salesforce.tasks.js] setTimeout openTaskOnWrap', taskId, that.taskId, checkCall);
                // Check if the taskService was reset and a new call was not initiated
                const matchedLeadId = (_.hasIn(that.callData, 'leadId')) ? LeadID === that.callData.leadId : false;
                if (!checkCall || !_.hasIn(that.callInfo, 'callType') || matchedLeadId) {
                  $log.info('[SF][salesforce.tasks.js] setTimeout openTaskOnWrap() SalesforceService.doPop()');
                  that.sforce.interaction.screenPop(taskId, true, (response) => {
                    that.writeToConsole('Task Screen Pop', response);
                    that.rootScope.$broadcast('event.system', {
                      action: 'call.wrap.complete',
                      data: response,
                    });
                  });
                } else {
                  $log.info('[SF][salesforce.tasks.js] ignoring openTaskOnWrap()');
                }
              }, openTaskDelay, that.taskId, checkNewCall, LeadId);
            }
            // Resolve as successful
            resolve({ result: 'success', response: msg });
          })
          .catch((error) => {
            // Reject with error
            reject(Error(error));
          });
      };

      checkCurrentPageAgainstRelatedRecords()
        .then(() => {
          if (that.isOnOutboundPreview) {
            if (!that.previewCalled) writeLog();
            else resolve();
          } else {
            writeLog();
          }
        })
        .catch((error) => {
          reject(Error(error));
        });
    }),
  };
};

export default angular.module('CCAdaptor.App.SFTasks', [])
  .factory('sftasks', SFTasks).name;
