import _ from 'lodash';

/**
 * Adding redirectTo from config ability.
 * @module Runners
 * @see Application
 * @param {Object} $rootScope - Global application model.
 * @param {Object} $state - Provides interfaces to current state.
 */

const Runners = ($injector, $log, $rootScope, $state, agent, ErrorService, AnalyticsService,
  IntegrationService, $transitions) => {
  'ngInject';

  $rootScope.displayHeaderFooter = () => $state.$current.name !== 'resource';

  $transitions.onSuccess({}, (transition) => {
    $log.info(
      `Transition: from ${transition.from().name} to ${transition.to().name}`);
  });

  $transitions.onStart({}, ($transition) => {
    if ($transition.to().redirectTo) {
      $state.transitionTo($transition.to().redirectTo);
      return;
    }

    $rootScope.toState = $transition.to().name;

    //----------------------------------------------------
    // websocket reconnection on refresh
    //----------------------------------------------------
    const AuthService = $injector.get('AuthService');

    if (AuthService.isLoggedIn()) {
      $injector.get('SocketFactory').connect();
    }
  });

  $state.defaultErrorHandler((error) => {
    $log.warn(error);
  });

  $rootScope.$on('event.system', (event, data) => {
    if (_.hasIn(data, 'action')) {
      if (data.action === 'agent.state.change') {
        const agentState = agent.readableState();
        if (data.params.to !== AnalyticsService.state) {
          AnalyticsService.send(agentState);
        }
      }
    }
  });
};

/** Export our runners */
export default Runners;
