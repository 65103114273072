import angular from 'angular';
import _ from 'lodash';

const relatedChoiceTemplate = require('./related-choice.directive.html');

const RelatedChoice = () => {
  class RelatedChoiceController {
    constructor($scope) {
      'ngInject';

      $scope.hasItems = () => {
        return _.get($scope, 'list.length', 0) > 0;
      };

      $scope.itemName = (item) => {
        return _.get(item, _.get($scope, 'displayKey', 'name'), $scope.placeholder);
      };

      $scope.selectedItemName = () => {
        return $scope.itemName($scope.selected);
      };

      $scope.$watch('disabled', (newVal, oldVal) => {
        if (newVal && $scope.hasItems) {
          $scope.setSelectedItem();
        }
      });

      $scope.setSelectedItem = (item) => {
        $scope.selected = item;
      };
    }
  }

  return {
    restrict: 'EA',
    template: relatedChoiceTemplate,
    controller: RelatedChoiceController,
    scope: {
      list: '=',
      selected: '=',
      disabled: '=',
      displayKey: '@',
      label: '@',
      placeholder: '@',
    },
  };
};

export default angular.module('CCAdaptor.App.RelatedChoice', [])
  .directive('relatedChoice', RelatedChoice).name;
