import angular from 'angular';
import _ from 'lodash';

class PaymentsService {
  constructor($log, storagemanager, $q, calls, CallService, ErrorService) {
    'ngInject';

    this.listeners = [];
    this.manager = storagemanager;
    this.locals = {};
    this.watchedAttributes = [];
    this.$log = $log;
    this.$q = $q;
    this.calls = calls;
    this.CallService = CallService;
    this.ErrorService = ErrorService;

    this.manager.watchers()
      .withObj(this.locals)
      .withId('payments')
      .buildWithAttributes((a) => {
        this.watchedAttributes.push(
          a.withStringKey('uuid').withDefault(null).build(),
          a.withStringKey('transactionId').withDefault(null).build(),
          a.withBooleanKey('disable').withDefault(false).build(),
          a.withBooleanKey('is_active').withDefault(false).build(),
        );
      });
  }

  get activityId() {
    return this.CallService.activityId;
  }

  get uuid() {
    return this.locals.uuid;
  }

  set uuid(val) {
    this.locals.uuid = val;
  }

  get transactionId() {
    return this.locals.transactionId;
  }

  set transactionId(id) {
    if (_.isNil(id)) {
      this.locals.transactionId = null;
      return;
    }
    this.locals.transactionId = id;
  }

  get disable() {
    return this.locals.disable;
  }

  set disable(bool) {
    this.locals.disable = bool;
  }

  get isActive() {
    return this.locals.is_active;
  }

  set isActive(bool) {
    this.locals.is_active = bool;
  }

  endCurrentPaymentTransaction() {
    const dfd = this.$q.defer();
    if (this.transactionId && this.activityId) {
      this.calls.withActivityId(this.activityId).endPayment(this.transactionId)
        .then((response) => {
          this.$log.info('Payment transaction ended: ', response.data.resultCode);
          this.transactionId = null;
          dfd.resolve();
        }).catch((err) => {
          this.ErrorService.report('Payment end transaction', err);
          dfd.reject(err);
        });
    }
    return dfd.promise;
  }

  reset() {
    this.uuid = null;
    this.transactionId = null;
    this.disable = false;
    this.isActive = false;
  }
}

export default angular.module('CCAdaptor.App.PaymentsService', [])
  .service('PaymentsService', PaymentsService).name;
