import angular from 'angular';

const template = require('./countdown.view.html');

const countdown = () => {
  'ngInject';

  class CountdownController {
    constructor($scope, $rootScope, $timeout, agentFSM, CallService) {
      'ngInject';

      let countdownTimeout = false;
      $scope.animationValue = parseInt($scope.timer, 10);

      const stopCountdown = () => {
        if (countdownTimeout) {
          $timeout.cancel(countdownTimeout);
          $scope.timer = 0;
          countdownTimeout = false;
        }
      };

      const onTimeout = () => {
        if ($scope.timer > 0) {
          $scope.timer -= 1;
          CallService.autoPreview = $scope.timer;
        }

        if ($scope.timer >= 1) {
          countdownTimeout = $timeout(onTimeout, 1000);
        } else {
          // if agent is in correct state we fire callback
          if (agentFSM.isOnOutboundPreview()) {
            $scope.callback();
          }
          stopCountdown();
        }
      };

      // Stop countdown if agent manually starts a call
      $rootScope.$on('event.user', (event, data) => {
        if (data.action === 'outbound.preview.dialling') {
          if (countdownTimeout) {
            stopCountdown();
          }
        }
      });

      // Stop countdown if agent manually wraps without making a call
      $rootScope.$on('event.user', (event, data) => {
        if (data.action === 'wrap') {
          if (countdownTimeout) {
            stopCountdown();
          }
        }
      });

      countdownTimeout = $timeout(onTimeout, 1000);
    }
  }
  return {
    restrict: 'EA',
    scope: {
      timer: '=',
      callback: '&callback',
    },
    controller: CountdownController,
    controllerAs: 'CountDown',
    template,
  };
};

export default angular.module('CCAdaptor.App.CountDown', [])
  .directive('countdown', countdown).name;
