import angular from 'angular';
import _ from 'lodash';
import BroadcastChannel from 'broadcast-channel';

const debugUtil = require('util');

const MessageHandler = ($log, $rootScope, $state, $injector, $uiRouterGlobals, agentFSM, $window,
  PhoneService, CallService, ToastrService, AgentStates, WebsocketStatus, ErrorService,
  AgentVoiceStatus, IntegrationService, AuthService, StatusService, AgentInfoService, calls,
  PaymentsService) => {
  'ngInject';

  const bc = new BroadcastChannel('ipscape');
  const debug = debugUtil;
  const rollbar = $window.IPSCAPE.Rollbar;
  let pingTimer = {};
  let hangupWarningActive = false;

  // ----------------------------------------------------------------------- *
  // Helper functions
  // ----------------------------------------------------------------------- */
  const stateName = () => { return $state.current.name; };
  const lineOne = () => { return PhoneService.lineStateOne; };
  const lineTwo = () => { return PhoneService.lineStateTwo; };
  const attended = () => { return PhoneService.attendedTransfer; };
  const conferenceCall = () => { return PhoneService.conferenceCall; };
  const activityId = () => { return CallService.activityId; };
  const authSST = () => { return AuthService.sst; };

  const hangupIfPossible = (msg, origin) => {
    const conditions = {
      canHangup: agentFSM.canHangUp(),
      canReset: agentFSM.canPerform('hangupToAvailable') || agentFSM.canPerform('hangupToPaused'),
      isCall: stateName() === 'call',
      isTransfer: stateName() === 'call.transfer',
      isOnConsultCall: lineTwo() !== PhoneService.states.disabled,
      isContactHangup: msg.op === 'contact.call.hangup',
      isAgentHangup: msg.op === 'agent.call.hangup',
      isOutboundCall: agentFSM.isOnOutboundCall(),
      isOnFailedCall: agentFSM.isOnFailedCall(),
      isOnUnallocatedFailedCall: agentFSM.isOnUnallocatedFailedCall(),
      isConference: conferenceCall(),
    };

    if (conditions.isConference && msg.data.agentStatus !== 'Wrap') {
      if (msg.op === 'contact.call.hangup') {
        ToastrService.makeToast({
          type: 'info',
          message: 'The original contact disconnected',
          heading: 'Line Disconnected',
          opts: {
            closeButton: true,
            timeout: 5000,
          },
        });
        PhoneService.lineStateOne = PhoneService.states.disabled;
      }
      return;
    }

    if (CallService.callInfo) CallService.setCallEndedDate(msg);

    if (conditions.canHangup) {
      $rootScope.$broadcast('event.system', {
        action: 'call.hangup',
        msg,
        origin,
      });
      // If line#2 is active we don't want to change the adaptor state to wrap
      if (!conditions.isOnConsultCall) {
        agentFSM.hangup(msg);
      }

      if (conditions.isContactHangup) CallService.pushToCallInfo('hangupOrigin', 'Contact');

      if (conditions.canReset) {
        $state.transitionTo('toolbar', $uiRouterGlobals.params, {
          reload: false,
          inherit: false,
          notify: true,
        });
      }
    } else {
      $log.info('*** IPS Websocket ***: Ignore additional hangup event %s', JSON.stringify(msg));
      return;
    }

    if (conditions.isTransfer) {
      const toastrHangupOptions = {
        closeButton: true,
        extendedTimeOut: 0,
        timeOut: 0,
        preventDuplicates: true,
        onHidden: () => {
          CallService.autoWrapInit();
          $state.transitionTo('call', $uiRouterGlobals.params, {
            reload: false,
            inherit: false,
            notify: true,
          });
          hangupWarningActive = false;
        },
      };
      // contact hangup
      if (conditions.isContactHangup) {
        ToastrService.makeToast({
          type: 'warning',
          message: 'The contact has disconnected.',
          opts: toastrHangupOptions,
        });
        hangupWarningActive = true;
      }
      // agent hangup
      if (conditions.isAgentHangup) {
        ToastrService.makeToast({
          type: 'warning',
          message: 'The call has been disconnected.',
          opts: toastrHangupOptions,
        });
        hangupWarningActive = true;
      }
    }

    if (conditions.isOnFailedCall) {
      if (conditions.isContactHangup) {
        const destinationState = (agentFSM.isOnOutboundAllocatedCallorDialling())
          ? AgentStates.OUTBOUND_WRAP : AgentStates.WRAP;
        calls.withActivityId(activityId())
          .hangup({ destinationAgentState: destinationState })
          .then((dta) => {
            if (dta.resultCode === 'success') {
              CallService.autoWrapInit();
            }
          });
        hangupWarningActive = true;
        return;
      }
    }

    if (conditions.isOnUnallocatedFailedCall) {
      if (conditions.isContactHangup) {
        const destinationState = (agentFSM.state() === 'onPhonePausedOnLine1UnallocManCallFailed')
          ? AgentStates.PAUSED : AgentStates.READY;
        calls.withActivityId(activityId())
          .hangup({ destinationAgentState: destinationState })
          .then((dta) => {
            $log.info('*** IPS Websocket ***: Hangup from Unallocated call failed');
          });
        hangupWarningActive = true;
        return;
      }
    }

    if (conditions.isCall) {
      if (!conditions.isOutboundCall) {
        // contact hangup
        if (conditions.isContactHangup) {
          $rootScope.$broadcast('event.notify', {
            action: 'info',
            tag: msg.op,
            message: 'The contact has disconnected.',
            disableHangupWarningActive: false,
          });
          PhoneService.TRANSIT(1, 'disable');
          hangupWarningActive = true;
          return;
        }
        // agent hangup
        if (conditions.isAgentHangup) {
          $rootScope.$broadcast('event.notify', {
            action: 'info',
            tag: msg.op,
            message: 'The call has been disconnected.',
            disableHangupWarningActive: true,
          });
          hangupWarningActive = true;
        }
      } else {
        $rootScope.$broadcast('event.notify', {
          action: 'info',
          tag: 'call.hangup',
          message: 'The call has been disconnected.',
          disableHangupWarningActive: true,
        });
        hangupWarningActive = true;
      }
    }
  };

  const unhandledMsgOp = (msg) => {
    if (msg.msgType) {
      $log.info(`[MHF]: missed option: type: ${msg.msgType} op: ${msg.op}`);
    } else {
      $log.info(`[MHF]: bogus message: ${debug.inspect(msg, true, null)}`);
    }
    $log.info(`[MHF]: received an unexpected response from the server - op= ${msg.op} , opId= ${msg.opId}`);
  };

  const syncStateHandler = (msg) => {
    $injector.get('SyncStateService').handleSyncState(msg);
  };

  const loginHandler = (msg) => {
    if (msg.resultCode === 'success') {
      WebsocketStatus.status = 'connected';
      syncStateHandler(msg);

      const ts = (_.hasIn(msg.result, 'agentInfo'))
        ? new Date(msg.result.agentInfo.agentStatusInfo.currentStateDt) : new Date();

      $rootScope.$broadcast(`event.${msg.op}`, {
        action: `user.${msg.op}`,
        timestamp: ts.toUTCString(),
      });
    }
  };

  const pingHandler = (msg) => {
    pingTimer.end = Date.now();
    pingTimer.start = msg.opId;
    pingTimer.duration = Math.floor(pingTimer.end - msg.opId);
    if (pingTimer.duration > 299) {
      console.warn(`[MHF]: Socket latency: ${JSON.stringify(msg)}`);
      ErrorService.warn('Socket latency warning', {
        host: IntegrationService.apiHostWS,
        duration: pingTimer.duration,
      });
    }
    msg.timing = pingTimer;
    pingTimer = {};
  };

  const autoLogoutEventHandler = (msg) => {
    console.groupCollapsed('*** Socket Autologout ***');
    console.log('SST received: %s', msg.data.sst);
    console.log('storage SST: %s', authSST());
    console.groupEnd();
    if (msg.data.sst !== authSST()) {
      $log.info('[MHF] Autologout Closing session.');
      $injector.get('SocketFactory').disconnect();
      $injector.get('AuthService').clearState();
      $state.transitionTo('login', $uiRouterGlobals.params, {
        reload: false,
        inherit: false,
        notify: true,
      });
      $log.info('[MHF] Autologout finished session logout.');
    }
  };

  const autoPauseEventHandler = (msg) => {
    agentFSM.recreate('onPhoneAvailable');
    agentFSM.pause(msg);
    StatusService.pauseReasonTitle = 'Auto paused';

    $rootScope.$broadcast('event.notify', {
      action: 'warning',
      tag: msg.op,
      message: 'You have been placed into an Auto Pause status because your phone line cannot be reached.',
      options: { sticky: true },
    });
  };

  const autoUnpauseEventHandler = (msg) => {
    agentFSM.recreate('onPhonePaused');
    agentFSM.unpause(msg);
    StatusService.pauseReasonTitle = null;

    $state.transitionTo('toolbar', $uiRouterGlobals.params, {
      reload: false,
      inherit: false,
      notify: true,
    });

    $rootScope.$broadcast('event.notify', {
      action: 'info',
      tag: msg.op,
      message: 'You were made ready.',
    });
  };

  const callFailedEventHandler = (msg) => {
    agentFSM.pickupFailed(msg);
  };

  const agentHangupEventHandler = (msg) => {
    hangupIfPossible(msg, 'Agent');
  };

  const agentPickupEventHandler = (msg) => {
    if (agentFSM.isOnPhoneAvailable() || agentFSM.isOnPhonePaused()) {
      if (!msg.data) ErrorService.report('[MHF] Missing data package [agentPickupEventHandler]', msg);
      // Reset Salesforce related objects
      CallService.resetRelatedObjects();

      agentFSM.inboundCallAllocated(msg);
      $rootScope.$broadcast('event.system', {
        action: 'call.agent.pickup',
        msg,
      });
    }
  };

  const agentTransferPickupEventHandler = (msg) => {
    if (agentFSM.isOnPhoneAvailable() || agentFSM.isOnPhonePaused()) {
      if (!msg.data) ErrorService.report('[MHF] Missing data package [agentTransferPickupEventHandler]', msg);
      // Reset Salesforce related objects
      CallService.resetRelatedObjects();
      agentFSM.inboundCallAllocated(msg);
      $rootScope.$broadcast('event.system', {
        action: 'call.transfer.pickup',
        msg,
      });
    }
  };

  const agentVoiceLoginEventHandler = (msg) => {
    AgentVoiceStatus.status = 'connected';
    $rootScope.$broadcast('event.system', {
      action: 'agent.voice.connect',
      msg,
    });
    ToastrService.makeToast({
      type: 'info',
      message: 'Voice connection established',
    });
  };

  const agentVoiceLogoutEventHandler = (msg) => {
    AgentVoiceStatus.status = 'disconnected';
    $rootScope.$broadcast('event.system', {
      action: 'agent.voice.disconnected',
      msg,
    });
  };

  const contactHangupEventHandler = (msg) => {
    hangupIfPossible(msg, 'Contact');
  };

  const contactPickupEventHandler = (msg) => {
    if (agentFSM.isOnOutboundCallDialling()) {
      agentFSM.outboundCallPickup(msg);
      $rootScope.$broadcast('event.system', {
        action: 'call.contact.pickup',
        msg,
      });
      return;
    }

    if (_.hasIn(msg, 'data')) {
      const canRecord = (_.hasIn(msg.data, 'canRecord')) ? msg.data.canRecord : false;
      const recordingStatus = (_.hasIn(msg.data, 'recordingStatus'))
        ? msg.data.recordingStatus === 1 : msg.data.recording;
      CallService.setCallRecording(canRecord, recordingStatus);
    }
    agentFSM.manualCallPickup(msg);
  };

  const agentStatusChangeHandler = (msg) => {
    $log.info(`[MHF] AgentStatusChange: ${JSON.stringify(msg)}`);
  };

  const agentReleaseCallHandler = (msg) => {
    if (CallService.callInfo) {
      CallService.setCallEndedDate(msg);
    }
  };

  const transferCallHangupHandler = (msg) => {
    if (agentFSM.isOnPhoneWrapping()) {
      return;
    }

    if (conferenceCall()) {
      if (!attended()) {
        ToastrService.makeToast({
          type: 'info',
          message: 'Conference member disconnected.',
          heading: 'Line Disconnected',
          opts: {
            closeButton: true,
            timeout: 5000,
          },
        });

        // Cancel the conference call state
        PhoneService.CONFERENCE('stop');

        return;
      }
    }

    if (lineOne() === PhoneService.states.disabled) {
      PhoneService.TRANSIT(2, 'disable');
      PhoneService.ATTENDED('stop');
      agentFSM.hangup();
    } else if (attended() && (lineOne() === PhoneService.states.active)) {
      calls.withActivityId(activityId()).retrieveCall()
        .then((data) => {
          PhoneService.ATTENDED('stop');
        });
    }

    // Disable transfer actions
    PhoneService.transferActionsDisabled = !agentFSM.isOnCall();

    // Display toastr message
    ToastrService.makeToast({
      type: 'info',
      heading: 'Line Disconnected Remotely',
      opts: {
        closeButton: true,
        timeout: 5000,
      },
    });
  };

  const outboundPickupEventHandler = (msg) => {
    if (!_.hasIn(msg, 'data')) ErrorService.report('[MHF] Missing data package [outboundPickupEventHandler]', msg);
    if (agentFSM.isOnPhoneAvailable() || agentFSM.isOnPhonePaused()) {
      // Reset Salesforce related objects
      CallService.resetRelatedObjects();
      agentFSM.outboundPreview(msg);
      $rootScope.$broadcast('event.system', {
        action: 'call.outbound.preview',
        msg,
      });
    }
  };

  const announcementEndHandler = (msg) => {
    $rootScope.$broadcast('event.system', {
      action: 'announcement.end',
      msg,
    });
  };

  const attendedWorkflowHangupHandler = (msg) => {
    PhoneService.cancelTransferStateToActiveLine1Call();
    ToastrService.makeToast({
      type: 'info',
      message: 'Workflow ended.',
    });
    $rootScope.$broadcast('event.system', {
      action: 'workflow.end',
      msg,
    });
  };

  const ssoRefreshTokenHandler = () => {
    if (AuthService.ssoProvider) {
      if (_.hasIn(window.IPSCAPE, 'adalConfig')) {
        try {
          const { isAuthenticated } = AuthService;
          if (isAuthenticated) {
            AuthService.thirdPartyLogin().then((response) => {
              $log.info('[MHF] SSO Login', response);
            });
          }
        } catch (error) {
          Error(error);
        }
      }
    }
  };

  const disableHangupWarningActive = () => {
    hangupWarningActive = false;
  };

  const messageHandler = (msg) => {
    // Send to the Message Service
    if (!_.isNil(PaymentsService.uuid) && ($window.name === PaymentsService.uuid)) {
      bc.postMessage({
        socket: msg,
      });
    }

    $log.info(`[MHF] Message received: ${msg.op}`);

    if (msg.resultCode === 'error') {
      // Log event to Rollbar
      ErrorService.report('[MHF] Socket Error',
        {
          op: msg.op,
          opId: msg.opId,
          host: IntegrationService.apiHostWS,
          currentState: stateName(),
          details: AgentInfoService.details,
          result: msg.result,
        });

      if (_.hasIn(msg.result, 'name') && msg.result.name === 'login.invalidSession') {
        // Invalid session call Auth.Logout
        AuthService.logOut();
      }
    }

    switch (msg.op) {
      case 'login':
      case 'sync': {
        loginHandler(msg);
        break;
      }
      case 'ping': {
        pingHandler(msg);
        break;
      }
      case 'status': {
        syncStateHandler(msg);
        break;
      }
      case 'agent.auto.logout': {
        autoLogoutEventHandler(msg);
        break;
      }
      case 'agent.auto.pause': {
        autoPauseEventHandler(msg);
        break;
      }
      case 'agent.auto.unpause': {
        autoUnpauseEventHandler(msg);
        break;
      }
      case 'agent.call.failed':
      case 'contact.call.failed': {
        callFailedEventHandler(msg);
        break;
      }
      case 'agent.call.hangup': {
        agentHangupEventHandler(msg);
        break;
      }
      case 'contact.call.hangup': {
        contactHangupEventHandler(msg);
        break;
      }
      case 'agent.pickup':
      case 'outbound.predictive.allocated': {
        agentPickupEventHandler(msg);
        break;
      }
      case 'agent.transfer.pickup': {
        agentTransferPickupEventHandler(msg);
        break;
      }
      case 'agent.voice.login': {
        agentVoiceLoginEventHandler(msg);
        break;
      }
      case 'agent.voice.logout': {
        agentVoiceLogoutEventHandler(msg);
        break;
      }
      case 'contact.pickup': {
        contactPickupEventHandler(msg);
        break;
      }
      case 'agent.request.status': {
        agentStatusChangeHandler(msg);
        break;
      }
      case 'agent.transfer.call':
      case 'agent.release.call': {
        agentReleaseCallHandler(msg);
        break;
      }
      case 'outbound.preview.allocated': {
        outboundPickupEventHandler(msg);
        break;
      }
      case 'transfer.call.hangup': {
        transferCallHangupHandler(msg);
        break;
      }
      case 'announcement.end': {
        announcementEndHandler(msg);
        break;
      }
      case 'attended.workflow.hangup': {
        attendedWorkflowHangupHandler(msg);
        break;
      }
      case 'agent.scheduled.logout': {
        ssoRefreshTokenHandler();
        break;
      }
      case 'closed': {
        $log.warn('[MHF] Socket closed', msg);
        break;
      }
      default: {
        unhandledMsgOp(msg);
        break;
      }
    }
  };

  $rootScope.$on('message.received', (evt, data) => {
    if (_.hasIn(data, 'socket')) messageHandler(data.socket);
    if (_.hasIn(data, 'event')) {
      if (data.event) {
        const dta = data.event;
        if (dta.action === 'unload') {
          $injector.get('SocketFactory').reconnect();
        }
      }
    }
  });

  return {
    unhandledMsgOp,
    syncStateHandler,
    loginHandler,
    pingHandler,
    autoLogoutEventHandler,
    autoPauseEventHandler,
    autoUnpauseEventHandler,
    callFailedEventHandler,
    agentHangupEventHandler,
    agentPickupEventHandler,
    agentTransferPickupEventHandler,
    agentVoiceLoginEventHandler,
    agentVoiceLogoutEventHandler,
    contactHangupEventHandler,
    contactPickupEventHandler,
    agentStatusChangeHandler,
    agentReleaseCallHandler,
    transferCallHangupHandler,
    outboundPickupEventHandler,
    announcementEndHandler,
    attendedWorkflowHangupHandler,
    disableHangupWarningActive,
    messageHandler,
  };
};

export default angular.module('CCAdaptor.App.SocketMessageFactory', [])
  .factory('MessageHandlerFactory', MessageHandler).name;
