import angular from 'angular';
import _ from 'lodash';

class StatusService {
  constructor(storagemanager, $window) {
    'ngInject';

    this.manager = storagemanager;
    this.$window = $window;

    this.locals = {};
    this.watchedAttributes = [];
    this.manager.watchers()
      .withObj(this.locals)
      .withId('status.service')
      .buildWithAttributes((a) => {
        this.watchedAttributes.push(
          a.withObjectKey('status')
            .withDefault({ label: 'paused', id: 0 }).build(),
        );
        this.watchedAttributes.push(
          a.withStringKey('pauseReasonTitle').build(),
        );
        this.watchedAttributes.push(
          a.withStringKey('currentStateDt')
            .withDefault(new Date().getTime().toString()).build(),
        );
      });
  }

  clear() {
    this.pauseReasonTitle = null;
  }

  get status() {
    return this.locals.status;
  }

  set status(obj) {
    this.locals.status = obj;
  }

  get pauseReasonTitle() {
    return this.locals.pauseReasonTitle;
  }

  get hasPauseReason() {
    const pauseTitle = _.get(this.locals, 'pauseReasonTitle');
    if (pauseTitle === 'Pause') {
      return false;
    }

    return _.get(this.locals, 'pauseReasonTitle.length', 0) !== 0;
  }

  set pauseReasonTitle(reasonTitle) {
    this.locals.pauseReasonTitle = reasonTitle;
  }

  get currentStateDt() {
    return this.locals.currentStateDt;
  }

  set currentStateDt(date) {
    this.locals.currentStateDt = date;
  }
}

export default angular.module('CCAdaptor.App.StatusService', [])
  .service('StatusService', StatusService).name;
