import angular from 'angular';
import _ from 'lodash';

const integrationFactory = ($injector, $q, $rootScope, IntegrationService,
  PhoneService, CallService, CampaignService, AnalyticsService) => {
  'ngInject';

  AnalyticsService.init();

  function setIntegration() {
    if (IntegrationService.connect) $injector.get('ApiIntegration').init();
    return new Promise((resolve, reject) => {
      const isValidPath = sessionStorage.getItem('isValidPath') === 'true';

      if (!isValidPath) {
        resolve($injector.get('emptyIntegration'));
      }
      switch (IntegrationService.type) {
        case 'sf':
          if (IntegrationService.version) {
            if (IntegrationService.version === 'lightning') {
              resolve($injector.get('salesforceLightning'));
            }
          } else {
            resolve($injector.get('salesforceClassic'));
          }
          break;
        case 'sn':
          resolve($injector.get('servicenowIntegration'));
          break;
        case 'zd':
          resolve($injector.get('zendeskIntegration'));
          break;
        default:
          resolve($injector.get('emptyIntegration'));
      }
    });
  }

  const setCallSettings = (data) => {
    const canRecord = (_.hasIn(data, 'canRecord')) ? data.canRecord : false;
    const recordingStatus = (_.hasIn(data, 'recordingStatus'))
      ? data.recordingStatus === 1 : data.recording;
    CallService.setCallRecording(canRecord, recordingStatus);
  };

  return {
    init: (config) => {
      IntegrationService.checkDependency();
      setIntegration().then((integration) => {
        integration.init(config);
      });
    },
    onEnterAvailable: (msg) => {
      setIntegration().then((integration) => {
        integration.onEnterAvailable(msg);
      });
    },
    onEnterPaused: (msg) => {
      setIntegration().then((integration) => {
        integration.onEnterPaused(msg);
      });
    },
    onInboundCall: (msg) => {
      CampaignService.createCampaignObject(msg.data);
      CallService.callType = msg.data.callType;
      setCallSettings(msg.data);
      PhoneService.TRANSIT(1, 'activate');
      setIntegration().then((integration) => {
        integration.onInboundCall(msg);
      });
    },
    onOutboundCall: (msg) => {
      CallService.callType = 'Outbound';
      setCallSettings(msg.data);
      PhoneService.TRANSIT(1, 'activate');
      setIntegration().then((integration) => {
        integration.onOutboundCall(msg);
      });
    },
    onOutboundPreview: (msg) => {
      PhoneService.TRANSIT(1, 'activate');
      setIntegration().then((integration) => {
        integration.onOutboundPreview(msg);
      });
    },
    onLeaveAvailable: (msg) => {
      setIntegration().then((integration) => {
        integration.onLeaveAvailable(msg);
      });
    },
    onLeavePaused: (msg) => {
      setIntegration().then((integration) => {
        integration.onLeavePaused(msg);
      });
    },
    onManCall: (msg) => {
      setCallSettings(msg);
      CallService.callType = 'Outbound';
      CampaignService.createCampaignObject(msg);
      setIntegration().then((integration) => {
        integration.onManCall(msg);
      });
    },
    onHangup: (msg) => {
      setIntegration().then((integration) => {
        integration.onHangup(msg);
      });
    },
    onBeforeOnWrap: (msg) => {
      return new Promise((resolve, reject) => {
        setIntegration().then((integration) => {
          integration.onBeforeOnWrap(msg).then((response) => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        });
      });
    },
    onWrap: (msg) => {
      setIntegration().then((integration) => {
        integration.onWrap(msg);
      });
    },
  };
};

export default angular.module('CCAdaptor.App.Integration', [])
  .factory('integrationFactory', integrationFactory).name;
