import angular from 'angular';
import _ from 'lodash';

const chance = require('chance')();

// ---------------------------------------
// Sync State Service
// ---------------------------------------

class SyncStateService {
  constructor($log, $rootScope, $state, $uiRouterGlobals, $q, AgentVoiceStatus, $injector, $window,
    agentFSM, SocketFactory, calls, CallService, PhoneService, AgentInfoService, TaskService,
    integrationFactory, StateTranslateService, StatusService, AuthService) {
    'ngInject';

    this.$log = $log;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$window = $window;
    this.$uiRouterGlobals = $uiRouterGlobals;
    this.$q = $q;
    this.agentFSM = agentFSM;
    this.SocketFactory = SocketFactory;
    this.AuthService = AuthService;
    this.CallService = CallService;
    this.calls = calls;
    this.StateTranslateService = StateTranslateService;
    this.hidden = undefined;
    this.visibilityChange = undefined;

    this.updateAgentVoiceStatus = (bool) => {
      if (!_.isNil(bool)) {
        AgentVoiceStatus.status = (bool) ? 'connected' : 'disconnected';
      }
    };

    this.updateAnnouncementStatus = (status) => {
      if (!_.isNil(status)) {
        CallService.resetAnnouncementStatus(status);
      }
    };

    this.updateOutboundData = (data) => {
      if (!_.isNil(data)) {
        CallService.outboundInfo = data;
      }
    };

    this.updateCurrentCallData = (data) => {
      if (!_.isNil(data)) {
        data.agentFullName = AgentInfoService.agentFullName;
        CallService.callInfo = data;

        const canRecord = (_.hasIn(data, 'canRecord')) ? data.canRecord : false;
        const recordingStatus = (_.hasIn(data, 'recordingStatus'))
          ? data.recordingStatus === 1 : data.recording;
        CallService.setCallRecording(canRecord, recordingStatus);

        const currentCallData = {
          data,
        };
        if (!agentFSM.isOnPhoneWrapping() && !PhoneService.attendedTransfer) {
          // check for a taskId so we don't create multiple tasks
          if (_.isNil(TaskService.taskId)
            && (PhoneService.lineStateOne === PhoneService.states.disabled)) {
            if (data.callType === 'Transfer' || data.callType === 'Inbound') {
              integrationFactory.onInboundCall(currentCallData);
            }
          }
        }
      }
    };

    this.syncApiStateToAgentState = (state, translated) => {
      StatusService.pauseReasonTitle = state.currentPauseReason;
      if (translated === 'onOutboundPreview') {
        switch (state.agentStatus) {
          case 'Talk':
            agentFSM.recreate('onPhoneLine1OutboundCall');
            break;
          case 'Wrap':
            agentFSM.recreate('onOutboundWrapping');
            break;
          case 'Hold':
            agentFSM.recreate('onPhoneLine1OutboundHold');
            break;
          case 'Dialling':
            agentFSM.recreate('onPhoneLine1OutboundCallDialling');
            break;
          default:
            agentFSM.recreate('onOutboundPreview');
            break;
        }
      } else {
        agentFSM.recreate(translated);
      }
    };

    this.syncService = (storageKey, serviceName, serviceParam, paramType, defaultValue) => {
      let storageValue;
      return new Promise((resolve, reject) => {
        if (localStorage.getItem(storageKey)) {
          switch (paramType) {
            case 'boolean': {
              storageValue = localStorage.getItem(storageKey) === 'true';
              break;
            }
            case 'object': {
              storageValue = JSON.parse(localStorage.getItem(storageKey));
              break;
            }
            case 'int': {
              storageValue = parseInt(localStorage.getItem(storageKey), 10);
              break;
            }
            case 'string':
            default: {
              storageValue = localStorage.getItem(storageKey);
              break;
            }
          }
        } else {
          storageValue = defaultValue;
        }

        try {
          const service = $injector.get(serviceName);
          // Start syncing service and apply
          // console.group('[SYNC]', `(${serviceName}.${serviceParam})`);
          // console.log('key: ', storageKey);
          // console.log('value: ', (typeof storageValue === 'object') ? JSON.stringify(storageValue) : storageValue);
          // console.groupEnd();

          // Apply
          $rootScope.$apply(() => {
            service[serviceParam] = storageValue;
          });

          resolve();
        } catch (error) {
          reject(error);
        }
      });
    };
  }

  getSyncState(data) {
    if (data.details.currentAgentStatus === 'AutoLogout') {
      this.AuthService.logout();
    } else {
      this.SocketFactory.currentAgentState();
    }
  }

  handleSyncState(msg) {
    if (_.hasIn(msg, 'resultCode')) {
      if (msg.resultCode === 'success') {
        if (msg.result) {
          const { result } = msg;
          const agentInfo = (_.hasIn(result, 'agentInfo')) ? result.agentInfo : null;
          const state = {
            agentState: this.agentFSM.state(),
            currentCall: (_.hasIn(agentInfo, 'currentCallData')) ? agentInfo.currentCallData : null,
            apiState: (_.hasIn(agentInfo, 'agentStatusInfo')) ? agentInfo.agentStatusInfo : null,
            previewLeadData: (_.hasIn(agentInfo, 'previewLeadData'))
              ? agentInfo.previewLeadData : null,
          };

          const translatedAgentStatus = this.StateTranslateService.getAdapterTargetState(
            state.apiState.agentStatus,
            state.currentCall,
            state.previewLeadData,
          );

          // Update Voice status
          if (state.apiState.agentStatus !== 'Login') {
            this.updateAgentVoiceStatus(state.apiState.voiceConnected);
          }

          // Update the announcement status
          this.updateAnnouncementStatus(state.apiState.announcementPlaying);

          // Update Outbound Data
          this.updateOutboundData(state.previewLeadData);

          if (translatedAgentStatus) {
            // If the APIstate is onManualPreview we need to cancel this and return
            // the application state back to the previous state (previousAgentState)
            if (translatedAgentStatus === 'onManualPreview') {
              const previousState = localStorage.getItem('agent.fsm.previousAgentState');
              // cancel Manual Preview and return to previous state
              this.calls.manualPreview(false, (response) => {
                if (response.resultCode === 'success') {
                  this.$log.info('Manual preview: cancelled');
                  if (previousState === 'onPhonePaused') {
                    this.agentFSM.pause();
                  } else if (previousState === 'onPhoneAvailable') {
                    this.agentFSM.unpause();
                  }
                }
              });
            }

            // If the adaptor is refreshing and misses the inbound call handler (SF)
            // it will not enable line one and the aux buttons will be disabled
            if (state.currentCall) {
              this.updateCurrentCallData(state.currentCall);
            }

            // If the APIstate does not match the front-end state we clear eveything
            // and reset the front-end
            if (translatedAgentStatus !== state.agentState) {
              this.syncApiStateToAgentState(state.apiState, translatedAgentStatus);
            }

            // If the agent API state is logged out, clear the Auth params and go to login
            if (this.StateTranslateService.isLoggedOutState(state.apiState.agentStatus)) {
              this.AuthService.clearState();
              this.$state.transitionTo('login', this.$uiRouterGlobals.params, {
                reload: false,
                inherit: false,
                notify: true,
              });
            }

            this.$state.transitionTo(this.$state.current, this.$uiRouterGlobals.params, {
              reload: true,
              inherit: false,
              notify: true,
            });
          }
        }
      }
    }
  }

  syncState() {
    const servicesToSync = [{
      name: 'AuthService',
      key: 'auth',
      params: [{
        key: 'loggedIn',
        property: 'loggedIn',
        type: 'boolean',
        default: false,
      }, {
        key: 'timestamp',
        property: 'timestamp',
        type: 'string',
        default: '',
      }, {
        key: 'sst',
        property: 'sst',
        type: 'string',
        default: chance.guid(),
      }, {
        key: 'sessionToken',
        property: 'sessionToken',
        type: 'string',
        default: null,
      }, {
        key: 'ssoProvider',
        property: 'ssoProvider',
        type: 'string',
        default: null,
      }],
    }, {
      name: 'AgentVoiceStatus',
      key: 'agent',
      params: [{
        key: 'voice',
        property: 'status',
        type: 'string',
        default: '',
      }],
    }, {
      name: 'AgentInfoService',
      key: 'agentinfo',
      params: [{
        key: 'details',
        property: 'details',
        type: 'object',
        default: {},
      }, {
        key: 'integration',
        property: 'integration',
        type: 'object',
        default: {},
      }, {
        key: 'organisation',
        property: 'organisationDetails',
        type: 'object',
        default: {},
      }, {
        key: 'pauseReasons',
        property: 'pauseReasons',
        type: 'object',
        default: [],
      }],
    }, {
      name: 'AnalyticsService',
      key: 'analytics',
      params: [{
        key: 'state',
        property: 'state',
        type: 'string',
        default: '',
      }],
    }, {
      name: 'FeatureFlagService',
      key: 'flags',
      params: [{
        key: 'enabled',
        property: 'enabledFlags',
        type: 'object',
        default: {},
      }],
    }, {
      name: 'StatusService',
      key: 'status.service',
      params: [{
        key: 'status',
        property: 'status',
        type: 'object',
        default: { label: 'paused', id: 0 },
      }, {
        key: 'pauseReasonTitle',
        property: 'pauseReasonTitle',
        type: 'string',
        default: '',
      }, {
        key: 'currentStateDt',
        property: 'currentStateDt',
        type: 'string',
        default: new Date().getTime().toString(),
      }],
    }, {
      name: 'CallService',
      key: 'CallService',
      params: [{
        key: 'callInfo',
        property: 'callInfo',
        type: 'object',
        default: {},
      }, {
        key: 'outboundInfo',
        property: 'outboundInfo',
        type: 'object',
        default: {},
      }, {
        key: 'callType',
        property: 'callType',
        type: 'string',
        default: null,
      }, {
        key: 'callNotes',
        property: 'callNotes',
        type: 'string',
        default: null,
      }, {
        key: 'callQuality',
        property: 'callQuality',
        type: 'int',
        default: 5,
      }, {
        key: 'callRecording',
        property: 'callRecording',
        type: 'boolean',
        default: false,
      }, {
        key: 'recordingStart',
        property: 'recordingStart',
        type: 'string',
        default: null,
      }, {
        key: 'controlRecording',
        property: 'controlCallRecording',
        type: 'boolean',
        default: false,
      }, {
        key: 'contacts',
        property: 'contacts',
        type: 'object',
        default: [],
      }, {
        key: 'contact',
        property: 'selectedContact',
        type: 'object',
        default: {},
      }, {
        key: 'items',
        property: 'items',
        type: 'object',
        default: [],
      }, {
        key: 'item',
        property: 'selectedItem',
        type: 'object',
        default: {},
      }, {
        key: 'leads',
        property: 'leadNumbers',
        type: 'object',
        default: [],
      }, {
        key: 'autowrap',
        property: 'autowrap',
        type: 'boolean',
        default: false,
      }, {
        key: 'autowrapWrapCode',
        property: 'autowrapWrapCode',
        type: 'string',
        default: null,
      }, {
        key: 'autowrapExtension',
        property: 'autowrapExtension',
        type: 'int',
        default: null,
      }, {
        key: 'autowrapDuration',
        property: 'autowrapDuration',
        type: 'int',
        default: null,
      }, {
        key: 'autoPreview',
        property: 'autoPreview',
        type: 'string',
        default: null,
      }, {
        key: 'campaignId',
        property: 'campaignId',
        type: 'int',
        default: null,
      }, {
        key: 'outcomes',
        property: 'outcomes',
        type: 'object',
        default: [],
      }, {
        key: 'selectedOutcome',
        property: 'selectedOutcome',
        type: 'object',
        default: null,
      }, {
        key: 'previewCalled',
        property: 'previewCalled',
        type: 'boolean',
        default: false,
      }, {
        key: 'callback',
        property: 'callback',
        type: 'object',
        default: {},
      }, {
        key: 'announcement',
        property: 'announcement',
        type: 'object',
        default: {
          title: 'Play Announcement',
          playing: false,
          selected: null,
        },
      }, {
        key: 'announcementList',
        property: 'announcementList',
        type: 'object',
        default: [],
      }],
    }, {
      name: 'PhoneService',
      key: 'phone',
      params: [{
        key: 'lineStateOne',
        property: 'lineStateOne',
        type: 'string',
        default: 'disabled',
      }, {
        key: 'lineStateTwo',
        property: 'lineStateTwo',
        type: 'string',
        default: 'disabled',
      }, {
        key: 'activeLine',
        property: 'activeLine',
        type: 'int',
        default: 1,
      }, {
        key: 'attendedTransfer',
        property: 'attendedTransfer',
        type: 'boolean',
        default: false,
      }, {
        key: 'conferenceCall',
        property: 'conferenceCall',
        type: 'boolean',
        default: false,
      }, {
        key: 'transferActionsDisabled',
        property: 'transferActionsDisabled',
        type: 'boolean',
        default: false,
      }, {
        key: 'allowTransferInvalidPhone',
        property: 'allowTransferInvalidPhone',
        type: 'boolean',
        default: false,
      }],
    }, {
      name: 'CampaignService',
      key: 'campaign.service',
      params: [{
        key: 'allocated',
        property: 'allocatedCampaigns',
        type: 'object',
        default: [],
      }, {
        key: 'selected',
        property: 'selectedCampaign',
        type: 'object',
        default: null,
      }],
    }, {
      name: 'SessionService',
      key: 'SessionService',
      params: [{
        key: 'config',
        property: 'config',
        type: 'object',
        default: {},
      }],
    }, {
      name: 'TaskService',
      key: 'task',
      params: [{
        key: 'openTaskOnWrap',
        property: 'openTaskOnWrap',
        type: 'boolean',
        default: false,
      }, {
        key: 'task_id',
        property: 'taskId',
        type: 'string',
        default: null,
      }, {
        key: 'task_params',
        property: 'taskParams',
        type: 'object',
        default: [],
      }, {
        key: 'last_caller',
        property: 'lastCaller',
        type: 'object',
        default: {},
      }],
    }];

    servicesToSync.forEach((service) => {
      const serviceName = service.name;
      const serviceKey = service.key;
      service.params.forEach((item) => {
        try {
          this.syncService(
            `${serviceKey}.${item.key}`,
            serviceName,
            item.property,
            item.type,
            item.default,
          );
        } catch (error) {
          this.$log.warn('Sync Error:', error);
        }
      });
    });

    if (this.AuthService.isLoggedIn()) {
      this.SocketFactory.reconnect();
    }

    // Broadcast event so directives can perform updates on internal scope
    this.$rootScope.$broadcast('event.system', { action: 'app.sync' });
  }
}

export default angular.module('CCAdaptor.App.SyncStateService', [])
  .service('SyncStateService', SyncStateService).name;
