import angular from 'angular';

const ApiStatusIndicatorTemplate = require('./../ApiStatusIndicator/ApiStatusIndicator.html');

class ApiStatusIndicatorController {
  constructor($scope, $state, WebsocketStatus) {
    'ngInject';

    $scope.agentConnectedApiStatus = WebsocketStatus.status;

    $scope.$watch(() => { return WebsocketStatus.status; }, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        $scope.agentConnectedApiStatus = newVal;
      }
    });

    $scope.$on('$viewContentLoading', (event, viewConfig) => {
      if ($state.current.name === 'login') {
        $scope.agentConnectedApiStatus = 'disconnected';
      }
    });
  }
}

const ApiStatusIndicator = () => {
  'ngInject';

  const directive = {
    restrict: 'E',
    template: ApiStatusIndicatorTemplate,
    scope: {
      Agentconnectedapistatus: '=',
    },
    controller: ApiStatusIndicatorController,
  };
  return directive;
};

export default angular.module('CCAdaptor.App.IPSApiStatusIndicator', [])
  .directive('ipsApiStatusIndicator', ApiStatusIndicator).name;
