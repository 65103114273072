// integration/common/mapData.js
import _ from 'lodash';
import { formatDate, numDigits } from './utilities';
import searchData from './searchData';

export default function mapData({ map = {}, data = {}, timezone = 'Australia/Sydney' }) {
  return new Promise((resolve, reject) => {
    const mapped = [];
    _.forEach(map, (value, key) => {
      // check for string values
      if (value.startsWith("'")) {
        const val = value.replace(/'/g, '');
        mapped.push({ [key]: val });
      } else {
        let found = searchData(data, value);
        if (found) {
          // Check if unix time stamp and add format
          if (typeof found === 'number' && numDigits(found) >= 13) {
            found = formatDate({
              dte: found,
              timezone,
              pattern: 'YYYY-MM-DDTHH:mm:ssZ',
            });
          }

          // Change number types to string
          if (typeof found === 'number') {
            found = found.toString();
          }
          mapped.push({ [key]: found });
        }
      }
    });

    if (mapped.length === 0) reject();

    resolve(mapped);
  });
}
