import angular from 'angular';

const vrTemplate = require('./voiceRecording.directive.html');

const voiceRecording = ($log) => {
  'ngInject';

  class VoiceRecordingController {
    constructor($scope, $window, AuthService, IntegrationService) {
      'ngInject';

      $scope.errorMsg = false;
      const resizeViewPort = (width, height) => {
        if (window.outerWidth) {
          window.resizeTo(
            width + (window.outerWidth - window.innerWidth),
            height + (window.outerHeight - window.innerHeight),
          );
        } else {
          window.resizeTo(500, 500);
          window.resizeTo(
            width + (500 - document.body.offsetWidth),
            height + (500 - document.body.offsetHeight),
          );
        }
      };

      // resize the viewport to the desired dimensions
      resizeViewPort(765, 520);

      if ($scope.activityId) {
        let targetHref = `${IntegrationService.apiHost}/workspace/xws_callRecording?activityId=${$scope.activityId}`;
        if (AuthService.isLoggedIn()) {
          targetHref += `&token=${AuthService.sessionToken}`;
        }
        // Redirect to API host
        $window.location.href = targetHref;
      } else {
        $scope.errorMsg = true;
      }
    }
  }

  return {
    restrict: 'E',
    template: vrTemplate,
    controller: VoiceRecordingController,
    controllerAs: 'VoiceRecording',
    scope: true,
    bindToController: {
      activityId: '=',
    },
  };
};

export default angular.module('CCAdaptor.App.VoiceRecording', [])
  .directive('voiceRecording', voiceRecording).name;
