import angular from 'angular';
import _ from 'lodash';

const ErrorTemplate = require('./error.directive.html');

const Error = () => {
  'ngInject';

  class ErrorController {
    constructor($scope, $log, ErrorTranslateService, ToastrService, SocketFactory, $state,
      $uiRouterGlobals, IntegrationService, ErrorService) {
      'ngInject';

      $scope.handleEvent = (data, type) => {
        const translatedError = ErrorTranslateService.getTranslatedError(data);
        const titleStr = (!_.isNil(type)) ? type.toLowerCase().replace(/\b[a-z]/g, (letter) => {
          return letter.toUpperCase();
        }) : '';

        const errorTitle = titleStr || ErrorTranslateService.getErrorTitle(data);
        const notificationType = type || ErrorTranslateService.getNotificationType(data);
        let toastrOpts;

        if (data.disableHangupWarningActive) {
          toastrOpts = {
            timeOut: 300,
            onHidden: () => {
              SocketFactory.disableHangupWarningActive();
            },
          };
        } else {
          if (_.hasIn(data, 'options')) {
            if (data.options.sticky) {
              toastrOpts.timeOut = 0;
              toastrOpts.closeButton = true;
              toastrOpts.closeHtml = '<button>&times;</button>';
            }
          }
        }

        ToastrService.makeToast({
          type: notificationType,
          message: translatedError,
          heading: errorTitle,
          opts: toastrOpts,
        });

        if (_.hasIn(data, 'details')) {
          // unavailable agent transfer error handling
          _.forEach(data.details, (value) => {
            if (_.hasIn(value, 'description')) {
              if (value.description === 'agent.unavailable' && value.name === 'agent.transferBlind') {
                // reload route
                $state.transitionTo($state.current, $uiRouterGlobals.params, {
                  reload: true,
                  inherit: false,
                  notify: true,
                });
                return false;
              }
            }
            return true;
          });
        }
      };

      $scope.$on('event.notify', (event, data) => {
        if (data.action === 'error') {
          const detail = data.details[0];
          const msg = (!_.isNil(detail)) ? detail.description || detail.message : data.message;
          const uuid = data.interactionId;
          // Get copy of current localStorage
          const storage = () => {
            const archive = {};
            const keys = Object.keys(localStorage);
            let i = keys.length - 1;
            do {
              archive[keys[i]] = localStorage.getItem(keys[i]);
              i -= 1;
            }
            while (i >= 0);

            return archive;
          };

          $log.error(event.name, JSON.stringify(data));
          if (IntegrationService.reporting) {
            ErrorService.report(msg, {
              details: data.details,
              response: data.response,
              localStorage: storage(),
              InteractionId: uuid,
            });
          }
          $scope.handleEvent(data, 'error');
        }
      });

      $scope.$on('event.notify', (event, data) => {
        if (data.action === 'info') {
          $log.info(`${event.name} : ${JSON.stringify(data)}`);
          $scope.handleEvent(data, 'info');
        }
      });

      $scope.$on('event.notify', (event, data) => {
        if (data.action === 'warning') {
          $log.warn(event.name, data);
          if (IntegrationService.reporting) {
            ErrorService.warn(data.tag, data);
          }
          $scope.handleEvent(data, 'warning');
        }
      });

      $scope.$on('event.notify', (event, data) => {
        if (data.action === 'success') {
          $log.info(`${event.name} : ${JSON.stringify(data)}`);
          $scope.handleEvent(data, 'success');
        }
      });
    }
  }

  const directive = {
    restrict: 'E',
    template: ErrorTemplate,
    scope: {
      // creationDate: '='
    },
    controller: ErrorController,
    controllerAs: 'vm',
    bindToController: true,
  };

  return directive;
};

export default angular.module('CCAdaptor.App.IPSError', [])
  .directive('ipsError', Error).name;
