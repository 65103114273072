import { Enum } from 'enumify';

// todo: (ldeck) consider internalising to agent.fsm
class AgentStates extends Enum {
  static props(stateName, label) {
    return {
      get state() { return stateName; },
      get label() { return label; },
    };
  }
}
/*
 * states are mapped to api state, and front end label.
 */
AgentStates.initEnum({
  OFF_PHONE: AgentStates.props('offPhone', 'Connection'),
  READY: AgentStates.props('Available', 'Ready'),
  PAUSED: AgentStates.props('Paused', 'Paused'),
  DIALLING: AgentStates.props('dialling', 'Dialing'),
  ON_CALL: AgentStates.props('onCall', 'On Call'),
  MANUAL_CALL: AgentStates.props('manualCall', 'On Call'),
  CALL_FAILED: AgentStates.props('callFailed', 'Call Failed'),
  HOLD: AgentStates.props('hold', 'On Hold'),
  WRAP: AgentStates.props('wrap', 'On Wrap'),
  MANUAL_PREVIEW: AgentStates.props('manualPreview', 'Preview'),
  OUTBOUND_PREVIEW: AgentStates.props('Preview', 'Preview'),
  OUTBOUND_CALL: AgentStates.props('onCall', 'On Call'),
  OUTBOUND_WRAP: AgentStates.props('wrap', 'On Wrap'),
});

export default AgentStates;
