// integration/common/mapData.js
import moment from 'moment-timezone';

const customFieldPrefix = 'ipSCAPE_';
const customFieldSuffix = '__c';

export function numDigits(x) {
  return Math.max(Math.floor(Math.log10(Math.abs(x))), 0) + 1;
}

export function formatDate({ dte = null, timezone = 'Australia/Sydney', pattern = 'YYYY-MM-DD' }) {
  return moment.tz(dte, timezone).format(pattern);
}

export function isJSON(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function makeParam(field, value) {
  return `${field}=${value}`;
}

export function makeCustomParam(field, value) {
  const labelStr = `${customFieldPrefix}${field}${customFieldSuffix}`;
  return { [labelStr]: value };
}

// Encode special characters for SF Activity log notes
export function encodeHTML(text) {
  // Replace &
  let str = text.replace(/[&]/g, '%26');

  // Replace ?
  str = str.replace(/[?]/g, match => encodeURIComponent(match));

  // Replace #
  str = str.replace('#', '%23');

  // Replace =
  str = str.replace('=', '%3D;');

  // Replace +
  str = str.replace('+', '%2B;');

  return str;
}
