import angular from 'angular';

const Phone = ($http, $httpParamSerializer, $q, $rootScope, $timeout,
  agentFSM, ErrorService, IntegrationService, AgentVoiceStatus) => {
  'ngInject';

  return {
    connect: (allowInvalidNumber, connectionMode, countryCode,
      isPersistentConnection, remotePhone) => {
      const dfd = $q.defer();
      AgentVoiceStatus.status = 'connecting';
      const endpoint = '/agent/loginphone';
      const isPersistentConnectionAsString = () => {
        return isPersistentConnection ? 'true' : 'false';
      };
      let parameters;
      if (connectionMode === 'agentCallsSystem') {
        parameters = {
          connectionType: connectionMode,
          countryCode,
        };
      } else if (connectionMode === 'ipscapeWebrtc') {
        parameters = {
          connectionType: connectionMode,
          isPersistentConnection: isPersistentConnectionAsString(),
        };
      } else {
        parameters = {
          allowInvalidPhoneNumber: (allowInvalidNumber) ? 1 : undefined,
          connectionType: connectionMode,
          countryCode,
          isPersistentConnection: isPersistentConnectionAsString(),
          remotePhone,
        };
      }

      $http.post(IntegrationService.latestApiUri(endpoint),
        $httpParamSerializer(parameters), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }).then((response) => {
        dfd.resolve(response.data);
      }).catch((response) => {
        if (response.data.errorCode !== 'phoneNumber.invalid') {
          ErrorService.handleError(endpoint, response);
        }
        dfd.reject(response);
      });

      return dfd.promise;
    },
    isHungUp: () => agentFSM.isOffPhone(),
  };
};

export default angular.module('CCAdaptor.App.Phone', []).factory('phone', Phone).name;
