import _ from 'lodash';

export default class TransferController {
  constructor($q, $log, $rootScope, $scope, $state, $transition$, CallService,
    agent, calls, agentFSM, ToastrService, PhoneService, CampaignService, util) {
    'ngInject';

    $scope.inDevMode = util.inDevMode();
    $scope.attemptingTransfer = false;
    $scope.optionsResolved = false;
    $scope.transferPoolPhoneShow = false;
    $scope.showOtherPhoneInput = false;
    $scope.invalidNumberPrompt = false;
    $scope.otherPhoneInput = '';
    $scope.transferType = null;
    $scope.transferResultPhone = [];
    $scope.transferResultAgent = [];
    $scope.transferResultWorkflow = [];
    $scope.transferTypeOptions = ['Phone Number'];
    $scope.setPreference = false;
    $scope.inConference = () => { return PhoneService.conferenceCall; };

    const conditionalFields = [
      'transferPhoneNumber',
      'transferPoolAgent',
      'transferPoolWorkflow',
      'transferPoolPhone',
    ];

    const campaignId = _.get(CallService, 'callInfo.campaignId');

    const initTransferValues = () => {
      $scope.transferPhoneNumber = null;
      $scope.transferPoolPhone = null;
      $scope.transferPoolWorkflow = null;
      $scope.transferPoolAgent = null;
    };

    const toggleShowOtherPhone = () => {
      $scope.showOtherPhoneInput = !$scope.showOtherPhoneInput;
    };

    initTransferValues();

    $scope.allowTransferToAnyPhone = CampaignService.allowTransferToAnyPhone();
    $scope.allowInvalidPhoneNumber = PhoneService.allowTransferInvalidPhone || false;

    const transfer = ({
      activityId = CallService.activityId,
      transferType = $scope.transferType,
      transferDestination = null,
      isAttended = 0,
      callNotes = CallService.callNotes,
      allowInvalidPhoneNumber = $scope.allowInvalidPhoneNumber,
    }) => {
      return new Promise((resolve, reject) => {
        calls.withActivityId(activityId).transfer(
          transferType,
          transferDestination,
          isAttended,
          allowInvalidPhoneNumber,
          callNotes,
        ).then((data) => {
          $rootScope.$broadcast('$viewContentLoading', { loading: false });
          $state.transitionTo('call', $transition$.params(), {
            reload: false,
            inherit: false,
            notify: true,
          });
          resolve(data);
        }).catch((response) => {
          if (_.hasIn(response, 'data')) {
            if (_.hasIn(response.data, 'errorCode')) {
              if (response.data.errorCode === 'phoneNumber.invalid') {
                // Show Prompt
                $scope.invalidNumberPrompt = true;
              } else {
                $scope.attemptingTransfer = false;
              }
            }
          }
          reject(response);
        });
      });
    };

    if (!_.isNil(campaignId)) {
      const promises = {
        loadTransferTarget: calls.withCampaignId(campaignId).loadTransferTarget(),
        allWorkflows: calls.allWorkflows(),
        allAgents: calls.allAgents(),
      };

      $q.all(promises).then((data, status, headers, config) => {
        if (data.loadTransferTarget.data.resultCode !== 'success') {
          $rootScope.$broadcast('event.notify', {
            action: 'error',
            tag: data.loadTransferTarget.errorCode,
            message: data.loadTransferTarget.errorDescription,
            details: data.loadTransferTarget.errorDetails,
          });

          return false;
        }
        data.loadTransferTarget.data.result = _.orderBy(data.loadTransferTarget.data.result, 'transferTargetDescription');

        if (!_.find(data.loadTransferTarget.data.result, { transferTargetType: 'Agent' })) {
          const buildAgentTarget = (agnt) => {
            return {
              availableForTransfer: agnt.availableForTransfer,
              transferTargetId: agnt.agentId,
              transferTargetType: 'Agent',
              transferTargetDescription: `${agnt.firstName} ${agnt.lastName}`,
            };
          };

          data.allAgents.data.result = _.orderBy(data.allAgents.data.result,
            (o) => { return o.firstName.toLowerCase(); });
          const agents = _.map(data.allAgents.data.result, buildAgentTarget);
          _.forEach(agents, (agnt) => {
            if (agnt) {
              data.loadTransferTarget.data.result.push(agnt);
            }
          });
        }

        if (!_.find(data.loadTransferTarget.data.result, { transferTargetType: 'Workflow' })) {
          const buildWorkflowTarget = (workflow) => {
            return {
              availableForTransfer: workflow.availableForTransfer,
              transferTargetId: workflow.workflowId,
              transferTargetType: 'Workflow',
              transferTargetDescription: workflow.name,
            };
          };
          data.allWorkflows.data.result = _.orderBy(data.allWorkflows.data.result,
            (o) => { return o.name.toLowerCase(); });
          const workflows = _.map(data.allWorkflows.data.result, buildWorkflowTarget);
          _.forEach(workflows, (workflow) => {
            if (workflow) {
              data.loadTransferTarget.data.result.push(workflow);
            }
          });
        }
        return data.loadTransferTarget.data.result;
      }).then((data, status, headers, config) => {
        $scope.optionsResolved = true;
        data.forEach((value) => {
          // filtering "Extensions" transferTargetType
          if (!_.isNil(value.transferTargetType)
            && value.availableForTransfer
            && !_.includes($scope.transferTypeOptions, value.transferTargetType)
            && value.transferTargetType !== 'Extension'
            && value.transferTargetType !== 'Queue - deprecated') {
            $scope.transferTypeOptions.push(value.transferTargetType);
          }

          if (value.availableForTransfer) {
            if (value.transferTargetType === 'Phone Number') {
              $scope.transferResultPhone.push(value);
            } else if (value.transferTargetType === 'Agent') {
              $scope.transferResultAgent.push(value);
            } else if (value.transferTargetType === 'Workflow') {
              $scope.transferResultWorkflow.push(value);
            }
          }

          if (value.transferTargetType === 'Phone Number' && !$scope.transferPoolPhoneShow) {
            $scope.transferPoolPhoneShow = true;
          }
        });
      }).catch((response) => {
        $rootScope.$broadcast('event.notify', {
          action: 'error',
          tag: response.data.errorCode,
          message: response.data.errorDescription,
          details: response.data.errorDetails,
        });
      });
    }

    $scope.transferTypeChanged = () => {
      if ($scope.showOtherPhoneInput) {
        toggleShowOtherPhone();
      }
      initTransferValues();
    };

    $scope.transferPhoneChanged = () => {
      if ($scope.transferPoolPhone === 'free_text') {
        if (!$scope.showOtherPhoneInput) {
          toggleShowOtherPhone();
        }
        return;
      }

      if ($scope.showOtherPhoneInput) {
        toggleShowOtherPhone();
      }
    };

    $scope.cancel = () => {
      $scope.allowInvalidPhoneNumber = false;
      $scope.invalidNumberPrompt = false;
    };

    $scope.continue = () => {
      if ($scope.setPreference) PhoneService.allowTransferInvalidPhone = true;

      $scope.allowInvalidPhoneNumber = true;

      // Drop the modal
      $scope.invalidNumberPrompt = false;
    };

    $scope.cancelClick = () => {
      if (!$scope.attemptingTransfer) {
        $state.transitionTo('call', $transition$.params(), {
          reload: false,
          inherit: false,
          notify: true,
        });
      } else {
        ToastrService.makeToast({ type: 'info', message: 'Please wait until transfer is complete' });
      }
    };

    $scope.blindTransfer = () => {
      $scope.attemptingTransfer = true;
      let { transferPoolPhone } = $scope;

      if ($scope.transferPoolPhone === 'free_text') {
        transferPoolPhone = $scope.otherPhoneInput;
      }

      const transferDestinationArr = [
        $scope.transferPhoneNumber,
        transferPoolPhone,
        $scope.transferPoolWorkflow,
        $scope.transferPoolAgent,
      ];
      let transferDestination = null;
      transferDestinationArr.find((el) => {
        if (el) {
          transferDestination = el;
        }
        return false;
      });

      $rootScope.$broadcast('event.user', {
        action: 'start.transfer.blind',
        data: {
          activityId: CallService.callInfo.activityId,
          transferType: $scope.transferType,
          transferDestination,
          CallNotes: CallService.callNotes,
        },
      });
      // Start transfer
      transfer({
        transferDestination,
        isAttended: 0,
      }).then((data) => {
        if (data.resultCode === 'success') {
          agentFSM.transferComplete();
          CallService.autoWrapInit();
          $state.transitionTo('call', $transition$.params(), {
            reload: true,
            inherit: false,
            notify: true,
          });
          PhoneService.TRANSIT(1, 'disable');
          $rootScope.$broadcast('event.info', {
            tag: 'transfer.blind.success',
            message: 'Blind transfer successful',
          });
        } else {
          $log.debug(data.errorDescription);
        }
        $scope.attemptingTransfer = false;
      }).catch((response) => {
        $log.info('[Transfer blind] error: ', JSON.stringify(response.data));
      });
    };

    $scope.disableConsultativeTransfer = () => {
      return !_.some(conditionalFields, (f) => {
        return _.get($scope, [f, 'length'], 0) > 0;
      });
    };

    $scope.disableBlindTransfer = $scope.disableConsultativeTransfer;

    $scope.disableAttendedTransfer = $scope.disableConsultativeTransfer;

    $scope.attendedTransfer = () => {
      // Set loading directive
      $rootScope.$broadcast('$viewContentLoading', { loading: true });

      const transferPoolPhone = ($scope.transferPoolPhone === 'free_text')
        ? $scope.otherPhoneInput : $scope.transferPoolPhone;
      let transferDestination = null;
      const transferDestinationArr = [
        $scope.transferPhoneNumber,
        transferPoolPhone,
        $scope.transferPoolWorkflow,
        $scope.transferPoolAgent,
      ];

      for (let i in transferDestinationArr) {
        if (transferDestinationArr[i]) {
          transferDestination = transferDestinationArr[i];
          break;
        }
      }

      // Start transfer
      transfer({
        transferDestination,
        isAttended: 1,
      }).then((data) => {
        $rootScope.$broadcast('event.user', {
          action: 'start.transfer.attended',
          data: {
            activityId: CallService.callInfo.activityId,
            transferType: $scope.transferType,
            transferDestination,
            callNotes: CallService.callNotes,
          },
        });
        $rootScope.$broadcast('$viewContentLoading', { loading: false });
        $state.transitionTo('call', $transition$.params(), {
          reload: false,
          inherit: false,
          notify: true,
        });
        PhoneService.TRANSIT(1, 'hold');
        PhoneService.TRANSIT(2, 'activate');
      }).catch((response) => {
        $log.info('[Transfer attended] error: ', JSON.stringify(response.data));
      });
    };
  }
}
