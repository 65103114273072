import angular from 'angular';
import _ from 'lodash';
import AuthenticationContext from 'adal-angular';

/**
 * @module Controller for page home of public section layout.
 * @class LoginController
 */

export default class LoginController {
  constructor($log, $rootScope, $scope, $state, $http, $injector, IntegrationService, calls,
    $transition$, SocketMan, AgentInfoService, TitleService, AnalyticsService, ApiService,
    FeatureFlagService, ErrorService, AuthService, SocketFactory) {
    'ngInject';

    this.errorService = ErrorService;

    TitleService.title = { newTitle: 'Login' };
    $scope.title = TitleService.title;
    AuthService.clearState();
    $scope.login = { name: null, password: null };
    $scope.displayAdalLoginButton = () => {
      if (_.hasIn(window.IPSCAPE, 'adalConfig')) {
        return localStorage.getItem('useSsoLogin') !== 'true';
      }
      return false;
    };
    $scope.isClicked = false;

    $scope.hasValidationMessages = () => {
      return angular.element('.toast-message').length > 0;
    };

    $scope.disableLogin = () => {
      if (!$scope.login.name || !$scope.login.password) return true;
      return false;
    };

    SocketMan.disconnect();

    const onSuccess = () => {
      AgentInfoService.fetchAll().then(() => {
        calls.apiDetails().then((response) => {
          ApiService.version = response.data.result.currentVersion;
          ApiService.build = response.data.result.buildNumber;
          ApiService.methods = response.data.result.availableMethods;
          if (ApiService.hasMethod('organisation/getenabledfeatureflags')) {
            FeatureFlagService.getFeatureFlags();
          }
        }).catch((error) => {
          Error(error);
        });

        if (IntegrationService.reporting) {
          const id = AgentInfoService.agentId;
          const name = AgentInfoService.agentFullName;
          const email = `${AgentInfoService.agentUserName}@${IntegrationService.apiUrl}`;

          const cfg = {
            payload: {
              person: {
                id,
                username: name,
                email,
              },
              server: {
                host: IntegrationService.apiUrl,
              },
            },
          };
          window.IPSCAPE.Rollbar.configure(cfg);
        }

        SocketFactory.connect();
        $state.transitionTo('connect', $transition$.params(), {
          reload: false,
          inherit: false,
          notify: true,
        });
      }).then(() => {
        try {
          AnalyticsService.heartbeat();
        } catch (error) {
          this.errorService.report(`GA Error: ${IntegrationService.apiUrl}`, { message: error });
        }
      }).catch((err) => {
        this.errorService.report('Login Callback Error: ', { message: err });
      });
    };

    if (_.hasIn(window.IPSCAPE, 'adalConfig')) {
      $rootScope.context = new AuthenticationContext(window.IPSCAPE.adalConfig);

      $scope.adalLoginSubmit = (ev) => {
        ev.preventDefault();
        $injector.get('adalAuthenticationService').login();
      };

      // SSO login success callback
      $scope.$on('adal:loginSuccess', () => {
        AuthService.thirdPartyLogin().then((response) => {
          if (_.hasIn(response, 'data')) {
            if (_.hasIn(response.data, 'resultCode')
              && response.data.resultCode === 'success') {
              localStorage.setItem('useSsoLogin', 'true');
              onSuccess();
            }
          }
        }).catch((error) => {
          ErrorService.handleError('[SSO] Third Party login', error);
        });
      });

      // SSO login fail callback
      $scope.$on('adal:loginFailure', () => {
        $log.info('[SSO] login failed');
      });

      // SSO unauthorised resource callback
      $scope.$on('adal:notAuthorized', (event, rejection, forResource) => {
        $log.info(`[SSO] Not Authorized for resource: ${forResource}`);
      });
    }

    $scope.loginSubmit = () => {
      if ($scope.hasValidationMessages()) {
        return;
      }

      AuthService.login($scope.login.name, $scope.login.password).then((response) => {
        if (response.data.resultCode === 'success') {
          onSuccess();
        } else {
          $scope.isClicked = false;
          ErrorService.handleError('agent login', response);
        }
      });
    };
  }
}
