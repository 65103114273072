import angular from 'angular';
import _ from 'lodash';

const IPSToolbarActions = ($log, agentFSM) => {
  "ngInject";

  class IPToolbarActionsController {

    constructor($log, $rootScope, $scope, $timeout, $uibModal, StatusService, agent, organisations, storagemanager, AuthService) {
      "ngInject";

      let that = this;
      that._scope = $scope;
      that._pauseReasons = [];

      //-------------------------------------------------
      // UI boolean functions
      //-------------------------------------------------

      $scope.isAvailableOrPaused = function() {
        return agent.isOnPhoneAvailable() || agent.isOnPhonePaused();
      };
      $scope.isOnPhoneAvailable = agent.isOnPhoneAvailable;
      $scope.isOnPhonePaused = agent.isOnPhonePaused;
      $scope.isOnPhonePausedWithReason = () => {
        return agent.isOnPhonePaused() && StatusService.hasPauseReason;
      };
      $scope.enforceValidPauseReason = agent.organisationDetails().enforceValidPauseReason;
      $scope.enforcePauseReason = agent.details().enablePauseReason;

      //-------------------------------------------------
      // pause reasons
      //-------------------------------------------------

      that._handleUpdatedPauseReasons = (result) => {
        that._pauseReasons = result;
        if(!$scope.enforceValidPauseReason) {
          that._pauseReasons.unshift({pauseReasonId: 0});
        }
      };

      that._selectedPauseId = () => {
        let pauseReason = _.find(that._pauseReasons, (pr) => _.isEqual(pr.pauseReasonTitle, StatusService.pauseReasonTitle));
        return _.get(pauseReason, 'pauseReasonId');
      };

      that.loadPauseReasons = () => {
        organisations.loadPauseReasons(that._handleUpdatedPauseReasons);
      };

      $scope.pauseReasons = () => {
        return this._pauseReasons;
      };

      $scope.toPauseClick = (pauseReason) => {
        let saveReason = () => {
          StatusService.pauseReasonTitle = pauseReason.pauseReasonTitle;
        };
        agent.pause(pauseReason.pauseReasonId, saveReason);
      };

      $scope.toPauseClickNoReason = () => {
        agent.pause(0, function() {$log.debug('paused no reason');});
      };

      $scope.updatePauseReasons = () => {
        return that._pauseReasons.filter((pr) => !_.isEqual(StatusService.pauseReasonTitle, pr.pauseReasonTitle));
      };

      $scope.toUpdatedPauseClick = (pauseReason) => {
        let saveReason = () => {
          StatusService.pauseReasonTitle = pauseReason.pauseReasonTitle;
        };
        agent.updatePause(pauseReason.pauseReasonId, saveReason);
      };

      that.loadPauseReasons();

      //--------------------------------------------------
      // dialogs
      //--------------------------------------------------

      $scope.unpauseClick = () => {
        let clearPauseReason = () => {
          StatusService.pauseReasonTitle = undefined;
        };
        $scope.runningPromise = agent.unpause(clearPauseReason);
      };

      //--------------------------------------------------
      // logout
      //--------------------------------------------------

      $scope.isLogoutPossible = () => {
        return agentFSM.canLogout();
      };
      $scope.logoutClick = () => {
        $scope.runningPromise = AuthService.logout();
      };
    }
  }

  return {
    restrict: 'EA',
    template: require('./ips.toolbar.actions.html'),
    controller: IPToolbarActionsController,
    controllerAs: 'TBActions',
    bindToController: true
  };
};

export default angular.module('CCAdaptor.App.IPSToolbarActions', [])
  .directive('ipsToolbarActions', IPSToolbarActions).name;
