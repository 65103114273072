import angular from 'angular';
import _ from 'lodash';

class ConnectService {
  constructor($window, $rootScope) {
    'ngInject';

    this.window = $window;
  }

  onReady(callback) {
    if (_.hasIn(this.window, 'ipscape')) {
      this.window.ipscape.connect.onReady(callback);
    }
  }

  disconnect(callback) {
    if (_.hasIn(this.window, 'ipscape')) {
      this.window.ipscape.connect.disconnect(callback);
    }
  }

  reconnect(callback) {
    if (_.hasIn(this.window, 'ipscape')) {
      this.window.ipscape.connect.reconnect(callback);
    }
  }

  sendDTMF(tone, callback) {
    if (_.hasIn(this.window, 'ipscape')) {
      this.window.ipscape.connect.sendDTMF(tone, callback);
    }
  }
}

export default angular.module('CCAdaptor.App.ConnectService', [])
  .service('ConnectService', ConnectService).name;
