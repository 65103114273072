import _ from 'lodash';

/**
 * @module Controller for page connect.
 * @class ConnectController
 */
export default class ConnectController {
  constructor($log, $scope, $rootScope, $state, $transition$, $cookies, AgentInfoService,
    TitleService, ConnectService, ErrorService, agent, phone) {
    'ngInject';

    this.windowObjectReference = null;

    this.getCookie = () => (($cookies.get('allowInvalidPhoneNumber')) ?
      $cookies.get('allowInvalidPhoneNumber') : false);

    this.setCookie = (allow) => {
      $cookies.put('allowInvalidPhoneNumber', allow);
    };

    this.isPersistentConnection = () => {
      if (_.isNil(agent.details().connectionMode) || _.isEmpty(agent.details().remotePhone)) {
        return _.get(agent.details(), 'defaultPersistentConnection', false);
      }

      return !!_.get(agent.details(), 'persistentConnection', _.get(agent.details(), 'defaultPersistentConnection'));
    };

    this.agent = AgentInfoService.details;

    this.openWebConnect = () => new Promise((resolve, reject) => {
      if (_.hasIn(this.agent, 'webrtcConfig')) {
        if (_.hasIn(this.agent.webrtcConfig, 'registrarServer')) {
          if (!this.windowObjectReference || this.windowObjectReference.closed) {
            const configString = encodeURIComponent(window.btoa(
              JSON.stringify(this.agent.webrtcConfig)));
            this.windowObjectReference = window.open(
              `./app/connect/index.html?config=${configString}&autoAnswer=false`,
              'ipscape.webRTC');
          } else {
            this.windowObjectReference.focus();
          }

          if (!this.windowObjectReference) reject();

          ConnectService.onReady((data) => {
            if (_.hasIn(data, 'name')) {
              if (data.name === 'ready') {
                resolve(data);
              } else if (data.name === 'error') {
                reject(data);
              }
            }
          });
        }
      } else {
        reject();
      }
    });

    this.connectPhone = () => {
      try {
        phone.connect(
          $scope.allowInvalidPhoneNumber,
          $scope.agent.connectionMode,
          $scope.agent.countryCode,
          $scope.agent.persistentConnection,
          $scope.agent.remotePhone,
        ).then(() => {
          AgentInfoService.fetchDetails();
          agent.connect();
          $state.transitionTo('toolbar', $transition$.params(), {
            reload: false,
            inherit: false,
            notify: true,
          });
        }).catch((response) => {
          if (response.data.errorCode === 'phoneNumber.invalid') {
            $scope.invalidNumberPrompt = true;
          }
        });
      } catch (error) {
        ErrorService.handleError('Connect Error', error);
      }
    };

    $scope.allowInvalidPhoneNumber = this.getCookie();

    $scope.agent = {
      agentId: this.agent.agentId,
      connectionMode: _.get(this.agent, 'connectionMode', this.agent.defaultConnectionMode),
      connectionMethod: () => {
        if (_.hasIn(this.agent, 'webrtcConfig') && !_.isNil(this.agent.webrtcConfig)) {
          return _.hasIn(this.agent.webrtcConfig, 'registrarServer');
        }
        return false;
      },
      remotePhone: _.get(this.agent, 'remotePhone', this.agent.defaultRemotePhone),
      countryCode: this.agent.countryCode,
      persistentConnection: _.get(this.agent, 'persistentConnection', this.agent.defaultPersistentConnection),
      connectionModeLabel: () => {
        switch (this.agent.connectionMode) {
          case 'agentCallsSystem':
            return 'Agent calls System';
          case 'systemCallsAgent':
            return 'System calls Agent';
          case 'ipscapeWebrtc':
            if ($scope.agent.connectionMethod()) {
              return 'Web Connect';
            } else if (this.agent.defaultConnectionMode === 'systemCallsAgent') {
              return 'System calls Agent';
            }
            return 'Agent calls System';
          default:
            return 'not selected';
        }
      },
    };

    $scope.changed = (start) => {
      if (start) return;
      AgentInfoService.connectionMode = $scope.agent.connectionMode;
      AgentInfoService.connectionPersistence = $scope.agent.persistentConnection;
      AgentInfoService.remotePhone = $scope.agent.remotePhone;
    };

    $scope.cancel = () => {
      $scope.allowInvalidPhoneNumber = false;
      $scope.invalidNumberPrompt = false;
    };

    $scope.continue = () => {
      if ($scope.setPreference) this.setCookie(true);

      $scope.allowInvalidPhoneNumber = true;

      // Drop the modal
      $scope.invalidNumberPrompt = false;
    };

    $scope.connectVoice = () => {
      // if WebRTC we have to open the connector
      if ($scope.agent.connectionMode === 'ipscapeWebrtc') {
        this.openWebConnect().then(() => {
          this.connectPhone();
        }).catch((response) => {
          ErrorService.report(`Web Connect error: ${response.msg}`);
        });
      } else {
        this.connectPhone();
      }
    };
  }
}
